import Webservice from 'utils/Webservice'
import { assureToken } from 'authent365/utils/utils'
import config from 'core/src/config'
import utils from 'directory/utils'

const api = {
  fetchUsers: (userType: UserType, searchBody: EngieUserResearchFormV4) =>
    assureToken(userType).then((token) =>
      Webservice<EngieUserResearchResultsV4>(
        userType,
        `${config.globalAPIVersion}/users/research`,
        'POST',
        {
          Authorization: `Bearer ${token}`,
        },
        undefined,
        searchBody
      )
    ),
  getUsers: (siteId: string, userType: UserType, usersIds: string[]) =>
    assureToken(userType).then((token) =>
      Webservice<EngieUsersV4>(
        userType,
        `${config.globalAPIVersion}/users?engieUserIds=${utils.idsToString(usersIds)}&siteId=${siteId}`,
        'GET',
        {
          Authorization: `Bearer ${token}`,
        }
      )
    ),
  getIdFromEmail: (userType: UserType, userMail: string) =>
    assureToken(userType).then((token) =>
      Webservice<EngieUserIdV4>(userType, `${config.globalAPIVersion}/users/${userMail}/engieUserId`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
}

export default api
