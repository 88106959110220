import React from 'react'
import Loader from 'react-loader-spinner'
import Select from 'react-select'
import { useParams } from 'react-router-dom'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useNavigation from 'utils/navigation/useNavigation'
import useI18n from 'i18n/useI18n'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as cguStore from 'cgu/cguStore'
import * as siteStore from 'sites/store/siteStore'

import { windowSizeBreakpoints } from 'utils/breakpoints'

import Button from 'components/button/Button'
import Icons from 'components/icons/Icons'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import TitleHelmet from 'components/title/TitleHelmet'

import cguApi from 'cgu/api'

type Params = {
  siteId?: string
  userType?: string
}

const CGUPrivacyDetailsScreen = () => {
  const url = window.location.href
  const { siteId, userType } = useParams<Params>()
  const mode = url.split('/')[url.split('/').length - (!!siteId ? 3 : !!userType ? 2 : 1)]
  const privacyType = userType

  const [Theme] = useTheme()
  const [scrollToBottom, setScrollToBottom] = React.useState(false)
  const [status, setStatus] = React.useState<'loading' | 'error' | 'ok'>('loading')
  const user = useReducer(userStore.store, (s) => s.user)
  const cgu = useReducer(cguStore.store, (s) =>
    !!user && (user.id || user.email) ? s.acceptedVersion[(user.id || user.email) as string] : undefined
  )
  const termsAndConditions = useReducer(cguStore.store, (s) => s.termsAndConditions)
  const allSites = useReducer(siteStore.store, (s) => s.allSites)
  const currentSite = useReducer(siteStore.store, (s) => s.site)
  const allPublicSites = useReducer(cguStore.store, (s) => s.termsAndConditions?.siteList || [])
  const badges = useReducer(userStore.store, (s) => s.badges)

  const navigation = useNavigation()
  const i18n = useI18n()

  const options = React.useMemo(
    () => [
      {
        value: 'general',
        label: i18n.t('screens.cgu.generalSite'),
      },
      ...(!!user
        ? allSites.map((site) => ({
            value: site.id,
            label: site.name,
          }))
        : allPublicSites.map((site) => ({
            value: site.siteId,
            label: site.siteName,
          }))),
    ],
    [allSites, allPublicSites]
  )

  const [selectedSite, setSelectedSite] = React.useState(options[0])

  const connected = !!user
  const alreadyAccepted =
    !connected ||
    (!!termsAndConditions &&
      termsAndConditions.generalTermsOfUse &&
      cgu === termsAndConditions.generalTermsOfUse.version)

  const findSiteNotice = React.useMemo(
    () =>
      termsAndConditions && termsAndConditions.siteNotices && selectedSite && selectedSite.value
        ? termsAndConditions.siteNotices.find(
            (site) => site && site.siteIds && site.siteIds.includes(selectedSite.value!)
          )
        : undefined,
    [selectedSite, termsAndConditions]
  )

  const htmlToDisplay = React.useMemo(
    () =>
      termsAndConditions && termsAndConditions.generalTermsOfUse
        ? mode === 'cgu'
          ? termsAndConditions.generalTermsOfUse.termsOfUse
          : findSiteNotice
          ? privacyType === 'internal'
            ? findSiteNotice.internalNotice || termsAndConditions.generalTermsOfUse.internalNotice
            : findSiteNotice.visitorNotice || termsAndConditions.generalTermsOfUse.visitorNotice
          : privacyType === 'internal'
          ? termsAndConditions.generalTermsOfUse.internalNotice
          : termsAndConditions.generalTermsOfUse.visitorNotice
        : undefined,
    [termsAndConditions, user, mode, selectedSite, findSiteNotice]
  )

  React.useEffect(() => {
    fetchCGU()
  }, [i18n.lang])

  React.useEffect(() => {
    // Pré-sélection du site
    if (!!termsAndConditions) {
      if (!!siteId) {
        const findSite = options.find((opt) => opt.value === siteId)
        if (!!findSite) {
          setSelectedSite(findSite)
        } else {
          setSelectedSite(options[0])
        }
      } else if (!user) {
        setSelectedSite(options[0])
      } else {
        if (user.type !== 'EXTERNAL') {
          if (currentSite) {
            const findSite = options.find((opt) => opt.value === currentSite.id)
            if (findSite) {
              setSelectedSite(findSite)
            } else {
              setSelectedSite(options[0])
            }
          } else {
            const findPrincipalBadge = badges.find((badge) => badge.isPrincipal)
            if (findPrincipalBadge) {
              const findSite = options.find((opt) => opt.value === findPrincipalBadge.siteId)
              if (findSite) {
                setSelectedSite(findSite)
              } else {
                setSelectedSite(options[0])
              }
            } else {
              const findSiteWithBadge = badges.length > 0 ? badges[0] : undefined
              if (findSiteWithBadge) {
                const findSite = options.find((opt) => opt.value === findSiteWithBadge.siteId)
                if (findSite) {
                  setSelectedSite(findSite)
                } else {
                  setSelectedSite(options[0])
                }
              } else {
                setSelectedSite(options[0])
              }
            }
          }
        } else {
          if (options.length > 1) {
            setSelectedSite(options[1])
          } else {
            setSelectedSite(options[0])
          }
        }
      }
    }
  }, [termsAndConditions])

  const fetchCGU = () => {
    setStatus('loading')
    cguApi
      .getTermsAndConditions(i18n.lang)
      .then((res) => {
        cguStore.actions.setTermsAndConditions(res)
        setStatus('ok')
      })
      .catch(() => setStatus('error'))
  }

  const handleScroll = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 70
    if (bottom && !scrollToBottom) {
      setScrollToBottom(true)
    }
  }

  return (
    <>
      <TitleHelmet title={i18n.t('screens.cgu.title')} />
      {status === 'loading' ? (
        <LoaderContainer>
          <Loader type="TailSpin" color={Theme.colors.blue} />
        </LoaderContainer>
      ) : status === 'error' || htmlToDisplay === undefined ? (
        <ErrorContainer>
          <ErrorText>{i18n.t('screens.cgu.error')}</ErrorText>
          <ErrorButtonContainer>
            <Button label={i18n.t('common.retry')} onClick={fetchCGU} font={Theme.fonts.h3Bold} />
          </ErrorButtonContainer>
        </ErrorContainer>
      ) : (
        <MainContainer connected={connected} alreadyAccepted={alreadyAccepted}>
          <ContentContainer alreadyAccepted={alreadyAccepted} onScroll={handleScroll}>
            {connected ? (
              <BreadcrumbContainer>
                {alreadyAccepted && <Breadcrumb screen="cgu" path="cgu" navigation={navigation} />}
              </BreadcrumbContainer>
            ) : (
              <GoBackContainer>
                <GoBackIcon
                  onClick={navigation.goBack}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      navigation.goBack()
                    }
                  }}>
                  <Icons name="cross" color={Theme.colors.black} size={35} />
                </GoBackIcon>
              </GoBackContainer>
            )}
            {mode === 'privacy' && (
              <DropDownContainer>
                <Select
                  value={selectedSite}
                  onChange={(siteSelected) => {
                    if (!!siteSelected) {
                      setSelectedSite(siteSelected)
                    }
                  }}
                  options={options}
                  styles={{
                    singleValue: (base, props) => ({
                      ...base,
                      fontSize: 16,
                      fontFamily: 'Lato',
                      fontWeight: 900,
                    }),
                    option: (base, props) => ({
                      ...base,
                      fontSize: 16,
                      fontFamily: 'Lato',
                      fontWeight: 900,
                    }),
                    control: (base, props) => ({
                      ...base,
                      borderColor: 'transparent',
                    }),
                  }}
                />
              </DropDownContainer>
            )}

            {mode === 'privacy' && (
              <DescriptionText>
                {selectedSite && i18n.t('screens.cgu.descriptionTextPart1')}
                {selectedSite && <b> {selectedSite.label}. </b>}
                {i18n.t('screens.cgu.descriptionTextPart3')}
              </DescriptionText>
            )}

            <Content dangerouslySetInnerHTML={{ __html: htmlToDisplay }} connected={connected} />
          </ContentContainer>
          <GoBackButtonContainer>
            <ButtonContainer>
              <Button label={i18n.t('screens.cgu.goBack')} onClick={navigation.goBack} font={Theme.fonts.h3Bold} />
            </ButtonContainer>
          </GoBackButtonContainer>
        </MainContainer>
      )}
    </>
  )
}

export default CGUPrivacyDetailsScreen

// CONTAINERS

const MainContainer = styled('div')<{ connected: boolean; alreadyAccepted?: boolean }>`
  ${(props) =>
    !props.connected &&
    `z-index: 2;
  background-color: ${props.theme.colors.white};
  backdrop-filter: blur(3px);
  padding-left: 20px;
  padding-right: 20px;
  top: 0;
  left: 0;
  right: 0;`}
  overflow: scroll;
  min-height: ${(props) => (props.connected ? 'calc(100vh - 116px)' : '100vh')};
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const ContentContainer = styled('div')<{ alreadyAccepted?: boolean }>`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 80px 0px 30px;

  /* Style scrollbar pour Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    width: 5px;
    background: white;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${(props) => props.theme.colors.scrollBar};
  }

  /* Style scrollbar pour Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.colors.scrollBar} transparent;

  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    padding-right: 30px;
  }
`
const Content = styled('div')<{ connected: boolean }>`
  ${(props) => props.theme.fonts.body};
  text-align: justify;
  color: ${(props) => props.theme.colors.black};
  ${(props) => props.connected && 'margin-bottom: 70px'};
`
const GoBackButtonContainer = styled('div')`
  align-self: flex-end;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};
  padding: 5px 0px 20px 0px;
`
const ButtonContainer = styled('div')`
  display: flex;
  min-width: 400px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    min-width: 200px;
  }
`
const GoBackContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
`
const GoBackIcon = styled('div')`
  cursor: pointer;
  display: flex;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.white};
  }
`

const BreadcrumbContainer = styled('div')`
  margin-left: -70px;
  margin-bottom: 70px;
`

const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`

const ErrorContainer = styled('div')`
  padding: 30px 50px;
  min-height: calc(100vh - 176px);
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 10px 10px;
    min-height: calc(100vh - 136px);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const ErrorButtonContainer = styled('div')`
  display: flex;
  width: 300px;
`

const ErrorText = styled('p')`
  ${(props) => props.theme.fonts.body};
  font-size: 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    text-align: center;
  }
`

const DropDownContainer = styled('div')`
  width: 312px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`

const DescriptionText = styled('p')`
  ${(props) => props.theme.fonts.contentTitleBold};
  color: ${(props) => props.theme.colors.black};
  font-weight: 300;
`
