import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icons from 'components/icons/Icons'

interface Props {
  onClick?: () => void
  selected?: boolean
  iconName?: IconName
  size?: number
  color?: string
}

const DrawerButton = ({ onClick, selected, iconName, size = 25, color }: Props) => {
  const [Theme] = useTheme()
  return (
    <Container onClick={onClick} selected={selected} tabIndex={-1}>
      {!!iconName && <Icons size={size} color={color === undefined ? Theme.colors.blue : color} name={iconName} />}
    </Container>
  )
}

const Container = styled('div')<{ selected?: boolean }>`
  display: flex;
  height: 54px;
  width: 54px;
  align-items: center;
  justify-content: center;
  border-radius: 27px;
  border: 0px;
  outline: 0px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? props.theme.colors.darkGrey : 'transparent')};
  ${(props) => props.selected && 'box-shadow: 0px 2px 11px rgba(53, 58, 61, 0.5); border-radius: 27px;'}
`

export default DrawerButton
