import * as React from 'react'
import styled from 'styled-components'

import Modal from 'components/modal/Modal'

interface Props {
  values: number[]
  selectedValue: number
  setValue: (value: number) => void
  allValues: number[]
  displayedValuesText: string[]
}

const SingleValueWheelPicker = ({ values, selectedValue, setValue, allValues, displayedValuesText }: Props) => {
  const renderValues = (value: number, index: number, valuesList: number[]) => {
    const selectable = values.indexOf(value) > -1 && value !== undefined
    const notLast = valuesList.length !== index + 1 && values.indexOf(value) !== values.length - 1

    return (
      <ValueContainer
        showBorder={notLast && selectable}
        selectable={selectable}
        key={index}
        onClick={() => {
          if (selectable) {
            setValue(value)
          }
        }}>
        <Valuetext selectable={selectable} selected={selectedValue === value}>
          {displayedValuesText[index]}
        </Valuetext>
      </ValueContainer>
    )
  }

  const onWheelFunc = (e: React.WheelEvent, current: number) => {
    const index = values.indexOf(current)
    const valuesMaxId = values.length - 1
    const wheelDelta = e.deltaY
    if (wheelDelta > 0 && index < valuesMaxId) {
      setValue(values[index + 1])
    } else if (wheelDelta < 0 && index > 0) {
      setValue(values[index - 1])
    }
  }

  const onKeyDownFunction = (e: React.KeyboardEvent, current: number) => {
    const index = values.indexOf(current)
    const valuesMaxId = values.length - 1
    if (e.key === 'Enter') {
      Modal.close()
    } else if (e.key === 'ArrowDown' && index < valuesMaxId) {
      setValue(values[index + 1])
    } else if (e.key === 'ArrowUp' && index > 0) {
      setValue(values[index - 1])
    }
  }

  return (
    <MainContainer
      onWheel={(e) => onWheelFunc(e, selectedValue)}
      tabIndex={0}
      onKeyDown={(e) => onKeyDownFunction(e, selectedValue)}>
      <ListContainer index={allValues.indexOf(selectedValue)}>{allValues.map(renderValues)}</ListContainer>
    </MainContainer>
  )
}

const MainContainer = styled('div')`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 50px;
  height: 149px;
  overflow: hidden;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const ListContainer = styled('div')<{ index: number }>`
  display: flex;
  position: absolute;
  flex-direction: column;
  top: ${(props) => 50 - props.index * 50}px;
  transition: top 0.1s linear;
`
const ValueContainer = styled('div')<{ showBorder: boolean; selectable: boolean }>`
  cursor: ${(props) => (props.selectable ? 'pointer' : 'default')};
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.showBorder ? 49 : 50)}px;
  ${(props) => props.showBorder && 'border-bottom: 1px solid'}
`
const Valuetext = styled('p')<{ selected: boolean; selectable: boolean }>`
  ${(props) => props.theme.fonts.bodyBold};
  font-size: 24px;
  margin: 0px;
  color: ${(props) => (props.selected ? props.theme.colors.blue : props.theme.colors.middleGrey)};
  ${(props) => !props.selectable && 'display: none'};

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

export default SingleValueWheelPicker
