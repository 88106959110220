import ws from 'utils/Webservice'
import config from 'core/src/config'
import { fetchPages } from './apiUtils'

import { assureToken } from 'authent365/utils/utils'
import { assureSSOToken } from './ssoToken'
import { getReservationTimes } from './utils'

const api = {
  getToken: (userType: UserType, siteId: string, ssoId: SSOID) =>
    assureToken(userType).then((token) =>
      ws<O365Token>(
        userType,
        `${config.SERVER_PREFIX}${config.globalAPIVersion}/token/sso?ssoTargeted=${ssoId}&siteId=${siteId}`,
        'GET',
        {
          Authorization: `Bearer ${token}`,
        }
      )
    ),
  zapfloor: {
    getUserTokenInfos: (userType: UserType, token: string) =>
      ws<UserInfosTokenResponse>(
        userType,
        `${config.zapfloor.host}/${config.zapfloor.version}/openid/exchange/${config.zapfloor.idEngieClient}`,
        'POST',
        undefined,
        {
          id_token: token,
        }
      ),
    getAllUserTokenInfos: (userType: UserType, token: string) =>
      fetchPages<UserInfosToken>(
        userType,
        `${config.zapfloor.host}/${config.zapfloor.version}/openid/exchange/${config.zapfloor.idEngieClient}/users`,
        'POST',
        undefined,
        undefined,
        undefined,
        {
          id_token: token,
        }
      ),
    getUserInfos: (userType: UserType, siteId: string) =>
      assureSSOToken(userType, siteId, 'ZAPFLOOR').then((token) =>
        ws<UserInfoResponse>(userType, `${config.zapfloor.host}/${config.zapfloor.version}/profile/me`, 'GET', {
          Authorization: `Bearer ${token}`,
        })
      ),
    putUserPreferencesInfo: (userType: UserType, siteId: string, userId: string, is_public_in_desking: boolean) =>
      assureSSOToken(userType, siteId, 'ZAPFLOOR').then((token) =>
        ws<UserInfoResponse>(
          userType,
          `${config.zapfloor.host}/${config.zapfloor.version}/users/${userId}`,
          'PUT',
          {
            Authorization: `Bearer ${token}`,
          },
          undefined,
          {
            user: {
              is_public_in_desking,
            },
          }
        )
      ),
    getUsersSearch: (userType: UserType, siteId: string, search: string) =>
      assureSSOToken(userType, siteId, 'ZAPFLOOR').then((token) =>
        fetchPages<UserResponse>(
          userType,
          `${config.zapfloor.host}/${
            config.zapfloor.version
          }/users?filters[needle]=${search}&filters[is_archived]=${false}`,
          'GET',
          {
            Authorization: `Bearer ${token}`,
          }
        )
      ),
    getUsersList: (userType: UserType, siteId: string, favUsersList: string) =>
      assureSSOToken(userType, siteId, 'ZAPFLOOR').then((token) =>
        fetchPages<UserResponse>(
          userType,
          `${config.zapfloor.host}/${config.zapfloor.version}/users?filters[user_id]=${favUsersList}`,
          'GET',
          {
            Authorization: `Bearer ${token}`,
          }
        )
      ),
    getAccessGroupsInfos: (userType: UserType, siteId: string, userId: string, locationId: string) =>
      assureSSOToken(userType, siteId, 'ZAPFLOOR').then((token) =>
        fetchPages<ZapfloorProfil>(
          userType,
          `${config.zapfloor.host}/${config.zapfloor.version}/users/${userId}/access_groups?filters[location_id]=${locationId}`,
          'GET',
          {
            Authorization: `Bearer ${token}`,
          }
        )
      ),
    getAvailableProfils: (
      userType: UserType,
      siteId: string,
      userId: string,
      date: string,
      locationId: string,
      startTime: string,
      endTime: string,
      profilId?: string
    ) =>
      assureSSOToken(userType, siteId, 'ZAPFLOOR').then((token) =>
        fetchPages<ZapfloorProfil>(
          userType,
          `${config.zapfloor.host}/${
            config.zapfloor.version
          }/workspace/access_groups/availability?filters[location_id]=${locationId}&user_id=${userId}&date=${date}&time_start=${startTime}&time_end=${endTime}${
            profilId ? `&filters[access_group_id]=${profilId}` : ''
          }`,
          'GET',
          {
            Authorization: `Bearer ${token}`,
          }
        )
      ),
    getAvailableZones: (
      userType: UserType,
      siteId: string,
      date: string,
      profilId: string,
      locationId: string,
      startTime: string,
      endTime: string,
      userId?: string,
      zoneId?: string
    ) =>
      assureSSOToken(userType, siteId, 'ZAPFLOOR').then((token) =>
        fetchPages<ZapfloorZone>(
          userType,
          `${config.zapfloor.host}/${
            config.zapfloor.version
          }/workspace/access_groups/unit_availability?filters[location_id]=${locationId}${
            !!userId ? `&user_id=${userId}` : ''
          }&date=${date}&filters[access_group_id]=${profilId}${
            !!zoneId ? `&filters[unit_id]=${zoneId}` : ''
          }&time_start=${startTime}&time_end=${endTime}`,
          'GET',
          {
            Authorization: `Bearer ${token}`,
          }
        )
      ),
    getHotDeskAvailable: (
      userType: UserType,
      siteId: string,
      date: string,
      locationId: string,
      slot: ReservationTime,
      profilId: string,
      userId: string,
      zoneId: string
    ) => {
      const { startTime, endTime } = getReservationTimes(slot)
      return assureSSOToken(userType, siteId, 'ZAPFLOOR').then((token) =>
        fetchPages<HotDeskAvailability>(
          userType,
          `${config.zapfloor.host}/${config.zapfloor.version}/workspace/access_groups/hot_desks_availability?filters[location_id]=${locationId}&user_id=${userId}&date=${date}&filters[access_group_id]=${profilId}&time_start=${startTime}&time_end=${endTime}&filters[unit_id]=${zoneId}`,
          'GET',
          { Authorization: `Bearer ${token}` }
        )
      )
    },
    bookHotDesk: (userType: UserType, siteId: string, body: BodyBookingHotDesking) =>
      assureSSOToken(userType, siteId, 'ZAPFLOOR').then((token) =>
        ws<ZapfloorBookingResult>(
          userType,
          `${config.zapfloor.host}/${config.zapfloor.version}/hot_desking_reservations`,
          'POST',
          {
            Authorization: `Bearer ${token}`,
          },
          undefined,
          body
        )
      ),
    getBookingsList: (userType: UserType, siteId: string, userId: string, dateFrom: string, locationId: string) =>
      assureSSOToken(userType, siteId, 'ZAPFLOOR').then((token) =>
        fetchPages<ZapfloorBooking>(
          userType,
          `${config.zapfloor.host}/${config.zapfloor.version}/hot_desking_reservations?filters[location_id]=${locationId}&filters[booked_for_user_id]=${userId}&filters[date_from_gteq]=${dateFrom}`,
          'GET',
          {
            Authorization: `Bearer ${token}`,
          }
        )
      ),
    deleteBooking: (userType: UserType, siteId: string, bookingId: string) =>
      assureSSOToken(userType, siteId, 'ZAPFLOOR').then((token) =>
        ws<void>(
          userType,
          `${config.zapfloor.host}/${config.zapfloor.version}/hot_desking_reservations/${bookingId}`,
          'DELETE',
          {
            Authorization: `Bearer ${token}`,
          }
        )
      ),
    pushToken: (userType: UserType, siteId: string, userId: string, notificationToken: string | null) =>
      assureSSOToken(userType, siteId, 'ZAPFLOOR').then((token) =>
        ws<void>(
          userType,
          `${config.zapfloor.host}/${config.zapfloor.version}/users/${userId}`,
          'PUT',
          {
            Authorization: `Bearer ${token}`,
          },
          {
            user: {
              notification_token: notificationToken,
            },
          }
        )
      ),
    getNotificationsInfos: (userType: UserType, siteId: string) =>
      assureSSOToken(userType, siteId, 'ZAPFLOOR').then((token) =>
        ws<ZapfloorNotifResponse>(
          userType,
          `${config.zapfloor.host}/${config.zapfloor.version}/notifications/types`,
          'GET',
          {
            Authorization: `Bearer ${token}`,
          }
        )
      ),
    updateNotification: (userType: UserType, siteId: string, typeId: string, body: ZapfloorNotifBody) =>
      assureSSOToken(userType, siteId, 'ZAPFLOOR').then((token) =>
        ws<void>(
          userType,
          `${config.zapfloor.host}/${config.zapfloor.version}/notifications/types/${typeId}`,
          'PUT',
          {
            Authorization: `Bearer ${token}`,
          },
          undefined,
          body
        )
      ),
  },
  zapfloorEngie: {
    postVisitorBooking: (siteId: string, userType: UserType, request: ZapfloorBookingV6) =>
      assureToken(userType).then((token) =>
        ws<ZapfloorBookingV6>(
          userType,
          `${config.globalAPIVersion}/zapfloor-bookings?siteId=${siteId}`,
          'POST',
          {
            Authorization: `Bearer ${token}`,
          },
          undefined,
          request
        )
      ),
    removeVisitorBooking: (zapfloorId: string, userType: UserType, siteId: string) =>
      assureToken(userType).then((token) =>
        ws<ZapfloorBookingV6>(
          userType,
          `${config.globalAPIVersion}/zapfloor-bookings/${zapfloorId}?siteId=${siteId}`,
          'DELETE',
          {
            Authorization: `Bearer ${token}`,
          }
        )
      ),
  },
  barclap: {
    getBookings: (userType: UserType, siteId: string, siteBarclapId: string, startDate: string, endDate: string) =>
      assureSSOToken(userType, siteId, 'BARCLAP').then((token) =>
        ws<BarclapBookingList>(
          userType,
          `${config.barclap.host}/common/account/my-bookings/${siteBarclapId}/${startDate}/${endDate}/100/0`,
          'GET',
          {
            accept: 'application/json',
            'x-auth-token': token,
          }
        )
      ),
    getUserSites: (userType: UserType, siteId: string) =>
      assureSSOToken(userType, siteId, 'BARCLAP').then((token) =>
        ws<BarclapUserSiteList>(userType, `${config.barclap.host}/s4d9/common/user-sites`, 'GET', {
          accept: 'application/json',
          'x-auth-token': token,
        })
      ),
    getRestaurantBookings: (
      userType: UserType,
      siteId: string,
      siteBarclapId: string,
      restaurantBarclapId: string,
      startDate: string,
      endDate: string
    ) =>
      assureSSOToken(userType, siteId, 'BARCLAP').then((token) =>
        ws<BarclapModuleBookingList>(
          userType,
          `${config.barclap.host}/s4d9/common/my-bookings/${siteBarclapId}/${restaurantBarclapId}/${startDate}/${endDate}/100/0`,
          'GET',
          {
            accept: 'application/json',
            'x-auth-token': token,
          }
        )
      ),
    getRestaurantDatas: (
      userType: UserType,
      siteId: string,
      siteBarclapId: string,
      restaurantBarclapId: string,
      date: string
    ) =>
      assureSSOToken(userType, siteId, 'BARCLAP').then((token) =>
        ws<BarclapModuleDatas>(
          userType,
          `${config.barclap.host}/s4d9/common/module-datas/${siteBarclapId}/${restaurantBarclapId}/${date}`,
          'GET',
          {
            accept: 'application/json',
            'x-auth-token': token,
          }
        )
      ),
    postBooking: (userType: UserType, siteId: string, body: BarclapBodyBooking) =>
      assureSSOToken(userType, siteId, 'BARCLAP').then((token) =>
        ws<BarclapPostBookingResponse>(
          userType,
          `${config.barclap.host}/s4d9/booking`,
          'POST',
          {
            accept: 'application/json',
            'x-auth-token': token,
          },
          undefined,
          body
        )
      ),
    modifyNumberOfGuestsInBooking: (userType: UserType, siteId: string, body: BarclapBodyModifyNumberOfGuestBooking) =>
      assureSSOToken(userType, siteId, 'BARCLAP').then((token) =>
        ws<void>(
          userType,
          `${config.barclap.host}/s4d9/booking/nb_guest`,
          'PATCH',
          {
            accept: 'application/json',
            'x-auth-token': token,
          },
          undefined,
          body
        )
      ),
    deleteBooking: (
      userType: UserType,
      siteId: string,
      siteBarclapId: string,
      restaurantBarclapId: string,
      bookingId: string
    ) =>
      assureSSOToken(userType, siteId, 'BARCLAP').then((token) =>
        ws<void>(
          userType,
          `${config.barclap.host}/s4d9/booking/delete/${siteBarclapId}/${restaurantBarclapId}/${bookingId}`,
          'DELETE',
          {
            accept: 'application/json',
            'x-auth-token': token,
          }
        )
      ),
    getSiteModules: (userType: UserType, siteId: string) =>
      assureSSOToken(userType, siteId, 'BARCLAP').then((token) =>
        ws<BarclapSiteModules[]>(userType, `${config.barclap.host}/s4d9/common/user-sites`, 'GET', {
          accept: 'application/json',
          'x-auth-token': token,
        })
      ),
    getModuleInfos: (
      moduleType: BarclapModuleType,
      userType: UserType,
      siteId: string,
      moduleId: string,
      siteBarclapId: string,
      date: string
    ) =>
      assureSSOToken(userType, siteId, 'BARCLAP').then((token) =>
        ws<BarclapModuleDatas>(
          userType,
          `${config.barclap.host}/${moduleType}/common/module-datas/${siteBarclapId}/${moduleId}/${date}`,
          'GET',
          {
            accept: 'application/json',
            'x-auth-token': token,
          }
        )
      ),
    getModuleTables: (
      userType: UserType,
      siteId: string,
      moduleId: string,
      siteBarclapId: string,
      date: string,
      nbGuest: number,
      timeFrom: string,
      timeTo: string
    ) =>
      assureSSOToken(userType, siteId, 'BARCLAP').then((token) =>
        ws<BarclapModuleTable[]>(
          userType,
          `${config.barclap.host}/s4d1/common/tables/${siteBarclapId}/${moduleId}/${date}/${nbGuest}?start_time=${timeFrom}&end_time=${timeTo}`,
          'GET',
          {
            accept: 'application/json',
            'x-auth-token': token,
          }
        )
      ),
    getModuleBookings: (
      userType: UserType,
      siteId: string,
      siteBarclapId: string,
      moduleId: string,
      startDate: string,
      endDate: string
    ) =>
      assureSSOToken(userType, siteId, 'BARCLAP').then((token) =>
        ws<BarclapModuleBookingList>(
          userType,
          `${config.barclap.host}/s4d1/common/my-bookings/${siteBarclapId}/${moduleId}/${startDate}/${endDate}/100/0`,
          'GET',
          {
            accept: 'application/json',
            'x-auth-token': token,
          }
        )
      ),
    postModuleBooking: (userType: UserType, siteId: string, body: BarclapBodyBookingModule) =>
      assureSSOToken(userType, siteId, 'BARCLAP').then((token) =>
        ws<BarclapPostBookingResponse>(
          userType,
          `${config.barclap.host}/s4d1/booking`,
          'POST',
          {
            accept: 'application/json',
            'x-auth-token': token,
          },
          undefined,
          body
        )
      ),
  },
  vamos: {
    getDataUserVamos: (userType: UserType, siteId: string) =>
      assureToken(userType).then((token) =>
        ws<VamosDataUserV6>(
          userType,
          `${config.SERVER_PREFIX}${config.globalAPIVersion}/vamos/users?siteId=${siteId}`,
          'GET',
          {
            Authorization: `Bearer ${token}`,
          }
        )
      ),
    getVamosBookings: (userType: UserType, siteId: string) =>
      assureToken(userType).then((token) =>
        ws<VamosBookingsList>(
          userType,
          `${config.SERVER_PREFIX}${config.globalAPIVersion}/vamos/bookings?siteId=${siteId}`,
          'GET',
          {
            Authorization: `Bearer ${token}`,
          }
        )
      ),
    postVamosBooking: (userType: UserType, siteId: string, body: VamosBookingRequestV6) =>
      assureToken(userType).then((token) =>
        ws<VamosBookingV6>(
          userType,
          `${config.SERVER_PREFIX}${config.globalAPIVersion}/vamos/bookings?siteId=${siteId}`,
          'POST',
          {
            Authorization: `Bearer ${token}`,
          },
          undefined,
          body
        )
      ),
    deleteVamosBookings: (userType: UserType, siteId: string, bookingId: string) =>
      assureToken(userType).then((token) =>
        ws<void>(
          userType,
          `${config.SERVER_PREFIX}${config.globalAPIVersion}/vamos/bookings?bookingParkingLotId=${bookingId}&siteId=${siteId}`,
          'DELETE',
          {
            Authorization: `Bearer ${token}`,
          }
        )
      ),
    vamosAccess: (userType: UserType, siteId: string, body: VamosAccessRequestV6) =>
      assureToken(userType).then((token) =>
        ws<void>(
          userType,
          `${config.SERVER_PREFIX}${config.globalAPIVersion}/vamos/access?siteId=${siteId}`,
          'POST',
          {
            Authorization: `Bearer ${token}`,
          },
          undefined,
          body
        )
      ),
  },
  sharvy: {
    getUserInfos: (userType: UserType, siteId: string) =>
      assureToken(userType).then((token) =>
        ws<SharvyDataUserV6>(
          userType,
          `${config.SERVER_PREFIX}${config.globalAPIVersion}/sharvy/me?siteId=${siteId}`,
          'GET',
          {
            Authorization: `Bearer ${token}`,
          }
        )
      ),
    postSharvyBookings: (userType: UserType, siteId: string, body: SharvyBookingsFormV6) =>
      assureToken(userType).then((token) =>
        ws<SharvyBookingResponseV6>(
          userType,
          `${config.SERVER_PREFIX}${config.globalAPIVersion}/sharvy/bookings?siteId=${siteId}`,
          'POST',
          {
            Authorization: `Bearer ${token}`,
          },
          undefined,
          body
        )
      ),
    deleteSharvyBookings: (userType: UserType, siteId: string, body: SharvyDeleteBookingsFormV6) =>
      assureToken(userType).then((token) =>
        ws<void>(
          userType,
          `${config.SERVER_PREFIX}${config.globalAPIVersion}/sharvy/bookings?siteId=${siteId}`,
          'DELETE',
          {
            Authorization: `Bearer ${token}`,
          },
          undefined,
          body
        )
      ),
  },
}

export default api
