const MIN_CHARACTERS = 3

const utils = {
  searchToWords: (search: string) =>
    search
      .split(/[ #&]/)
      .filter((w) => w !== '' && w.length >= MIN_CHARACTERS)
      .map((s) => s.replace(/'/g, "''")),
  formatNames: (word: string) =>
    word.toLowerCase().replace(/(?:^|\s|['`‘’.-])[^\x00-\x60^\x7B-\xDF](?!(\s|$))/g, (s) => s.toUpperCase()),
  capitalize: (word: string) => (word.length > 0 ? word[0].toLocaleUpperCase() + word.slice(1) : ''),
  getYMDFromDate: (date: Date) =>
    new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0],
  getTextFromHTML: (content: string) =>
    content
      .replace(/<(?:.|\n)*?>/gm, '')
      .replace(/^[\s]+|\n/gm, '')
      .replace(/&#8217;/gm, "'")
      .replace(/&nbsp;/gm, ' '),
}

export default utils
