import Webservice from 'utils/Webservice'
import { assureToken } from 'authent365/utils/utils'
import config from 'core/src/config'

const api = {
  postBoxIdea: (boxIdeaForm: FormData, siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<void>(
        userType,
        `${config.globalAPIVersion}/box-idea?siteId=${siteId}`,
        'POST',
        {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data;',
        },
        undefined,
        boxIdeaForm
      )
    ),
}

export default api
