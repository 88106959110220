import * as React from 'react'

import styled from 'theme/styled-components'

interface Props {
  label?: string
  color: string
  backgroundColor: string
  highlight?: boolean
  totem?: boolean
}

const Tag = ({ label, color, backgroundColor, highlight, totem }: Props) => {
  if (!label) {
    return null
  }
  return (
    <Container color={backgroundColor} highlight={highlight} totem={totem}>
      <Label color={color} highlight={highlight} totem={totem}>
        {label}
      </Label>
    </Container>
  )
}
const Container = styled('div')<{ color: string; highlight?: boolean; totem?: boolean }>`
  ${(props) => !props.highlight && `background-color: ${props.color};`}
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.totem ? 65 : 50)}px;
  height: ${(props) => (props.totem ? 26 : 22)}px;
  ${(props) =>
    props.highlight &&
    `
  width: ${props.totem ? 130 : 110}px;
  height: ${props.theme ? 26 : 22}px;`}
`
const Label = styled('p')<{ color: string; highlight?: boolean; totem?: boolean }>`
  ${(props) =>
    props.highlight
      ? props.totem
        ? props.theme.fonts.bodyBold
        : props.theme.fonts.labelBold
      : props.totem
      ? props.theme.fonts.body
      : props.theme.fonts.label};
  color: ${(props) => (props.highlight ? props.theme.colors.blue : props.color)};
  ${(props) => props.totem && props.highlight && 'font-weight: 700;'}
`
export default Tag
