import React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import OccupationComponent from './OccupationTotemComponent'
import Map from 'map/Map'
import Icons from 'components/icons/Icons'

import api from '../api/restaurantsApi'

import Logger from 'utils/Logger'
import useNavigation from 'utils/navigation/useNavigation'

import useI18n from 'i18n/useI18n'
import { useLocation } from 'react-router-dom'

const REFRESH_INTERVAL = 30000

const TotemScreen = () => {
  const i18n = useI18n()
  const navigation = useNavigation()
  const [Theme] = useTheme()
  const location = useLocation()
  const [restaurants, setRestaurants] = React.useState<TotemRestaurant[]>([])

  const seats = restaurants.flatMap((r) => r.seats).filter((seat) => !!seat) as Seat[]

  const query = new URLSearchParams(location.search)
  const { restaurantsId, siteId, uniqueName } = {
    siteId: query.get('siteId'),
    uniqueName: query.get('uniqueName'),
    restaurantsId: query.get('restaurants')?.split(','),
  }

  React.useEffect(() => {
    fetchRestaurants()
    const interval = setInterval(fetchRestaurants, REFRESH_INTERVAL)
    return () => clearInterval(interval)
  }, [])

  const fetchRestaurants = () => {
    if (!!restaurantsId && !!siteId && restaurantsId.length > 0) {
      Promise.all(restaurantsId.map((id: string) => api.getTotemRestaurant(id, siteId)))
        .then((res: TotemRestaurant[]) => setRestaurants(res))
        .catch(Logger.error)
    }
  }

  const renderItem = (restaurant: TotemRestaurant, index: number) => (
    <ItemContainer>
      <Title>{restaurant.name}</Title>
      {(!!restaurant.currentCapacity || restaurant.currentCapacity === 0) &&
        restaurant.currentCapacity !== -1 &&
        !!restaurant.maxCapacity && (
          <OccupationContainer>
            <IconUserContainer>
              <Icons name="users-wm" size={34} color={Theme.colors.blue} />
            </IconUserContainer>
            <OccupationText>
              {i18n.t('screens.catering.occupationLight', {
                currentCapacity: restaurant.currentCapacity,
                maxCapacity: restaurant.maxCapacity,
              })}
            </OccupationText>
            <OccupationComponent
              currentCapacity={restaurant.currentCapacity}
              maxCapacity={restaurant.maxCapacity}
              key={restaurant.id}
            />
          </OccupationContainer>
        )}
      <OpenTime>
        {i18n.t('screens.catering.openingTimes.cafeteria', {
          time1: restaurant.openingTime,
          time2: restaurant.closingTime,
        })}
      </OpenTime>
      {!!restaurants && index !== restaurants.length - 1 && <Separator />}
    </ItemContainer>
  )

  return (
    <MainContainer>
      <TopBarContainer>
        <Icons name="engie" color={Theme.colors.white} size={79} height={60} />
        <Icons name="logo" color={Theme.colors.white} size={79} height={60} />
      </TopBarContainer>
      {!!restaurants && restaurants.length > 0 && !!restaurants[0].webVisioglobeHash && !!uniqueName && (
        <ContentContainer>
          <LeftContainer>{restaurants.map((restaurant, index) => renderItem(restaurant, index))}</LeftContainer>
          <RightContainer>
            <Map
              hash={restaurants[0].webVisioglobeHash}
              areaUniqueName={uniqueName}
              navigation={navigation}
              isModalFromZapfloor
              seats={seats}
            />
          </RightContainer>
        </ContentContainer>
      )}
    </MainContainer>
  )
}

export default TotemScreen

const MainContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
`

const LeftContainer = styled('div')`
  width: 25%;
  display: flex;
  flex-direction: column;
  margin: 60px 80px;
`

const RightContainer = styled('div')`
  width: 75%;
  display: flex;
  flex: 1;
  align-items: center;
`

const ItemContainer = styled('div')`
  display: flex;
  flex-direction: column;
`

const OccupationContainer = styled('div')`
  display: flex;
  flex-directin: row;
  align-items: center;
  padding: 16px 0px;
`

const IconUserContainer = styled('div')`
  justify-content: center;
`
const Separator = styled('div')`
  width: 50px;
  height: 3px;
  background-color: ${(props) => props.theme.colors.blue};
  margin: 40px 0px;
`
const Title = styled('p')`
  ${(props) => props.theme.fonts.totemBig}
  margin: 0px;
`

const OpenTime = styled('p')`
  ${(props) => props.theme.fonts.totemMiddle}
  margin: 0px;
`
const OccupationText = styled('p')`
  ${(props) => props.theme.fonts.totemLight};
  margin: 0px;
  padding: 0px 10px;
`
const TopBarContainer = styled('div')`
  display: flex;
  flex-direction: row;
  padding: 12px 100px;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.colors.blue};
`
const ContentContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
`
