import * as React from 'react'
import styled from 'styled-components'

import { windowSizeBreakpoints } from 'utils/breakpoints'

interface Props {
  placeHolderURL?: string
  onClick?: () => void
  imageURL?: string
  focus?: boolean
  height?: number
  width?: number
  animation?: boolean
  alt?: string
}

const NewsImage = ({
  placeHolderURL = '',
  onClick,
  imageURL,
  focus = true,
  height = 310,
  width = 500,
  animation = true,
  alt,
}: Props) => {
  const [imageError, setImageError] = React.useState(false)

  const source = React.useMemo(() => (imageError || !imageURL ? placeHolderURL : imageURL), [imageURL, imageError])

  return (
    <Photo
      alt={alt || ''}
      onError={() => setImageError(true)}
      src={source}
      onClick={onClick}
      onKeyPress={(evt) => {
        if ((evt.key === 'Enter' || evt.key === ' ') && !!onClick) {
          onClick()
        }
      }}
      focus={focus}
      height={height}
      width={width}
      animation={animation}
      aria-hidden={!alt}
    />
  )
}

const Photo = styled('img')<{ height: number; width: number; focus: boolean; animation: boolean }>`
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  object-fit: cover;
  user-select: none;

  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 100%;
  }

  :hover {
    transform: scale(1.02);
  }
`

export default NewsImage
