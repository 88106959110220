import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import { blobToB64, executeGraphRequest } from './utils'
import Logger from 'utils/Logger'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as pictureStore from './pictureStore'

type Size = 'large' | 'small' | 'smaller'

interface Props {
  userId?: string
  userFirstName?: string
  userLastName?: string
  userDisplayName?: string
  customValue?: string
  size: Size
  color?: string
  textColor?: string
  picture?: string
  noWS?: boolean
}

const AzureImage = ({
  size,
  color,
  textColor,
  userId,
  userFirstName,
  userLastName,
  userDisplayName,
  customValue,
  picture,
  noWS,
}: Props) => {
  const [Theme] = useTheme()
  const storedUser = useReducer(userStore.store, (s) => s.user)
  const hasPicturesList = useReducer(pictureStore.store, (p) => p.hasPictureList)
  const userDoesntHavePicture = React.useMemo(
    () => userId && hasPicturesList && userId in hasPicturesList && hasPicturesList[userId] === false,
    [hasPicturesList, userId]
  )
  const displayedName = !userFirstName && !!userDisplayName ? userDisplayName.split(' ') : ''

  const [userPicture, setUserPicture] = React.useState<string>()

  React.useEffect(() => {
    if (!noWS && !userDoesntHavePicture && userId && storedUser && storedUser.type !== 'EXTERNAL') {
      executeGraphRequest<Blob>(
        storedUser.type,
        `/users/${userId}/photos/${size === 'large' ? '96x96' : '48x48'}/$value`,
        'GET',
        undefined,
        'blob'
      )
        .then(blobToB64)
        .then(setUserPicture)
        .catch((err) => {
          Logger.error(err)
          pictureStore.actions.setHasPicture({ [userId]: false })
        })
    }
  }, [])

  React.useEffect(() => {
    if (!!picture) {
      setUserPicture(picture)
    }
  }, [picture])

  return (
    <Holder size={size} color={color || Theme.colors.darkGrey} hasImage={!!userPicture}>
      {!!userPicture ? (
        <Img size={size} src={userPicture} alt="" aria-hidden />
      ) : (
        <HolderText size={size} color={textColor || Theme.colors.white} aria-hidden>
          {!!userFirstName && userFirstName.length >= 1 && !!userLastName && userLastName.length >= 1
            ? `${userFirstName[0]}${userLastName[0]}`
            : !!displayedName && displayedName.length >= 2
            ? `${displayedName[0][0]}${displayedName[1][0]}`
            : customValue || ''}
        </HolderText>
      )}
    </Holder>
  )
}

export default AzureImage

const Img = styled('img')<{ size: Size }>`
  height: ${(props) =>
    props.size === 'large'
      ? props.theme.constants.userPicture.large.size
      : props.size === 'small'
      ? props.theme.constants.userPicture.small.size
      : props.theme.constants.userPicture.smaller.size}px;
  width: ${(props) =>
    props.size === 'large'
      ? props.theme.constants.userPicture.large.size
      : props.size === 'small'
      ? props.theme.constants.userPicture.small.size
      : props.theme.constants.userPicture.smaller.size}px;
  border-radius: ${(props) =>
    props.size === 'large'
      ? props.theme.constants.userPicture.large.borderRadius
      : props.size === 'small'
      ? props.theme.constants.userPicture.small.borderRadius
      : props.theme.constants.userPicture.smaller.borderRadius}px;
`

const Holder = styled('div')<{ size: Size; color: string; hasImage: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) =>
    props.size === 'large'
      ? props.theme.constants.userPicture.large.size
      : props.size === 'small'
      ? props.theme.constants.userPicture.small.size
      : props.theme.constants.userPicture.smaller.size}px;
  width: ${(props) =>
    props.size === 'large'
      ? props.theme.constants.userPicture.large.size
      : props.size === 'small'
      ? props.theme.constants.userPicture.small.size
      : props.theme.constants.userPicture.smaller.size}px;
  border-radius: ${(props) =>
    props.size === 'large'
      ? props.theme.constants.userPicture.large.borderRadius
      : props.size === 'small'
      ? props.theme.constants.userPicture.small.borderRadius
      : props.theme.constants.userPicture.smaller.borderRadius}px;
  background-color: ${(props) => (props.hasImage ? 'transparent' : props.color)};
`

const HolderText = styled('p')<{ size: Size; color: string }>`
  ${(props) =>
    props.size === 'large'
      ? props.theme.fonts.bodyBold
      : props.size === 'small'
      ? props.theme.fonts.h3Bold
      : props.theme.fonts.subtitleBold};
  ${(props) => props.size === 'large' && 'font-size: 48px'};
  color: ${(props) => props.color};
  margin: 0px;
  margin-top: ${(props) => (props.size === 'large' ? 5 : 1)}px;
  text-transform: uppercase;
`
