import React from 'react'

import styled from 'theme/styled-components'

const ToastContent = (title: string, color: string) => (
  <ToastContainer color={color}>
    <ModalTitle>{title}</ModalTitle>
  </ToastContainer>
)

export default ToastContent

const ToastContainer = styled('div')<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  width: 170px;
  background-color: ${(props) => props.color};
`
const ModalTitle = styled('h1')`
  ${(props) => props.theme.fonts.label};
  font-size: 14px;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
`
