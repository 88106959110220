import * as React from 'react'
import styled from 'styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'
import Logger from 'utils/Logger'
import { windowSizeBreakpoints } from 'utils/breakpoints'

import graphApi from 'roombooking/api/graphRoomApi'
import { DirectoryAttendee, Attendee } from 'roombooking/types'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'

import Button from 'components/button/Button'
import AttendeeInfos from './AttendeeInfos'
import ValidationContent from 'components/modal/ValidationContent'
import Alert from 'components/alert/Alert'
import ToastAlert from 'components/toast/ToastAlert'
import Directory from 'directory/DirectoryScreen'
import Icons from 'components/icons/Icons'
import Modal from 'components/modal/Modal'

import Loader from 'react-loader-spinner'

type Status = 'ok' | 'loading' | 'error'

interface Props {
  attendees: Attendee[]
  roomAttendee: Attendee
  reservationId: string
  refreshBookings: () => void
  navigation: Navigation
}

const ReservationAttendees = ({ attendees, roomAttendee, reservationId, refreshBookings, navigation }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const user = useReducer(userStore.store, (s) => s.user)
  const currentSite = useReducer(siteStore.store, (s) => s.site)

  const [customList, setCustomList] = React.useState<Attendee[]>(attendees)
  const [directoryUsers, setDirectoryUsers] = React.useState<DirectoryAttendee[]>([])

  const [status, setStatus] = React.useState<Status>('ok')

  const directoryAttendees = React.useMemo(
    () =>
      customList
        .filter((a) => a.emailAddress.address.toLowerCase() !== user?.email?.toLowerCase())
        .reduce((acc, cur) => {
          const index = directoryUsers.map((u) => u.mail.toLowerCase()).indexOf(cur.emailAddress.address.toLowerCase())
          if (index > -1) {
            acc.push({ ...directoryUsers[index], type: 'ENGIE' })
          } else {
            const names = cur.emailAddress.name.toLowerCase().split(' ')
            acc.push({
              id: cur.emailAddress.address.toLowerCase(),
              givenName: names[0],
              surname: names[1],
              mail: cur.emailAddress.address.toLowerCase(),
              type: 'EXTERNAL',
            } as DirectoryAttendee)
          }
          return acc
        }, [] as DirectoryAttendee[]),
    [directoryUsers]
  )

  React.useEffect(() => {
    refreshList()
  }, [customList])

  const refreshList = () => {
    //Loader
    if (user) {
      graphApi
        .getUsersByMail(
          user.type,
          customList.map((a) => a.emailAddress.address.toLowerCase())
        )
        .then(setDirectoryUsers)
        .catch(Logger.error)
    }
  }

  const customRemove = (mail: string) => {
    if (user) {
      setStatus('loading')
      const updatedList = [
        roomAttendee,
        ...customList.filter((a) => a.emailAddress.address.toLowerCase() !== mail.toLowerCase()),
      ]
      graphApi
        .updateAttendees(user.type, reservationId, updatedList)
        .then((res) => {
          setCustomList(
            res.attendees.filter(
              (a) => a.emailAddress.address.toLowerCase() !== roomAttendee.emailAddress.address.toLowerCase()
            )
          )
          refreshList()
          refreshBookings()
        })
        .catch(Logger.error)
        .finally(() => setStatus('ok'))
    }
  }

  const openDirectory = () => {
    Alert.open(() => (
      <Directory
        title={i18n.t(`screens.room.attendees.addingAttendees${currentSite?.id === '4' ? 'OrResource' : ''}`)}
        addVisitorMode
        onSelectContact={addingAttendees}
        modalType="alert"
        searchAriaLabel={i18n.t('label.ariaLabel.room.searchParticipant')}
      />
    ))
  }

  const addingAttendees = (contact: UserData, external?: boolean) => {
    if (directoryAttendees.find((p) => p.id === contact.graphId) || user?.id === contact.graphId) {
      ToastAlert.open(() => (
        <ValidationContent
          title={i18n.t(`screens.room.attendees.alreadyParticipating${currentSite?.id === '4' ? 'WithResource' : ''}`)}
          onConfirm={ToastAlert.close}
          ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
        />
      ))
    } else if (!contact.email || contact.email === '') {
      ToastAlert.open(() => (
        <ValidationContent
          title={i18n.t('screens.directory.noEmail')}
          onConfirm={ToastAlert.close}
          ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
        />
      ))
    } else {
      if (user) {
        setStatus('loading')
        graphApi
          .updateAttendees(user.type, reservationId, [
            roomAttendee,
            ...customList,
            {
              type: 'required',
              emailAddress: { name: contact.firstname + ' ' + contact.lastname, address: contact.email },
            },
          ])
          .then((res) => {
            setCustomList(
              res.attendees.filter(
                (a) => a.emailAddress.address.toLowerCase() !== roomAttendee.emailAddress.address.toLowerCase()
              )
            )
            refreshList()
            refreshBookings()
          })
          .catch(Logger.error)
          .finally(() => setStatus('ok'))
      }

      Alert.close()
    }
  }

  return (
    <MainContainer>
      <CrossContainer>
        <IconContainer onClick={Modal.close} aria-label={i18n.t('label.ariaLabel.windowCrossIcon')}>
          <Icons name="cross" color={Theme.colors.darkGrey} size={25} />
        </IconContainer>
      </CrossContainer>
      <Title>{i18n.t(`screens.room.attendees.listAttendees${currentSite?.id === '4' ? 'AndResources' : ''}`)}</Title>
      <NumberOfAttendees>
        {i18n.t(`screens.room.attendees.numberAttendees${currentSite?.id === '4' ? 'AndResources' : ''}`, {
          count: directoryAttendees.length,
        })}
      </NumberOfAttendees>

      {status === 'loading' ? (
        <LoaderContainer>
          <Loader type="TailSpin" color={Theme.colors.blue} />
        </LoaderContainer>
      ) : (
        <div>
          {directoryAttendees.map((a, i, list) => (
            <AttendeeInfos
              attendee={a}
              isLast={list.length === i + 1}
              key={a.mail}
              removeAttendeeFunction={customRemove}
              registration
              navigation={navigation}
              myReservationMode
            />
          ))}
        </div>
      )}

      <ButtonCenterContainer attendeesEmpty={directoryAttendees.length === 1}>
        <ButtonContainer>
          <Button
            label={i18n.t(`screens.room.attendees.addAttendees${currentSite?.id === '4' ? 'OrResource' : ''}`)}
            color={Theme.colors.blue}
            verticalPadding={14}
            font={Theme.fonts.h3Bold}
            onClick={openDirectory}
          />
        </ButtonContainer>
      </ButtonCenterContainer>
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 360px;
  padding: 40px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 20px;
    width: calc(100vw - 70px);
  }
`
const ButtonContainer = styled('div')`
  display: flex;
  width: 100%;
  margin: 0px 24px;
`
const ButtonCenterContainer = styled('div')<{ attendeesEmpty: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: ${(props) => (props.attendeesEmpty ? 20 : 5)}px;
`
const CrossContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
const IconContainer = styled('button')`
  display: flex;
  align-items: center;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0px;
`

// TEXTES

const Title = styled('p')`
  ${(props) => props.theme.fonts.contentTitle};
  color: ${(props) => props.theme.colors.darkGrey};
  font-size: 24px;
  margin: 0px 0px 20px 0px;
`
const NumberOfAttendees = styled('h4')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
  margin: 0px 0px 6px 0px;
  padding: 6.5px 0px 6.5px 24px;
  border-top: 0.5px solid ${(props) => props.theme.colors.mediumDarkGrey};
  border-bottom: 1px solid ${(props) => props.theme.colors.mediumDarkGrey};
`

export default ReservationAttendees
