import * as React from 'react'
import styled from 'styled-components'

import api from 'directory/api'
import { DirectoryAttendee } from 'roombooking/types'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'

import Logger from 'utils/Logger'

interface Props {
  attendee: DirectoryAttendee
}

const AttendeePicture = ({ attendee }: Props) => {
  const [contactPicture, setContactPicture] = React.useState('')
  const userType = useReducer(userStore.store, (s) => s.user?.type || 'ENGIE')

  React.useEffect(() => {
    if (attendee.id) {
      api.getUserPhoto(userType, attendee.id).then(setContactPicture).catch(Logger.error)
    }
  }, [])

  return (
    <PictureContainer>
      {!!contactPicture ? (
        <Picture src={contactPicture} />
      ) : attendee.givenName && attendee.surname ? (
        <ContactLetters>
          {attendee.givenName[0]}
          {attendee.surname[0]}
        </ContactLetters>
      ) : (
        <ContactLetters>{attendee.mail[0]}</ContactLetters>
      )}
    </PictureContainer>
  )
}

// CONTAINERS

const PictureContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
  border-radius: 28px;
  background-color: ${(props) => props.theme.colors.mediumDarkGrey};
`

// TEXTES

const ContactLetters = styled('p')`
  ${(props) => props.theme.fonts.subtitleBold};
  color: ${(props) => props.theme.colors.darken};
  text-transform: uppercase;
  margin-left: 1.5px;
`

// AUTRES

const Picture = styled('img')`
  width: 33px;
  height: 33px;
  border-radius: 28px;
`

export default AttendeePicture
