import * as React from 'react'
import styled from 'theme/styled-components'

import useI18n from 'i18n/useI18n'

import { windowSizeBreakpoints } from 'utils/breakpoints'
import useBreakpoint from 'utils/useBreakpoints'

interface Props {
  screen: string
  details?: string
  path: string // Pour quand le nom du screen ne correspond pas au url path
  whiteBackground?: boolean
  navigation: Navigation
}

const Breadcrumb = ({ screen, details, path, whiteBackground = false, navigation }: Props) => {
  const i18n = useI18n()

  const [, currentBreakpoint] = useBreakpoint()

  const onEnterKey = (e: React.KeyboardEvent, path: string) => {
    if (e.key === 'Enter') {
      navigation.push(path)
    }
  }

  return (
    <>
      {currentBreakpoint !== 'phone' && (
        <MainContainer whiteBackground={whiteBackground}>
          {screen !== 'home' ? (
            <NavigationText
              clickable
              onClick={() => navigation.push('/')}
              tabIndex={0}
              onKeyDown={(e) => onEnterKey(e, '/')}>
              {i18n.t('screens.home.title')}
            </NavigationText>
          ) : (
            <NavigationText>{i18n.t('screens.home.title')}</NavigationText>
          )}

          {screen !== 'home' && (
            <>
              <ChevronText>{' > '}</ChevronText>
              {details !== undefined ? (
                <NavigationText
                  clickable
                  onClick={() => navigation.push(`/${path}`)}
                  tabIndex={0}
                  onKeyDown={(e) => onEnterKey(e, `/${path}`)}>
                  {i18n.t(`screens.${screen}.title`)}
                </NavigationText>
              ) : (
                <NavigationText isLast>{i18n.t(`screens.${screen}.title`)}</NavigationText>
              )}
            </>
          )}

          {details !== undefined && (
            <>
              <ChevronText>{' > '}</ChevronText>
              <NavigationText isLast>{details}</NavigationText>
            </>
          )}
        </MainContainer>
      )}
    </>
  )
}

export default Breadcrumb

// CONTAINERS

const MainContainer = styled('nav')<{ whiteBackground: boolean }>`
  ${(props) => props.theme.fonts.body};
  display: flex;
  align-items: center;
  margin: ${(props) => (props.whiteBackground ? '25px 0px 0px 71px' : '30px 0px 0px 76px')};
  ${(props) => props.whiteBackground && `background-color: ${props.theme.colors.white}; padding: 5px;`};
  @media only screen and (max-width: ${windowSizeBreakpoints.laptop}px) {
    margin-left: 76px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    margin-left: 56px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    margin-left: 16px;
  }
`

// TEXTES

const NavigationText = styled('h3')<{ clickable?: boolean; isLast?: boolean }>`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => (props.isLast ? props.theme.colors.blue : props.theme.colors.darkGrey)};
  margin: 0px;
  ${(props) =>
    props.clickable &&
    `cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  :focus-visible {
    outline: 2px solid ${props.theme.colors.darkBlue};
  }`};
`
const ChevronText = styled('h3')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px 5px;
`
