import { Configuration, PublicClientApplication, AccountInfo } from '@azure/msal-browser'
import { AUTH_TYPE } from 'utils/const'
import conf from 'core/src/config'
import axios from 'axios'

import Logger from 'utils/Logger'

import * as store from 'store/store'
import * as SiteStore from 'sites/store/siteStore'
import * as UserStore from 'store/user/user'

const GRAPH_PREFIX = conf.GRAPH_PREFIX

const config: Configuration = {
  auth: {
    clientId: conf.azureEngie.clientId,
    authority: conf.azureEngie.authority,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  system: {
    loadFrameTimeout: 3000,
  },
}

const resources = {
  graph: conf.azureEngie.additionalScopes,
  server: [conf.azureEngie.serverAPI],
  client: [`${conf.azureEngie.clientId}`],
}
type Resources = keyof typeof resources

export const instance = new PublicClientApplication(config)

instance?.handleRedirectPromise()

const fullClear = () => {
  store.reset()
  UserStore.actions.resetUser()
  SiteStore.actions.resetSite()
  new Array(localStorage.length).fill(0).forEach((x, idx) => {
    const key = localStorage.key(idx)
    if (key && !key.startsWith('persist')) {
      localStorage.removeItem(key)
      sessionStorage.removeItem(key)
    }
  })
}

const getActiveAccount = async (): Promise<AccountInfo | undefined> => {
  if (!instance) {
    throw new Error()
  }
  for (let i = 0; i < 10; i++) {
    if (instance.getAllAccounts()[0]) {
      return instance.getAllAccounts()[0]
    }
    await new Promise((resolve) => setTimeout(resolve, 200))
  }
  return
}

let isFetchingToken = false

const MSALWebAuth: MSAL = {
  login: (resource: Resources = 'server') => {
    if (!instance) {
      throw new Error()
    }

    instance.loginRedirect({
      scopes: resources[resource],
      prompt: 'select_account',
      redirectUri: conf.azureEngie.redirectUri,
    })
  },
  assureToken: async (resource: Resources = 'server') => {
    if (!instance) {
      throw new Error()
    }

    // -----
    // Le code suivant fait en sorte d'attendre la fin du refresh token avant d'éxecuter les suivants, afin que les suivants prennent dans le cache
    if (isFetchingToken) {
      while (isFetchingToken) {
        await new Promise((res) => setTimeout(res, 20))
      }
    } else {
      isFetchingToken = true
    }
    // -----

    return getActiveAccount().then((account) =>
      instance
        .acquireTokenSilent({ scopes: resources[resource], account, redirectUri: conf.azureEngie.redirectUri })
        .then((tokens) => tokens.accessToken)
        .catch((err) => {
          if (err.errorCode !== 'no_account_error') {
            return instance
              .acquireTokenRedirect({
                scopes: resources[resource],
                account,
                redirectUri: conf.azureEngie.redirectUri,
              })
              .then(() => MSALWebAuth.assureToken())
              .catch((err) => {
                fullClear()
                throw err
              })
          } else {
            fullClear()
          }

          throw err
        })
        .finally(() => {
          isFetchingToken = false
        })
    )
  },
  me: () =>
    MSALWebAuth.assureToken('graph').then((token) =>
      Promise.all([
        execute<AzureMe>(
          '/me?$select=id,displayName,mail,mobilePhone,businessPhones,jobTitle,userPrincipalName,surname,givenName,companyName',
          token,
          'GET'
        ),
        execute<DistributionGroupGraph>(
          `/me/transitiveMemberOf?$select=id,displayName&$filter=startswith(displayName,'DL-GBS-ROOM')&$count=true`,
          token,
          'GET'
        ),
      ])
        .then(([infos, groups]) => {
          const user: UserSelf = {
            id: infos.id,
            email: infos.mail,
            displayName: infos.displayName,
            jobTitle: infos.jobTitle,
            company: infos.companyName,
            lastName: infos.surname,
            firstName: infos.givenName,
            photoUrl: '/me/photos/120x120/$value',
            type: 'ENGIE',
            mobilePhone: infos.mobilePhone,
            businessPhones: infos.businessPhones,
            distributionGroups: groups.value.map((g) => g.displayName),
          }
          return user
        })
        .catch((err) => {
          Logger.error(err)
          MSALWebAuth.logout()
          throw new Error("Can't fetch user info from Azure")
        })
    ),
  logout: () => {
    if (!instance) {
      fullClear()
      throw new Error()
    }

    if (localStorage.getItem(AUTH_TYPE) === 'AAD') {
      fullClear()
      return Promise.resolve()
    }

    fullClear()
    return Promise.resolve()
  },
  executeGraphRequest: (url, method, body, responseType, timezone) =>
    MSALWebAuth.assureToken('graph').then((token) => execute<any>(url, token, method, body, responseType, timezone)),
}

export default MSALWebAuth

const execute = <T>(
  url: string,
  token: string,
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  body?: any,
  responseType?: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream',
  timezone?: string
): Promise<T> => {
  const headers: { [key: string]: string } = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
    ConsistencyLevel: 'eventual',
    Prefer: `outlook.timezone="${timezone || 'Europe/Paris'}"`,
  }

  return axios
    .request<T>({
      url: GRAPH_PREFIX + url,
      method: method,
      headers,
      data: body ? JSON.stringify(body) : undefined,
      responseType,
    })
    .then((res) => {
      if (res.status >= 400) {
        throw new Error('Unable to query graph ' + url + ' : ' + res.status + ' / ' + res.statusText)
      }

      return res.data
    })
}
