import { getTime, isSameDay } from 'date-fns'
import { I18n } from 'i18n/types'

export const sortNews = (articles: NewsSimpleV6[]) => {
  return articles.sort((a, b) => {
    if (a.type === 'EVENT' && b.type === 'EVENT') {
      const aDuration =
        !a.startTime || !a.endTime
          ? getTime(new Date(a.endDate || a.publicationDate)) - getTime(new Date(a.startDate || a.publicationDate))
          : getTime(new Date(a.endTime)) - getTime(new Date(a.startTime))
      const bDuration =
        !b.startTime || !b.endTime
          ? getTime(new Date(b.endDate || b.publicationDate)) - getTime(new Date(b.startDate || b.publicationDate))
          : getTime(new Date(b.endTime)) - getTime(new Date(b.startTime))
      return bDuration - aDuration
    }
    return getTime(new Date(b.publicationDate)) - getTime(new Date(a.publicationDate))
  })
}

export const showedDate = (i18n: I18n, date1: Date, date2: Date) => {
  if (isSameDay(date1, date2)) {
    return i18n.t('screens.news.dateSameDay', { date: date1 })
  }
  if (date1.getFullYear() === date2.getFullYear()) {
    return i18n.t('screens.news.dateSameYear', {
      start: date1,
      end: date2,
      year: date1,
    })
  }
}
