import Webservice from 'utils/Webservice'
import { assureToken } from 'authent365/utils/utils'
import config from 'core/src/config'

const MAX_SEARCH_RESULT = 100

const api = {
  getAllAreas: (siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<AreaListV4>(userType, `${config.globalAPIVersion}/areas?siteId=${siteId}`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  fetchAreaReferentiel: (siteId: string, areaId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<AreaV4>(userType, `${config.globalAPIVersion}/areas/${areaId}?siteId=${siteId}`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  fetchAreaReferentielWithUniqueName: (siteId: string, areaUniqueName: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<RoomResearchResultV4>(
        userType,
        `${config.globalAPIVersion}/areas?siteId=${siteId}&uniqueName=${areaUniqueName}`,
        'GET',
        {
          Authorization: `Bearer ${token}`,
        }
      )
    ),
  searchPlaces: (
    searchBody: AreaResearchFormV4,
    siteId: string,
    userType: UserType,
    pageNumber: number,
    pageSize: number,
    buildingName?: string
  ) =>
    assureToken(userType).then((token) =>
      Webservice<AreaResearchResultV4>(
        userType,
        `${config.globalAPIVersion}/areas?siteId=${siteId}${
          !!buildingName ? `&buildingName=${buildingName}` : ''
        }&pageNumber=${pageNumber}&pageSize=${pageSize}`,
        'POST',
        {
          Authorization: `Bearer ${token}`,
        },
        undefined,
        searchBody
      )
    ),
  getAllSearchedPlaces: (query: string, siteId: string, userType: UserType, floor?: number, buildingName?: string) =>
    api.searchPlaces({ query, floor }, siteId, userType, 0, MAX_SEARCH_RESULT, buildingName).then((res) => {
      const numberOfPages = !!res.areas && res.areas.length > 0 ? res.totalNumberOfPage : undefined
      if (!!numberOfPages && numberOfPages > 1) {
        return Promise.all(
          Array.from(Array(numberOfPages - 2).keys()).map((pageNumber) =>
            api.searchPlaces({ query, floor }, siteId, userType, pageNumber, MAX_SEARCH_RESULT, buildingName)
          )
        ).then((results) => {
          return results.reduce(
            (acc, curr) => ({
              areas: acc.areas ? (curr.areas ? [...acc.areas, ...curr.areas] : acc.areas) : curr.areas ?? [],
            }),
            { areas: res.areas ?? [] }
          )
        })
      } else {
        return res
      }
    }),
  getAllStatus: (siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<AreaStatusV4>(userType, `${config.globalAPIVersion}/areas/status?siteId=${siteId}`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
}

export default api
