import * as React from 'react'
import styled from 'theme/styled-components'

interface Props {
  value: string
  onChange: (val: string) => void
  size: number
}

const CodeInput = ({ value, onChange, size }: Props) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [pos, setPos] = React.useState(0)
  const [focused, setFocused] = React.useState(true)

  const onKey = (evt: KeyboardEvent) => {
    if (evt.code === 'Backspace' && value.length > 0) {
      // Remove on backspace
      onChange(value.slice(0, value.length - 1))
      setPos(value.length - 1)
    } else if (evt.key.match(/[0-9]/) && pos < size) {
      // change char at pos
      onTextChange(value.slice(0, pos) + evt.key + value.slice(pos + 1, value.length))
    }
  }

  const onPaste = (e: ClipboardEvent) => {
    if (e.clipboardData) {
      const data = e.clipboardData.getData('text').slice(0, size)
      if (!isNaN(Number(data))) {
        onChange(data)
        setPos(data.length)
      }
    }
  }

  React.useEffect(() => {
    if (focused) {
      // use keylistener, onChange is not always fired
      document.addEventListener('keydown', onKey)
      return () => document.removeEventListener('keydown', onKey)
    }
  }, [pos, value, focused])

  React.useEffect(() => {
    if (inputRef.current) {
      // focus on mount
      inputRef.current.focus()
    }
    document.addEventListener('paste', onPaste)

    return () => document.removeEventListener('paste', onPaste)
  }, [inputRef])

  const onTextChange = (val: string) => {
    onChange(val)
    const nextPos = pos + 1
    if (val[nextPos]) {
      onItemPress(nextPos)
    } else {
      setPos(nextPos)
    }
  }

  const onItemPress = (idx: number) => {
    setPos(Math.min(idx, value.length))
    if (inputRef.current) {
      inputRef.current.focus()
      inputRef.current.setSelectionRange(idx, idx + 1)
    }
  }

  const renderItem = (z: string, idx: number) => {
    const char = value[idx]
    const active = focused && pos === idx

    return (
      <Item key={idx} complete={!!char} sel={active} onClick={() => onItemPress(idx)}>
        {!!char && <ItemChar>{char}​</ItemChar>}​
      </Item>
    )
  }

  return (
    <Container>
      <Input
        ref={inputRef}
        value={value}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        maxLength={size}
      />
      {new Array(size).fill(0).map(renderItem)}​
    </Container>
  )
}
export default CodeInput

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  position: relative;
`
const Input = styled('input')`
  height: 0px;
  width: 0px;
  background-color: transparent;
  border: none;
  outline: none;
`
const Item = styled('div')<{ complete: boolean; sel: boolean }>`
  height: ${(props) => (props.complete ? 102 : 100)}px;
  width: 84px;
  border-bottom: ${(props) => (props.complete ? 'transparent' : `2px solid white`)};
  margin: 0px 6.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: text;
  /* SELECTED */
  ${(props) => (props.sel ? `background: ${props.theme.colors.transparentWhite}` : 'background: transparent')}
`
const ItemChar = styled('div')`
  ${(props) => props.theme.fonts.validationCodeNumber}
  color: ${(props) => props.theme.colors.white};
  user-select: none;
`
