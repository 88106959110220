import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './index.css'

import { setStorage } from 'store/store'

window.addEventListener('CookiebotOnConsentReady', (e: any) => {
  const consents = e.currentTarget.Cookiebot.consent

  import('../../shared/utils/analytics').then((analytics) => analytics.handleTracking(consents.statistics))

  if (consents.preferences === true) {
    setStorage('local')
  } else {
    setStorage('session')
  }
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
