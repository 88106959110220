import { necessaryStores, preferencesStores } from './store'

export type Storage = {
  name: 'local' | 'session'
  setItem: (key: string, value: string) => Promise<void>
  getItem: (key: string) => Promise<string | null>
  removeItem: (key: string) => Promise<void>
  listKeys: (onlyPreferences?: boolean) => string[]
}
export const LocalStorage: Storage = {
  name: 'local',
  setItem: (key: string, value: string): Promise<void> => Promise.resolve(localStorage.setItem(key, value)),
  getItem: (key: string): Promise<string | null> => Promise.resolve(localStorage.getItem(key)),
  removeItem: (key: string): Promise<void> => Promise.resolve(localStorage.removeItem(key)),
  listKeys: (onlyPreferences?: boolean) =>
    onlyPreferences
      ? preferencesStores.map((s) => `persist:${s.shortName}`)
      : !!(window as any).Cookiebot?.consent?.preferences
      ? [...necessaryStores, ...preferencesStores].map((s) => `persist:${s.shortName}`)
      : necessaryStores.map((s) => `persist:${s.shortName}`),
}

export const SessionStorage: Storage = {
  name: 'session',
  setItem: (key: string, value: string): Promise<void> => Promise.resolve(sessionStorage.setItem(key, value)),
  getItem: (key: string): Promise<string | null> => Promise.resolve(sessionStorage.getItem(key)),
  removeItem: (key: string): Promise<void> => Promise.resolve(sessionStorage.removeItem(key)),
  listKeys: (onlyPreferences?: boolean) =>
    onlyPreferences
      ? preferencesStores.map((s) => `persist:${s.shortName}`)
      : !!(window as any).Cookiebot?.consent?.preferences
      ? []
      : preferencesStores.map((s) => `persist:${s.shortName}`),
}
