import * as React from 'react'
import styled from 'theme/styled-components'

interface CategoryProps {
  name: CategoryTransportIconName
  size?: number
  height?: number
  ariaLabel?: string
  alt?: string
}

interface LineProps {
  name: LineTransportIconName
  size?: number
  height?: number
  ariaLabel?: string
  alt?: string
}

const categoryTransportIcons: { [key in CategoryTransportIconName]: string } = {
  LYON_BUS: require('./LYON/BUS/logo.svg').default,
  LYON_METRO: require('./LYON/METRO/logo.svg').default,
  LYON_VELO: require('./LYON/BIKES/velov.svg').default,
  LYON_TRAMWAY: require('./LYON/TRAMWAY/logo.svg').default,
  LYON_RER: require('./LYON/TRAMWAY/logo.svg').default,
  LYON_TRAIN: require('./LYON/TRAMWAY/logo.svg').default,

  PARIS_BUS: require('./PARIS/BUS/logo.svg').default,
  PARIS_METRO: require('./PARIS/METRO/logo.svg').default,
  PARIS_RER: require('./PARIS/RER/logo.svg').default,
  PARIS_TRAIN: require('./PARIS/TRANSILIEN/logo.svg').default,
  PARIS_TRAMWAY: require('./PARIS/TRAMWAY/logo.svg').default,
  PARIS_VELO: require('./PARIS/BIKES/velib.svg').default,

  BRUSSELS_BUS: require('./BRUSSELS/BUS/busBrussels.svg').default,
  BRUSSELS_METRO: require('./BRUSSELS/METRO/metroBrussels.svg').default,
  BRUSSELS_RER: require('./BRUSSELS/TRAIN/train.svg').default,
  BRUSSELS_TRAIN: require('./BRUSSELS/TRAIN/train.svg').default,
  BRUSSELS_TRAMWAY: require('./BRUSSELS/TRAM/tramBrussels.svg').default,
  BRUSSELS_VELO: require('./LYON/TRAMWAY/logo.svg').default, // NON EXISTANT

  MELBOURNE_BUS: require('./MELBOURNE/BUS/logo.svg').default,
  MELBOURNE_METRO: require('./MELBOURNE/METRO/logo.svg').default,
  MELBOURNE_VLINE: require('./MELBOURNE/VLINE/logo.svg').default,
  MELBOURNE_TRAIN: require('./MELBOURNE/TRAIN/logo.svg').default,
  MELBOURNE_TRAMWAY: require('./MELBOURNE/TRAMWAY/logo.svg').default,

  // Common
  PARKING: require('./COMMON/BIKES/parking.svg').default,
  VELO: require('./COMMON/BIKES/bike.svg').default,
}

const linesIcons: { [key in LineTransportIconName]: string } = {
  // Lyon
  LYON_302A: require('./LYON/METRO/lineB.svg').default,
  LYON_34A: require('./LYON/BUS/line34.svg').default,
  LYON_60A: require('./LYON/BUS/line60.svg').default,
  LYON_64A: require('./LYON/BUS/line64.svg').default,
  LYON_T1A: require('./LYON/TRAMWAY/line1.svg').default,
  LYON_T6: require('./LYON/TRAMWAY/line6.svg').default,

  // Lines Paris
  PARIS_METRO_1: require('./PARIS/METRO/1.svg').default,
  PARIS_TRAM_2: require('./PARIS/TRAMWAY/2.svg').default,
  PARIS_TRAIN_L: require('./PARIS/TRANSILIEN/L.svg').default,
  PARIS_TRAIN_U: require('./PARIS/TRANSILIEN/U.svg').default,
  PARIS_RER_A: require('./PARIS/RER/A.svg').default,
  PARIS_RER_E: require('./PARIS/RER/E.svg').default,
  PARIS_TRAIN_J: require('./PARIS/RER/J.svg').default,
  PARIS_BUS_140: require('./PARIS/BUS/140.svg').default,
  PARIS_BUS_176: require('./PARIS/BUS/176.svg').default,
  PARIS_BUS_276: require('./PARIS/BUS/276.svg').default,
  PARIS_BUS_340: require('./PARIS/BUS/340.svg').default,
  PARIS_BUS_366: require('./PARIS/BUS/366.svg').default,

  // Line Brussels
  BRUSSELS_BUS_14: require('./BRUSSELS/BUS/bus14.svg').default,
  BRUSSELS_BUS_57: require('./BRUSSELS/BUS/bus57.svg').default,
  BRUSSELS_BUS_58: require('./BRUSSELS/BUS/bus58.svg').default,
  BRUSSELS_BUS_61: require('./BRUSSELS/BUS/bus61.svg').default,
  BRUSSELS_BUS_88: require('./BRUSSELS/BUS/bus88.svg').default,
  BRUSSELS_METRO_2: require('./BRUSSELS/METRO/metro2.svg').default,
  BRUSSELS_METRO_6: require('./BRUSSELS/METRO/metro6.svg').default,
  BRUSSELS_TRAIN_BRUSSELS_MIDI: require('./BRUSSELS/TRAIN/sncb.svg').default,
  BRUSSELS_TRAIN_BRUSSELS_NORD: require('./BRUSSELS/TRAIN/sncb.svg').default,
  BRUSSELS_TRAM_25: require('./BRUSSELS/TRAM/tram25.svg').default,
  BRUSSELS_TRAM_3: require('./BRUSSELS/TRAM/tram3.svg').default,
  BRUSSELS_TRAM_4: require('./BRUSSELS/TRAM/tram4.svg').default,
  BRUSSELS_TRAM_55: require('./BRUSSELS/TRAM/tram55.svg').default,
  BRUSSELS_BUS_EXPRESS_126: require('./BRUSSELS/BUS/bus126.svg').default,
  BRUSSELS_BUS_EXPRESS_212: require('./BRUSSELS/BUS/bus212.svg').default,
  BRUSSELS_BUS_EXPRESS_410: require('./BRUSSELS/BUS/bus410.svg').default,
  BRUSSELS_BUS_EXPRESS_460: require('./BRUSSELS/BUS/bus460.svg').default,
}

const CategoryIcon = ({ name, size = 20, height, ariaLabel, alt }: CategoryProps) => {
  const iconSrc = categoryTransportIcons[name]

  if (!!iconSrc) {
    return (
      <ImageSize
        src={iconSrc}
        size={size}
        height={height}
        alt={alt || ''}
        aria-hidden={!ariaLabel}
        role={!!ariaLabel ? 'img' : undefined}
        aria-label={ariaLabel || undefined}
      />
    )
  }
  return null
}

const LineIcon = ({ name, size = 20, height, ariaLabel, alt }: LineProps) => {
  const iconSrc = linesIcons[name]

  if (!!iconSrc) {
    return (
      <ImageSize
        src={iconSrc}
        size={size}
        height={height}
        alt={alt || ''}
        aria-hidden={!ariaLabel}
        role={!!ariaLabel ? 'img' : undefined}
        aria-label={ariaLabel || undefined}
      />
    )
  }
  return null
}

const ImageSize = styled('img')<{ size: number; height: undefined | number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.height || props.size}px;
`

export { CategoryIcon, LineIcon }
