import * as React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'

import { windowSizeBreakpoints } from 'utils/breakpoints'
import useBreakpoint from 'utils/useBreakpoints'
import Logger from 'utils/Logger'
import { isAfter, addHours } from 'date-fns'
import analytics, { analyticsKeys, externalLinkTracking } from 'utils/analytics'

import api from '../api/restaurantsApi'

import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'
import useReducer from 'store/useReducer'
import useNavigation from 'utils/navigation/useNavigation'

import Shortcut from 'components/button/Shortcut'
import Menu from './MenuScreen'
import RestaurantImage from '../RestaurantImage'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import TitleHelmet from 'components/title/TitleHelmet'
import BarclapScreen from 'planning/barclap/BarclapScreen'
import Modal from 'components/modal/Modal'
import Icons from 'components/icons/Icons'
import OccupationComponent from './OccupationComponent'

import Loader from 'react-loader-spinner'

interface Props {
  id: string
  barclap?: string
}

type Status = 'loading' | 'error' | 'ok'

const RestaurantsScreen = ({ id, barclap }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const navigation = useNavigation()
  const site = useReducer(siteStore.store, (s) => s.site)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)

  const user = useReducer(userStore.store, (s) => s.user)

  const now = new Date()

  const [restaurant, setRestaurant] = React.useState<RestaurantV6>()
  const [barclapRestaurants, setBarclapRestaurants] = React.useState<RestaurantV6[]>([])
  const [status, setStatus] = React.useState<Status>('ok')

  const [, currentBreakpoint] = useBreakpoint()

  const openingDate = restaurant
    ? new Date(i18n.t('screens.catering.openingTimes.dateFormat', { date: now }) + 'T' + restaurant.openingTime)
    : new Date()
  const closingDate = restaurant
    ? new Date(i18n.t('screens.catering.openingTimes.dateFormat', { date: now }) + 'T' + restaurant.closingTime)
    : new Date()

  const isClosed = restaurant ? isAfter(now, addHours(new Date(closingDate), 2)) : false

  React.useEffect(() => {
    if (user && site) {
      setStatus('loading')
      api
        .getRestaurant(user.type, id, site.id)
        .then((restaurant) => {
          setRestaurant(restaurant)
          if (restaurant.barclapId) {
            api
              .getAllRestaurants(user.type, site.id)
              .then((res) => {
                setBarclapRestaurants(res.restaurants.filter((r) => r.barclapId))
                setStatus('ok')
              })
              .catch(() => setStatus('ok'))
          } else {
            setStatus('ok')
          }
        })
        .catch((err) => {
          Logger.error(err)
          setStatus('error')
        })

      if (site) {
        analytics.track({
          screenName: analyticsKeys.restaurantDetails,
          userType: user.type,
          currentSite: site,
          mySites,
        })
      }
    }
  }, [])

  React.useEffect(() => {
    if (!!barclap && !!id && barclapRestaurants.length > 0) {
      Modal.open(() => (
        <BarclapScreen closeModal={Modal.close} restaurants={barclapRestaurants} bookingTab="FORM" restaurantId={id} />
      ))
    }
  }, [barclap, id, barclapRestaurants])

  if (status === 'loading') {
    return (
      <LoaderContainer>
        <Loader type="TailSpin" color={Theme.colors.blue} />
      </LoaderContainer>
    )
  }

  if (status === 'error') {
    return <ErrorMessage>{i18n.t('screens.catering.restaurantError')}</ErrorMessage>
  }

  return (
    <MainContainer>
      <TitleHelmet title={restaurant?.name || i18n.t('screens.cartography.title')} />
      {currentBreakpoint !== 'phone' && (
        <Breadcrumb screen="catering" path="catering" details={restaurant?.name} navigation={navigation} />
      )}

      {restaurant && (
        <RestaurantInfoContainer>
          <LeftContainer>
            <ImageContainer>
              <RestaurantImage imageURL={restaurant.photo} />
            </ImageContainer>
            <InfoContainer>
              <Name>{restaurant.name}</Name>
              {(!!restaurant.currentCapacity || restaurant.currentCapacity === 0) &&
                restaurant.currentCapacity !== -1 &&
                !!restaurant.maxCapacity && (
                  <OccupationContainer>
                    <IconUserContainer>
                      <Icons name="users-wm" size={16} color={Theme.colors.blue} />
                    </IconUserContainer>
                    <OccupationText>
                      {i18n.t('screens.catering.occupation', {
                        currentCapacity: restaurant.currentCapacity,
                        maxCapacity: restaurant.maxCapacity,
                      })}
                    </OccupationText>
                    <OccupationComponent
                      currentCapacity={restaurant.currentCapacity}
                      maxCapacity={restaurant.maxCapacity}
                    />
                  </OccupationContainer>
                )}
              {(restaurant.webBookingLink ||
                restaurant.webMenuProviderLink ||
                (restaurant.pdfUrl && restaurant.pdfButtonName) ||
                restaurant.barclapId) && (
                <ShortcutsMainContainer>
                  {restaurant.webMenuProviderLink && (
                    <ShortcutContainer>
                      <Shortcut
                        color={Theme.colors.blue}
                        onClick={() => window.open(restaurant.webMenuProviderLink)}
                        iconName="chef"
                        circleSize={30}
                        iconSize={20}
                        label={i18n.t(
                          `screens.catering.${restaurant.menuProvider === 'EUREST' ? 'foodi' : 'rechargeAccount'}`
                        )}
                        thirdParty
                        useMargin={false}
                        useFilter
                        font={Theme.fonts.labelBold}
                        ariaLabel={i18n.t(
                          `label.ariaLabel.restaurant.${restaurant.menuProvider === 'EUREST' ? 'foodi' : 'myMRS'}`
                        )}
                      />
                    </ShortcutContainer>
                  )}

                  {restaurant.webBookingLink && (
                    <ShortcutContainer>
                      <Shortcut
                        color={Theme.colors.blue}
                        onClick={() => window.open(restaurant.webBookingLink)}
                        iconName="event-confirm"
                        circleSize={30}
                        iconSize={20}
                        label={i18n.t('screens.catering.bookSlot')}
                        thirdParty
                        useMargin={false}
                        useFilter
                        font={Theme.fonts.labelBold}
                        ariaLabel={i18n.t('label.ariaLabel.restaurant.bookSlot')}
                      />
                    </ShortcutContainer>
                  )}

                  {restaurant.barclapId && (
                    <ShortcutContainer>
                      <Shortcut
                        color={Theme.colors.blue}
                        onClick={() =>
                          Modal.open(() => (
                            <BarclapScreen
                              closeModal={Modal.close}
                              restaurants={barclapRestaurants}
                              bookingTab="FORM"
                            />
                          ))
                        }
                        iconName="event-confirm"
                        circleSize={30}
                        iconSize={20}
                        label={i18n.t('screens.catering.bookSlot')}
                        thirdParty
                        useMargin={false}
                        useFilter
                        font={Theme.fonts.labelBold}
                        ariaLabel={i18n.t('label.ariaLabel.restaurant.bookSlot')}
                      />
                    </ShortcutContainer>
                  )}

                  {!!restaurant.pdfButtonName && !!restaurant.pdfUrl && (
                    <ShortcutContainer>
                      <Shortcut
                        color={Theme.colors.blue}
                        onClick={() =>
                          navigation.push('/pdf', {
                            pdfUrl: restaurant.pdfUrl,
                            pdfTitle: restaurant.pdfButtonName,
                          })
                        }
                        iconName="info2"
                        circleSize={30}
                        iconSize={20}
                        label={restaurant.pdfButtonName}
                        thirdParty
                        useMargin={false}
                        useFilter
                        font={Theme.fonts.labelBold}
                        ariaLabel={restaurant.pdfButtonName}
                      />
                    </ShortcutContainer>
                  )}
                </ShortcutsMainContainer>
              )}
              {restaurant.restaurantType && (
                <ScheduleText>
                  {i18n.t(`screens.catering.openingTimes.${restaurant.restaurantType.toLowerCase()}`, {
                    time1: openingDate,
                    time2: closingDate,
                  })}
                </ScheduleText>
              )}
              <DescriptionText
                dangerouslySetInnerHTML={{ __html: restaurant.description }}
                onClick={(evt) => {
                  const target = evt.target as HTMLAnchorElement
                  const url = target.href
                  if (!!url) {
                    externalLinkTracking(url, analyticsKeys.restaurantDetails, user, site, mySites)
                  }
                }}
              />
            </InfoContainer>
          </LeftContainer>

          {restaurant.restaurantType === 'RESTAURANT' && (
            <RightContainer>
              <Menu
                restaurantId={restaurant.id}
                isClosed={isClosed}
                hasMenu={restaurant.hasMenu}
                menuProvider={restaurant.menuProvider}
              />
            </RightContainer>
          )}
        </RestaurantInfoContainer>
      )}
    </MainContainer>
  )
}

export default RestaurantsScreen

// CONTAINERS

const MainContainer = styled('div')`
  min-height: calc(100vh - 146px);
`
const RestaurantInfoContainer = styled('div')`
  display: flex;
  margin: 47px 0px 60px 155px;
  @media only screen and (max-width: ${windowSizeBreakpoints.laptop}px) {
    margin-left: 100px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    flex-direction: column;
    margin-left: 155px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    margin-left: 50px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-left: 0px;
    margin-top: 0px;
    width: 100%;
  }
`
const LeftContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-right: 99px;
  @media only screen and (max-width: ${windowSizeBreakpoints.laptop}px) {
    margin-right: 40px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-right: 0px;
  }
`
const RightContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  @media only screen and (max-width: ${windowSizeBreakpoints.laptop}px) {
    margin-left: 40px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    margin-left: 0px;
    margin-top: 50px;
    width: 480px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    width: 420px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: auto;
    align-items: center;
  }
`
const InfoContainer = styled('div')`
  width: 415px;
  margin: 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: calc(100% - 40px);
  }
`
const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`
const ImageContainer = styled('div')`
  display: flex;
  height: 268px;
  width: 455px;
  background-color: ${(props) => props.theme.colors.placeholderGrey};
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 100%;
  }
`
const ShortcutsMainContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`
const ShortcutContainer = styled('div')`
  margin: 0px 25px;
`

const OccupationContainer = styled('div')`
  display: flex;
  gap: 10px;
  margin-top: 8px;
  align-items: center;
`

const IconUserContainer = styled('div')`
  justify-content: center;
`

// TEXTES

const Name = styled('h1')`
  ${(props) => props.theme.fonts.h1};
  margin: 0px;
`
const ErrorMessage = styled('p')`
  ${(props) => props.theme.fonts.body};
  font-size: 20px;
  margin: 30px;
  text-align: center;
`
const ScheduleText = styled('p')`
  ${(props) => props.theme.fonts.labelBold};
  font-size: 14px;
  margin: 31px 0px 15px 0px;
`
const DescriptionText = styled('p')`
  ${(props) => props.theme.fonts.subtitle};
  margin: 0px;
`

const OccupationText = styled('p')`
  ${(props) => props.theme.fonts.labelBold};
`
