import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'
import useNavigation from 'utils/navigation/useNavigation'
import { windowSizeBreakpoints } from 'utils/breakpoints'

import useReducer from 'store/useReducer'
import * as roomStore from './roomStore'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'

import api from './api/roomApi'
import { AllTimes, FavoriteRoomsOfFloor, Attendee } from './types'

import Logger from 'utils/Logger'
import dateUtils from 'utils/date'

import Modal from 'components/modal/Modal'
import ValidationContent from 'components/modal/ValidationContent'
import RoomInfo from './RoomInfo'

import Loader from 'react-loader-spinner'
import { differenceInMinutes, addMinutes } from 'date-fns'
import utils from './utils'
import { findLevelWording } from 'intervention/utils'

interface ReservationForm {
  startDate: Date
  endDate: Date
  subject: string
}

interface Props {
  searchedRooms: AreaV4[]
  form: ReservationForm
  confirmReservation: (
    name: string,
    o365Id: string,
    start: string,
    end: string,
    attendees: Attendee[],
    subject: string
  ) => void
  attendees: Attendee[]
  formTimes: AllTimes
}

type Status = 'ok' | 'error' | 'loading'

const FavoritesTab = ({ searchedRooms, form, confirmReservation, attendees, formTimes }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const navigation = useNavigation()

  const now = dateUtils.roundToNextQuarter(new Date())

  const favorites = useReducer(roomStore.store, (s) => s.favorites)
  const user = useReducer(userStore.store, (s) => s.user)
  const site = useReducer(siteStore.store, (s) => s.site)

  const [favoriteRooms, setFavoriteRooms] = React.useState<AreaV4[]>([])
  const [status, setStatus] = React.useState<Status>('ok')

  const isInLessThan15Minutes = differenceInMinutes(now, formTimes.startDate) < 15

  const roomsByFloor = React.useMemo(() => {
    if (favoriteRooms) {
      return utils.sortedRoomsFloors(
        favoriteRooms.reduce((acc, cur) => {
          const index = acc.findIndex((r) => r.floor === cur.floor)
          if (index > -1) {
            acc[index].rooms.push(cur)
          } else {
            acc.push({
              floor: cur.floor,
              rooms: [cur],
              floorCustomName: cur.floorCustomName,
              floorOrder: cur.floorOrder,
            })
          }
          return acc
        }, [] as FavoriteRoomsOfFloor[])
      )
    }
    return []
  }, [favoriteRooms])

  React.useEffect(() => {
    if (user && site && favorites.length > 0) {
      setStatus('loading')
      api
        .getRoomsByIds(site.id, user.type, favorites)
        .then((res) => {
          setFavoriteRooms(res.areas)
          setStatus('ok')
        })
        .catch((err) => {
          Logger.error(err)
          setStatus('error')
        })
    }
  }, [favorites, searchedRooms])

  const confirmBookingFunction = (name: string, o365Id: string) => {
    const alreadyBooked = !searchedRooms.find((r) => r.name === name)

    if (!alreadyBooked) {
      confirmReservation(
        name,
        o365Id,
        addMinutes(formTimes.startDate, -new Date().getTimezoneOffset()).toISOString().replace('Z', ''),
        addMinutes(formTimes.endDate, -new Date().getTimezoneOffset()).toISOString().replace('Z', ''),
        attendees,
        form.subject
      )
    } else {
      Modal.open(() => <ValidationContent title={i18n.t('screens.room.alreadyBooked')} onConfirm={Modal.close} />)
    }
  }

  return (
    <MainContainer>
      <Title>{i18n.t('screens.room.myFavorites')}</Title>
      {status === 'loading' ? (
        <LoaderContainer>
          <Loader type="TailSpin" color={Theme.colors.blue} />
        </LoaderContainer>
      ) : status === 'ok' ? (
        roomsByFloor.length > 0 ? (
          <ScrollableContainer>
            {roomsByFloor.map((r) => (
              <div key={r.floor}>
                <DateTitle>{findLevelWording(r.floor, i18n, r.floorCustomName)}</DateTitle>
                <RoomsContainer>
                  {r.rooms.map((room, i, list) => (
                    <RoomInfo
                      key={room.id}
                      room={room}
                      index={i}
                      list={list}
                      i18n={i18n}
                      navigation={navigation}
                      confirmReservation={(name: string, o365Id: string) => confirmBookingFunction(name, o365Id)}
                      formTimes={formTimes}
                      bookingSoon={isInLessThan15Minutes}
                    />
                  ))}
                </RoomsContainer>
              </div>
            ))}
          </ScrollableContainer>
        ) : (
          <ErrorText>{i18n.t('screens.room.noFavorites')}</ErrorText>
        )
      ) : (
        <ErrorText>{i18n.t('screens.room.favoritesError')}</ErrorText>
      )}
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 60px);
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    max-height: auto;
  }
`
const RoomsContainer = styled('div')`
  margin: 0px 14px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin: 0px 16px 0px 24px;
    padding-top: 6px;
  }
`
const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`
const ScrollableContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white};
  overflow-y: scroll;

  /* Style scrollbar pour Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    width: 5px;
    background: white;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${(props) => props.theme.colors.scrollBar};
  }

  /* Style scrollbar pour Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.colors.scrollBar} transparent;

  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    overflow: none;
  }
`

// TEXTES

const Title = styled('h1')`
  ${(props) => props.theme.fonts.bodyBold};
  margin: 0px 0px 20px 14px;
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    ${(props) => props.theme.fonts.bodyBold};
    font-size: 24px;
  }
`
const ErrorText = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px 0px 0px 14px;
`
const DateTitle = styled('h2')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
  margin: 0px;
  padding: 6.5px 0px 6.5px 24px;
  text-transform: capitalize;
  border-top: 0.5px solid ${(props) => props.theme.colors.mediumDarkGrey};
  border-bottom: 1px solid ${(props) => props.theme.colors.mediumDarkGrey};
`

export default FavoritesTab
