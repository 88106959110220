import { executeGraphRequest } from 'authent365/utils/utils'
import { addMonths, subHours } from 'date-fns'
import conf from 'core/src/config'

import { MeetingGraph, Attendee } from 'roombooking/types'

const api = {
  bookRoom: (userType: UserType, meeting: MeetingGraph): Promise<any> =>
    executeGraphRequest(userType, '/me/events', 'POST', meeting),
  delete: (userType: UserType, id: string): Promise<any> => executeGraphRequest(userType, `/me/events/${id}`, 'DELETE'),
  getBooking: (userType: UserType, id: string): Promise<MeetingGraph> =>
    executeGraphRequest(userType, `/me/events/${id}`, 'GET'),
  updateAttendees: (userType: UserType, id: string, attendees: Attendee[]): Promise<MeetingGraph> =>
    executeGraphRequest(userType, `/me/calendar/events/${id}`, 'PATCH', { attendees }),
  monthEvents: (userType: UserType, timezone: string): Promise<MeetingGraph[]> => {
    const start = subHours(new Date(), 1)
    const end = addMonths(start, 1)
    return api.nextEvents(
      userType,
      [],
      `/me/calendarview?startdatetime=${start.toISOString()}&enddatetime=${end.toISOString()}&$select=id,subject,attendees,start,end,location,isOnlineMeeting&$filter=isOrganizer eq true`,
      timezone
    )
  },
  nextEvents: (
    userType: UserType,
    buffer: MeetingGraph[],
    link?: string,
    timezone?: string
  ): Promise<MeetingGraph[]> => {
    if (!link) {
      return Promise.resolve(buffer)
    }
    const decodedLink = decodeURIComponent(link).replace(conf.GRAPH_PREFIX, '')
    return executeGraphRequest(userType, decodedLink, 'GET', undefined, undefined, timezone).then((result: any) =>
      api.nextEvents(userType, [...buffer, ...result.value], result['@odata.nextLink'])
    )
  },
  getUsersByMail: (userType: UserType, userMail: string[]): Promise<DirectoryUser[]> => {
    const maxItemByRequest = 5
    const nbRequest = Math.ceil(userMail.length / maxItemByRequest)

    return Promise.all(
      Array.from({ length: nbRequest }, (_, i) => userMail.slice(i * maxItemByRequest, (i + 1) * maxItemByRequest))
        .map((mls) => '(' + mls.map((mail) => "startswith(mail, '" + mail + "')").join(' or ') + ')')
        .map((params) =>
          executeGraphRequest<UsersGraphResponse>(userType, '/users?$filter=' + params, 'GET').then((res) => res.value)
        )
    )
      .then((res) => res.reduce((acc, cur) => [...acc, ...cur], []))
      .then((res: DirectoryUser[]) =>
        res
          .filter((u) => u.surname)
          .sort((a, b) => a.surname.toLocaleLowerCase().localeCompare(b.surname.toLocaleLowerCase()))
          .map((user) => ({
            ...user,
            mail: user.mail && user.mail !== '-' ? user.mail : '',
          }))
      )
  },
}

export default api
