import * as React from 'react'
import ReactDOM from 'react-dom'

import styled from 'theme/styled-components'
import Provider from 'theme/Provider'

import FocusLock from 'react-focus-lock'

const ANIM_DELAY = 200

interface Props {
  Content: () => JSX.Element
}

const ToastAlert = ({ Content }: Props) => {
  const [opened, setOpened] = React.useState(false)

  const fadeout = () => {
    setOpened(false)
    setTimeout(ToastFunction.close, ANIM_DELAY)
  }

  const onKeyDown: React.KeyboardEventHandler = (evt) => {
    if (evt.key === 'Escape') {
      ToastFunction.close()
    }
  }

  React.useEffect(() => {
    setOpened(true)

    window.onpopstate = ToastFunction.close
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  return (
    <MainContainer opened={opened} onKeyDown={onKeyDown}>
      <BlackScreen onClick={fadeout} />
      <ModalContainer>
        <FocusLock autoFocus={true}>
          <Content />
        </FocusLock>
      </ModalContainer>
    </MainContainer>
  )
}

const MainContainer = styled('div')<{ opened: boolean }>`
  transition: opacity 0.2s;
  opacity: ${(props) => (props.opened ? 1 : 0)};

  z-index: 51;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;
`

const BlackScreen = styled('div')`
  opacity: 0.5;
  background-color: ${(props) => props.theme.colors.black};

  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;

  :hover {
    cursor: pointer;
  }
`

const ModalContainer = styled('div')`
  background-color: ${(props) => props.theme.colors.white};
  z-index: 52;
  margin: 15px;

  max-height: -webkit-fill-available;
  overflow: auto;
`

let toastRoot: HTMLElement | null
let active = false

let elementToFocusOnModalClose: any | null

const ToastFunction = {
  open: (content: () => JSX.Element) => {
    elementToFocusOnModalClose = document.activeElement
    toastRoot = document.getElementById('toast_root')
    if (toastRoot && active) {
      ReactDOM.unmountComponentAtNode(toastRoot)
    }
    if (toastRoot) {
      ReactDOM.render(
        <Provider>
          <ToastAlert Content={content} />
        </Provider>,
        toastRoot
      )
    }
    active = true
  },
  close: () => {
    if (toastRoot && active) {
      ReactDOM.unmountComponentAtNode(toastRoot)
      active = false
      if (elementToFocusOnModalClose) {
        elementToFocusOnModalClose.focus()
        elementToFocusOnModalClose = null
      }
    }
  },
}

export default ToastFunction
