import { startOfDay, setHours, setMinutes, addMinutes, subMinutes, isAfter, isBefore } from 'date-fns'

export const secondsToTime = (seconds: number): { hours: number; minutes: number } => {
  const decimalHours = seconds / 3600
  const hours = Math.trunc(decimalHours)
  const minutes = Math.floor((decimalHours - hours) * 60)
  return { hours, minutes }
}

export const timeString = ({ hours, minutes }: { hours: number; minutes: number }) =>
  `${hours}h${`0${minutes}`.slice(-2)}`

export const setTime = (date: Date, { hours, minutes }: { hours: number; minutes: number }): Date =>
  setHours(setMinutes(startOfDay(date), minutes), hours)

export const isBookingServiceVisible = (booking: BarclapBooking) => {
  const startTime = new Date(booking.start_time)
  const endTime = new Date(booking.end_time)
  const isVisible = isAfter(new Date(), subMinutes(startTime, 15)) && isBefore(new Date(), addMinutes(endTime, 15))
  return isVisible
}
