import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'

interface Props {
  currentCapacity: number
  maxCapacity: number
}

const OccupationComponent = ({ currentCapacity, maxCapacity }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const percentage = Math.round(Math.min(Math.max((currentCapacity / maxCapacity) * 100, 0), 100))
  const roundedPercentage = Math.round(percentage / 10) * 10 // On l'arrondit à la dizaine pour matcher avec les noms d'icones
  const color =
    roundedPercentage <= 40
      ? Theme.colors.turquoise
      : roundedPercentage <= 70
      ? Theme.colors.orange
      : Theme.colors.occupied

  return (
    <Container>
      <GaugeContaner>
        {Array.from(Array(10).keys()).map((k) => (
          <Circle key={k} full={k * 10 < percentage} color={color} />
        ))}
      </GaugeContaner>
      <PercentageText color={color}>{i18n.t('common.percentage', { percentage })}</PercentageText>
    </Container>
  )
}

export default OccupationComponent

const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

const Circle = styled('div')<{ full: boolean; color: string }>`
  height: 6px;
  width: 6px;
  border-radius: 3px;
  background-color: ${(props) => (props.full ? props.color : props.theme.colors.mediumLightGrey)};
`

const GaugeContaner = styled('div')`
  display: flex;
  align-items: center;
  gap: 2px;
`

const PercentageText = styled('p')<{ color: string }>`
  ${(props) => props.theme.fonts.labelBold};
  color: ${(props) => props.color};
  margin: 0px;
`
