import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import api from './api'

import useReducer from 'store/useReducer'
import * as siteStore from 'sites/store/siteStore'
import * as favoritesStore from './favoritesStore'
import * as userStore from 'store/user/user'
import useI18n from 'i18n/useI18n'
import Logger from 'utils/Logger'
import config from 'core/src/config'

import Tag from 'components/label/Tag'
import Icons from 'components/icons/Icons'
import Button from 'components/button/Button'
import Modal from 'components/modal/Modal'
import { CategoryIcon, LineIcon } from './svg/TransportIcon'
import Toast from 'components/toast/Toast'
import ToastContent from 'components/toast/ToastContent'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import TitleHelmet from 'components/title/TitleHelmet'

import { getDistances } from './utils'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import analytics, { analyticsKeys, externalLinkTracking } from 'utils/analytics'
import useNavigation from 'utils/navigation/useNavigation'

import Loader from 'react-loader-spinner'
import FocusLock from 'react-focus-lock'

const REFRESH_DELAY = 60

const ONLYMOOV_LINK = `https://www.onlymoov.com/personnalisation-widget-carte-multimodale/?type=14778421378&displayMode=0&key=${config.ONLYMOOV_KEY}&zoom=1&defaultZoom=15&searchA=1&modeC=1&previsionC=1&layersC=1&lat=45.727800&lng=4.823931&address=Avenue%20Tony%20Garnier%2C%20Lyon&showPOICenter=1&vp=journey-time%7Ctraffic%7Cevent%7Ccamera&tc=tcl%7Cparking&velo=cycleroad%7Cvelov&pedestrian=walking&height=420`

const frenchCities: TransportStationsV6['city'][] = ['PARIS', 'LYON']

const TransportScreen = () => {
  const [Theme] = useTheme()
  const navigation = useNavigation()

  const [traffic, setTraffic] = React.useState<TransportStationsV6>()
  const [bikes, setBikes] = React.useState<BikeStationsV6>()
  const [current, setCurrent] = React.useState(new Date())
  const [modifying, setModifying] = React.useState(false)
  const [transportsLoaded, setTransportsLoaded] = React.useState(false)
  const [bikesLoaded, setBikesLoaded] = React.useState(false)
  const [favoriteLoaded, setFavoriteLoaded] = React.useState(false)

  const site = useReducer(siteStore.store, (s) => s.site)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)
  const favorites = useReducer(favoritesStore.store, (s) => s.favorites)
  const user = useReducer(userStore.store, (s) => s.user)
  const i18n = useI18n()

  const displayedFavorites = favorites.filter((f) => f.siteId === site?.id)

  const city: TypeCity | undefined = traffic ? (traffic.city as TypeCity) : bikes ? (bikes.city as TypeCity) : undefined

  const isInFrance = !!city && frenchCities.includes(city)

  const citymapperLink =
    site &&
    `https://citymapper.com/directions?endcoord=${site.latitude}%2C${site.longitude}&endname=${site.name}&endaddress=${site.address}`
  const wazeLink =
    site && `https://www.waze.com/${i18n.lang}/live-map/directions?to=ll.${site.latitude}%2C${site.longitude}`
  const wazeIFrame =
    site && `https://embed.waze.com/iframe?zoom=14&lat=${site.latitude}&lon=${site.longitude}&ct=livemap&pin=1`
  const googlemapsLink = site && `https://www.google.com/maps/search/?api=1&query=${site.address}`
  const CARSHARING_LINK = 'https://carsharing.engie.com/'

  const sortedCategory = ['METRO', 'TRAMWAY', 'BUS', 'RER']

  const stationsInfo = React.useMemo(() => {
    if (traffic) {
      return traffic.stations
        .reduce((acc, { name, line, category }) => {
          const index = acc.findIndex((o) => o.name === name)
          if (index > -1) {
            const lineAlreadyAdded = !!acc[index].lines.find((o) => o.name === line)
            if (!lineAlreadyAdded) {
              acc[index].lines.push({ name: line })
            }
          } else {
            acc.push({ name: name, lines: [{ name: line }], category: category })
          }
          return acc
        }, [] as DisplayedStation[])
        .sort((a, b) =>
          a.category && b.category ? sortedCategory.indexOf(a.category) - sortedCategory.indexOf(b.category) : 0
        )
    }
    return []
  }, [traffic])

  const displayedStations = React.useMemo(
    () =>
      traffic?.stations
        .filter((s) => s.schedules.length > 0)
        .sort((a, b) =>
          a.line === b.line
            ? a.schedules[0].direction.localeCompare(b.schedules[0].direction)
            : a.line.localeCompare(b.line)
        )
        .reduce((acc, cur) => {
          cur.schedules.forEach((sch) => {
            const directionIndex = acc.findIndex(
              (item) =>
                item.schedules[0].direction === sch.direction && item.name === cur.name && item.line === cur.line
            )
            if (directionIndex < 0) {
              acc.push({ ...cur, schedules: cur.schedules.filter((s) => s.direction === sch.direction) })
            }
          })

          return acc
        }, [] as TransportStationV6[]),
    [traffic]
  )

  const addFavoriteStation = (station: TransportStationV6) => {
    const alreadyFavorite = favorites.find(
      (s) =>
        s.station === station.name &&
        s.line === station.line &&
        s.direction === station.schedules[0].direction &&
        s.siteId === site?.id
    )
    if (!alreadyFavorite && site) {
      favoritesStore.actions.setFav([
        ...favorites,
        {
          station: station.name,
          line: station.line,
          direction: station.schedules[0].direction,
          category: station.category,
          siteId: site.id,
        },
      ])
    }
  }

  const deleteFavorite = (station: FavoriteDirection) => {
    const updatedFav = favorites.filter(
      (f) =>
        f.station !== station.station ||
        f.line !== station.line ||
        f.direction !== station.direction ||
        f.siteId !== site?.id
    )
    favoritesStore.actions.setFav(updatedFav)
    if (updatedFav.length === 0) {
      setModifying(false)
    }
  }

  const favoriteFunc = (alreadyFavorite: boolean, station: TransportStationV6) => {
    if (alreadyFavorite && site) {
      const favStation: FavoriteDirection = {
        station: station.name,
        line: station.line,
        direction: station.schedules[0].direction,
        siteId: site.id,
        category: station.category,
      }
      deleteFavorite(favStation)
    }
    addFavoriteStation(station)
  }

  const ModalContent = () => (
    <ModalContainer>
      <ModaleTitle>{i18n.t('screens.transport.calculateItinerary')}</ModaleTitle>
      <ModalButtonsContainer>
        <SingleModaleButton>
          <Button
            label={i18n.t('screens.transport.citymapperTitle')}
            font={Theme.fonts.h3Bold}
            onClick={() => {
              if (!!citymapperLink) {
                externalLinkTracking(citymapperLink, analyticsKeys.transports, user, site, mySites)
              }
              window.open(citymapperLink)
            }}
            ariaLabel={i18n.t('label.ariaLabel.transport.openSiteForItinerary', {
              site: i18n.t('screens.transport.citymapperTitle'),
            })}
          />
        </SingleModaleButton>
        <SingleModaleButton>
          <Button
            label={i18n.t('screens.transport.wazeTitle')}
            font={Theme.fonts.h3Bold}
            onClick={() => {
              if (!!wazeLink) {
                externalLinkTracking(wazeLink, analyticsKeys.transports, user, site, mySites)
              }
              window.open(wazeLink)
            }}
            ariaLabel={i18n.t('label.ariaLabel.transport.openSiteForItinerary', {
              site: i18n.t('screens.transport.wazeTitle'),
            })}
          />
        </SingleModaleButton>
        <SingleModaleButton>
          <Button
            label={i18n.t('screens.transport.googlemapsTitle')}
            font={Theme.fonts.h3Bold}
            onClick={() => {
              if (!!googlemapsLink) {
                externalLinkTracking(googlemapsLink, analyticsKeys.transports, user, site, mySites)
              }
              window.open(googlemapsLink)
            }}
            ariaLabel={i18n.t('label.ariaLabel.transport.openSiteForItinerary', {
              site: i18n.t('screens.transport.googlemapsTitle'),
            })}
          />
        </SingleModaleButton>
        <SingleModaleButton>
          <Button
            label={i18n.t('screens.transport.copyAddress')}
            font={Theme.fonts.h3Bold}
            onClick={() => {
              if (site && site.address) {
                navigator.clipboard.writeText(site.address)
              }
              Modal.close()
              Toast.open(() => ToastContent(i18n.t('screens.transport.addressCopied'), Theme.colors.darkGrey), 'top')
            }}
            ariaLabel={i18n.t('label.ariaLabel.transport.copyAddress')}
          />
        </SingleModaleButton>
        <SingleModaleButton>
          <Button
            label={i18n.t('common.cancel')}
            font={Theme.fonts.h3Bold}
            color={Theme.colors.white}
            textColor={Theme.colors.blue}
            shadow
            onClick={() => Modal.close()}
            ariaLabel={i18n.t('label.ariaLabel.windowCrossIcon')}
          />
        </SingleModaleButton>
      </ModalButtonsContainer>
    </ModalContainer>
  )

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrent(new Date())
      if (site && user) {
        api.traffic(site.id, user.type).then(setTraffic).catch(Logger.error)
        api
          .bikes(site.id, user.type)
          .then((b) =>
            setBikes({
              bikes: b.bikes.sort((a, b) =>
                a.publicName && b.publicName ? a.publicName?.localeCompare(b.publicName) : 0
              ),
              city: b.city,
            })
          )
          .catch(Logger.error)
      }
    }, REFRESH_DELAY * 1000)

    return () => clearTimeout(timeout)
  }, [current])

  React.useEffect(() => {
    if (site && user) {
      api
        .traffic(site.id, user.type)
        .then(setTraffic)
        .catch(Logger.error)
        .finally(() => {
          setTransportsLoaded(true)
          setFavoriteLoaded(true)
        })
      api
        .bikes(site.id, user.type)
        .then((b) =>
          setBikes({
            bikes: b.bikes.sort((a, b) =>
              a.publicName && b.publicName ? a.publicName?.localeCompare(b.publicName) : 0
            ),
            city: b.city,
          })
        )
        .catch(Logger.error)
        .finally(() => setBikesLoaded(true))
    }

    if (user) {
      analytics.track({ screenName: analyticsKeys.transports, userType: user.type, currentSite: site, mySites })
    }
  }, [])

  const toggleModifyFavorite = () => setModifying(!modifying)

  const renderIcon = (name: LineTransportIconName, category: TransportStationV6['category']) => (
    <IconTitleContainer>
      {!!category && (
        <IconContainer>
          <TrafficIconContainer>
            <CategoryIcon name={`${city}_${category}` as CategoryTransportIconName} size={24} />
          </TrafficIconContainer>
        </IconContainer>
      )}
      <IconContainer>
        <LineIcon name={name} size={23} ariaLabel={name.replace(/\D/g, '')} />
      </IconContainer>
    </IconTitleContainer>
  )

  const renderTag = (schedule: ScheduleV6, index: number, distance?: string, favorite = false) => (
    <TagContainer key={`${schedule.direction} - ${schedule.time} - ${favorite} - ${distance} - ${index}`}>
      <Tag
        label={distance}
        backgroundColor={favorite ? Theme.colors.white : Theme.colors.mediumDarkGrey}
        color={Theme.colors.darkGrey}
        highlight={distance === i18n.t('screens.transport.now') || distance === i18n.t('screens.transport.next')}
      />
    </TagContainer>
  )

  const renderFavorite = (station: FavoriteDirection) => {
    if (!site) {
      return null
    }

    const favoriteStation = displayedStations?.find(
      (s) => s.name === station.station && s.line === station.line && s.schedules[0].direction === station.direction
    )

    const schedule = getDistances(i18n, new Date(), site.timezone, favoriteStation).filter((s) => !!s)

    const deleteFromFavorite = () => deleteFavorite(station)

    return (
      favoriteStation && (
        <FavoriteContainer key={`${station.station} - ${station.line} - ${station.direction}`}>
          <IconTitleContainer>
            {renderIcon(station.line as LineTransportIconName, station.category)}
            <DirectionTitle>{favoriteStation.schedules[0].direction}</DirectionTitle>
          </IconTitleContainer>
          <Tags>
            {!favoriteLoaded ? (
              <NoNextDistanceTag>{i18n.t('common.loading')}...</NoNextDistanceTag>
            ) : favoriteStation && schedule.length > 0 ? (
              schedule.map((d, i) => renderTag(favoriteStation.schedules[0], i, d, true))
            ) : (
              <NoNextDistanceTag>{i18n.t('screens.transport.noNextTransport')}</NoNextDistanceTag>
            )}
          </Tags>
          {modifying && (
            <DeleteButton
              onClick={deleteFromFavorite}
              aria-label={i18n.t('label.ariaLabel.transport.deletePinnedLine')}>
              <DeleteBackground>
                <Icons name="cross" size={15} color={Theme.colors.blue} />
              </DeleteBackground>
            </DeleteButton>
          )}
        </FavoriteContainer>
      )
    )
  }

  const renderDirection = (station: TransportStationV6, notLast: boolean) => {
    if (!site) {
      return null
    }

    const schedule = getDistances(i18n, new Date(), site.timezone, station).filter((s) => !!s)
    const alreadyFavorite = !!favorites.find(
      (s) =>
        s.station === station.name &&
        s.line === station.line &&
        s.direction === station.schedules[0].direction &&
        s.siteId === site?.id
    )

    const toggleFavorite = () => favoriteFunc(alreadyFavorite, station)

    return (
      <DirectionContainer key={station.schedules[0].direction}>
        <IconTitleContainer>
          {renderIcon(station.line as LineTransportIconName, station.category)}
          <DirectionTitle>{station.schedules[0].direction}</DirectionTitle>
        </IconTitleContainer>
        <Tags>
          {schedule.length > 0 ? (
            schedule.map((d, i) => renderTag(station.schedules[0], i, d))
          ) : (
            <NoNextDistanceTag>{i18n.t('screens.transport.noNextTransport')}</NoNextDistanceTag>
          )}
        </Tags>
        <AddFavoriteContainer onClick={toggleFavorite}>
          <Icons name={alreadyFavorite ? 'less' : 'plus'} color={Theme.colors.blue} size={15} />
          <AddFavoriteText>
            {i18n.t(`screens.transport.${alreadyFavorite ? 'removeFavorites' : 'addFavorites'}`)}
          </AddFavoriteText>
        </AddFavoriteContainer>
        {notLast && <BlueLineBreak role="presentation" />}
      </DirectionContainer>
    )
  }

  const renderLine = (lines: LineStation, station: DisplayedStation) => {
    const displayedLineStation = displayedStations?.filter((s) => s.line === lines.name && s.name === station.name)
    return (
      <LineContainer key={`${lines.name} - ${station.name}`}>
        <LineTitle>
          {i18n.t([`screens.transport.lineName.${lines.name}`, 'screens.transport.lineName.UNKNOWN'])} - {station.name}
        </LineTitle>
        <div>
          {displayedLineStation && displayedLineStation.length > 0 ? (
            displayedLineStation.map((s, i) => renderDirection(s, displayedLineStation.length !== i + 1))
          ) : (
            <StationErrorMessageContainer>
              <DirectionTitle>{i18n.t('screens.transport.noNextTransport')}</DirectionTitle>
            </StationErrorMessageContainer>
          )}
        </div>
      </LineContainer>
    )
  }

  const renderBike = (station: BikeStationV6, index: number, list?: BikeStationV6[]) => {
    const notLast = list && list.length !== index + 1
    return (
      <BikeStation key={station.id}>
        <IconTitleContainer>
          <CategoryIcon name={`${city}_VELO` as CategoryTransportIconName} size={22} />
          <BikeDirectionTitle>{station.publicName}</BikeDirectionTitle>
        </IconTitleContainer>
        <AvailabilityContainer>
          <IconTitleContainer>
            <CategoryIcon name="VELO" size={20} />
            <BikeNumber>{i18n.t('screens.transport.bikeLeft', { count: station.bikes })}</BikeNumber>
          </IconTitleContainer>
          <IconTitleContainer>
            <CategoryIcon name="PARKING" size={20} />
            <BikePlace>{i18n.t('screens.transport.bikeStandLeft', { count: station.stands })}</BikePlace>
          </IconTitleContainer>
        </AvailabilityContainer>
        {notLast && <BlueLineBreak role="presentation" />}
      </BikeStation>
    )
  }

  if (!site || !user) {
    return null
  }

  return (
    <>
      <TitleHelmet title={i18n.t('screens.transport.title')} />
      <Breadcrumb screen="transport" path="transport" navigation={navigation} />

      <MainContainer>
        <TopContainer>
          <PinnedLines disabled={!modifying} autoFocus={false}>
            <TopTitleContainer>
              <FavoriteTitleContainer>
                <CategoryTitle>{i18n.t('screens.transport.favorites')}</CategoryTitle>
              </FavoriteTitleContainer>
              {favorites.length > 0 && (
                <ModifyText
                  onClick={toggleModifyFavorite}
                  aria-label={i18n.t(
                    `label.ariaLabel.transport.${modifying ? 'finishPinnedLines' : 'modifyPinnedLines'}`
                  )}>
                  {i18n.t(`common.${modifying ? 'finish' : 'modify'}`)}
                </ModifyText>
              )}
            </TopTitleContainer>
            {displayedFavorites.length > 0 && site ? (
              <GridContainer>{displayedFavorites.map(renderFavorite)}</GridContainer>
            ) : (
              <NoFavoritesMessage>{i18n.t('screens.transport.noFavorites')}</NoFavoritesMessage>
            )}
          </PinnedLines>
          <ItineraryContainer>
            <CategoryTitle>{i18n.t('screens.transport.planRoute')}</CategoryTitle>
            <ButtonsContainer>
              <SingleButtonContainer>
                <Button
                  label={i18n.t('screens.transport.calculateItinerary')}
                  font={Theme.fonts.h3Bold}
                  verticalPadding={10}
                  onClick={() => Modal.open(ModalContent)}
                />
              </SingleButtonContainer>
              {isInFrance && user.type !== 'EXTERNAL' && (
                <SingleButtonContainer>
                  <Button
                    label={i18n.t('screens.transport.carSharing')}
                    font={Theme.fonts.h3Bold}
                    verticalPadding={10}
                    onClick={() => {
                      externalLinkTracking(CARSHARING_LINK, analyticsKeys.transports, user, site, mySites)
                      window.open(CARSHARING_LINK)
                    }}
                    ariaLabel={i18n.t('label.ariaLabel.transport.openCarSharing')}
                  />
                </SingleButtonContainer>
              )}
            </ButtonsContainer>
          </ItineraryContainer>
        </TopContainer>
        <BottomContainer infoTraffic={site.name === 'Urban Garden'}>
          <TrafficContainer>
            <CategoryTitle>{i18n.t('screens.transport.nextLines')}</CategoryTitle>
            <GridContainer>
              {!transportsLoaded ? (
                <LoaderContainer>
                  <Loader type="TailSpin" color={Theme.colors.blue} />
                </LoaderContainer>
              ) : displayedStations && stationsInfo.length > 0 ? (
                stationsInfo.map((s) => s.lines.map((l) => renderLine(l, s)))
              ) : (
                <DirectionTitle>{i18n.t('screens.transport.noNextTransport')}</DirectionTitle>
              )}
            </GridContainer>
          </TrafficContainer>
          <BikesContainer>
            {!bikesLoaded ? (
              <LoaderContainer>
                <Loader type="TailSpin" color={Theme.colors.blue} />
              </LoaderContainer>
            ) : (
              !!bikes &&
              !!bikes.bikes &&
              bikes.bikes.length > 0 &&
              bikes.city !== 'UNKNOWN' && (
                <>
                  <CategoryTitle>{i18n.t(`screens.transport.availableBike${bikes.city}`)}</CategoryTitle>
                  <Bikes>{bikes.bikes.map(renderBike)}</Bikes>
                </>
              )
            )}
          </BikesContainer>
        </BottomContainer>
        <TrafficInfoContainer>
          <CategoryTitle>{i18n.t(`screens.transport.trafficInfo`)}</CategoryTitle>
          <IframeContainer>
            <iframe
              title={i18n.t('label.ariaLabel.transport.onlymoov')}
              src={site.onlymoovEnabled ? ONLYMOOV_LINK : wazeIFrame}
              width="100%"
              height="420px"
              scrolling="no"
              style={{ border: 0 }}
            />
          </IframeContainer>
        </TrafficInfoContainer>
      </MainContainer>
    </>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 26px;
  min-height: calc(100vh - 116px);
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-left: 0px;
    min-width: 100%;
  }
`
const TopContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-left: 5%;
  margin-bottom: 45px;
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    display: flex;
    flex-direction: column;
    padding-right: 3%;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-right: 5px;
  }
`
const BottomContainer = styled('div')<{ infoTraffic: boolean }>`
  display: grid;
  flex: 1;
  min-height: 430px;
  grid-template-columns: repeat(3, 1fr);
  padding: 40px 0px 0px 5%;
  ${(props) => !props.infoTraffic && 'padding-bottom: 4%'};
  background-color: ${(props) => props.theme.colors.mediumLightGrey};
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    display: flex;
    flex-direction: column;
    padding-right: 3%;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-right: 5px;
  }
`
const TrafficContainer = styled('div')`
  margin-right: 15px;
  grid-column: 1 / 3;
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    margin-right: 0px;
  }
`
const BikesContainer = styled('div')`
  margin-left: 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    margin-left: 0px;
    max-width: calc(50% - 7px);
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    max-width: none;
    margin-right: 40px;
  }
`
const GridContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  margin-left: -5px;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    display: flex;
    flex-direction: column;
  }
`
const LineContainer = styled('div')`
  background-color: ${(props) => props.theme.colors.white};
  padding: 20px;
  padding-bottom: 3px;
  margin: 5px;
  box-shadow: 0px 0px 15px ${(props) => props.theme.colors.shadow};
  min-width: 300px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    min-width: unset;
    margin-right: 0px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    margin-right: 0px;
  }
`
const DirectionContainer = styled('div')`
  margin: 15px 0px;
`
const Tags = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`
const TagContainer = styled('div')`
  margin: 10px 7px 10px 0px;
`
const Bikes = styled('div')`
  background-color: ${(props) => props.theme.colors.white};
  padding: 20px;
  padding-top: 5px;
  margin-top: 29px;
  box-shadow: 0px 0px 15px ${(props) => props.theme.colors.shadow};
  width: 300px;
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    max-width: 300px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    width: 100%;
    max-width: none;
  }
`
const AvailabilityContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  gap: 10px;
`
const BikeStation = styled('div')`
  margin-top: 20px;
`
const FavoriteContainer = styled('div')`
  position: relative;
  padding: 20px;
  padding-bottom: 8px;
  margin: 5px;
  background-color: ${(props) => props.theme.colors.paleBlue};
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    margin-right: 0px;
  }
`
const TopTitleContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-right: 6px;
`
const FavoriteTitleContainer = styled('div')`
  flex: 1;
`
const PinnedLines = styled(FocusLock)`
  grid-column: 1 / 3;
  margin-right: 40px;
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    margin-right: 0px;
  }
`
const ItineraryContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-bottom: 50px;
  height: 150px;
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    margin-left: 0px;
  }
`
const ButtonsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: -10px;
  margin-top: -5px;
  padding-right: 10px;
`
const SingleButtonContainer = styled('div')`
  display: flex;
  margin: 10px;
  width: 300px;
`
const ModalContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 50px;
  margin-top: -10px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    padding: 30px;
    align-items: center;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    margin-top: -15px;
  }
`
const ModalButtonsContainer = styled('div')`
  display: flex;
  flex-direction: column;
`
const SingleModaleButton = styled('div')`
  display: flex;
  width: 330px;
  margin: 5px 0px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    width: 150;
  }
`
const IconTitleContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
`
const IconContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-right: 5px;
`
const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`
const TrafficIconContainer = styled('div')`
  margin-top: 4px;
`
const TrafficInfoContainer = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 430px;
  padding: 16px 0px 60px 5%;
  background-color: ${(props) => props.theme.colors.mediumLightGrey};
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    display: flex;
    flex-direction: column;
    padding-right: 3%;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-right: 5px;
  }
`
const IframeContainer = styled('div')`
  width: calc(65.5% + 380px);
  margin-top: -11px;
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    width: auto;
    padding-right: 5px;
  }
`

// TEXTES

const CategoryTitle = styled('h1')`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 24px;
  line-height: 26px;
  min-width: 400px;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    min-width: 200px;
  }
`
const ModaleTitle = styled('h1')`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 24px;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    text-align: center;
    line-height: 25px;
  }
`
const LineTitle = styled('h2')`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 18px;
  margin-top: 0px;
`
const DirectionTitle = styled('h3')`
  ${(props) => props.theme.fonts.body}
  margin: 0px;
`
const BikeDirectionTitle = styled('h3')`
  ${(props) => props.theme.fonts.bodyBold}
  margin: 0px;
  margin-left: 8px;
`
const BikeNumber = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 0;
`
const BikePlace = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 0;
`
const AddFavoriteText = styled('p')`
  ${(props) => props.theme.fonts.label}
  margin: 0px 0px 0px 8px;
  padding: 3px 0px;
`
const ModifyText = styled('button')`
  ${(props) => props.theme.fonts.linkUnderlined}
  &:hover {
    color: ${(props) => props.theme.colors.blue};
  }
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const NoFavoritesMessage = styled('p')`
  ${(props) => props.theme.fonts.body}
`
const NoNextDistanceTag = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 12px 0px 10px 10px;
  font-size: 14px;
`
const StationErrorMessageContainer = styled('div')`
  margin-bottom: 20px;
`

// AUTRES

const BlueLineBreak = styled('div')`
  width: 60px;
  height: 3px;
  background-color: ${(props) => props.theme.colors.blue};
  margin: 10px 0px;
  border-radius: 2px;
`
const DeleteButton = styled('button')`
  position: absolute;
  top: -4px;
  right: -4px;
  justify-content: center;
  align-items: center;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const DeleteBackground = styled('div')`
  display: flex;
  height: 22px;
  width: 22px;
  border-radius: 11px;
  background-color: ${(props) => props.theme.colors.white};
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 10px ${(props) => props.theme.colors.shadow};
  &:hover {
    background-color: ${(props) => props.theme.colors.lightGrey};
  }
`
const AddFavoriteContainer = styled('button')`
  display: inline-flex;
  align-items: center;
  padding-right: 5px;
  margin: -5px 0px 9px 0px;
  &:hover {
    ${AddFavoriteText} {
      color: ${(props) => props.theme.colors.blue};
    }
  }
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`

export default TransportScreen
