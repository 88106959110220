import * as React from 'react'
import styled from 'theme/styled-components'

import { windowSizeBreakpoints } from 'utils/breakpoints'

import Checkbox from 'components/button/Checkbox'

interface Props {
  choices: QuestionChoice[]
  multipleChoices?: boolean
  onChange: (choices: string[]) => void
  editable?: boolean
  maxChoices?: number
  selectedIDs: string[]
}

const MultipleChoices = ({ choices, multipleChoices, onChange, editable = true, maxChoices, selectedIDs }: Props) => {
  const [choicesDisabled, setChoicesDisabled] = React.useState(false)

  React.useEffect(() => {
    setChoicesDisabled(!!multipleChoices && !!maxChoices && selectedIDs.length >= maxChoices)
  }, [selectedIDs])

  const onPress = (id: string) => {
    if (editable) {
      if (selectedIDs.includes(id)) {
        onChange(selectedIDs.filter((sID) => sID !== id))
      } else if (multipleChoices) {
        if (!choicesDisabled) {
          onChange([...selectedIDs, id])
        }
      } else {
        onChange([id])
      }
    }
  }

  const renderItem = (item: QuestionChoice) => {
    const itemId = item.id
    const isSelected = selectedIDs.includes(itemId)
    const isDisabled = choicesDisabled && !isSelected

    if (multipleChoices) {
      return (
        <SingleChoiceContainer onClick={() => onPress(itemId)}>
          <Checkbox value={isSelected} onChange={() => onPress(itemId)} />
          <SingleChoiceLabel>{item.text}</SingleChoiceLabel>
        </SingleChoiceContainer>
      )
    }

    return (
      <ChoiceContainer
        selected={isSelected}
        onClick={() => {
          if (!isDisabled) {
            onPress(itemId)
          }
        }}>
        <Label isDisabled={isDisabled} selected={isSelected}>
          {item.text}
        </Label>
      </ChoiceContainer>
    )
  }

  return <MainContainer>{choices.map(renderItem)}</MainContainer>
}

export default MultipleChoices

const MainContainer = styled('div')`
  margin-top: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`

const Label = styled('p')<{ isDisabled: boolean; selected: boolean }>`
  text-align: center;
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) =>
    props.selected
      ? props.theme.colors.white
      : props.isDisabled
      ? props.theme.colors.middleGrey
      : props.theme.colors.blue};
  margin: 0px;
`

const ChoiceContainer = styled('button')<{ selected: boolean }>`
  justify-content: center;
  width: 400px;
  align-items: center;
  padding: 10px 16px;
  margin-bottom: 10px;
  background-color: ${(props) => (props.selected ? props.theme.colors.blue : props.theme.colors.white)};
  border: 1px solid ${(props) => props.theme.colors.blue};
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 90%;
  }
`

const SingleChoiceContainer = styled('div')`
  display: flex;
  cursor: pointer;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`

const SingleChoiceLabel = styled('p')`
  text-align: center;
  ${(props) => props.theme.fonts.body};
  margin: 0px;
  margin-left: 10px;
`
