import ws from 'utils/Webservice'
import config from 'core/src/config'
import { assureToken } from 'authent365/utils/utils'

const api = {
  getParkingUrl: () =>
    ws<string>(
      'ENGIE',
      `https://${config.PARKING_HOST}/api/parking/IdentityProviderRedirectURL?identityProvider=urbangardensharvy&customer&mobile=false`,
      'GET',
      undefined,
      undefined,
      undefined,
      true
    ),
  getParkingInfos: (siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      ws<ParkingAreasV4>(userType, `${config.globalAPIVersion}/parking/areas?siteId=${siteId}`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  getParkingBookings: (siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      ws<ParkingSpotRequestsV4>(userType, `${config.globalAPIVersion}/parking/requests/me?siteId=${siteId}`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  postParkingBooking: (siteId: string, userType: UserType, body: ParkingSpotFormsV4) =>
    assureToken(userType).then((token) =>
      ws<ParkingSpotRequestsV4>(
        userType,
        `${config.globalAPIVersion}/parking/requests?siteId=${siteId}`,
        'POST',
        {
          Authorization: `Bearer ${token}`,
        },
        undefined,
        body
      )
    ),
  deleteParkingBooking: (siteId: string, userType: UserType, requestId: string) =>
    assureToken(userType).then((token) =>
      ws<void>(userType, `${config.globalAPIVersion}/parking/requests/${requestId}?siteId=${siteId}`, 'DELETE', {
        Authorization: `Bearer ${token}`,
      })
    ),
}

export default api
