import screens from 'core/src/layout/Screens'
import { getScreenNameFromFeatureType } from 'home/utils'

const categories: { [key: string]: string } = {
  THIRD_PART: 'third_part',
  NEWS: 'information',
  PRACTICAL_INFORMATION: 'information',
  home: 'home',
  account: 'home',
  CARTOGRAPHY: 'moving',
  TRANSPORT: 'moving',
  sites: 'moving',
  DESK: 'booking',
  GUEST: 'booking',
  ROOM: 'booking',
  CATERING: 'booking',
  cgu: 'help',
  PARKING: 'booking',
  PARKING_COMMUTY: 'booking',
  INCIDENT: 'help',
  SITE: 'booking',
}

const CATEGORY_ORDER = ['home', 'information', 'moving', 'booking', 'third_part', 'help']

const NOT_SHOWN = [
  'iframe',
  'informationDetails',
  'login',
  'newsDetails',
  'restaurantDetails',
  'roomDetails',
  'sitemap',
  'traffic_info',
  'surveysDetails',
  'myMRS',
]

const sortByCategory = (feature: SimplifiedFeature) => {
  const category = categories[feature.type]

  return {
    name: feature.name,
    category: category,
    position: feature.position,
    path: feature.path,
    url: feature.url,
  } as FeatureCategory
}

const filterFeatures = (features: FeatureV4[]) => {
  const featuresTypes = features.map((f: FeatureV4) => getScreenNameFromFeatureType(f.type))
  const featuredItems: ScreenDefinition[] = Object.keys(screens)
    .map((key) => screens[key])
    .filter((screen) => screen.drawer && featuresTypes.indexOf(screen.name) !== -1)

  return features
    .filter((f) => NOT_SHOWN.indexOf(f.type) === -1 && (f.type === 'THIRD_PART' ? !!f.webUrl : true))
    .map((f) => {
      const correspondingItem = featuredItems.find((s) => s.name === getScreenNameFromFeatureType(f.type))
      return {
        name: f.name,
        type: f.type,
        position: f.position,
        path: correspondingItem ? correspondingItem.path : undefined,
        url: f.webUrl,
      }
    })
}

const orderCategory = (featuresSorted: FeatureByCategory[]) =>
  featuresSorted.sort((a, b) => CATEGORY_ORDER.indexOf(a.category) - CATEGORY_ORDER.indexOf(b.category))

export { sortByCategory, filterFeatures, orderCategory }
