import * as React from 'react'
import { MapBuilding, MovePosition } from './types'
import { VGWindow, EventActionCallback, Mapviewer, Route, RouteNavigation, RouteResult, ExploreState } from './visioweb'

import useTheme from 'theme/useTheme'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'

import useResize from './useResize'
import Logger from 'utils/Logger'
import { AreaWithStatusColor } from './Map'

const BASE_URL = 'https://mapserver.visioglobe.com/'

const OPTIMIZATIONS = {
  batchTexts: {
    enabled: true,
    padding: 2,
    atlasSize: 1024,
    delayCanvasCreation: false,
  },
  batchIcons: {
    enabled: true,
    padding: 2,
    atlasSize: 1024,
    delayCanvasCreation: true,
  },
  unloadDelay: 2000,
}

export const PMR_EXCLUDE = ['stairway', 'escalator', 'stair']

export interface ItineraryProps {
  nav: RouteNavigation | undefined
  route: Route | undefined
  computeRoute: (from: string, to: string, pmr: boolean, lng: string) => Promise<RouteResult>
  stopRoute: () => void
  nextRoute: () => void
  previousRoute: () => void
  from: AreaV4 | undefined
  setFrom: React.Dispatch<React.SetStateAction<AreaV4 | undefined>>
  to: AreaV4 | undefined
  setTo: React.Dispatch<React.SetStateAction<AreaV4 | undefined>>
}

export type MapViewerStatus = 'notReady' | 'initialized'

const HEADER_HEIGHT = 60

const useMap = (
  areas: AreaWithStatusColor[],
  areasStatus: StatusV4[],
  isAccessible: boolean,
  hash: string,
  lang: string,
  isModalFromZapfloor?: boolean,
  deskAreas?: ZapfloorDeskArea[],
  selectDesk?: (clickedPoiId: string) => void,
  mapWidth?: number,
  mapHeight?: number,
  customAreas?: Pick<AreaV4, 'id' | 'uniquePlaceName' | 'occupationStatus' | 'colorCode' | 'contrastedColorCode'>[],
  uniquePlaceInitial?: string,
  myDesk?: string,
  parkingMode?: boolean
) => {
  const [theme] = useTheme()
  const user = useReducer(userStore.store, (s) => s.user)
  const favoritePeoples = useReducer(userStore.store, (s) => s.favoriteUsers) || []

  const MapViewer = React.useMemo(
    () => /* TODO MBA */ new (((window as unknown) as VGWindow).visioweb as any).Mapviewer() as Mapviewer,
    []
  )
  const ref = React.useRef<HTMLDivElement>(null)

  const [height, width] = useResize()

  const [status, setStatus] = React.useState<MapViewerStatus>('notReady')
  const [building, setBuilding] = React.useState<string>()
  const [floor, setFloor] = React.useState<string>()
  const [place, setPlace] = React.useState<AreaV4>()

  const updatePlace = (p?: AreaWithStatusColor) => {
    if (place && place.uniquePlaceName !== p?.uniquePlaceName) {
      const findArea = areasStatus.find((a) => a.id === place.id)
      const hotDeskPois = hotDeskPoisWithColor.find((a) => a.id === place.id)
      const hasFullRights =
        !!user && (place.entity === user.type || (place.entity === 'ALL' && user.type !== 'EXTERNAL'))
      const placeColor = !hasFullRights
        ? undefined
        : !!findArea
        ? isAccessible
          ? findArea.contrastedColorCode
          : findArea.colorCode
        : !!hotDeskPois
        ? hotDeskPois.statusColor
        : undefined
      if (placeColor) {
        MapViewer.setPlaceColor(place.uniquePlaceName, placeColor)
      } else {
        MapViewer.resetPlaceColor([MapViewer.getPlace(place.uniquePlaceName)])
      }
    }
    //If we are booking by desk, then only the previously selected POIs can be clicked
    if (!!isModalFromZapfloor && !!deskAreas) {
      const isPlaceHotDesk = deskAreas.find(
        (poi) => poi.area.zapfloorId === p?.zapfloorId && poi.area.uniquePlaceName.includes('/')
      )
      if (isPlaceHotDesk || !clickedPoiId) {
        setPlace(p)
      } else {
        setPlace(undefined)
      }
    } else {
      setPlace(p)
    }
  }

  const [clickedPoiId, setClickedPoiId] = React.useState<string>()

  const [buildings, setBuildings] = React.useState<MapBuilding[]>([])

  const [from, setFrom] = React.useState<AreaV4>()
  const [to, setTo] = React.useState<AreaV4>()
  const [route, setRoute] = React.useState<Route>()
  const [nav, setNav] = React.useState<RouteNavigation>()
  const [navigating, setNavigating] = React.useState(false)

  const hotDeskPoisWithColor: AreaWithStatusColor[] = React.useMemo(() => {
    if (isModalFromZapfloor && !!deskAreas) {
      return deskAreas.map((d) => {
        const isFavorite =
          !!d.zapfloorDesk.attributes.hot_desk_bookings[0] &&
          !!favoritePeoples.find((p) => p === d.zapfloorDesk.attributes.hot_desk_bookings[0].user_id)

        const isMyDesk = !!myDesk && d.area.uniquePlaceName === myDesk

        return {
          ...d.area,
          statusColor: !d.zapfloorDesk.attributes.hot_desk_in_use
            ? theme.colors.green
            : (!!isFavorite || !!isMyDesk) && !parkingMode
            ? theme.colors.blue
            : theme.colors.red,
        }
      })
    }
    return []
  }, [favoritePeoples, deskAreas, isModalFromZapfloor])

  React.useEffect(() => {
    hotDeskPoisWithColor.map((poi) => {
      if (poi.statusColor && clickedPoiId !== poi.uniquePlaceName) {
        MapViewer.setPlaceColor(poi.uniquePlaceName, poi.statusColor)
      }
    }),
      [hotDeskPoisWithColor]
  })

  React.useEffect(() => {
    if (!!customAreas) {
      customAreas.map((area) =>
        MapViewer.setPlaceColor(
          area.uniquePlaceName,
          isAccessible
            ? area.contrastedColorCode || area.colorCode || ''
            : area.colorCode || area.contrastedColorCode || ''
        )
      )
    }
  }, [customAreas])

  React.useEffect(() => {
    if ((areas.length > 0 || hotDeskPoisWithColor.length > 0) && clickedPoiId) {
      const newPlace =
        areas.find((a) => a.uniquePlaceName === clickedPoiId) ||
        hotDeskPoisWithColor.find((a) => a.uniquePlaceName === clickedPoiId)
      if (!!newPlace && newPlace.clickable) {
        updatePlace(newPlace)
      } else {
        updatePlace(undefined)
      }
    }
  }, [clickedPoiId, hotDeskPoisWithColor])

  React.useEffect(() => {
    if (place && !navigating) {
      const pl = MapViewer.getPlace(place.uniquePlaceName)
      const poi = !isModalFromZapfloor
        ? areas.find((a) => a.id === place.id)
        : hotDeskPoisWithColor.find((a) => a.id === place.id && a.uniquePlaceName.includes('/')) // a.uniquePlaceName.includes('/') permet de déterminer si c'est un bureau
      if (!!poi) {
        MapViewer.setPlaceColor(place.uniquePlaceName, theme.colors.darkBlue)
        if (pl) {
          const POI = MapViewer.addPOI({
            position: { ...pl.vg.position, z: (pl.vg.position.z || 0) + 2 },
            url: '/map/pin.png',
            scale: { x: 1.5, y: 2 },
          })

          return () => {
            if (POI) {
              POI.remove()
            }
          }
        }
      }

      // Si on a une initialisation spécifique pour un uniquePlaceName (ex: cas de la localisation de contacts sur le plan)
      if (!!uniquePlaceInitial) {
        const initPl = MapViewer.getPlace(uniquePlaceInitial)
        MapViewer.setPlaceColor(uniquePlaceInitial, theme.colors.darkBlue)
        if (!!initPl) {
          const POI = MapViewer.addPOI({
            position: { ...initPl.vg.position, z: (initPl.vg.position.z || 0) + 2 },
            url: '/map/pin.png',
            scale: { x: 1.5, y: 2 },
          })

          return () => {
            if (POI) {
              POI.remove()
            }
          }
        }
      }
    }
  }, [place, navigating])

  /**
   * callback called when map is fully loaded and venue is ready
   */
  const onVenueLoaded = () => {
    const datas =
      MapViewer.getExtraData().resources[lang]?.localized.locale[lang] ||
      MapViewer.getExtraData().resources.default.localized.locale.default
    const bs = MapViewer.multiBuildingView.getVenueLayout().buildings

    const next: MapBuilding[] = bs.map((b) => ({
      id: b.id,
      name: datas.venueLayout[b.id] ? datas.venueLayout[b.id].name : b.id,
      floors: b.floors.map((f) => ({
        id: f.id,
        name: datas.venueLayout[f.id] ? datas.venueLayout[f.id].name : f.id,
      })),
    }))

    // get all buildings for venue
    setBuildings(next)
  }

  /**
   * callback called when map change building or floor
   * @param move the moving object from visioglobe
   */
  const onMove: EventActionCallback = (move) => {
    if (move.type === 'exploreStateWillChange') {
      setBuilding((move.args.target as MovePosition).buildingID)
      setFloor((move.args.target as MovePosition).floorID)
    }
  }

  const goTo = (building: string, floor?: string, placeId?: string) => {
    updatePlace(areas.find((p) => p.uniquePlaceName === placeId))
    setFloor(floor)
    setBuilding(building)
  }

  /**
   * update pois on map based on referentials
   * @param pois mapping id -> name
   */
  const updatePOIs = (pois: StatusV4[], isAccessible: boolean, isVisitor: boolean) => {
    if (!!user && !isVisitor) {
      const poisWithStatusColor = pois.map((p) => {
        const findPoi = areas.find((area) => area.id === p.id)
        const hasFullRights =
          !!findPoi &&
          !!user &&
          (findPoi.entity === user.type || (findPoi.entity === 'ALL' && user.type !== 'EXTERNAL'))
        return {
          ...p,
          statusColor: !hasFullRights ? undefined : isAccessible ? p.contrastedColorCode : p.colorCode,
        }
      })
      poisWithStatusColor.map((p) => {
        if ((!place || p.id !== place.id) && p.statusColor && !isModalFromZapfloor) {
          MapViewer.setPlaceColor(p.uniqueName, p.statusColor)
        }
      })
    }
  }

  /**
   * start route between 2 places
   * @param from starting place
   * @param to ending place
   * @param pmr should use PMR or not ?
   * @param lng translation for instructions
   */
  const computeRoute = (from: string, to: string, pmr: boolean, lng: string) => {
    // calculate route information
    return MapViewer.computeRoute({
      src: from,
      dst: to,
      language: lng,
      computeNavigation: true,
      routingParameters: {
        requestType: 'fastest',
        excludedAttributes: pmr ? PMR_EXCLUDE : [],
      },
    }).then((res) => {
      // create route
      const r = /* TODO MBA */ new (((window as unknown) as VGWindow).visioweb as any).Route(
        MapViewer,
        res.data
      ) as Route

      // route invalid
      if (!r.isValid() || res.data.status !== 200) {
        throw new Error('route invalid')
      }

      // navigate to start
      setBuilding('')
      updatePlace(undefined)
      setFloor(undefined)

      if (r.initialFloor) {
        MapViewer.multiBuildingView.goTo({ floorID: r.initialFloor, place: from })
      } else {
        MapViewer.multiBuildingView.goTo({ place: from })
      }

      // and show route
      r.show()

      // store route
      setRoute(r)
      setNav(r.navigation)

      // translate route navigation steps
      MapViewer.navigationTranslator.translateInstructions(res.data.navigation, lng)

      return res
    })
  }

  /**
   * navigate to next step
   */
  const nextRoute = () => {
    if (nav) {
      nav.displayNextInstruction()
    }
  }

  /**
   * navigate to previous step
   */
  const previousRoute = () => {
    if (nav) {
      nav.displayPrevInstruction()
    }
  }

  /**
   * stop route and reset navigation
   */
  const stopRoute = () => {
    if (route) {
      route.remove()
    }

    setNav(undefined)
    setRoute(undefined)
  }

  /**
   * Callback called when place is selected
   * @param ev the event
   * @param el the visioglobe element selected
   */
  const onClick = (ev: any, el: any) => {
    if (el.vg && el.vg.id) {
      setClickedPoiId(el.vg.id)
      if (!!selectDesk) {
        selectDesk(el.vg.id)
      }
    } else if (el.options && el.options() && el.options().id) {
      //Sur web, le clic sur les icônes est géré différemment
      setClickedPoiId(el.options().id)
    }
  }

  /**
   * Callback called when main map system is initialized
   */
  const onInit = () => {
    // start render and give dimensions
    MapViewer.start()
    MapViewer.resize(mapHeight ? mapHeight : height - HEADER_HEIGHT, mapWidth ? mapWidth : width)

    // set the map as multi building and multi floors
    MapViewer.setupMultiBuildingView({
      viewType: 'multibuilding',
      animationType: 'translation',
      container: ref.current!,
    })

    // set the translator
    MapViewer.setupNavigationTranslator(MapViewer.getPlaces())

    const { exploreParams, _extra } = MapViewer.getExtraData().app_params

    // set camera params
    MapViewer.camera.minRadius = _extra.minAltitude
    MapViewer.camera.maxRadius = _extra.maxAltitude
    MapViewer.camera.pitch = exploreParams.pitch
    MapViewer.multiBuildingView.globalModePitch = exploreParams.pitch
    MapViewer.multiBuildingView.buildingModePitch = exploreParams.pitch
    MapViewer.multiBuildingView.floorModePitch = exploreParams.pitch
    MapViewer.camera.pitchManipulatorEnabled = !_extra.lockPitch
    MapViewer.cameraDrivenExplorer.maxExploreDistance = 300.0
    MapViewer.cameraDrivenExplorer.setEnabled(true)

    // add listener on building/floor changed
    MapViewer.on('exploreStateWillChange', onMove)
    // start at global location
    MapViewer.multiBuildingView.goTo({ mode: 'global', noViewpoint: true })

    // venue loaded we try to find informations
    onVenueLoaded()

    // update status at initialized
    setStatus('initialized')
  }

  React.useEffect(() => {
    // add listener on initialization
    MapViewer.on('initializeCompleted', onInit)

    // download map and setup render
    MapViewer.load({
      path: `${BASE_URL}${hash}/descriptor.json`,
      cameraType: 'perspective',
      onObjectMouseUp: onClick,
      optimizations: OPTIMIZATIONS,
    }).then(() => {
      if (ref.current) {
        MapViewer.setupView(ref.current)
      }
    })
  }, [MapViewer])

  React.useEffect(() => {
    if (status === 'initialized') {
      // resize the map when container size changed
      MapViewer.resize(mapWidth || width, mapHeight || height - HEADER_HEIGHT)
    }
  }, [height, width, mapHeight, mapWidth, status, MapViewer])

  React.useEffect(() => {
    if (buildings.length === 1) {
      setBuilding(buildings[0].id)
    }
  }, [buildings, setBuildings])

  React.useEffect(() => {
    setNavigating(true)
    try {
      if (place) {
        MapViewer.multiBuildingView
          .goTo({
            place: place.uniquePlaceName,
            noViewpoint: isModalFromZapfloor && place.uniquePlaceName.includes('/'),
          })
          .finally(() => setNavigating(false))
      } else if (!isModalFromZapfloor && floor) {
        MapViewer.multiBuildingView.goTo({ mode: 'floor', floorID: floor }).finally(() => setNavigating(false))
      } else if (!isModalFromZapfloor && building) {
        MapViewer.multiBuildingView.goTo({ mode: 'global', buildingID: building }).finally(() => setNavigating(false))
      }
    } catch (err) {
      Logger.error(err)
    }
  }, [building, floor, place, MapViewer])

  const actions = {
    goTo,
    setPlace: updatePlace,
    updatePOIs,
    zoomTo: (props: ExploreState) => MapViewer.multiBuildingView.goTo(props),
  }

  const itinerary = {
    nav,
    route,
    computeRoute,
    stopRoute,
    nextRoute,
    previousRoute,
    from,
    setFrom,
    to,
    setTo,
  }

  return [ref, status, building, floor, place, buildings, actions, itinerary] as const
}

export default useMap
