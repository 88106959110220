import React, { useEffect } from 'react'
import { Detector } from 'react-detect-offline'
import i18next from 'i18next'
import Provider from 'theme/Provider'

import MainLayout from './layout/MainLayout'
import OfflineScreen from './layout/OfflineScreen'
import FullScreenLoader from 'components/modal/FullScreenLoader'

import { persistNecessaryStores, persistPreferencesStores } from 'store/store'
import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as i18nStore from 'i18n/i18n'

import api from 'i18n/api'

import { i18nextIsInitialized } from 'i18n/i18n'

import i18nUtils from 'utils/i18nUtils'

import 'theme/fonts.css'

const App = () => {
  const [rehydrated, setRehydrated] = React.useState(false)

  const displayFullLoader = useReducer(userStore.fullLoaderStore, (s) => s.fullLoader)
  const i18nKeys = useReducer(i18nStore.I18nKeysStore, (s) => s.i18nKeys)

  useEffect(() => {
    persistNecessaryStores().then(() => persistPreferencesStores().then(() => setRehydrated(true)))
  }, [])

  useEffect(() => {
    if (rehydrated && i18nextIsInitialized) {
      if (!!i18nKeys) {
        for (const [language, translations] of Object.entries(i18nKeys)) {
          i18next.addResourceBundle(language, 'translation', translations, true, true)
        }
      }
      api.getPoEditorTerms().then((res) => {
        if (res.translations) {
          const transformedTranslations = i18nUtils.transformTranslations(res.translations)
          i18nStore.I18nKeysActions.setI18nKeys(transformedTranslations)

          for (const [language, translations] of Object.entries(transformedTranslations)) {
            i18next.addResourceBundle(language, 'translation', translations, true, true)
          }
        }
      })
    }
  }, [rehydrated, i18nextIsInitialized])

  if (!rehydrated) {
    return null
  }

  return (
    <Provider>
      {displayFullLoader && <FullScreenLoader />}
      <Detector
        polling={false}
        render={({ online }: { online: boolean }) => (online ? <MainLayout /> : <OfflineScreen />)}
      />
    </Provider>
  )
}

export default App
