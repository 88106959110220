import { I18n } from 'i18n/types'

export const getNumberFromFloorName = (floorName: string) => {
  const numInName = floorName.match(/^\d+|\d+\b|\d+(?=\w)/g)
  if (numInName) {
    const num = parseInt(numInName[0])
    return floorName.includes('Sous-sol') || floorName.includes('Underground') ? -num : num // Visioglobe ne nous renvoie que du français ou de l'anglais
  } else return 0
}

export const getTimeRounded = (i18n: I18n, time: number) => {
  const roundedTime = Math.round(time / 60)

  if (roundedTime < 1) {
    return i18n.t('screens.cartography.destinationNear')
  }
  return i18n.t('screens.cartography.itineraryTime', { count: roundedTime })
}

export const getDistanceRounded = (i18n: I18n, distance: number) => {
  const roundedDistance = Math.round(distance)

  return i18n.t('screens.cartography.itineraryDistance', { count: roundedDistance })
}
