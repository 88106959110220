import * as React from 'react'
import styled from 'styled-components'
import useTheme from 'theme/useTheme'

import Icons from 'components/icons/Icons'

interface Props {
  iconName: IconName
  onClick?: () => void
  size?: number
  backgroundColor?: string
  iconColor?: string
  iconSize?: number
  shadow?: boolean
}

const PhoneNavigationShortcut = ({
  iconName,
  onClick,
  size = 50,
  backgroundColor,
  iconColor,
  iconSize = 28,
  shadow = false,
}: Props) => {
  const [Theme] = useTheme()
  return (
    <MainContainer
      size={size}
      backgroundColor={backgroundColor || Theme.colors.white}
      shadow={shadow}
      onClick={onClick}>
      <Icons name={iconName} color={iconColor || Theme.colors.darkGrey} size={iconSize} />
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')<{ size: number; backgroundColor: string; shadow: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${(props) => props.size / 2}px;
  background-color: ${(props) => props.backgroundColor};
  ${(props) => props.shadow && 'box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.13)'};
`

export default PhoneNavigationShortcut
