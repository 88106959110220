import React from 'react'

import { roundToNearestMinutes, addMinutes } from 'date-fns'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useReducer from 'store/useReducer'
import * as themeStore from 'theme/store'
import * as userStore from 'store/user/user'
import * as featureStore from 'sites/store/featureStore'
import * as mapStore from './mapStore'
import * as siteStore from 'sites/store/siteStore'
import * as roomStore from 'roombooking/roomStore'

import useI18n from 'i18n/useI18n'

import { ItineraryProps } from './useMap'
import { TagName } from './Map'
import { PlaceDetailsShortcuts } from './types'

import useBreakpoint from 'utils/useBreakpoints'
import Logger from 'utils/Logger'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import roomBookingUtils from 'roombooking/utils'
import { hasTheRightDistributionList } from 'utils/graphUtils'
import analytics, { analyticsKeys, analyticsActionKeys, externalLinkTracking } from 'utils/analytics'
import { findLevelWording } from 'intervention/utils'

import Icons from 'components/icons/Icons'
import InteractiveToast from 'components/toast/InteractiveToast'
import Modal from 'components/modal/Modal'
import Shortcut from 'components/button/Shortcut'
import ValidationContent from 'components/modal/ValidationContent'
import Button from 'components/button/Button'

import RoomRequests from 'intervention/modal/RoomRequests'

import apiBookings from 'roombooking/api/roomApi'
import graphApi from 'roombooking/api/graphRoomApi'
import { MeetingGraph } from 'roombooking/types'

interface Props {
  poi?: AreaV4
  setSelectedTab: (t: TagName) => void
  isVisitor: boolean
  navigation: Navigation
  itinerary: ItineraryProps
}

const PlaceDetails = ({ poi, setSelectedTab, isVisitor, navigation, itinerary }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const [, currentBreakpoint] = useBreakpoint()
  const user = useReducer(userStore.store, (shortcuts) => shortcuts.user)
  const myFeatures = useReducer(featureStore.store, (f) => f.features)
  const isAccessible = useReducer(themeStore.store, (shortcuts) => shortcuts.theme) === 'accessible'

  const site = useReducer(siteStore.store, (s) => s.site)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)

  const [imageError, setImageError] = React.useState(false)
  const [statusColor, setStatusColor] = React.useState(Theme.colors.darkGrey)
  const [statusName, setStatusName] = React.useState<string>()
  const [availabilityForNextHour, setAvailabilityForNextHour] = React.useState<number>()
  const [isLoading, setIsLoading] = React.useState(false)
  const [isLoadingAvailability, setIsLoadingAvailability] = React.useState(false)

  const isImage = React.useMemo(() => (!!poi ? roomBookingUtils.isPlaceTypeIsImage(poi.placeType) : false), [poi])

  const hasFullRights = React.useMemo(
    () => !!poi && !!user && (poi.entity === user.type || (poi.entity === 'ALL' && user.type !== 'EXTERNAL')),
    [poi, user]
  )

  const canBook = React.useMemo(
    () =>
      !!poi && poi.bookable && !isVisitor && poi.office365Id && hasFullRights && hasTheRightDistributionList(poi, user),
    [poi, hasFullRights, user, isVisitor]
  )

  const feature = React.useMemo(() => myFeatures.map((f) => f.type).indexOf('INCIDENT') > -1, [myFeatures])

  const shortcuts: PlaceDetailsShortcuts[] = React.useMemo(() => {
    const shortcutsToDisplay: PlaceDetailsShortcuts[] = []
    if (poi) {
      shortcutsToDisplay.push({
        iconName: 'itinerary',
        label: 'itinerary',
        onClick: () => {
          analytics.track({
            screenName: analyticsKeys.map,
            userType: user?.type,
            currentSite: site,
            mySites,
            action: analyticsActionKeys.poiItineraryClickButton,
          })
          mapStore.actions.setItineraryStatus('notStarted')
          itinerary.stopRoute()
          itinerary.setTo(poi)
          setSelectedTab('itinerary')
        },
      })
      if (poi.hasIntervention && hasFullRights && !isVisitor && feature) {
        shortcutsToDisplay.push({
          iconName: 'intervention',
          label: 'requestIntervention',
          onClick: () => {
            analytics.track({
              screenName: analyticsKeys.map,
              userType: user?.type,
              currentSite: site,
              mySites,
              action: analyticsActionKeys.poiInterventionClickButton,
            })
            Modal.open(() => <RoomRequests navigation={navigation} room={poi} origin="plans" />)
          },
        })
      }
      if (!!poi.buttonTitle && !!poi.webButtonLink && !isVisitor && hasFullRights) {
        shortcutsToDisplay.push({
          iconName: 'globe',
          label: poi.buttonTitle,
          onClick: () => {
            externalLinkTracking(poi.webButtonLink!, analyticsKeys.map, user, site, mySites, poi.buttonTitle)
            window.open(poi.webButtonLink)
          },
        })
      }
      if (!!poi.button2Title && !!poi.webButton2Link && !isVisitor && hasFullRights) {
        shortcutsToDisplay.push({
          iconName: 'globe',
          label: poi.button2Title,
          onClick: () => {
            externalLinkTracking(poi.webButton2Link!, analyticsKeys.map, user, site, mySites, poi.button2Title)
            window.open(poi.webButton2Link)
          },
        })
      }
    }
    return shortcutsToDisplay
  }, [poi])

  React.useEffect(() => {
    if (poi) {
      setStatusColor((isAccessible ? poi.contrastedColorCode : poi.colorCode) || '')
      setStatusName(roomBookingUtils.getStatusName(poi.occupationStatus, poi.bookable, poi.booked))
    }
  }, [poi, isAccessible])

  React.useEffect(() => {
    setImageError(false)
  }, [poi])

  React.useEffect(() => {
    if (canBook && poi && poi.office365Id && !!user) {
      setIsLoadingAvailability(true)
      apiBookings
        .findAvailabilityForNextHour(user.type, poi.office365Id)
        .then((res) => {
          if (res.value.length > 0) {
            setAvailabilityForNextHour(roomBookingUtils.checkAvailabilityForNextHour(res.value[0].scheduleItems))
          }
        })
        .finally(() => setIsLoadingAvailability(false))
    }
  }, [canBook, user, poi])

  const openModal = (title: string, showCancel: boolean, onConfirm?: () => void, confirmTitle?: string) =>
    Modal.open(() => (
      <ValidationContent
        title={title}
        onConfirm={() => {
          Modal.close()
          if (!!onConfirm) {
            onConfirm()
          }
        }}
        cancelButton={showCancel}
        confirmButtonTitle={confirmTitle}
        font={Theme.fonts.label}
      />
    ))

  const bookForNextMinutes = (minutes: number) => {
    if (poi && poi.office365Id && !!site && !!user) {
      const NOW = roundToNearestMinutes(new Date(), { nearestTo: 15 })
      const currentLocationTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

      const event: MeetingGraph = {
        start: {
          dateTime: addMinutes(NOW, -new Date().getTimezoneOffset()).toISOString().replace('Z', ''),
          timeZone: currentLocationTimeZone,
        },
        end: {
          dateTime: addMinutes(addMinutes(NOW, minutes), -new Date().getTimezoneOffset())
            .toISOString()
            .replace('Z', ''),
          timeZone: currentLocationTimeZone,
        },
        location: {
          displayName: poi.office365Id,
          locationEmailAddress: poi.office365Id,
        },
        attendees: [
          {
            emailAddress: {
              name: poi.office365Id,
              address: poi.office365Id,
            },
            type: 'required',
          },
        ],
        subject: i18n.t('screens.room.defaultSubject'),
        isOnlineMeeting: true,
      }

      setIsLoading(true)

      graphApi
        .bookRoom(user.type, event)
        .then((res) => {
          InteractiveToast.close()
          roomStore.actions.setGraphIdBooking(res.id)
          roomStore.actions.setGraphEmailBooking(poi.office365Id!)
          InteractiveToast.open(
            i18n.t('screens.room.toast.waitingTitle'),
            i18n.t('screens.room.toast.waitingDescription'),
            'info-circle',
            Theme.colors.toastInfoText,
            Theme.colors.toastInfoBackground,
            currentBreakpoint === 'phone' ? 'bottom' : 'top-right',
            navigation
          )
          navigation.push('/room', {
            openBookings: true,
          })
        })
        .catch((err) => {
          Logger.error(err)
          openModal(i18n.t('screens.room.reservationFailed'), true)
        })
        .finally(() => setIsLoading(false))
    }
  }

  const renderBookButtons = () => {
    if (!canBook) {
      return null
    }
    return (
      <BottomBookButtonsContainer>
        <BookingButtonContainer>
          <Button
            label={i18n.t(
              !availabilityForNextHour
                ? 'common.book'
                : `screens.cartography.bookFor${availabilityForNextHour === 60 ? '1h' : 'Xmn'}`,
              {
                minutes: availabilityForNextHour,
              }
            )}
            onClick={() => {
              if (!!availabilityForNextHour) {
                bookForNextMinutes(availabilityForNextHour)
              }
            }}
            disabled={!availabilityForNextHour}
            loading={isLoading || isLoadingAvailability}
            font={Theme.fonts.h3Bold}
          />
        </BookingButtonContainer>
        <BookingButtonContainer>
          <Button
            label={i18n.t('screens.cartography.seeAvailability')}
            onClick={() => {
              analytics.track({
                screenName: analyticsKeys.map,
                userType: user?.type,
                currentSite: site,
                mySites,
                action: analyticsActionKeys.poiRoomBookingClickButton,
              })
              if (!!poi) {
                navigation.push(`/room/${poi.id}`)
              }
            }}
            shadow
            font={Theme.fonts.h3Bold}
            color={Theme.colors.white}
            textColor={Theme.colors.blue}
          />
        </BookingButtonContainer>
      </BottomBookButtonsContainer>
    )
  }

  return (
    <>
      <MainContainer>
        <CrossContainer>
          <BackIconContainer onClick={() => setSelectedTab('search')}>
            <Icons name="chevron_left" size={20} color={Theme.colors.blue} />
          </BackIconContainer>
          <CrossIconContainer onClick={() => setSelectedTab(undefined)}>
            <Icons name="cross" size={30} color={Theme.colors.blue} />
          </CrossIconContainer>
        </CrossContainer>
        {poi ? (
          <>
            <PoiContainer>
              <ImageContainer>
                {imageError || !poi.picture ? (
                  !isImage ? (
                    <Icons
                      name={roomBookingUtils.getRoomPlaceholder(poi.placeType)}
                      color={Theme.colors.blue}
                      size={100}
                    />
                  ) : (
                    <Photo src={roomBookingUtils.getRoomImagePlaceholder(poi.placeType)} aria-hidden alt="" />
                  )
                ) : (
                  <Photo src={poi.picture} onError={() => setImageError(true)} aria-hidden alt="" />
                )}
              </ImageContainer>
              <InfoContainer>
                <PlaceName>{poi.name}</PlaceName>
                {!!poi.description && <PlaceDescription>{poi.description}</PlaceDescription>}
                {!!poi.capacity && !isVisitor && hasFullRights && (
                  <CapacityContainer>
                    <Icons name="user" size={14} color={Theme.colors.blue} ariaLabel={i18n.t('label.svg.capcity')} />
                    <PlaceCapacity>{i18n.t('screens.cartography.capacity', { count: poi.capacity })}</PlaceCapacity>
                  </CapacityContainer>
                )}
                {
                  <CapacityContainer>
                    <Icons name="building" size={14} color={Theme.colors.blue} />
                    <PlaceCapacity>{findLevelWording(poi.floor, i18n, poi.floorCustomName)}</PlaceCapacity>
                  </CapacityContainer>
                }
                {!isVisitor &&
                  hasFullRights &&
                  !poi.hasCountingSensor &&
                  !poi.groupedSensor &&
                  !!statusName &&
                  statusName !== 'UNKNOWN' &&
                  user &&
                  user.type !== 'EXTERNAL' &&
                  (user.type === poi.entity || poi.entity === 'ALL') && (
                    <RoomStatusContainer>
                      <StatusIndicator statusColor={statusColor} />
                      <StatusText statusColor={statusColor}>
                        {i18n.t([`screens.room.status.${statusName}`, 'screens.room.status.UNKNOWN'])}
                      </StatusText>
                    </RoomStatusContainer>
                  )}
                {!!poi.equipments &&
                  hasFullRights &&
                  poi.equipments.length > 0 &&
                  poi.equipments[0] !== '' &&
                  !isVisitor && (
                    <EquipmentsContainer>
                      <EquipmentTitle>{i18n.t('screens.cartography.equipment')}</EquipmentTitle>
                      <EquipmentsList>
                        {poi.equipments.map((e) => (
                          <Equipment key={e}>{e}</Equipment>
                        ))}
                      </EquipmentsList>
                    </EquipmentsContainer>
                  )}
                {poi.departments.length > 0 && hasFullRights && poi.departments[0] !== '' && !isVisitor && (
                  <EquipmentsContainer>
                    <EquipmentTitle>{i18n.t('screens.cartography.entities')}</EquipmentTitle>
                    <EquipmentsList>
                      {poi.departments.map((e) => (
                        <Equipment key={e}>{e}</Equipment>
                      ))}
                    </EquipmentsList>
                  </EquipmentsContainer>
                )}
              </InfoContainer>
              {shortcuts.length > 0 && (
                <ShortcutsMainContainer>
                  {shortcuts.map((s, index) => (
                    <Shortcut
                      key={index}
                      color={Theme.colors.blue}
                      onClick={s.onClick}
                      iconName={s.iconName}
                      circleSize={30}
                      iconSize={20}
                      label={s.iconName === 'globe' ? s.label : i18n.t(`screens.cartography.${s.label}`)}
                      font={Theme.fonts.labelBold}
                      thirdParty
                      margin={12}
                    />
                  ))}
                </ShortcutsMainContainer>
              )}
            </PoiContainer>
            {renderBookButtons()}
          </>
        ) : (
          <InfoContainer>
            <ErrorMessage>{i18n.t('errors.map.details')}</ErrorMessage>
          </InfoContainer>
        )}
      </MainContainer>
    </>
  )
}

export default PlaceDetails

// CONTAINERS

const PoiContainer = styled.div`
  padding: 20px 0px;
  display: inline-block;
  flex: 1;
`

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0px 20px;
  overflow-y: scroll;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-top: 10px;
    margin-bottom: 50px;
  }
`
const BottomBookButtonsContainer = styled('div')`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`
const BookingButtonContainer = styled('div')`
  margin-bottom: 18px;
  display: flex;
  flex: 1;
  height: 52px;
`
const ImageContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 330px;
  height: 170px;
  background-color: ${(props) => props.theme.colors.mediumDarkGrey};
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 100%;
    margin-top: 10px;
  }
`
const InfoContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`
const CapacityContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 8px;
`
const EquipmentsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`
const EquipmentsList = styled('ul')`
  margin: 3px 0px 0px 0px;
  padding-left: 20px;
`
const CrossContainer = styled('div')`
  display: none;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    display: flex;
  }
`
const CrossIconContainer = styled('button')``
const BackIconContainer = styled('button')``
const RoomStatusContainer = styled('div')`
  display: flex;
  align-items: center;
  margin: 15px 0px 7px 0px;
`

// TEXTES

const PlaceName = styled('h1')`
  ${(props) => props.theme.fonts.bodyBold};
  font-size: 24px;
  line-height: 27px;
  color: ${(props) => props.theme.colors.blue};
  margin: 0px;
`
const PlaceDescription = styled('h2')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
  margin-top: 8px;
  margin-bottom: 0px;
`
const PlaceCapacity = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  font-size: 12px;
  line-height: 13px;
  margin: 0px 0px 0px 5px;
`
const Equipment = styled('li')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.darkGrey};
`
const EquipmentTitle = styled('p')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
`
const ErrorMessage = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin-left: 10px;
  text-align: center;
`
const StatusText = styled('p')<{ statusColor: string }>`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.statusColor};
  margin: 0px;
`

// AUTRES

const Photo = styled('img')`
  width: 330px;
  height: 170px;
  object-fit: cover;
`
const StatusIndicator = styled('div')<{ statusColor: string }>`
  width: 5px;
  height: 5px;
  background-color: ${(props) => props.statusColor};
  border-radius: 2px;
  margin: 3px 5px 0px 5px;
`

const ShortcutsMainContainer = styled('div')<{ unbookable?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
