import * as React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as surveysStore from 'surveys/store'
import * as siteStore from 'sites/store/siteStore'

import Loader from 'react-loader-spinner'
import { compareAsc } from 'date-fns'

import useNavigation from 'utils/navigation/useNavigation'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import stringUtils from 'utils/strings'

import Icon from 'components/icons/Icons'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import TitleHelmet from 'components/title/TitleHelmet'

import api from './api'

const SurveysScreen = () => {
  const i18n = useI18n()
  const [theme] = useTheme()
  const navigation = useNavigation()

  const surveys = useReducer(surveysStore.store, (s) => s.surveys)
  const user = useReducer(userStore.store, (s) => s.user)
  const currentSite = useReducer(siteStore.store, (s) => s.site)

  const [statusScreen, setStatusScreen] = React.useState<'loading' | 'error' | 'ok'>('loading')

  const orderedSurveys = React.useMemo(
    () => surveys.sort((a, b) => compareAsc(new Date(a.endDate), new Date(b.endDate))),
    [surveys]
  )

  React.useEffect(() => {
    if (!!user && !!currentSite) {
      setStatusScreen('loading')
      api
        .getSurveysList(user.type, currentSite.id)
        .then((res) => {
          setStatusScreen('ok')
          surveysStore.actions.setSurveys(res.surveys)
        })
        .catch(() => setStatusScreen('error'))
    }
  }, [i18n.lang])

  React.useEffect(() => {
    // Si 1 seul sondage, on redirige
    // directement vers ce sondage
    if (statusScreen === 'ok' && surveys.length === 1) {
      navigation.replace(`/surveys/${surveys[0].id}`)
    }
  }, [surveys, statusScreen])

  const renderSurvey = (item: Survey, index: number) => (
    <>
      <SurveyContainer
        onClick={() => navigation.push(`/surveys/${item.id}`)}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            navigation.push(`/surveys/${item.id}`)
          }
        }}>
        <InfosContainer>
          <TitleSurvey>{item.title}</TitleSurvey>
          {!!item.description && (
            <DescriptionContainer>
              <Description>{stringUtils.getTextFromHTML(item.description)}</Description>
            </DescriptionContainer>
          )}
          <DescriptionContainer>
            <EndDate>
              {i18n.t('screens.surveys.endDate', {
                date: new Date(item.endDate),
              })}
            </EndDate>
          </DescriptionContainer>
        </InfosContainer>

        <IconContainer>
          <Icon name="chevron_right" size={30} color={theme.colors.blue} />
        </IconContainer>
      </SurveyContainer>
      {index < orderedSurveys.length - 1 && <Separator role="presentation" />}
    </>
  )

  if (statusScreen === 'loading') {
    return (
      <LoaderContainer>
        <Loader type="TailSpin" color={theme.colors.blue} />
      </LoaderContainer>
    )
  }

  if (statusScreen === 'error' || surveys.length === 0) {
    return (
      <ErrorContainer>
        <ErrorText>{i18n.t(`screens.surveys.${statusScreen === 'error' ? 'error' : 'noSurvey'}`)}</ErrorText>
      </ErrorContainer>
    )
  }

  return (
    <>
      <TitleHelmet title={i18n.t('screens.surveys.title')} />
      <Breadcrumb screen="surveys" path="surveys" navigation={navigation} />
      <MainContainer>
        <TitleContainer>
          <Title>{i18n.t('screens.surveys.title')}</Title>
        </TitleContainer>
        {orderedSurveys.map(renderSurvey)}
      </MainContainer>
    </>
  )
}

export default SurveysScreen

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 60px;
  margin-left: 5%;
  margin-right: 10%;
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    margin-right: 2%;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    margin-right: 4%;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-left: 4%;
  }
`

const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`

const ErrorContainer = styled('div')`
  padding: 30px 50px;
  min-height: calc(100vh - 176px);
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 10px 10px;
    min-height: calc(100vh - 136px);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const ErrorText = styled('p')`
  ${(props) => props.theme.fonts.body};
  font-size: 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    text-align: center;
  }
`

const TitleContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    margin-top: 70px;
  }
`

const Separator = styled('div')`
  width: 60px;
  height: 3px;
  background-color: ${(props) => props.theme.colors.blue};
  margin: 0px;
  border-radius: 2px;
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 24px;
  margin: 0px;
`

const SurveyContainer = styled('div')`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  min-height: 70px;
  margin: 20px 0px;
  padding: 20px;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`

const InfosContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 26px;
`

const DescriptionContainer = styled('div')`
  margin-top: 10px;
`

const TitleSurvey = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
  margin: 0px;
`

const Description = styled('div')`
  ${(props) => props.theme.fonts.label};
  margin: 0px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`

const EndDate = styled('p')`
  ${(props) => props.theme.fonts.labelBold};
  margin: 0px;
`

const IconContainer = styled('div')``
