import * as React from 'react'
import styled from 'styled-components'

import useReducer from 'store/useReducer'
import * as iframeStore from './iframeStore'

import { windowSizeBreakpoints } from 'utils/breakpoints'

const IFrame = () => {
  const iframeLink = useReducer(iframeStore.store, (s) => s.iframeURL)

  React.useEffect(() => {
    return iframeStore.actions.resetIFrameLink
  }, [])

  return (
    <MainContainer>
      <IFrameContainer src={iframeLink} />
    </MainContainer>
  )
}

const MainContainer = styled('div')`
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  height: calc(100vh - 60px);
  width: 100%;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 100%;
  }
`
const IFrameContainer = styled('iframe')`
  width: 100%;
  height: 100%;
  border: 0;
`

export default IFrame
