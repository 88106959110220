import * as React from 'react'
import styled from 'theme/styled-components'
import config from 'core/src/config'
import { useLocation } from 'react-router-dom'
import useI18n from 'i18n/useI18n'

const OnlyMoovMobile = () => {
  const query = new URLSearchParams(useLocation().search)
  const mapHeight = parseInt(query.get('height') || '1000')
  const i18n = useI18n()

  const ONLYMOOV_LINK = `https://www.onlymoov.com/personnalisation-widget-carte-multimodale/?type=14778421378&displayMode=0&key=${config.ONLYMOOV_KEY}&zoom=1&defaultZoom=15&searchA=1&modeC=1&previsionC=1&layersC=1&lat=45.727800&lng=4.823931&address=Avenue%20Tony%20Garnier%2C%20Lyon&showPOICenter=1&vp=journey-time%7Ctraffic%7Cevent%7Ccamera&tc=tcl%7Cparking&velo=cycleroad%7Cvelov&pedestrian=walking&height=${mapHeight}`

  return (
    <OnlymoovContainer mapHeight={mapHeight}>
      <iframe
        title={i18n.t('label.ariaLabel.transport.onlymoov')}
        src={ONLYMOOV_LINK}
        width="100%"
        height="100%"
        scrolling="no"
        style={{ border: 0 }}
      />
    </OnlymoovContainer>
  )
}

export default OnlyMoovMobile

const OnlymoovContainer = styled('div')<{ mapHeight: number }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: ${(props) => props.mapHeight}px;
  overflow: hidden;
`
