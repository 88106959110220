import Webservice from 'utils/Webservice'
import { assureToken } from 'authent365/utils/utils'
import config from 'core/src/config'

const api = {
  traffic: (siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<TransportStationsV6>(
        userType,
        `${config.globalAPIVersion}/transport/arrivals?siteId=${siteId}`,
        'GET',
        {
          Authorization: `Bearer ${token}`,
        }
      )
    ),
  bikes: (siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<BikeStationsV6>(userType, `${config.globalAPIVersion}/transport/bikes?siteId=${siteId}`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  trafficPublic: (siteId: string) =>
    Webservice<TransportStationsV6>(
      'ENGIE',
      `${config.globalAPIVersion}/public/totems/transport/arrivals?siteId=${siteId}`,
      'GET',
      {
        'X-Api-Key': config.API_KEY,
      }
    ),
  bikesPublic: (siteId: string) =>
    Webservice<BikeStationsV6>(
      'ENGIE',
      `${config.globalAPIVersion}/public/totems/transport/bikes?siteId=${siteId}`,
      'GET',
      {
        'X-Api-Key': config.API_KEY,
      }
    ),
}

export default api
