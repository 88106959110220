import * as React from 'react'
import styled from 'theme/styled-components'

import useI18n from 'i18n/useI18n'
import useNavigation from 'utils/navigation/useNavigation'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import analytics, { analyticsKeys } from 'utils/analytics'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'
import * as featureStore from 'sites/store/featureStore'
import * as iframeStore from 'iframe/iframeStore'

import RestaurantImage from 'restaurants/RestaurantImage'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import TitleHelmet from 'components/title/TitleHelmet'
import Modal from 'components/modal/Modal'
import ValidationContent from 'components/modal/ValidationContent'

import { getScreenNameFromFeatureType } from 'home/utils'
import screens from 'core/src/layout/Screens'

const OtherServicesScreen = () => {
  const i18n = useI18n()
  const navigation = useNavigation()
  const user = useReducer(userStore.store, (s) => s.user)
  const site = useReducer(siteStore.store, (s) => s.site)
  const allSiteStore = useReducer(siteStore.store, (s) => s)
  const mySites = allSiteStore.mySites
  const features = useReducer(featureStore.store, (f) => f.features)
  const parkingUrl = useReducer(siteStore.store, (s) => s.parkingUrl)

  const otherServicesFeatures = React.useMemo(
    () => features.filter((f) => f.presentOtherService && f.type !== 'DOMOTIC' && f.type !== 'RELEASE'),
    [features]
  )

  React.useEffect(() => {
    if (user && site) {
      analytics.track({
        screenName: analyticsKeys.otherServices,
        userType: user.type,
        currentSite: site,
        mySites,
      })
    }
  }, [])

  const goToDetails = (service: FeatureV4) => {
    if (service.type === 'THIRD_PART') {
      if (service.webUrlType === 'EXTERNAL') {
        analytics.track({
          screenName: analyticsKeys.thirdPartyApp,
          userType: user?.type || 'EXTERNAL',
          currentSite: site,
          mySites,
          label: service.webUrl,
          thirdPartyLabel: service.name,
        })
        window.open(service.webUrl)
      } else {
        iframeStore.actions.setIFrameLink(service.webUrl || '')
        iframeStore.actions.setCurrentIFrame(service.name)
        analytics.track({
          screenName: analyticsKeys.thirdPartyApp,
          userType: user?.type || 'EXTERNAL',
          currentSite: site,
          mySites,
          label: service.webUrl,
          thirdPartyLabel: service.name,
        })
        navigation.push('/iframe')
      }
    } else if (service.type === 'PARKING') {
      if (site?.id === '1') {
        window.open(parkingUrl)
      } else {
        Modal.open(() =>
          ValidationContent({ title: i18n.t('errors.parking.unavailable'), onConfirm: () => Modal.close() })
        )
      }
    } else {
      const featuresTypes = features.map((f: FeatureV4) => getScreenNameFromFeatureType(f.type))
      const featuredItems: ScreenDefinition[] = Object.keys(screens)
        .map((key) => screens[key])
        .filter((screen) => screen.drawer && featuresTypes.indexOf(screen.name) !== -1)
      const correspondingItem = featuredItems.find((s) => s.name === getScreenNameFromFeatureType(service.type))
      if (!!correspondingItem) {
        navigation.push(correspondingItem.path)
      }
    }
  }

  const renderService = (service: FeatureV4) => {
    if (!service.otherServiceDescription || !service.otherServiceImageUrl) {
      return null
    }

    const urlImage = service.otherServiceImageUrl.startsWith('/')
      ? service.otherServiceImageUrl
      : `/${service.otherServiceImageUrl}`

    return (
      <ServiceContainer
        key={service.id}
        onClick={() => goToDetails(service)}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            goToDetails(service)
          }
        }}>
        <ImageContainer>
          <RestaurantImage width={372} height={230} imageURL={urlImage} />
        </ImageContainer>
        <InfosContainer>
          <Name>{service.name}</Name>
          <DescriptionText dangerouslySetInnerHTML={{ __html: service.otherServiceDescription }} />
        </InfosContainer>
      </ServiceContainer>
    )
  }

  return (
    <>
      <TitleHelmet title={i18n.t('screens.other_services.title')} />
      <Breadcrumb screen="other_services" path="otherServices" navigation={navigation} />

      <ServicesContainer>
        <ServicesListContainer>{otherServicesFeatures.map((service) => renderService(service))}</ServicesListContainer>
      </ServicesContainer>
    </>
  )
}

export default OtherServicesScreen

// CONTAINERS

const ServicesContainer = styled('div')`
  margin: 20px 60px 40px 60px;
  display: flex;
  flex-direction: column;
`

const ServicesListContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  flex: 1;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    align-items: center;
    padding-top: 0px;
    margin-left: 0px;
  }
`
const ServiceContainer = styled('div')`
  width: 372px;
  cursor: pointer;
  margin: 0px;
  padding-bottom: 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.13);
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin: 20px 0px 0px 0px;
  }
`
const ImageContainer = styled('div')`
  display: flex;
  height: 230px;
  width: 372px;
  background-color: ${(props) => props.theme.colors.placeholderGrey};
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 100%;
  }
`

// TEXTES

const Name = styled('p')`
  ${(props) => props.theme.fonts.thinTitle}
  margin: 0px;
  overflow: hidden;
  white-space: wrap;
  text-overflow: ellipsis;
`
const DescriptionText = styled.p`
  ${(props) => props.theme.fonts.labelBold};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
`

const InfosContainer = styled('div')`
  margin-left: 16px;
  padding-top: 20px;
`
