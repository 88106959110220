import React from 'react'
import useI18n from 'i18n/useI18n'

import useReducer from 'store/useReducer'
import * as shortcutStore from './store/shortcutStore'
import * as siteStore from 'sites/store/siteStore'
import * as featureStore from 'sites/store/featureStore'

import Modal from 'components/modal/Modal'
import PickerModalContent from 'components/picker/PickerModalContent'

import { getScreenNameFromFeatureType } from 'home/utils'

const ModalContent = (hasSitesShortcut: boolean, allShortcuts: ProcessedFeatures[]) => {
  const i18n = useI18n()
  const site = useReducer(siteStore.store, (s) => s.site)

  const siteShorcuts = site ? useReducer(shortcutStore.store, (s) => s.sitesShortcuts[site.id]) : undefined
  const userFeature = useReducer(featureStore.store, (f) => f.features).map((f) => getScreenNameFromFeatureType(f.type))

  const availableShortcuts = allShortcuts
    .filter(
      (f) =>
        f.name === 'account' ||
        userFeature.indexOf(f.name) > -1 ||
        f.path === undefined ||
        (hasSitesShortcut && f.name === 'sites' && (hasSitesShortcut || userFeature.indexOf('sites') === -1))
    )
    .map((feature) => {
      return {
        id: feature.id,
        label: !!feature.path ? i18n.t(`screens.${feature.name.toLowerCase()}.title`) : feature.name,
        name: feature.name,
      }
    })

  const remainingShortcuts = availableShortcuts.filter(
    (n) => siteShorcuts && siteShorcuts.list.map((s) => s.name).indexOf(n.name) < 0
  )

  const addShortcut = (label: string, id?: string) => {
    if (siteShorcuts && site) {
      const updatedShortcut = {
        siteId: site.id,
        list: [
          ...siteShorcuts.list.filter((s1) => allShortcuts.find((s2) => s1.name === s2.name)),
          { id: id, name: label },
        ],
        isDefault: false,
      }
      shortcutStore.actions.setSiteShortcuts(updatedShortcut)
      if (updatedShortcut.list.length === 4 || remainingShortcuts.length === 1) {
        Modal.close()
      }
    }
  }

  return (
    <PickerModalContent
      close={Modal.close}
      options={remainingShortcuts.map((s) => ({ label: s.label, value: s }))}
      selectOption={(val) => addShortcut(val.name, val.id)}
      title={i18n.t('screens.home.chooseShortcut')}
      generateAriaLabel={(opt) => i18n.t('label.ariaLabel.home.addShortcut', { label: opt.label })}
    />
  )
}

export default ModalContent
