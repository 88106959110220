import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'

import api from './apiEngieDirectory'

import Logger from 'utils/Logger'
import { windowSizeBreakpoints } from 'utils/breakpoints'

import useReducer from 'store/useReducer'
import * as contactStore from 'directory/contactStore'
import * as siteStore from 'sites/store/siteStore'
import * as userStore from 'store/user/user'

import ContactInfos from './ContactInfosEngie'

import Loader from 'react-loader-spinner'

type Status = 'ok' | 'error' | 'loading'

interface Props {
  onSelectContact: (contact: EngieUserV4) => void
}

const FavoriteContactsEngie = ({ onSelectContact }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const site = useReducer(siteStore.store, (s) => s.site)
  const user = useReducer(userStore.store, (s) => s.user)
  const favorites = useReducer(contactStore.store, (s) => s.favorites).filter(
    (favorite) => !!favorite.engieUserId
  ) as EngieFavorite[]

  const [favoritesProfile, setFavoritesProfile] = React.useState<EngieUserV4[]>([])
  const [status, setStatus] = React.useState<Status>()

  const getFavoriteUsers = (favs: EngieFavorite[]) => {
    if (user && site) {
      setStatus('loading')
      if (favs.length === 0) {
        setFavoritesProfile([])
        setStatus('ok')
      } else {
        api
          .getUsers(
            site.id,
            user.type,
            favs.map((f) => f.engieUserId)
          )
          .then((res) => {
            setFavoritesProfile(res.users)
            setStatus('ok')
          })
          .catch((err) => {
            setStatus('error')
            Logger.error(err)
          })
      }
    }
  }

  React.useEffect(() => {
    getFavoriteUsers(favorites)
  }, [])

  const sortContactsByLetter = React.useMemo(() => {
    if (favoritesProfile) {
      return favoritesProfile.reduce((acc, cur) => {
        const index = acc.findIndex((u) => u.letter === cur.lastName.charAt(0).toUpperCase())
        if (index > -1) {
          acc[index].users.push(cur)
        } else {
          acc.push({ letter: cur.lastName.charAt(0).toUpperCase(), users: [cur] })
        }
        return acc
      }, [] as EngieUsersByLetters[])
    }
  }, [favoritesProfile])

  return (
    <MainContainer>
      {status === 'loading' ? (
        <LoaderContainer>
          <Loader type="TailSpin" color={Theme.colors.blue} />
        </LoaderContainer>
      ) : status === 'ok' ? (
        sortContactsByLetter && sortContactsByLetter.length > 0 ? (
          <ListContainer>
            {sortContactsByLetter.map((data) => (
              <div key={data.letter}>
                <CategoryLetter>{data.letter}</CategoryLetter>
                {data.users
                  .sort((a, b) => a.lastName.localeCompare(b.lastName))
                  .map((u, i) => (
                    <ContactInfos
                      contact={u}
                      isLast={data.users.length === i + 1}
                      key={u.id}
                      onSelectContact={onSelectContact}
                      updateFavorites={(favs) => getFavoriteUsers(favs as EngieFavorite[])}
                    />
                  ))}
              </div>
            ))}
          </ListContainer>
        ) : (
          <ErrorMessageContainer>
            <ErrorMessage>{i18n.t('screens.directory.noFavorites')}</ErrorMessage>
          </ErrorMessageContainer>
        )
      ) : (
        <ErrorMessageContainer>
          <ErrorMessage>{i18n.t('screens.directory.noFavorites')}</ErrorMessage>
        </ErrorMessageContainer>
      )}
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: calc(100% - 116.85px);
  margin-top: 16.85px;
  background-color: ${(props) => props.theme.colors.white};
`
const ListContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;

  margin: 0 40px;

  /* Style scrollbar pour Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    width: 5px;
    background: white;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${(props) => props.theme.colors.scrollBar};
  }

  /* Style scrollbar pour Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.colors.scrollBar} transparent;

  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin: 0 12px;
  }
`
const ErrorMessageContainer = styled('div')`
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 0 40px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin: 0 12px;
  }
`
const ErrorMessage = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin-top: 20px;
  text-align: center;
`
const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  width: 375px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    min-width: 300px;
  }
`

// TEXTES

const CategoryLetter = styled('h4')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
  margin: 5px 0px 6px 0px;
  padding: 7px 0px 6px 24px;
  text-transform: capitalize;
  border-top: 0.5px solid ${(props) => props.theme.colors.mediumDarkGrey};
  border-bottom: 1px solid ${(props) => props.theme.colors.mediumDarkGrey};
`

export default FavoriteContactsEngie
