import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import api from '../api/restaurantsApi'

import Logger from 'utils/Logger'
import useI18n from 'i18n/useI18n'
import { isSameDay } from 'date-fns'
import { windowSizeBreakpoints } from 'utils/breakpoints'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'

import Icons from 'components/icons/Icons'

import Loader from 'react-loader-spinner'
import { getDayTimezone } from 'planning/utils'

interface DishesByStand {
  stand: string
  dishes: DishV6[]
}

interface Props {
  restaurantId: string
  isClosed: boolean
  hasMenu: boolean
  menuProvider?: 'MRS' | 'EUREST'
}

type Status = 'ok' | 'loading' | 'error'

const MenuScreen = ({ restaurantId, isClosed, hasMenu, menuProvider }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const user = useReducer(userStore.store, (s) => s.user)
  const site = useReducer(siteStore.store, (s) => s.site)

  const [menus, setMenus] = React.useState<MenuV6[]>([])
  const [currentMenu, setCurrentMenu] = React.useState<MenuV6>()
  const [defaultMenu, setDefaultMenu] = React.useState<MenuV6>()
  const [status, setStatus] = React.useState<Status>('ok')

  const previousExist = currentMenu ? menus.indexOf(currentMenu) !== 0 && defaultMenu !== currentMenu : false
  const nextExist = currentMenu ? menus.indexOf(currentMenu) !== menus.length - 1 : false

  React.useEffect(() => {
    if (user && site) {
      setStatus('loading')
      api
        .getMenus(user.type, restaurantId, [], site.id)
        .then((m) => {
          const index = m.menus
            .map((m) => m.day)
            .findIndex((d) => isSameDay(getDayTimezone(d), getDayTimezone(new Date().toISOString())))
          const choosenMenu = !isClosed ? m.menus[index] : m.menus[index + 1]
          setCurrentMenu(choosenMenu)
          setDefaultMenu(choosenMenu)
          setMenus(m.menus)
          setStatus('ok')
        })
        .catch((err) => {
          setStatus('error')
          Logger.error(err)
        })
    }
  }, [])

  const nextMenu = (menu: MenuV6) => {
    const index = menus.indexOf(menu)
    setCurrentMenu(menus[index + 1])
  }

  const previousMenu = (menu: MenuV6) => {
    const index = menus.indexOf(menu)
    setCurrentMenu(menus[index - 1])
  }

  const renderDishes = (dishes: DishesByStand, index: number, list: DishesByStand[]) => {
    const isLast = list.length === index + 1

    return (
      <StandContainer key={dishes.stand}>
        <StandName>
          {menuProvider === 'EUREST' ? i18n.t(`screens.catering.categories.${dishes.stand}`) : dishes.stand}
        </StandName>
        {dishes.dishes.map((d) => (
          <DishName key={d.id}>{d.name}</DishName>
        ))}
        {!isLast && <BlueLineBreak />}
      </StandContainer>
    )
  }

  const renderMenu = (menu: MenuV6) => {
    const categoryDishes = menu.dishes.reduce((acc, cur) => {
      const index = acc.findIndex((d) => d.stand === cur.category)
      if (index > -1) {
        acc[index].dishes.push(cur)
      } else {
        acc.push({ stand: cur.category || '', dishes: [cur] })
      }
      return acc
    }, [] as DishesByStand[])
    const standsDishes = menu.dishes
      .reduce((acc, cur) => {
        const index = acc.findIndex((d) => d.stand === cur.stand)
        if (index > -1) {
          acc[index].dishes.push(cur)
        } else {
          acc.push({ stand: cur.stand || '', dishes: [cur] })
        }
        return acc
      }, [] as DishesByStand[])
      .sort((a, b) => a.stand.localeCompare(b.stand))

    return (
      <div key={menu.id}>
        {menuProvider === 'EUREST' ? categoryDishes.map(renderDishes) : standsDishes.map(renderDishes)}
      </div>
    )
  }

  if (status === 'loading') {
    return (
      <LoaderContainer>
        <Loader type="TailSpin" color={Theme.colors.blue} />
      </LoaderContainer>
    )
  }

  return (
    <MainContainer>
      {!hasMenu || menus.length === 0 ? (
        <ErrorMessage>{i18n.t('screens.catering.noMenuWeek')}</ErrorMessage>
      ) : status === 'ok' && currentMenu ? (
        <>
          <MenuDayContainer
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'ArrowLeft' && previousExist) {
                previousMenu(currentMenu)
              } else if (e.key === 'ArrowRight' && nextExist) {
                nextMenu(currentMenu)
              }
            }}>
            <ChevronContainer
              onClick={() => {
                if (previousExist) {
                  previousMenu(currentMenu)
                }
              }}
              isVisible={previousExist}>
              {previousExist && <Icons name="chevron_left" size={22} color={Theme.colors.blue} />}
            </ChevronContainer>
            <MenuDayText>{i18n.t('screens.catering.menuDay', { date: getDayTimezone(currentMenu.day) })}</MenuDayText>
            <ChevronContainer
              onClick={() => {
                if (nextExist) {
                  nextMenu(currentMenu)
                }
              }}
              isVisible={nextExist}>
              {nextExist && <Icons name="chevron_right" size={22} color={Theme.colors.blue} />}
            </ChevronContainer>
          </MenuDayContainer>
          {
            <MenuContainer>
              {currentMenu.dishes.length > 0 ? (
                renderMenu(currentMenu)
              ) : (
                <ErrorMessage>{i18n.t('screens.catering.noMenuDay')}</ErrorMessage>
              )}
            </MenuContainer>
          }
        </>
      ) : currentMenu === undefined ? (
        <ErrorMessage>{i18n.t('screens.catering.noMenuDay')}</ErrorMessage>
      ) : (
        status === 'error' && <ErrorMessage>{i18n.t('screens.catering.menuError')}</ErrorMessage>
      )}
    </MainContainer>
  )
}

export default MenuScreen

// CONTAINERS

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 315px;
  padding: 40px 70px 30px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.13);
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 40px 25px 30px;
    margin-bottom: 20px;
  }
`
const MenuDayContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const ChevronContainer = styled('div')<{ isVisible: boolean }>`
  cursor: ${(props) => (props.isVisible ? 'pointer' : 'default')};
  width: 22px;
  height: 22px;
`
const StandContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0px;
`
const MenuContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`
const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`

// TEXTES

const MenuDayText = styled('h2')`
  ${(props) => props.theme.fonts.h3Bold};
  color: ${(props) => props.theme.colors.blue};
  margin: 0px;
`
const StandName = styled('h3')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
  margin: 0px 0px 10px 0px;
  text-transform: uppercase;
`
const DishName = styled('h4')`
  ${(props) => props.theme.fonts.menuSubtitle};
  text-align: center;
  margin: 0px 0px 10px 0px;
`
const ErrorMessage = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
  text-align: center;
`

// AUTRES

const BlueLineBreak = styled('div')`
  width: 30px;
  height: 1px;
  background-color: ${(props) => props.theme.colors.blue};
  margin: 0px;
  border-radius: 1px;
`
