import * as React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'
import { windowSizeBreakpoints } from 'utils/breakpoints'

import Map from 'map/Map'

import zapfloorApi from '../api'

import useReducer from 'store/useReducer'
import * as siteStore from 'sites/store/siteStore'
import * as userStore from 'store/user/user'
import * as planningStore from '../planningStore'
import * as featureStore from 'sites/store/featureStore'

import useI18n from 'i18n/useI18n'
import Button from 'components/button/Button'
import Alert from 'components/alert/Alert'
import Icons from 'components/icons/Icons'
import ValidationContent from 'components/modal/ValidationContent'
import Modal from 'components/modal/Modal'
import ReservationModal from './ReservationModal'
import UserPicture from 'authent365/utils/UserPicture'

import utils from 'utils/strings'
import { getReservationTimes } from '../utils'
import useWindowDimensions from 'utils/useWindowDimensions'

import LazyContainer from 'react-lazyload'
import Loader from 'react-loader-spinner'
interface Props {
  initialDate: Date
  closeModal: () => void
  userId: string
  teamId: string
  refreshData: () => void
  reservationTime: ReservationTime
  navigation: Navigation
  unitAreaUniqueName?: string // L'id de la zone à afficher dans la carte
  deskAreas: ZapfloorDeskArea[]
  location: ZapfloorZone
  fromFavoriteZone?: boolean
  initialPlace?: ZapfloorDeskArea
  isFromContact?: boolean
  myDesk?: string
  title?: string
  parkingMode: boolean
  visitorMode: boolean
  visits: PersonalVisitV6[]
  zapfloorPois: AreaV4[]
  selectedVisitor?: PersonalVisitV6
  visitorInfos?: UserResponse
}

const SelectionOnPlanModal = ({
  userId,
  unitAreaUniqueName,
  navigation,
  closeModal,
  initialDate,
  refreshData,
  reservationTime,
  teamId,
  location,
  fromFavoriteZone,
  deskAreas,
  initialPlace,
  isFromContact,
  myDesk,
  title,
  parkingMode,
  visitorMode,
  visits,
  zapfloorPois,
  selectedVisitor,
  visitorInfos,
}: Props) => {
  const i18n = useI18n()
  const [Theme] = useTheme()
  const site = useReducer(siteStore.store, (s) => s.site)
  const user = useReducer(userStore.store, (s) => s.user)
  const favoritePeoples = useReducer(userStore.store, (s) => s.favoriteUsers) || []
  const zapfloorUserId = useReducer(planningStore.store, (s) => s.zapfloorUserId)
  const features = useReducer(featureStore.store, (f) => f.features)

  const currentSiteHasVamosParking = React.useMemo(() => features.find((feature) => feature.type === 'VAMOS_PARKING'), [
    features,
  ])

  const [isWaiting, setIsWaiting] = React.useState(false)
  const [selectedDeskArea, setSelectedDeskArea] = React.useState<ZapfloorDeskArea>()

  React.useEffect(() => {
    if (!!initialPlace) {
      setSelectedDeskArea(initialPlace)
    }
  }, [])

  const { width: windowWidth, height: windowHeight } = useWindowDimensions()

  const inUse = React.useMemo(() => !!selectedDeskArea && selectedDeskArea.zapfloorDesk.attributes.hot_desk_in_use, [
    selectedDeskArea,
  ])

  const onBookHotDeskError = (errorMessage?: string) => {
    setIsWaiting(false)
    Alert.open(() => (
      <ValidationContent
        title={i18n.t('screens.planning.referenceWeek.errorBooking')}
        onConfirm={Alert.close}
        description={errorMessage}
      />
    ))
  }

  const confirmBooking = (deskArea: ZapfloorDeskArea) => {
    if (!!fromFavoriteZone) {
      if (!!site && !!site.locationId && !!zapfloorUserId && !!user) {
        setIsWaiting(true)
        const reservationTimesValues = getReservationTimes(reservationTime)
        const request: BodyBookingHotDesking = {
          reservation: {
            date_from: i18n.t('screens.planning.secondForm.dateToSend', {
              date: initialDate,
            }),
            time_from: reservationTimesValues.startTime,
            time_to: reservationTimesValues.endTime,
            location_id: site.locationId,
            waiting_list: false,
            description: parkingMode ? 'Parking' : '',
            create_type: 1,
          },
          booked_for_users: [zapfloorUserId],
          unit_ids: [],
          access_group_ids: [teamId],
          hot_desks: [deskArea.zapfloorDesk.attributes.hot_desk_id],
        }
        if (!parkingMode && currentSiteHasVamosParking) {
          zapfloorApi.vamos
            .vamosAccess(user.type, site.id, {
              accessDay: i18n.t('screens.planning.secondForm.dateToSend', {
                date: initialDate,
              }),
            })
            .then(() =>
              zapfloorApi.zapfloor
                .bookHotDesk(user.type, site.id, request)
                .then(() => {
                  Modal.close()
                  refreshData()
                })
                .catch(onBookHotDeskError)
                .finally(() => setIsWaiting(false))
            )
            .catch((err) => {
              onBookHotDeskError(
                err.data.error
                  ? i18n.t('screens.planning.parking.genericError', { errorCode: err.data.error })
                  : undefined
              )
            })
        } else {
          zapfloorApi.zapfloor
            .bookHotDesk(user.type, site.id, request)
            .then(() => {
              Modal.close()
              refreshData()
            })
            .catch(onBookHotDeskError)
            .finally(() => setIsWaiting(false))
        }
      }
    } else {
      Modal.open(() => (
        <ReservationModal
          userId={userId}
          teamId={teamId}
          closeModal={Modal.close}
          location={location}
          initialDate={initialDate}
          refreshData={refreshData}
          reservationTime={reservationTime}
          deskArea={deskArea}
          parkingMode={parkingMode}
          visitorMode={visitorMode}
          visits={visits}
          poisInfos={zapfloorPois}
          selectedVisitor={selectedVisitor}
          visitorInfos={visitorInfos}
          fromMap
        />
      ))
    }
  }

  return (
    <MainContainer width={windowWidth - 48} height={windowHeight - 48}>
      <Title>{title || i18n.t(`screens.planning.selectionOnPlanModal.title${parkingMode ? 'Parking' : ''}`)}</Title>
      <MapAndInfosContainer>
        <MapContainer>
          {!!site && !!site.webVisioglobeHash && !isWaiting ? (
            <Map
              hash={site.webVisioglobeHash}
              areaUniqueName={unitAreaUniqueName}
              navigation={navigation}
              isModalFromZapfloor
              deskAreas={deskAreas}
              selectDesk={setSelectedDeskArea}
              uniquePlaceInitial={!!initialPlace ? initialPlace.area.uniquePlaceName : undefined}
              myDesk={myDesk}
              parkingMode={parkingMode}
            />
          ) : (
            <LoaderContainer>
              <Loader type="TailSpin" color={Theme.colors.blue} height={50} width={50} />
              <ErrorMessage>{i18n.t('screens.planning.selectionOnPlanModal.loadingPlans')}</ErrorMessage>
            </LoaderContainer>
          )}
        </MapContainer>
        <RightContainer>
          <InfosContainer>
            <TitlesContainer>
              <Title>{deskAreas[0]?.zapfloorDesk.attributes.unit_name}</Title>
              {!!selectedDeskArea && <Title>{selectedDeskArea.area.name}</Title>}
            </TitlesContainer>
            {!!selectedDeskArea && (
              <>
                <DateAndStatusContainer>
                  <DateContainer>
                    <Icons name="agenda" size={15} color={Theme.colors.blue} />
                    <DateText>
                      {i18n.t('screens.planning.selectionOnPlanModal.date', { date: initialDate }) +
                        (reservationTime !== 'ALL_DAY'
                          ? ' - ' + i18n.t(`screens.planning.selectionOnPlanModal.reservationTime.${reservationTime}`)
                          : '')}
                    </DateText>
                  </DateContainer>
                  <StatusContainer>
                    <PointContainer color={inUse ? Theme.colors.red : Theme.colors.available} />
                    <Availability color={inUse ? Theme.colors.red : Theme.colors.available}>
                      {i18n.t(`screens.planning.selectionOnPlanModal.${inUse ? 'un' : ''}available`)}
                    </Availability>
                  </StatusContainer>
                </DateAndStatusContainer>
                {selectedDeskArea.area.equipments.length > 0 && (
                  <EquipmentsContainer>
                    <EquipmentTitle>{i18n.t('screens.cartography.equipment')}</EquipmentTitle>
                    <EquipmentsList>
                      {selectedDeskArea.area.equipments.map((e) => (
                        <Equipment key={e}>{e}</Equipment>
                      ))}
                    </EquipmentsList>
                  </EquipmentsContainer>
                )}
                {!!selectedDeskArea.zapfloorDesk.attributes.hot_desk_bookings[0] && !parkingMode ? (
                  <>
                    <Info>{i18n.t('screens.planning.selectionOnPlanModal.reservedBy')}</Info>
                    <ElementContainer key={selectedDeskArea.zapfloorDesk.attributes.hot_desk_bookings[0].user_id}>
                      <UserContainer>
                        <LazyContainer offset={200} throttle={150} overflow>
                          <UserPicture
                            key={selectedDeskArea.zapfloorDesk.attributes.hot_desk_bookings[0].user_id}
                            color={Theme.colors.blue}
                            size="smaller"
                            userFirstName={selectedDeskArea.zapfloorDesk.attributes.hot_desk_bookings[0].user_firstname}
                            userLastName={selectedDeskArea.zapfloorDesk.attributes.hot_desk_bookings[0].user_lastname}
                            userId={selectedDeskArea.zapfloorDesk.attributes.hot_desk_bookings[0].user_email}
                          />
                        </LazyContainer>
                        <UserInfoContainer>
                          <NameContainer>
                            {selectedDeskArea.zapfloorDesk.attributes.hot_desk_bookings[0].user_lastname.toUpperCase()}{' '}
                            {utils.formatNames(
                              selectedDeskArea.zapfloorDesk.attributes.hot_desk_bookings[0].user_firstname
                            )}
                          </NameContainer>
                          <EmailContainer>
                            {selectedDeskArea.zapfloorDesk.attributes.hot_desk_bookings[0].user_email}
                          </EmailContainer>
                        </UserInfoContainer>
                        {favoritePeoples.indexOf(
                          selectedDeskArea.zapfloorDesk.attributes.hot_desk_bookings[0].user_id
                        ) < 0 ? (
                          <LikeContainer
                            onClick={(e) => {
                              e.stopPropagation()
                              userStore.actions.setFavoriteUsers([
                                ...favoritePeoples,
                                selectedDeskArea.zapfloorDesk.attributes.hot_desk_bookings[0].user_id,
                              ])
                            }}>
                            <Icons name="heart" color={Theme.colors.blue} size={26} />
                          </LikeContainer>
                        ) : (
                          <LikeContainer
                            onClick={(e) => {
                              e.stopPropagation()
                              userStore.actions.setFavoriteUsers(
                                favoritePeoples.filter(
                                  (p) => p !== selectedDeskArea.zapfloorDesk.attributes.hot_desk_bookings[0].user_id
                                )
                              )
                            }}>
                            <Icons name="heart-filled" color={Theme.colors.blue} size={26} />
                          </LikeContainer>
                        )}
                      </UserContainer>
                    </ElementContainer>
                  </>
                ) : null}
              </>
            )}
          </InfosContainer>
          {!selectedDeskArea && (
            <IndicationsText>
              {i18n.t(`screens.planning.selectionOnPlanModal.selectOffice${parkingMode ? 'Parking' : ''}`)}
            </IndicationsText>
          )}
          <div>
            {!inUse && selectedDeskArea && !isFromContact && (
              <ButtonContainer>
                <Button
                  label={i18n.t('common.confirm')}
                  onClick={() => confirmBooking(selectedDeskArea)}
                  color={Theme.colors.blue}
                  textColor={Theme.colors.white}
                  shadow
                  font={Theme.fonts.h3Bold}
                />
              </ButtonContainer>
            )}
            <ButtonContainer>
              <Button
                label={isFromContact ? i18n.t('screens.planning.userList.close') : i18n.t('common.cancel')}
                onClick={closeModal}
                color={Theme.colors.white}
                textColor={Theme.colors.blue}
                shadow
                font={Theme.fonts.h3Bold}
              />
            </ButtonContainer>
          </div>
        </RightContainer>
      </MapAndInfosContainer>
    </MainContainer>
  )
}

export default SelectionOnPlanModal

//VIEW STYLE

const MainContainer = styled('div')<{ width: number; height: number }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 40px;
  gap: 20px;
`

const MapAndInfosContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    flex-direction: column;
  }
`

const MapContainer = styled.div`
  display: flex;
  flex: 1;
`

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 400px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    width: 100%;
  }
`

const InfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const IndicationsText = styled.label`
  ${(props) => props.theme.fonts.body};
  align-self: center;
  text-align: center;
`

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const DateAndStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
`

const DateContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: 10px;
  justify-content: center;
`

const TitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const ErrorMessage = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
`
const Title = styled.p`
  ${(props) => props.theme.fonts.h2Bold}
  font-size: 18px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.blue};
  margin: 0;
`

const Availability = styled.label<{ color: string }>`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.color};
`

const DateText = styled.p`
  ${(props) => props.theme.fonts.body};
  text-transform: capitalize;
  margin: 0;
`

const Info = styled.p`
  ${(props) => props.theme.fonts.body}
  margin: 0;
`

const PointContainer = styled.div<{ color: string }>`
  width: 4px;
  height: 4px;
  border-radius: 2px;
  margin-right: 7px;
  background-color: ${(props) => props.color};
`

//Display user
const ElementContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const UserContainer = styled.div`
  display: flex;
  margin: 15px 0px 15px;
`
const NameContainer = styled.div`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 16px;
  line-height: 19px;
`

const EmailContainer = styled.div`
  ${(props) => props.theme.fonts.body}
  font-size: 12px;
  line-height: 14px;
`
const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 15px;
  cursor: pointer;
`
const LikeContainer = styled('button')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px;
`

const EquipmentsContainer = styled('div')`
  display: flex;
  flex-direction: column;
`

const EquipmentsList = styled('ul')`
  margin-top: 5px;
  padding-left: 20px;
`

const EquipmentTitle = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
`

const Equipment = styled('li')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
`
