import { createEvent, createStore } from 'effector'

export const actions = {
  setIFrameLink: createEvent<string>('setIFrameLink'),
  setCurrentIFrame: createEvent<string>('setCurrentIFrame'),
  resetIFrameLink: createEvent('resetIFrameLink'),
}

const initialState: IFrameState = {
  iframeURL: '',
  currentIFrame: '',
}

export const store = createStore<IFrameState>(initialState, { name: 'Iframe Info' })
  .on(actions.setIFrameLink, (s, iframeURL) => ({ ...s, iframeURL }))
  .on(actions.setCurrentIFrame, (s, currentIFrame) => ({ ...s, currentIFrame }))
  .on(actions.resetIFrameLink, () => initialState)
