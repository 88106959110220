import * as React from 'react'
import styled from 'theme/styled-components'

import useTheme from 'theme/useTheme'
import { windowSizeBreakpoints } from 'utils/breakpoints'

import api from '../api/editoApi'

import Logger from 'utils/Logger'
import useI18n from 'i18n/useI18n'
import useNavigation from 'utils/navigation/useNavigation'
import analytics, { analyticsKeys, externalLinkTracking } from 'utils/analytics'
import useBreakpoint from 'utils/useBreakpoints'

import useReducer from 'store/useReducer'
import * as siteStore from 'sites/store/siteStore'
import * as userStore from 'store/user/user'

import CarouselRework from 'components/image/CarouselRework'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import TitleHelmet from 'components/title/TitleHelmet'
import LabelWithIcon from 'components/label/LabelWithIcon'
import ZoomImage from 'components/image/ZoomImage'
import NewsImage from 'news/NewsImage'

import Loader from 'react-loader-spinner'
import {
  HEIGHT_IMAGE_LEFT,
  HEIGHT_IMAGE_RIGHT,
  MAX_IMAGE_WINDOW_WIDTH,
  WIDTH_IMAGE_RIGHT,
} from 'news/screens/NewsDetails'

interface Props {
  id: string
}

type Status = 'loading' | 'error' | 'success'

const EditosDetailScreen = ({ id }: Props) => {
  const [Theme] = useTheme()
  const [edito, setEdito] = React.useState<InformationDetailV6>()
  const [status, setStatus] = React.useState<Status>('loading')
  const [photoURLs, setPhotoURLs] = React.useState<CarouselItem[]>([])

  const site = useReducer(siteStore.store, (s) => s.site)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)
  const user = useReducer(userStore.store, (s) => s.user)

  const i18n = useI18n()
  const navigation = useNavigation()
  const [windowSize] = useBreakpoint()

  const imagesOnRight = React.useMemo(() => windowSize > MAX_IMAGE_WINDOW_WIDTH, [windowSize])

  React.useEffect(() => {
    if (site && user) {
      api
        .getEdito(id, site.id, user.type)
        .then((edito) => {
          analytics.track({
            screenName: analyticsKeys.practicalInfoDetails,
            userType: user.type,
            currentSite: site,
            mySites,
            label: edito.englishTitle || edito.title,
          })
          setPhotoURLs(
            edito.photos
              .sort((a, b) => a.position - b.position)
              .map((p) => ({ url: p.url, key: `KEY_${p.position}`, description: p.description }))
          )
          setEdito(edito)
          setStatus('success')
        })
        .catch((err) => {
          Logger.error(err)

          // cannot get detail, redirect to home
          navigation.reset()
        })
    }
  }, [i18n.lang])

  const onEnterKey = (e: React.KeyboardEvent, onClickFunc: () => void) => {
    if (e.key === 'Enter') {
      onClickFunc()
    }
  }

  const openPDF = (pdf: InformationPDFV6) => {
    if (!!pdf.associatedButtonName && !!pdf.url) {
      if (pdf.url.endsWith('.pdf')) {
        navigation.push('/pdf', {
          pdfUrl: pdf.url,
          pdfTitle: pdf.associatedButtonName,
        })
      } else {
        window.open(`/${i18n.lang}/${pdf.url}`)
      }
    }
    return null
  }

  if (status === 'loading') {
    return (
      <LoaderContainer>
        <Loader type="TailSpin" color={Theme.colors.blue} />
      </LoaderContainer>
    )
  }

  if (status === 'error' || !edito) {
    return (
      <CenteredView imagesOnRight>
        <NoEditoError>{i18n.t('screens.practical_information.error')}</NoEditoError>
      </CenteredView>
    )
  }

  return (
    <>
      <TitleHelmet title={edito?.title || i18n.t('screens.practical_information.title')} />
      <MainContainer>
        <Breadcrumb screen="practical_information" details={edito.title} path="information" navigation={navigation} />
        <EditoMainContainer>
          <CenteredView imagesOnRight={imagesOnRight}>
            <ArticleTitle>{edito.title}</ArticleTitle>
            {!imagesOnRight && photoURLs.length > 0 && (
              <ImagesContainer imagesOnRight={imagesOnRight}>
                <ImageContainer imagesOnRight={imagesOnRight}>
                  <CarouselRework
                    fullWidth
                    height={HEIGHT_IMAGE_LEFT}
                    photosUrl={photoURLs.map((photo) => photo.url)}
                  />
                </ImageContainer>
              </ImagesContainer>
            )}
            {!!edito.pdfs && edito.pdfs.length > 0 && (
              <InfoBar>
                {edito.pdfs
                  .filter((p) => !!p.associatedButtonName && !!p.url)
                  .map((p) => (
                    <BottomLabelContainer
                      key={p.url}
                      onKeyDown={(e) => onEnterKey(e, () => openPDF(p))}
                      aria-label={p.associatedButtonName}>
                      <LabelWithIcon
                        text={p.associatedButtonName as string}
                        onClick={() => openPDF(p)}
                        iconName="file-article"
                        colorIcon={Theme.colors.blue}
                        colorText={Theme.colors.iconGrey}
                        underline
                      />
                    </BottomLabelContainer>
                  ))}
              </InfoBar>
            )}
            <Content
              dangerouslySetInnerHTML={{ __html: edito.description }}
              tabIndex={0}
              onClick={(evt) => {
                const target = evt.target as HTMLAnchorElement
                const url = target.href
                if (!!url) {
                  externalLinkTracking(url, analyticsKeys.practicalInfoDetails, user, site, mySites)
                }
              }}
            />
          </CenteredView>
          {imagesOnRight && (
            <ImagesContainer imagesOnRight={imagesOnRight}>
              {photoURLs.map((photo) => (
                <ImageContainer key={photo.key} imagesOnRight={imagesOnRight}>
                  <NewsImage
                    onClick={() => {
                      if (site) {
                        ZoomImage.open(photo.url)
                      }
                    }}
                    height={HEIGHT_IMAGE_RIGHT}
                    width={WIDTH_IMAGE_RIGHT}
                    imageURL={photo.url}
                  />
                </ImageContainer>
              ))}
            </ImagesContainer>
          )}
        </EditoMainContainer>
      </MainContainer>
    </>
  )
}

export default EditosDetailScreen

const MainContainer = styled('div')`
  margin-right: 4%;
`
const EditoMainContainer = styled('div')`
  display: flex;
  flex-direction: row;
`
const CenteredView = styled.div<{ imagesOnRight: boolean }>`
  display: flex;
  flex-direction: column;
  margin-left: 80px;
  margin-top: 40px;
  width: ${(props) => (props.imagesOnRight ? '55%' : '100%')};
  color: ${(props) => props.theme.colors.middleGrey};

  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    margin-left: 56px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    margin-left: 16px;
  }
`
const ArticleTitle = styled('h1')`
  ${(props) => props.theme.fonts.h1};
  font-size: 30px;
  line-height: 31px;
  padding-top: 12px;
  margin: 0;
`
const Content = styled.div`
  ${(props) => props.theme.fonts.body};
  margin-top: 34px;
  text-align: justify;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 16px;
`
const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`

const InfoBar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 38px;
`

const BottomLabelContainer = styled('button')`
  padding-right: 30px;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`

const NoEditoError = styled.p`
  ${(props) => props.theme.fonts.body};
  font-size: 20px;
`

const ImagesContainer = styled('div')<{ imagesOnRight: boolean }>`
  display: flex;
  flex-direction: column;
  ${(props) => (props.imagesOnRight ? 'margin-left: 28px;' : 'margin-top: 28px;')}
  width: ${(props) => (props.imagesOnRight ? `${WIDTH_IMAGE_RIGHT}px` : '100%')};
`

const ImageContainer = styled('div')<{ imagesOnRight: boolean }>`
  width: ${(props) => (props.imagesOnRight ? `${WIDTH_IMAGE_RIGHT}px` : '100%')};
  height: ${(props) => (props.imagesOnRight ? `${HEIGHT_IMAGE_RIGHT}px` : `${HEIGHT_IMAGE_LEFT}px`)};
  cursor: pointer;
  ${(props) => !!props.imagesOnRight && 'margin-bottom: 32px;'}
  background-color: ${(props) => props.theme.colors.middleGrey};
`
