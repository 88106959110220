import { Store, createEvent } from 'effector'
import { LocalStorage, SessionStorage } from './storage'
// import * as auth from './auth/auth'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'
import * as newsStore from 'news/store'
import * as shortcutStore from 'home/store/shortcutStore'
import * as favoritesStore from 'transport/favoritesStore'
import * as featureStore from 'sites/store/featureStore'
import * as cguStore from 'cgu/cguStore'
import * as roomStore from 'roombooking/roomStore'
import * as contactStore from 'directory/contactStore'
import * as iframeStore from 'iframe/iframeStore'
import * as themeStore from 'theme/store'
import * as languageStore from 'store/lang/languageStore'
import * as planningStore from 'planning/planningStore'
import * as surveysStore from 'surveys/store'
import * as i18nStore from 'i18n/i18n'

export type StorageType = 'local' | 'session'

// Mettre ici tous les stores qui doivent être toujours persisité
// ceux qui sont en nécessaire sur CookieBot

export const necessaryStores: Store<any>[] = [
  shortcutStore.store,
  cguStore.store,
  roomStore.store,
  themeStore.store,
  languageStore.store,
  planningStore.store,
  // Store nécessaire pour conserver les shortcuts / salles favorites
  userStore.store,
  i18nStore.I18nKeysStore,
  userStore.temporaryDeeplinkStore,
]

// Mettre ici tous les stores qui ne sont pas forcément persisité
// ceux qui sont en préférence sur CookieBot

export const preferencesStores: Store<any>[] = [
  newsStore.store,
  contactStore.store,
  iframeStore.store,
  surveysStore.store,
  featureStore.store,
  favoritesStore.store,
  siteStore.store,
]

const necessaryStoreStorage = LocalStorage
let preferencesStoresStorage = !!(window as any).Cookiebot?.consent?.preferences ? LocalStorage : SessionStorage

export const persistNecessaryStores = (): Promise<void> => {
  const VERSION = '3'
  const VERSION_KEY = 'persist:version'

  const allNecessaryStores = !!(window as any).Cookiebot?.consent?.preferences
    ? [...necessaryStores, ...preferencesStores]
    : necessaryStores

  return necessaryStoreStorage.getItem(VERSION_KEY).then((version) => {
    return (version === VERSION
      ? Promise.resolve(true)
      : necessaryStoreStorage.setItem(VERSION_KEY, VERSION).then(() => false)
    ).then((reload) => {
      // inspiré de https://github.com/MrFranke/effector-persist/blob/master/src/index.ts
      const rehydrate = createEvent('REHYDRATE')

      const persistStore = <State>(store: Store<State>): Promise<void> => {
        const name = store.shortName
        const persistKey = `persist:${name}`

        return (reload || name === 'CGU'
          ? necessaryStoreStorage.getItem(persistKey).then((snapshot) => {
              if (snapshot) {
                store.on(rehydrate, () => JSON.parse(snapshot))
                rehydrate()
              }
            })
          : Promise.resolve()
        ).then(() => {
          store.watch((state) => {
            necessaryStoreStorage.setItem(persistKey, JSON.stringify(state))
          })
        })
      }

      return Promise.all(allNecessaryStores.map(persistStore)).then()
    })
  })
}

export const persistPreferencesStores = (): Promise<void> => {
  const VERSION = '3'
  const VERSION_KEY = 'persist:version'

  const allPreferencesStores = !!(window as any).Cookiebot?.consent?.preferences ? [] : preferencesStores

  return preferencesStoresStorage.getItem(VERSION_KEY).then((version) => {
    return (version === VERSION
      ? Promise.resolve(true)
      : preferencesStoresStorage.setItem(VERSION_KEY, VERSION).then(() => false)
    ).then((reload) => {
      // inspiré de https://github.com/MrFranke/effector-persist/blob/master/src/index.ts
      const rehydrate = createEvent('REHYDRATE')

      const persistStore = <State>(store: Store<State>): Promise<void> => {
        const name = store.shortName
        const persistKey = `persist:${name}`

        return (reload || name === 'CGU'
          ? preferencesStoresStorage.getItem(persistKey).then((snapshot) => {
              if (snapshot) {
                store.on(rehydrate, () => JSON.parse(snapshot))
                rehydrate()
              }
            })
          : Promise.resolve()
        ).then(() => {
          store.watch((state) => {
            preferencesStoresStorage.setItem(persistKey, JSON.stringify(state))
          })
        })
      }

      return Promise.all(allPreferencesStores.map(persistStore)).then()
    })
  })
}

export const setStorage = (name: 'local' | 'session') => {
  // Cas ou on refuse les cookies de préférences (donc on reçoit session)
  // On récupère la liste des stores dans LocalStorage qui sont de type préférence
  // On les passe dans le SessionStorage et on les supprime du LocalStorage

  // Cas où on accepte les cookies de préférences (donc on reçoit local)
  // On récupère la liste des stores dans SessionStorage
  // On les passe dans le LocalStorage et on les supprime du SessionStorage

  const toStorage =
    name === 'local' && preferencesStoresStorage.name !== LocalStorage.name
      ? LocalStorage
      : name === 'session' && preferencesStoresStorage.name !== SessionStorage.name
      ? SessionStorage
      : undefined

  if (!toStorage) {
    return
  }

  return preferencesStoresStorage
    .listKeys(true)
    .reduce(
      (next, key) =>
        next
          .then(() => preferencesStoresStorage.getItem(key))
          .then((value) => {
            if (value) {
              return toStorage.setItem(key, value).then(() => preferencesStoresStorage.removeItem(key))
            }
          }),
      Promise.resolve()
    )
    .then(() => {
      preferencesStoresStorage = toStorage
    })
}

export const getCurrentStorageNecessary = () => necessaryStoreStorage

export const getCurrentStoragePreferences = () => preferencesStoresStorage

export const reset = () => {
  // auth.actions.resetAuth()
  siteStore.actions.resetSite()
  userStore.actions.resetUser()
  featureStore.actions.resetUserFeatures()
  iframeStore.actions.resetIFrameLink()
}
