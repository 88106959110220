import React from 'react'
import { useLocation } from 'react-router-dom'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icons from 'components/icons/Icons'
import Tag from 'components/label/Tag'

import { CategoryIcon, LineIcon } from '../svg/TransportIcon'

import useI18n from 'i18n/useI18n'

import api from '../api'

import Logger from 'utils/Logger'
import { getDistances } from '../utils'

const REFRESH_INTERVAL = 60

type LineType = 'PARIS_RER_A' | 'PARIS_RER_E' | 'PARIS_TRAM_2' | 'PARIS_METRO_1' | 'PARIS_TRAIN_L' | 'PARIS_TRAIN_U'

const TotemTransportScreen = () => {
  const i18n = useI18n()
  const [Theme] = useTheme()
  const location = useLocation()

  const [traffic, setTraffic] = React.useState<TransportStationsV6>()

  const query = new URLSearchParams(location.search)
  const siteId = query.get('siteId')

  const city: TypeCity | undefined = traffic ? (traffic.city as TypeCity) : undefined

  const sortedCategory = ['METRO', 'TRAMWAY', 'BUS', 'RER']

  const linesPriority: Record<LineType, number> = {
    PARIS_RER_A: 1,
    PARIS_RER_E: 2,
    PARIS_TRAM_2: 3,
    PARIS_METRO_1: 4,
    PARIS_TRAIN_L: 5,
    PARIS_TRAIN_U: 6,
  }

  const stationsInfo = React.useMemo(() => {
    if (traffic) {
      return traffic.stations
        .reduce((acc, { name, line, category }) => {
          const index = acc.findIndex((o) => o.name === name)
          if (index > -1) {
            const lineAlreadyAdded = !!acc[index].lines.find((o) => o.name === line)
            if (!lineAlreadyAdded) {
              acc[index].lines.push({ name: line })
            }
          } else {
            acc.push({ name: name, lines: [{ name: line }], category: category })
          }
          return acc
        }, [] as DisplayedStation[])
        .sort((a, b) =>
          a.category && b.category ? sortedCategory.indexOf(a.category) - sortedCategory.indexOf(b.category) : 0
        )
    }
    return []
  }, [traffic])

  const linesInfos = React.useMemo(() => {
    if (stationsInfo.length > 0) {
      return stationsInfo[0].lines.sort((a, b) => linesPriority[a.name as LineType] - linesPriority[b.name as LineType])
    }
    return []
  }, [stationsInfo])

  const firstColumn = React.useMemo(() => linesInfos.filter((l) => l.name === 'PARIS_RER_A'), [linesInfos])

  const secondColumn = React.useMemo(
    () => linesInfos.filter((l) => l.name === 'PARIS_RER_E' || l.name === 'PARIS_TRAM_2' || l.name === 'PARIS_METRO_1'),
    [linesInfos]
  )

  const thirdColumn = React.useMemo(
    () => linesInfos.filter((l) => l.name === 'PARIS_TRAIN_L' || l.name === 'PARIS_TRAIN_U'),
    [linesInfos]
  )

  const displayedStations = React.useMemo(
    () =>
      traffic?.stations
        .filter((s) => s.schedules.length > 0)
        .sort((a, b) =>
          a.line === b.line
            ? a.schedules[0].direction.localeCompare(b.schedules[0].direction)
            : a.line.localeCompare(b.line)
        )
        .reduce((acc, cur) => {
          cur.schedules.forEach((sch) => {
            const directionIndex = acc.findIndex(
              (item) =>
                item.schedules[0].direction === sch.direction && item.name === cur.name && item.line === cur.line
            )
            if (directionIndex < 0) {
              acc.push({ ...cur, schedules: cur.schedules.filter((s) => s.direction === sch.direction) })
            }
          })

          return acc
        }, [] as TransportStationV6[]),
    [traffic]
  )

  React.useEffect(() => {
    fetchTransport()
    const interval = setInterval(fetchTransport, REFRESH_INTERVAL * 1000)
    return () => clearInterval(interval)
  }, [])

  const fetchTransport = () => {
    if (!!siteId) {
      api.trafficPublic(siteId).then(setTraffic).catch(Logger.error)
    }
  }

  const renderTag = (schedule: ScheduleV6, index: number, distance?: string) => (
    <TagContainer key={`${schedule.direction} - ${schedule.time} - ${distance} - ${index}`}>
      <Tag
        totem
        label={distance}
        backgroundColor={Theme.colors.mediumDarkGrey}
        color={Theme.colors.darkGrey}
        highlight={distance === i18n.t('screens.transport.now') || distance === i18n.t('screens.transport.next')}
      />
    </TagContainer>
  )

  const renderIcon = (name: LineTransportIconName, category: TransportStationV6['category']) => (
    <IconTitleContainer>
      {!!category && (
        <IconContainer>
          <TrafficIconContainer>
            <CategoryIcon name={`${city}_${category}` as CategoryTransportIconName} size={32} />
          </TrafficIconContainer>
        </IconContainer>
      )}
      <IconContainer>
        <LineIcon name={name} size={32} ariaLabel={name.replace(/\D/g, '')} />
      </IconContainer>
    </IconTitleContainer>
  )

  const renderDirection = (station: TransportStationV6, notLast: boolean) => {
    const schedule = getDistances(i18n, new Date(), traffic?.timezone || '', station).filter((s) => !!s)

    return (
      <DirectionContainer key={station.schedules[0].direction}>
        <IconTitleContainer>
          {renderIcon(station.line as LineTransportIconName, station.category)}
          <DirectionTitle>{station.schedules[0].direction}</DirectionTitle>
        </IconTitleContainer>
        <Tags>
          {schedule.length > 0 ? (
            schedule.map((d, i) => renderTag(station.schedules[0], i, d))
          ) : (
            <NoNextDistanceTag>{i18n.t('screens.transport.noNextTransport')}</NoNextDistanceTag>
          )}
        </Tags>
        {notLast && <BlueLineBreak role="presentation" />}
      </DirectionContainer>
    )
  }

  const renderLine = (lines: LineStation, station: DisplayedStation) => {
    const displayedLineStation = displayedStations?.filter((s) => s.line === lines.name && s.name === station.name)
    return (
      <LineContainer key={`${lines.name} - ${station.name}`}>
        <div>
          {displayedLineStation && displayedLineStation.length > 0 ? (
            displayedLineStation.map((s, i) => renderDirection(s, displayedLineStation.length !== i + 1))
          ) : (
            <StationErrorMessageContainer>
              <DirectionTitle>{i18n.t('screens.transport.noNextTransport')}</DirectionTitle>
            </StationErrorMessageContainer>
          )}
        </div>
      </LineContainer>
    )
  }

  return (
    <MainContainer>
      <TopBarContainer>
        <Icons name="engie" color={Theme.colors.white} size={79} height={60} />
        <Title>{i18n.t('screens.transport.totem.title')}</Title>
        <Icons name="logo" color={Theme.colors.white} size={79} height={60} />
      </TopBarContainer>
      {!!traffic && (
        <ContentContainer>
          {displayedStations && linesInfos.length > 0 && stationsInfo.length > 0 ? (
            <TrafficContainer>
              <ColumnContainer>{firstColumn.map((l) => renderLine(l, stationsInfo[0]))}</ColumnContainer>
              <ColumnContainer>{secondColumn.map((l) => renderLine(l, stationsInfo[0]))}</ColumnContainer>
              <ColumnContainer noMargin>{thirdColumn.map((l) => renderLine(l, stationsInfo[0]))}</ColumnContainer>
            </TrafficContainer>
          ) : (
            <DirectionTitle>{i18n.t('screens.transport.noNextTransport')}</DirectionTitle>
          )}
        </ContentContainer>
      )}
    </MainContainer>
  )
}

export default TotemTransportScreen

const MainContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.lightGrey};
`

const TopBarContainer = styled('div')`
  display: flex;
  flex-direction: row;
  padding: 12px 100px;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.colors.blue};
`
const ContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 44px 60px;
`
const ColumnContainer = styled('div')<{ noMargin?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${(props) => !props.noMargin && 'margin-right: 30px;'}
  margin-bottom: 30px;
`
const TrafficContainer = styled('div')`
  display: flex;
  flex-direction: row;
  flex: 1;
`
const LineContainer = styled('div')`
  background-color: ${(props) => props.theme.colors.white};
  padding: 24px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 15px ${(props) => props.theme.colors.shadow};
`
const DirectionContainer = styled('div')``
const Tags = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`
const TagContainer = styled('div')`
  margin: 10px 7px 10px 0px;
`
const IconTitleContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
`
const IconContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-right: 5px;
`
const TrafficIconContainer = styled('div')`
  margin-top: 4px;
`
const DirectionTitle = styled('h3')`
  ${(props) => props.theme.fonts.totemTransport};
  margin: 0px;
`
const NoNextDistanceTag = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 12px 0px 10px 10px;
  font-size: 16px;
`
const StationErrorMessageContainer = styled('div')`
  margin-bottom: 20px;
`

const BlueLineBreak = styled('div')`
  width: 60px;
  height: 3px;
  background-color: ${(props) => props.theme.colors.blue};
  margin: 10px 0px;
  border-radius: 2px;
`

const Title = styled('p')`
  ${(props) => props.theme.fonts.totemBig};
  color: ${(props) => props.theme.colors.white};
  font-size: 30px;
`
