import * as React from 'react'
import useI18N from 'i18n/useI18n'

import styled from 'theme/styled-components'

const OfflineScreen = () => {
  const i18n = useI18N()

  return (
    <CenteredContainer>
      <p>{i18n.t('offline.message')}</p>
    </CenteredContainer>
  )
}

export default OfflineScreen

const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
