import * as React from 'react'
import styled from 'styled-components'

import { windowSizeBreakpoints } from 'utils/breakpoints'

import PlaceHolder from 'components/image/assets/restaurant-thumbnail.jpg'

interface Props {
  imageURL?: string
  height?: number
  width?: number
}

const RestaurantImage = ({ imageURL = '', height = 268, width = 455 }: Props) => {
  const [imageError, setImageError] = React.useState(false)

  const source = React.useMemo(() => (imageError || !imageURL ? PlaceHolder : imageURL), [imageURL, imageError])

  return <Photo onError={() => setImageError(true)} src={source} height={height} width={width} alt="" />
}

const Photo = styled('img')<{ height: number; width: number }>`
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  object-fit: cover;
  user-select: none;

  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 100%;
  }
`

export default RestaurantImage
