import * as React from 'react'
import styled from 'theme/styled-components'

import Icon from 'components/icons/Icons'
import useTheme from 'theme/useTheme'

interface Props {
  value: boolean
  onChange: (val: boolean) => void
}

const Checkbox = ({ value, onChange }: Props) => {
  const [theme] = useTheme()

  return (
    <Container
      onClick={(e) => {
        e.stopPropagation()
        onChange(!value)
      }}
      active={value}>
      {!!value && <Icon name="check" size={20} color={theme.colors.white} />}
    </Container>
  )
}

export default Checkbox

const Container = styled('button')<{ active: boolean }>`
  background-color: ${(props) => (props.active ? props.theme.colors.blue : props.theme.colors.lightGrey)};
  border: 1px solid ${(props) => props.theme.colors.middleGrey};
  height: 25px;
  width: 25px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
