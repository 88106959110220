import * as React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'
import * as featureStore from 'sites/store/featureStore'

import useNavigation from 'utils/navigation/useNavigation'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import analytics, { analyticsKeys } from 'utils/analytics'
import { documentMimeTypes, imageMimeTypes } from 'intervention/utils'
import Logger from 'utils/Logger'

import Icons from 'components/icons/Icons'
import { FormTextInput } from 'components/form/InterventionFormItems'
import Button from 'components/button/Button'
import TitleHelmet from 'components/title/TitleHelmet'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import Checkbox from 'components/button/Checkbox'
import Modal from 'components/modal/Modal'
import ValidationContent from 'components/modal/ValidationContent'

import api from './api'

type Status = 'ok' | 'loading' | 'error'

interface Attachment {
  file: File
  format: AttachmentImageFormat | AttachmentDocumentFormat
  type: 'IMAGE' | 'DOCUMENT'
  name: string
  data: string
}

interface BoxIdeasValues {
  title?: string
  description?: string
  anonymous: boolean
  attachments: Attachment[]
}

const formValidationSchema = () =>
  Yup.object().shape({
    title: Yup.string().required().max(50),
    description: Yup.string().required().max(500),
    anonymous: Yup.boolean().required(),
  })

const MAX_INPUT = 1

const BoxIdeasScreen = () => {
  const i18n = useI18n()
  const [theme] = useTheme()
  const navigation = useNavigation()

  const user = useReducer(userStore.store, (s) => s.user)
  const currentSite = useReducer(siteStore.store, (s) => s.site)
  const features = useReducer(featureStore.store, (f) => f.features)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)

  const currentSiteHasIntervention = features.find((feature) => feature.type === 'INCIDENT')

  const [status, setStatus] = React.useState<Status>('ok')

  const hiddenFileInput: any = React.useRef()

  React.useEffect(() => {
    if (user && currentSite) {
      analytics.track({ screenName: analyticsKeys.boxIdeas, userType: user.type, currentSite, mySites })
    }
  }, [])

  const { values, handleChange, handleSubmit, setFieldValue, isValid, setValues, initialValues } = useFormik<
    BoxIdeasValues
  >({
    initialValues: {
      title: '',
      description: '',
      anonymous: false,
      attachments: [],
    } as BoxIdeasValues,
    onSubmit: (values: BoxIdeasValues) => {
      if (!!user && !!currentSite) {
        setStatus('loading')

        const formData = new FormData()

        if (!!values.title) {
          formData.append('title', values.title)
        }
        if (!!values.description) {
          formData.append('description', values.description)
        }
        formData.append('anonymous', String(values.anonymous))
        if (values.attachments.length > 0) {
          formData.append('attachment', values.attachments[0].file)
        }

        api
          .postBoxIdea(formData, currentSite.id, user.type)
          .then(() => {
            setValues(initialValues)
            Modal.open(() => (
              <ModalInfosContainer>
                <ModalTitle>{i18n.t('screens.box_idea.successTitle')}</ModalTitle>
                <ModalDescription>{i18n.t('screens.box_idea.successDescription')}</ModalDescription>
                <ModalDescription>{i18n.t('screens.box_idea.infos.descriptionPart2')}</ModalDescription>
                <ModalDescription>
                  {i18n.t('screens.box_idea.infos.descriptionPart3')}{' '}
                  <TextCliquable onClick={() => window.open('mailto:immo-gdpr-rgpd.gbs@engie.com')}>
                    {i18n.t('screens.box_idea.infos.descriptionEmail1')}
                  </TextCliquable>{' '}
                  {i18n.t('screens.box_idea.infos.descriptionPart4')}{' '}
                  <TextCliquable onClick={() => window.open('mailto:dpo@engie.com')}>
                    {i18n.t('screens.box_idea.infos.descriptionEmail2')}
                  </TextCliquable>
                </ModalDescription>
                <ModalDescription>{i18n.t('screens.box_idea.infos.descriptionPart5')}</ModalDescription>
                <ButtonContainer justifyCenter>
                  <Button
                    label={i18n.t('common.close')}
                    onClick={Modal.close}
                    color={theme.colors.blue}
                    textColor={theme.colors.white}
                    shadow
                    font={theme.fonts.h3Bold}
                    verticalPadding={14}
                    horizontalPadding={100}
                    noFlex
                    width={330}
                  />
                </ButtonContainer>
              </ModalInfosContainer>
            ))
          })
          .catch((err) => {
            Logger.error(err)
            Modal.open(() => (
              <ValidationContent
                title={i18n.t('common.error')}
                description={i18n.t('screens.box_idea.error')}
                onConfirm={Modal.close}
                confirmButtonTitle={i18n.t('common.ok')}
              />
            ))
          })
          .finally(() => setStatus('ok'))
      }
    },
    validationSchema: formValidationSchema(),
    validateOnChange: true,
    validateOnMount: true,
  })

  const onUploadFile = (uploadEvent: React.ChangeEvent<HTMLInputElement>) => {
    if (uploadEvent.target.files && uploadEvent.target.files[0]) {
      const file = uploadEvent.target.files[0]
      const reader = new FileReader()

      reader.readAsDataURL(file)
      reader.onload = (readEvent) => {
        if (readEvent.target) {
          const imageFormat = Object.keys(imageMimeTypes).find(
            (key) => imageMimeTypes[key as AttachmentImageFormat] === file.type
          )
          const documentFormat = Object.keys(documentMimeTypes).find(
            (key) => documentMimeTypes[key as AttachmentDocumentFormat] === file.type
          )
          if (imageFormat || documentFormat) {
            const format = (imageFormat || documentFormat) as AttachmentImageFormat | AttachmentDocumentFormat

            const attachment: Attachment = {
              file: file,
              name: file.name,
              data: readEvent.target.result as string,
              format,
              type: !!imageFormat ? 'IMAGE' : 'DOCUMENT',
            }
            setFieldValue('attachments', [...values.attachments, attachment])
          }
        }
      }
    }
  }

  const renderDescriptionPicker = (name: string, mandatory: boolean, value?: string, maxLength?: number) => (
    <FormTextInput
      id={name}
      name={name}
      mandatory={mandatory}
      onChange={handleChange}
      title={i18n.t(`screens.box_idea.form.${name}`)}
      value={value || ''}
      placeholder={i18n.t(`screens.box_idea.form.${name}Placeholder`)}
      maxLength={maxLength}
    />
  )

  const renderAttachmentPicker = () => (
    <LabelAndInputContainer>
      <PhotoLabelContainer>
        <InputLabel>{i18n.t('screens.incident.form.reportAttachment')}</InputLabel>
      </PhotoLabelContainer>
      {values.attachments.map((a, index) => (
        <>
          <PictureContainer>
            <DeleteIcon
              onClick={() =>
                setFieldValue(
                  'attachments',
                  values.attachments.filter((a, idx) => idx !== index)
                )
              }>
              <Icons name="cross" size={20} />
            </DeleteIcon>
            {a.type === 'IMAGE' ? <IncidentPicture src={a.data} /> : <Icons name="news" size={50} />}
          </PictureContainer>
          <AttachmentLabel>{a.name}</AttachmentLabel>
        </>
      ))}
      {values.attachments.length < MAX_INPUT && (
        <AddPictureButton
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              hiddenFileInput.current.click()
            }
          }}
          tabIndex={0}
          aria-label={i18n.t('label.ariaLabel.incident.uploadPicture')}>
          <Icons name="plus" size={30} />
          <AddPictureInput
            ref={hiddenFileInput}
            type="file"
            accept={[...Object.values(imageMimeTypes), ...Object.values(documentMimeTypes)].join(',')}
            onChange={onUploadFile}
          />
        </AddPictureButton>
      )}
    </LabelAndInputContainer>
  )

  const renderDataCheckBox = () => (
    <DataCheckBoxContainer>
      <CheckBoxContainer>
        <Checkbox onChange={(newValue) => setFieldValue('anonymous', !newValue)} value={!values.anonymous} />
      </CheckBoxContainer>
      <DataCheckBoxDescription>{i18n.t('screens.box_idea.form.anonymous')}</DataCheckBoxDescription>
      <LearnMore onClick={openModalInfos}>{i18n.t('screens.box_idea.form.more')}</LearnMore>
    </DataCheckBoxContainer>
  )

  const openModalInfos = () => {
    Modal.open(() => (
      <ModalInfosContainer>
        <ModalTitle>{i18n.t('screens.box_idea.infos.title')}</ModalTitle>
        <ModalDescription>{i18n.t('screens.box_idea.infos.descriptionPart1')}</ModalDescription>
        <ModalDescription>{i18n.t('screens.box_idea.infos.descriptionPart2')}</ModalDescription>
        <ModalDescription>
          {i18n.t('screens.box_idea.infos.descriptionPart3')}{' '}
          <TextCliquable onClick={() => window.open('mailto:immo-gdpr-rgpd.gbs@engie.com')}>
            {i18n.t('screens.box_idea.infos.descriptionEmail1')}
          </TextCliquable>{' '}
          {i18n.t('screens.box_idea.infos.descriptionPart4')}{' '}
          <TextCliquable onClick={() => window.open('mailto:dpo@engie.com')}>
            {i18n.t('screens.box_idea.infos.descriptionEmail2')}
          </TextCliquable>
        </ModalDescription>
        <ModalDescription>{i18n.t('screens.box_idea.infos.descriptionPart5')}</ModalDescription>
        <ButtonContainer justifyCenter>
          <Button
            label={i18n.t('common.close')}
            onClick={Modal.close}
            color={theme.colors.blue}
            textColor={theme.colors.white}
            shadow
            font={theme.fonts.h3Bold}
            verticalPadding={14}
            horizontalPadding={100}
            noFlex
            width={330}
          />
        </ButtonContainer>
      </ModalInfosContainer>
    ))
  }

  return (
    <>
      <TitleHelmet title={i18n.t('screens.box_idea.title')} />
      <Breadcrumb screen="box_idea" path="/boxIdeas" navigation={navigation} />

      <MainContainer>
        <TitleContainer>
          <Title>{i18n.t('screens.box_idea.title')}</Title>
        </TitleContainer>
        <DescriptionContainer>
          <DescriptionMessage>{i18n.t('screens.box_idea.descriptionPart1')}</DescriptionMessage>
        </DescriptionContainer>
        <DescriptionContainer>
          <DescriptionMessageBold>{i18n.t('screens.box_idea.descriptionPart2')}</DescriptionMessageBold>
        </DescriptionContainer>
        {currentSiteHasIntervention && (
          <InterventionMessageContainer>
            <IconContainer>
              <Icons name="warning-circle" size={26} color={theme.colors.middleGrey} />
            </IconContainer>
            <DescriptionMessage>
              {i18n.t('screens.box_idea.descriptionInterventionPart1')}{' '}
              <TextCliquable onClick={() => navigation.push('/incident')}>
                {i18n.t('screens.box_idea.descriptionInterventionPart2')}
              </TextCliquable>{' '}
              {i18n.t('screens.box_idea.descriptionInterventionPart3')}
            </DescriptionMessage>
          </InterventionMessageContainer>
        )}
        <FormContainer>
          {renderDescriptionPicker('title', true, values.title, 50)}
          {renderDescriptionPicker('description', true, values.description, 500)}
          {renderAttachmentPicker()}
          {renderDataCheckBox()}
          <ButtonContainer>
            <Button
              label={i18n.t('screens.box_idea.form.submit')}
              onClick={handleSubmit}
              type="submit"
              disabled={!isValid || status === 'loading'}
              loading={status === 'loading'}
              font={theme.fonts.h3Bold}
              verticalPadding={14}
              horizontalPadding={100}
              noFlex
            />
          </ButtonContainer>
        </FormContainer>
      </MainContainer>
    </>
  )
}

export default BoxIdeasScreen

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 60px;
  margin-left: 5%;
  margin-right: 10%;
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    margin-right: 2%;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    margin-right: 4%;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-left: 4%;
  }
`

const TitleContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 36px;
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 24px;
  margin: 0px;
`

const DescriptionContainer = styled('div')`
  padding-bottom: 28px;
`

const DescriptionMessage = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 0px;
`

const DescriptionMessageBold = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  margin: 0px;
`

const InterventionMessageContainer = styled('div')`
  background-color: ${(props) => props.theme.colors.lightGrey};
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 750px;
`

const IconContainer = styled.div`
  padding-right: 14px;
`

const TextCliquable = styled.span`
  ${(props) => props.theme.fonts.body};
  text-decoration: underline;
  cursor: pointer;
  color: ${(props) => props.theme.colors.blue};
`

const FormContainer = styled.div`
  margin-top: 36px;
`

const LabelAndInputContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 350px;
  max-width: 450px;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    min-width: 100%;
  }
`
const PhotoLabelContainer = styled('div')`
  display: flex;
  align-items: center;
  margin: 10px 0px;
`

const InputLabel = styled('label')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
`

const PictureContainer = styled('div')`
  display: flex;
  position: relative;
  width: fit-content;
`
const DeleteIcon = styled('div')`
  display: flex;
  position: absolute;
  top: -10px;
  right: -10px;
  height: 25px;
  width: 25px;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 0px 5px 1px ${(props) => props.theme.colors.shadow};
`

const IncidentPicture = styled('img')`
  max-width: 100%;
  object-fit: cover;
`

const AttachmentLabel = styled('label')`
  width: 100%;
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
  padding: 10px 0px;
  text-overflow: ellipsis;
`

const AddPictureButton = styled('label')`
  width: 58px;
  height: 58px;
  background-color: ${(props) => props.theme.colors.blue10p};
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
  :focus {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`

const AddPictureInput = styled('input')`
  display: none;
`

const ButtonContainer = styled('div')<{ justifyCenter?: boolean }>`
  margin-top: 35px;
  display: flex;
  ${(props) => props.justifyCenter && 'justify-content: center;'}
`

const DataCheckBoxContainer = styled('div')`
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  align-items: center;
`

const CheckBoxContainer = styled('div')`
  width: 25px;
`

const DataCheckBoxDescription = styled('p')`
  ${(props) => props.theme.fonts.body};
  max-width: 600px;
  padding-left: 24px;
  margin: 0px;
`

const LearnMore = styled('p')`
  padding-left: 24px;
  ${(props) => props.theme.fonts.body};
  text-decoration: underline;
  cursor: pointer;
  color: ${(props) => props.theme.colors.blue};
  margin: 0px;
  min-width: 100px;
`

const ModalInfosContainer = styled('div')`
  padding: 32px;
  max-width: 550px;
`

const ModalTitle = styled('p')`
  ${(props) => props.theme.fonts.h2Bold};
  margin: 0px;
  padding-bottom: 20px;
`

const ModalDescription = styled('p')`
  ${(props) => props.theme.fonts.body};
  padding-bottom: 12px;
`
