import Webservice from 'utils/Webservice'
import config from 'core/src/config'
import { Language } from './types'

const api = {
  getPoEditorTerms: (languages?: Language[]) =>
    Webservice<PoEditorTranslations>(
      'EXTERNAL',
      `${config.globalAPIVersion}/public/poeditor/translations/list?env=WEB${
        !!languages && languages.length > 0 ? `&languages=${languages.join(',')}` : ''
      }`,
      'GET',
      {
        'X-Api-Key': config.API_KEY,
      }
    ),
}

export default api
