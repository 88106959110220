import * as React from 'react'
import styled from 'theme/styled-components'

interface Props {
  imageURL: string
  label?: string
  selected?: boolean
  circleSize?: number
}

const SiteImage = ({ selected = false, circleSize = 90, label, imageURL }: Props) => {
  return (
    <MainContainer>
      <Image size={circleSize} selected={selected} src={imageURL} />
      {!!label && <Label selected={selected}>{label}</Label>}
    </MainContainer>
  )
}

const MainContainer = styled('div')`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Image = styled('img')<{ size: number; selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  height: ${(props) => (props.selected ? props.size - 6 : props.size)}px;
  width: ${(props) => (props.selected ? props.size - 6 : props.size)}px;
  border-radius: ${(props) => props.size}px;
  background-color: ${(props) => props.theme.colors.darkGrey};
  ${(props) => props.selected && `border: 3px solid ${props.theme.colors.blue};`};
`
const Label = styled('p')<{ selected: boolean }>`
  flex: 1;
  ${(props) => (props.selected ? props.theme.fonts.labelBold : props.theme.fonts.label)}
  margin-top: 7px;
  margin-bottom: 0px;
  text-align: center;
  color: ${(props) => (props.selected ? props.theme.colors.blue : props.theme.colors.darkGrey)};
`

export default SiteImage
