import React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icons from 'components/icons/Icons'
import SwitchFilter from 'components/switch/SwitchFilter'
import CustomDatePicker from 'components/picker/CustomDatePicker'
import Button from 'components/button/Button'
import Alert from 'components/alert/Alert'
import ValidationContent from 'components/modal/ValidationContent'

import api from '../api'
import mapApi from 'map/mapApi'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as planningStore from '../planningStore'
import * as siteStore from 'sites/store/siteStore'
import * as featureStore from 'sites/store/featureStore'

import { windowSizeBreakpoints } from 'utils/breakpoints'
import utils from 'utils/strings'
import {
  findIconName,
  findColorOccupation,
  findPercentageOccupation,
  findAvailabilityColor,
  findAvailabilityIcon,
  findPriorityError,
  getDatesInInterval,
  getWeekDaysNumber,
  filterAvailableBookings,
  filterNotAvailableBookings,
  updateBookingsStatus,
  getMessageError,
  getNextSelectableDate,
  getStatusErrorMessage,
  getReservationTimes,
  getDayTimezone,
} from '../utils'
import analytics, { analyticsActionKeys, analyticsKeys } from 'utils/analytics'

import useI18n from 'i18n/useI18n'
import Loader from 'react-loader-spinner'
import { addDays, compareAsc, isBefore, getDay, isSameDay, setHours, setMinutes, isAfter, isWeekend } from 'date-fns'
import Logger from 'utils/Logger'
import { WEEK_DAY_SORTER } from 'parking/utils'

type ScreenStatus = 'loading' | 'success' | 'error' | 'notStarted'

interface Props {
  initialDate: Date
  location: ZapfloorZone
  closeModal: () => void
  userId: string
  teamId: string
  refreshData: () => void
  reservationTime: ReservationTime
  deskArea?: ZapfloorDeskArea
  fromFavoriteZone?: boolean
  parkingMode: boolean
  visitorMode: boolean
  visitorInfos?: UserResponse
  selectedVisitor?: PersonalVisitV6
  visits: PersonalVisitV6[]
  poisInfos: AreaV4[]
  fromMap?: boolean
}

const CURRENT_DATE = new Date()
const MAX_DAYS = 30

const WEEKDAYS: WeekDay[] = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY']

const ReservationModal = ({
  initialDate,
  location,
  closeModal,
  userId,
  teamId,
  refreshData,
  reservationTime,
  deskArea,
  fromFavoriteZone,
  parkingMode,
  visitorMode,
  visitorInfos,
  selectedVisitor,
  visits,
  poisInfos,
  fromMap,
}: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()

  const user = useReducer(userStore.store, (s) => s.user)
  const zapfloorUserId = useReducer(planningStore.store, (s) => s.zapfloorUserId)
  const currentSite = useReducer(siteStore.store, (s) => s.site)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)
  const features = useReducer(featureStore.store, (f) => f.features)
  const maxDaysInFuture = useReducer(planningStore.store, (s) => s.maxDaysInFuture)

  const maxBookingDays = React.useMemo(() => maxDaysInFuture || currentSite?.maxDeskBookingDays || MAX_DAYS, [
    currentSite,
    maxDaysInFuture,
  ])

  const MIN_DATE = React.useMemo(
    () =>
      isAfter(setHours(setMinutes(CURRENT_DATE, 0), 12), CURRENT_DATE) && !isWeekend(CURRENT_DATE)
        ? CURRENT_DATE
        : getNextSelectableDate(
            isAfter(setHours(setMinutes(CURRENT_DATE, 0), 12), CURRENT_DATE) ? CURRENT_DATE : addDays(CURRENT_DATE, 1),
            addDays(CURRENT_DATE, maxBookingDays)
          ),
    [CURRENT_DATE, maxBookingDays]
  )

  const MAX = React.useMemo(() => addDays(setHours(setMinutes(CURRENT_DATE, 59), 23), maxBookingDays), [
    MIN_DATE,
    maxBookingDays,
  ])
  const MAX_DATE = React.useMemo(() => getNextSelectableDate(MAX, MAX), [MAX])

  const [isRandom, setIsRandom] = React.useState(false)
  const [isRecurrenceActive, setIsRecurrenceActive] = React.useState(false)
  const [statusScreen, setStatusScreen] = React.useState<ScreenStatus>('loading')
  const [isBooking, setIsBooking] = React.useState(false)
  const [availability, setAvailability] = React.useState<ZapfloorZone>()
  const [selectedWeekDays, setSelectedWeekDays] = React.useState<WeekDay[]>([])
  const [endDateRecurrence, setEndDateRecurrence] = React.useState<Date>(MAX_DATE)
  const [availabilityRecurrence, setAvailabilityRecurrence] = React.useState<{ [key: string]: ZapfloorZone }>({})
  const [remainingRecurrenceWS, setRemainingRecurrenceWS] = React.useState(0)
  const [isDatePickerVisible, setIsDatePickerVisible] = React.useState(false)
  const [zapfloorPois, setZapfloorPois] = React.useState<AreaV4[]>([])

  const findTranslationMessage = React.useMemo(
    () => (!!availability ? findPriorityError(availability.attributes.error_response, visitorMode) : undefined),
    [availability]
  )
  const bookingForUser = React.useMemo(() => zapfloorUserId === userId, [zapfloorUserId, userId])

  const reservationTimeWording = React.useMemo(
    () => (reservationTime === 'ALL_DAY' ? undefined : i18n.t(`screens.desk.reservationTime.${reservationTime}`)),
    [reservationTime]
  )

  const reservationTimesValues = React.useMemo(() => getReservationTimes(reservationTime), [reservationTime])

  const isInitialDateNotBookable =
    findTranslationMessage === 'has_booking_applied_day' ||
    findTranslationMessage === 'weekday_not_available' ||
    findTranslationMessage === 'user_limit_reached' ||
    findTranslationMessage === 'user_limit_reached_manual' ||
    findTranslationMessage === 'user_limit_reached_manual_waiting_full' ||
    findTranslationMessage === 'user_limit_reached_manual_waiting_priority' ||
    findTranslationMessage === 'max_days_in_future_exceeded'

  const isInitialDateVisitorNotBookable = findTranslationMessage === 'zone_is_full_booked'

  const initialBooking: ZapFloorZoneRecurrence = {
    date: initialDate.toString(),
    zone: location,
  }

  const currentSiteHasVamosParking = React.useMemo(() => features.find((feature) => feature.type === 'VAMOS_PARKING'), [
    features,
  ])

  const sortedAvailabilityRecurrence: ZapFloorZoneRecurrence[] = React.useMemo(() => {
    const daysNumber = getWeekDaysNumber(selectedWeekDays)
    const newRecurrenceBookings = Object.entries(availabilityRecurrence)
      .map((data) => ({
        date: data[0],
        zone: data[1],
      }))
      .filter(
        (item) =>
          isBefore(new Date(item.date), setHours(setMinutes(new Date(endDateRecurrence), 59), 23)) &&
          daysNumber.includes(getDay(new Date(item.date)))
      )
      .sort((a, b) => compareAsc(new Date(a.date), new Date(b.date)))
    const updatedBookingsStatus = updateBookingsStatus([initialBooking, ...newRecurrenceBookings])
    return updatedBookingsStatus
  }, [availabilityRecurrence, endDateRecurrence, selectedWeekDays])

  const availableBookings = isRecurrenceActive ? filterAvailableBookings(sortedAvailabilityRecurrence, isRandom) : []
  const disableButton =
    remainingRecurrenceWS > 0 ||
    (isRecurrenceActive
      ? availableBookings.length === 0 && isInitialDateNotBookable
      : visitorMode
      ? isInitialDateVisitorNotBookable
      : isInitialDateNotBookable)
  const [notAvailableBookings, setNotAvailableBookings] = React.useState<ZapFloorZoneRecurrence[]>([])

  const filteredNotAvailableBookings = React.useMemo(
    () =>
      notAvailableBookings.filter((booking) =>
        sortedAvailabilityRecurrence.find((availability) =>
          isSameDay(new Date(availability.date), new Date(booking.date))
        )
      ),
    [notAvailableBookings, sortedAvailabilityRecurrence]
  )

  React.useEffect(() => {
    if (!isRandom) {
      setNotAvailableBookings(filterNotAvailableBookings(sortedAvailabilityRecurrence))
    }
  }, [sortedAvailabilityRecurrence])

  React.useEffect(() => {
    if (currentSite && user) {
      mapApi
        .getAllAreas(currentSite.id, user.type)
        .then((res) =>
          setZapfloorPois(res.areas.filter((poi) => !!poi.zapfloorId && poi.uniquePlaceName.includes('/')))
        )
        .catch(Logger.error)
    }
  }, [])

  React.useEffect(() => {
    if (!!user && !!zapfloorUserId && !!currentSite && !!currentSite.locationId && !deskArea) {
      setStatusScreen('loading')
      api.zapfloor
        .getAvailableZones(
          user.type,
          currentSite.id,
          i18n.t('screens.planning.secondForm.dateToSend', {
            date: initialDate,
          }),
          teamId,
          currentSite.locationId,
          reservationTimesValues.startTime,
          reservationTimesValues.endTime,
          userId,
          location.attributes.unit_id
        )
        .then((res) => {
          setStatusScreen('success')
          if (res.length > 0) {
            setAvailability(res[0])
          }
        })
        .catch(() => setStatusScreen('error'))
    } else if (!!user && !!zapfloorUserId && !!currentSite && !!currentSite.locationId && !!deskArea) {
      setStatusScreen('loading')
      api.zapfloor
        .getHotDeskAvailable(
          user.type,
          currentSite.id,
          i18n.t('screens.planning.secondForm.dateToSend', {
            date: initialDate,
          }),
          currentSite.locationId!,
          reservationTime,
          teamId,
          userId,
          location.attributes.unit_id
        )
        .then((res) => {
          setStatusScreen('success')
          if (res.length > 0) {
            setAvailability(res[0])
          }
        })
        .catch(() => setStatusScreen('error'))
    }
  }, [])

  React.useEffect(() => {
    if (!isRandom) {
      setAvailabilityRecurrence({})
    }
  }, [isRandom])

  React.useEffect(() => {
    if (Object.keys(availabilityRecurrence).length === 0) {
      fetchData()
    }
  }, [availabilityRecurrence])

  React.useEffect(() => {
    fetchData()
  }, [isRecurrenceActive, selectedWeekDays, endDateRecurrence, isRandom])

  const fetchData = () => {
    if (isRecurrenceActive && !!user && !!zapfloorUserId && !!currentSite && !!currentSite.locationId) {
      const weekDaysNumber = getWeekDaysNumber(selectedWeekDays)
      const daysToBook = getDatesInInterval(initialDate, endDateRecurrence, weekDaysNumber)
      const filteredDaysToBook = daysToBook
        .filter((day) => !availabilityRecurrence[day.toString()])
        .filter((date) => !isSameDay(date, initialDate))

      const data = isRandom
        ? [...notAvailableBookings.map((booking) => new Date(booking.date)), ...filteredDaysToBook]
        : filteredDaysToBook

      if (data.length > 0) {
        data.forEach((day) => {
          setRemainingRecurrenceWS((value) => value + 1)
          if (!!deskArea) {
            api.zapfloor
              .getHotDeskAvailable(
                user.type,
                currentSite.id,
                i18n.t('screens.planning.secondForm.dateToSend', {
                  date: day,
                }),
                currentSite.locationId!,
                reservationTime,
                teamId,
                userId,
                location.attributes.unit_id
              )
              .then((res) => {
                if (res.length > 0) {
                  const selectedDesk = res.find(
                    (d) => d.attributes.hot_desk_id === deskArea.zapfloorDesk.attributes.hot_desk_id
                  )
                  const availableDesk = res.filter(
                    (d) =>
                      !d.attributes.hot_desk_in_use &&
                      zapfloorPois.find((poi) => poi.zapfloorId === d.attributes.hot_desk_id)
                  )
                  const findDeskAvailable = !!selectedDesk && selectedDesk.attributes.hot_desk_in_use === false
                  if (availableDesk.length > 0 && isRandom && !findDeskAvailable) {
                    setAvailabilityRecurrence((prev) => ({ ...prev, [day.toString()]: availableDesk[0] }))
                  } else if (!!selectedDesk) {
                    setAvailabilityRecurrence((prev) => ({ ...prev, [day.toString()]: selectedDesk }))
                  }
                  setRemainingRecurrenceWS((value) => value - 1)
                }
              })
              .catch(() => setStatusScreen('error'))
          } else {
            api.zapfloor
              .getAvailableZones(
                user.type,
                currentSite.id,
                i18n.t('screens.planning.secondForm.dateToSend', {
                  date: day,
                }),
                teamId,
                currentSite.locationId!,
                reservationTimesValues.startTime,
                reservationTimesValues.endTime,
                userId,
                location.attributes.unit_id
              )
              .then((res) => {
                if (res.length > 0) {
                  setAvailabilityRecurrence((prev) => ({ ...prev, [day.toString()]: res[0] }))
                }
                setRemainingRecurrenceWS((value) => value - 1)
              })
              .catch(() => setStatusScreen('error'))
          }
        })
      }
    }
  }

  const onClickBook = () => {
    if (!!user && !!zapfloorUserId && !!currentSite && !!currentSite.locationId) {
      if (!!visitorInfos && !!selectedVisitor) {
        if (sortedAvailabilityRecurrence.length > 0) {
          const booking = sortedAvailabilityRecurrence[0]
          const findVisit = visits.find(
            (visit) =>
              isSameDay(getDayTimezone(visit.day), new Date(booking.date)) &&
              visit.multiDayGroupId === selectedVisitor.multiDayGroupId
          )
          if (!!findVisit) {
            const request: BodyBookingHotDesking = {
              reservation: {
                date_from: i18n.t('screens.planning.secondForm.dateToSend', {
                  date: new Date(booking.date),
                }),
                time_from: reservationTimesValues.startTime,
                time_to: reservationTimesValues.endTime,
                location_id: currentSite.locationId!,
                description: parkingMode ? 'Parking' : '',
                waiting_list: false,
                create_type: 1,
              },
              booked_for_users: [visitorInfos.id],
              unit_ids: !!deskArea ? [] : [booking.zone.attributes.unit_id],
              access_group_ids: [teamId],
              hot_desks: !!deskArea
                ? [booking.zone.attributes.hot_desk_id || deskArea?.zapfloorDesk.attributes.hot_desk_id]
                : [],
            }
            setIsBooking(true)
            api.zapfloor
              .bookHotDesk(user.type, currentSite.id, request)
              .then((res) => {
                const hotDeskId = res.data.attributes.hot_desk_items[0].hot_desk_id
                const desk = poisInfos.find((poi) => poi.zapfloorId === hotDeskId)
                const request: ZapfloorBookingV6 = {
                  bookingType: parkingMode ? 'PARKING' : 'DESK',
                  zapfloorId: res.data.id,
                  endDateTime: `${findVisit.day}T${reservationTimesValues.endTime}`,
                  startDateTime: `${findVisit.day}T${reservationTimesValues.startTime}`,
                  engieVisitId: findVisit.visitId,
                  place: desk ? desk.name : '',
                  zone: booking.zone.attributes.unit_name,
                }
                api.zapfloorEngie
                  .postVisitorBooking(currentSite.id, user.type, request)
                  .then(() => {
                    refreshData()
                    closeModal()
                    Alert.open(
                      () => (
                        <ValidationContent
                          title={i18n.t('screens.planning.modal.confirmedRegistration', { count: 1 })}
                          onConfirm={() => {
                            Alert.close()
                          }}
                          ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
                        />
                      ),
                      true
                    )
                  })
                  .catch(() => {
                    // On supprime la résa Zapfloor
                    api.zapfloor.deleteBooking(user.type, currentSite.id, res.data.id)
                    Alert.open(
                      () => (
                        <ValidationContent
                          title={i18n.t('screens.planning.modal.errorRegistration')}
                          onConfirm={Alert.close}
                          ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
                        />
                      ),
                      true
                    )
                  })
                  .finally(() => setIsBooking(false))
              })
              .catch(() => {
                setIsBooking(false)
                Alert.open(
                  () => (
                    <ValidationContent
                      title={i18n.t('screens.planning.modal.errorRegistration')}
                      onConfirm={Alert.close}
                      ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
                    />
                  ),
                  true
                )
              })
          }
        }
      } else {
        if (!parkingMode) {
          analytics.track({
            screenName: analyticsKeys.zapfloor,
            userType: user.type,
            currentSite,
            mySites,
            action: fromMap
              ? analyticsActionKeys.bookingMap
              : deskArea
              ? analyticsActionKeys.bookingList
              : analyticsActionKeys.bookingZone,
          })
        }
        const dataToSend = isRecurrenceActive
          ? sortedAvailabilityRecurrence.filter(
              (booking) =>
                getStatusErrorMessage(findPriorityError(booking.zone.attributes.error_response)) !== 'error' &&
                (!booking.zone.attributes.hot_desk_in_use || isRandom)
            )
          : sortedAvailabilityRecurrence.filter((booking) => isSameDay(new Date(booking.date), initialDate))
        setIsBooking(true)
        const vamosPromises =
          !parkingMode && currentSiteHasVamosParking
            ? dataToSend.map((booking) =>
                api.vamos.vamosAccess(user.type, currentSite.id, {
                  accessDay: i18n.t('screens.planning.secondForm.dateToSend', {
                    date: new Date(booking.date),
                  }),
                })
              )
            : []
        Promise.all(vamosPromises)
          .then(() => {
            const promises = dataToSend.map((booking) => {
              const errorMessage = findPriorityError(booking.zone.attributes.error_response)
              const statusErrorMessage = getStatusErrorMessage(errorMessage)
              const request: BodyBookingHotDesking = {
                reservation: {
                  date_from: i18n.t('screens.planning.secondForm.dateToSend', {
                    date: new Date(booking.date),
                  }),
                  time_from: reservationTimesValues.startTime,
                  time_to: reservationTimesValues.endTime,
                  location_id: currentSite.locationId!,
                  waiting_list: statusErrorMessage === 'waiting',
                  description: parkingMode ? 'Parking' : '',
                  create_type: 1,
                },
                booked_for_users: [userId],
                unit_ids: !!deskArea && statusErrorMessage === 'ok' ? [] : [booking.zone.attributes.unit_id],
                access_group_ids: [teamId],
                hot_desks:
                  !!deskArea && statusErrorMessage === 'ok'
                    ? [booking.zone.attributes.hot_desk_id || deskArea?.zapfloorDesk.attributes.hot_desk_id]
                    : [],
              }
              return api.zapfloor.bookHotDesk(user.type, currentSite.id, request)
            })
            Promise.all(promises)
              .then(() => {
                refreshData()
                closeModal()
                Alert.open(
                  () => (
                    <ValidationContent
                      title={i18n.t('screens.planning.modal.confirmedRegistration', { count: dataToSend.length })}
                      onConfirm={Alert.close}
                      ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
                    />
                  ),
                  true
                )
                setIsBooking(false)
              })
              .catch(() => {
                setIsBooking(false)
                Alert.open(
                  () => (
                    <ValidationContent
                      title={i18n.t('screens.planning.modal.errorRegistration')}
                      onConfirm={Alert.close}
                      ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
                    />
                  ),
                  true
                )
              })
          })
          .catch((err) => {
            setIsBooking(false)
            Alert.open(
              () => (
                <ValidationContent
                  title={i18n.t('screens.planning.modal.errorRegistration')}
                  description={
                    err.data.error
                      ? i18n.t('screens.planning.parking.genericError', { errorCode: err.data.error })
                      : undefined
                  }
                  onConfirm={Alert.close}
                  ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
                />
              ),
              true
            )
          })
      }
    }
  }

  const toggleWeekDay = (day: WeekDay) =>
    setSelectedWeekDays((days) =>
      days.includes(day)
        ? days.length === 1
          ? days
          : days.filter((d) => d !== day).sort((d1, d2) => WEEK_DAY_SORTER[d1] - WEEK_DAY_SORTER[d2])
        : [...days, day].sort((d1, d2) => WEEK_DAY_SORTER[d1] - WEEK_DAY_SORTER[d2])
    )

  const setAllWeekDays = () => setSelectedWeekDays(WEEKDAYS)

  const renderDay = (item: ZapFloorZoneRecurrence) => {
    const isHotDeskInUse = !!deskArea && !isRandom ? item.zone.attributes.hot_desk_in_use : false
    const errorMessage = findPriorityError(item.zone.attributes.error_response, visitorMode)
    const percentage = findPercentageOccupation(item.zone.attributes)
    const occupancyIcon = findIconName(percentage)
    const occupancyColor = findColorOccupation(percentage, Theme)
    const availabilityIcon = findAvailabilityIcon(errorMessage, isHotDeskInUse, visitorMode)
    const availabilityColor = findAvailabilityColor(errorMessage, Theme, isHotDeskInUse, visitorMode)
    const findPOI = zapfloorPois.find((poi) => poi.zapfloorId === item.zone.attributes.hot_desk_id)
    const findInitialBooking = notAvailableBookings.find((booking) =>
      isSameDay(new Date(item.date), new Date(booking.date))
    )
    //check if a desk has been replaced by the randomDesk function
    const isRandomDesk =
      !!findPOI && !!findInitialBooking && findInitialBooking.zone.attributes.hot_desk_id !== findPOI.zapfloorId

    return (
      <div key={item.date.toString()}>
        <RowContainer>
          <LeftContainer>
            <Day>{utils.capitalize(i18n.t('screens.planning.reservationDate', { date: new Date(item.date) }))}</Day>
            {!!reservationTimeWording && (
              <ReservationTimeContainer>
                <Icons name="clock" size={14} color={Theme.colors.blue} />
                <ReservationTime color="black">{reservationTimeWording}</ReservationTime>
              </ReservationTimeContainer>
            )}
          </LeftContainer>
          <IconContainer>
            {!deskArea && (
              <>
                <Icons
                  name={occupancyIcon}
                  size={18}
                  color={occupancyColor}
                  ariaLabel={i18n.t('screens.planning.iconsAction.occupancyRate')}
                />
                <OccupancyRate occupancyColor={occupancyColor}>{percentage}%</OccupancyRate>
              </>
            )}
            <Icons name={availabilityIcon} size={26} color={availabilityColor} />
          </IconContainer>
        </RowContainer>

        {!(errorMessage === 'zone_is_full_booked' && !!deskArea && !isRandom) && errorMessage !== 'ok' ? (
          <MessageContainer>
            <Message color={availabilityColor}>
              {i18n.t(
                errorMessage === 'zone_is_full_booked' && isRandom
                  ? `screens.planning.modal.noOtherDeskAvailable${parkingMode ? 'Parking' : ''}`
                  : getMessageError(errorMessage, bookingForUser, visitorMode)
              )}
            </Message>
          </MessageContainer>
        ) : !!deskArea && isHotDeskInUse ? (
          <MessageContainer>
            <Message color={availabilityColor}>
              {i18n.t(`screens.planning.modal.unavailableDesk${parkingMode ? 'Parking' : ''}`)}
            </Message>
          </MessageContainer>
        ) : !!findPOI && !!isRandomDesk && !!deskArea && !isHotDeskInUse && isRandom ? (
          <MessageContainer>
            <Message color={availabilityColor}>
              {i18n.t(`screens.planning.modal.randomDesk${parkingMode ? 'Parking' : ''}`, {
                deskName: findPOI.name,
              })}
            </Message>
          </MessageContainer>
        ) : null}
      </div>
    )
  }

  const renderDays = () => {
    if (remainingRecurrenceWS > 0) {
      return (
        <LoaderContainer>
          <Loader type="TailSpin" color={Theme.colors.blue} />
        </LoaderContainer>
      )
    }
    return sortedAvailabilityRecurrence
      .filter((booking) => !isSameDay(new Date(booking.date), initialDate))
      .map(renderDay)
  }

  const renderInitialDay = () => {
    if (!availability) {
      return null
    }
    const initialBooking: ZapFloorZoneRecurrence = {
      date: initialDate.toString(),
      zone: location,
    }
    return renderDay(initialBooking)
  }

  const renderRecurrenceDay = (day: WeekDay) => {
    const isSelected = !!selectedWeekDays.find((d) => d === day)
    return (
      <DayChoice key={day} selected={isSelected} onClick={() => toggleWeekDay(day)}>
        <DayLetter selected={isSelected}>{i18n.t(`common.weekdays.${day}`)[0]}</DayLetter>
      </DayChoice>
    )
  }

  const renderRecurrencePanel = () => (
    <DaySelectionContainer>
      {WEEKDAYS.map(renderRecurrenceDay)}
      <EveryDayContainer onClick={setAllWeekDays}>{i18n.t('screens.planning.modal.everyDay')}</EveryDayContainer>
    </DaySelectionContainer>
  )

  const renderButton = () => {
    return (
      <ButtonContainer>
        <Button
          label={i18n.t(
            `screens.planning.modal.${
              !fromFavoriteZone
                ? parkingMode
                  ? 'confirmReservationParking'
                  : 'confirmReservation'
                : parkingMode
                ? 'bookParking'
                : 'bookDesk'
            }`,
            {
              count: availableBookings.length,
            }
          )}
          onClick={() => {
            onClickBook()
          }}
          color={Theme.colors.blue}
          textColor={Theme.colors.white}
          font={Theme.fonts.h3Bold}
          disabled={disableButton}
          loading={isBooking}
        />
      </ButtonContainer>
    )
  }

  const filterCalendarDates = (date: Date) =>
    !getDatesInInterval(new Date(initialDate), MAX_DATE, [0, 6]).find((d) => isSameDay(d, date))

  const renderRecurrence = () => (
    <>
      <RowContainer>
        <Label>{i18n.t('screens.planning.modal.recurrence')}</Label>
        <IconContainer>
          <SwitchFilter value={isRecurrenceActive} setValue={setIsRecurrenceActive} />
        </IconContainer>
      </RowContainer>
      {isRecurrenceActive && (
        <>
          <RowContainer>{renderRecurrencePanel()}</RowContainer>
          <RowContainer>
            <Label>{i18n.t('screens.planning.modal.to')}</Label>
            <ChoiceContainer>
              <CliquableDiv onClick={() => setIsDatePickerVisible(true)}>
                <ChoiceText>
                  {utils.capitalize(i18n.t('screens.planning.reservationDate', { date: endDateRecurrence }))}
                </ChoiceText>
                <IconContainer>
                  <Icons name="chevron_right" color={Theme.colors.darkGrey} />
                </IconContainer>
              </CliquableDiv>
              {isDatePickerVisible && (
                <CustomDatePicker
                  selectedDate={endDateRecurrence}
                  onChange={setEndDateRecurrence}
                  minDate={initialDate}
                  maxDate={MAX_DATE}
                  closePicker={() => setIsDatePickerVisible(false)}
                  filterDate={filterCalendarDates}
                />
              )}
            </ChoiceContainer>
          </RowContainer>
          <RowContainer>
            <BlueLine role="presentation" />
          </RowContainer>

          {renderDays()}
        </>
      )}
    </>
  )

  const renderRandomDesk = () => {
    if (isRecurrenceActive && filteredNotAvailableBookings.length > 0 && statusScreen !== 'loading') {
      return (
        <RandomDeskContainer>
          <BlueLine role="presentation" />
          <RowContainer>
            <LeftContainer>
              <IconContainer>
                <Icons name="warning" color={Theme.colors.orange} size={30} />
              </IconContainer>
            </LeftContainer>
            <Warning>
              {i18n.t(
                `screens.planning.errorMessages.warningDeskUnavailabilityRecurrence${parkingMode ? 'Parking' : ''}`
              )}
            </Warning>
          </RowContainer>
          <RowContainer>
            <LeftContainer>
              <Label>{i18n.t(`screens.planning.errorMessages.randomDesk${parkingMode ? 'Parking' : ''}`)}</Label>
            </LeftContainer>
            <SwitchFilter value={isRandom} setValue={setIsRandom} />
          </RowContainer>
        </RandomDeskContainer>
      )
    }
  }

  return (
    <MainContainer>
      <Title>{location.attributes.unit_name}</Title>
      {!!deskArea && <Title>{deskArea.area.name}</Title>}
      {statusScreen === 'loading' ? (
        <LoaderContainer>
          <Loader height={60} type="TailSpin" color={Theme.colors.blue} />
        </LoaderContainer>
      ) : statusScreen === 'error' ? (
        <ErrorContainer>
          <Error>{i18n.t('screens.planning.errorMessages.availabilityUnknown')}</Error>
        </ErrorContainer>
      ) : (
        <>
          {renderInitialDay()}
          {!fromFavoriteZone && !visitorMode && renderRecurrence()}
          {renderRandomDesk()}
          {renderButton()}
        </>
      )}
      <ButtonContainer>
        <Button
          label={i18n.t('common.cancel')}
          onClick={closeModal}
          color={Theme.colors.white}
          textColor={Theme.colors.blue}
          shadow
          font={Theme.fonts.h3Bold}
        />
      </ButtonContainer>
    </MainContainer>
  )
}

export default ReservationModal

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  max-height: 70vh;
  overflow: auto;
  width: 400px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    width: 280px;
  }
`

const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 10px 0px 5px 0px;
`

const BlueLine = styled.div`
  width: 60px;
  height: 3px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.blue};
  margin-bottom: 15px;
`

const RandomDeskContainer = styled.div`
  padding-top: 30px;
`

const Day = styled.p`
  ${(props) => props.theme.fonts.body}
  font-size: 16px;
  line-height: 19px;
  margin: 2px 0px 0px 0px;
`

const Title = styled.p`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 18px;
  line-height: 20px;
  margin: 0px 0px 20px;
  color: ${(props) => props.theme.colors.blue};
`

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
`

const OccupancyRate = styled.p<{ occupancyColor: string }>`
  margin: 2px 5px 0px 5px;
  ${(props) => props.theme.fonts.body}
  font-size: 12px;
  line-height: 14px;
  color: ${(props) => props.occupancyColor};
`

const DayChoice = styled.button<{ selected: boolean }>`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin: 5px;
  background-color: ${(props) => (props.selected ? props.theme.colors.blue : props.theme.colors.lightGrey)};
`

const DayLetter = styled.p<{ selected: boolean }>`
  ${(props) => props.theme.fonts.body}
  color: ${(props) => (props.selected ? props.theme.colors.white : props.theme.colors.black)};
  font-size: 16px;
  line-height: 19px;
  margin: 0px;
  text-transform: capitalize;
`

const DaySelectionContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`

const EveryDayContainer = styled.button`
  ${(props) => props.theme.fonts.body}
  font-size: 12px;
  line-height: 14px;
  width: 50px;
  padding-top: 7px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const Label = styled.p`
  ${(props) => props.theme.fonts.label}
  margin: 0px;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  flex: 1;
`

const CliquableDiv = styled.button`
  display: flex;
`

const ChoiceContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 3;
`

const ChoiceText = styled.p`
  ${(props) => props.theme.fonts.labelBold}
  color: ${(props) => props.theme.colors.blue};
  font-size: 16px;
  line-height: 19px;
  margin: 0px;
  padding-right: 5px;
`

const ButtonContainer = styled('div')`
  display: flex;
  flex: 1;
  margin-top: 10px;
`

const MessageContainer = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const Message = styled('p')<{ color: string }>`
  ${(props) => props.theme.fonts.label};
  margin-top: 0px;
  color: ${(props) => props.color};
`

const ErrorContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 20px;
`
const Error = styled('p')`
  ${(props) => props.theme.fonts.body};
  text-align: center;
`
const Warning = styled.p`
  ${(props) => props.theme.fonts.subtitle}
  color: ${(props) => props.theme.colors.orange};
`

const ReservationTimeContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 6px;
`

const LeftContainer = styled('div')``

const ReservationTime = styled('p')`
  ${(props) => props.theme.fonts.label};
  margin: 0px 0px 0px 6px;
`
