import Webservice from 'utils/Webservice'
import { assureToken } from 'authent365/utils/utils'
import config from 'core/src/config'
import { addHours, roundToNearestMinutes } from 'date-fns'
import { executeGraphRequest } from 'authent365/utils/utils'

const MAX_SEARCH_RESULT = 100

const api = {
  searchRoomsPage: (
    userType: UserType,
    siteId: string,
    pageNumber: number,
    pageSize: number,
    body: RoomResearchFormV4
  ) =>
    assureToken(userType).then((token) =>
      Webservice<AreaResearchResultV4>(
        userType,
        `${config.globalAPIVersion}/rooms?siteId=${siteId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
        'POST',
        {
          Authorization: `Bearer ${token}`,
        },
        undefined,
        body
      )
    ),
  getAllRooms: (siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<AreaResearchResultV4>(userType, `${config.globalAPIVersion}/rooms?siteId=${siteId}`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  getRoomsByIds: (siteId: string, userType: UserType, roomIds: string[]) => {
    const idsList = roomIds.join(',')
    return assureToken(userType).then((token) =>
      Webservice<RoomResearchResultV4>(
        userType,
        `${config.globalAPIVersion}/rooms?roomIdsFilter=${idsList}&siteId=${siteId}`,
        'GET',
        {
          Authorization: `Bearer ${token}`,
        }
      )
    )
  },
  getRoomPlanning: (userType: UserType, roomId: number, siteId: string) =>
    assureToken(userType).then((token) =>
      Webservice<RoomWithPlanningV4>(
        userType,
        `${config.globalAPIVersion}/rooms/${roomId}/planning?siteId=${siteId}`,
        'GET',
        {
          Authorization: `Bearer ${token}`,
        }
      )
    ),
  searchRooms: (userType: UserType, body: RoomResearchFormV4, siteId: string) =>
    api.searchRoomsPage(userType, siteId, 0, MAX_SEARCH_RESULT, body).then((res) => {
      const numberOfPages = !!res.areas && res.areas.length > 0 ? res.totalNumberOfPage : undefined
      if (!!numberOfPages && numberOfPages > 1) {
        return Promise.all(
          Array.from(Array(numberOfPages - 1).keys()).map((pageNumber) =>
            api.searchRoomsPage(userType, siteId, pageNumber + 1, MAX_SEARCH_RESULT, body)
          )
        ).then((results) => {
          return results.reduce(
            (acc, curr) => ({
              areas: acc.areas ? (curr.areas ? [...acc.areas, ...curr.areas] : acc.areas) : curr.areas ?? [],
            }),
            { areas: res.areas ?? [] }
          )
        })
      } else {
        return res
      }
    }),
  findAvailabilityForNextHour: (userType: UserType, roomEmail: string) => {
    const start = roundToNearestMinutes(new Date(), { nearestTo: 15 })
    const end = addHours(start, 1)
    const currentLocationTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    return executeGraphRequest<RoomScheduleGraphList>(
      userType,
      `/me/calendar/getschedule`,
      'POST',
      {
        Schedules: [roomEmail],
        StartTime: {
          dateTime: start.toISOString(),
          timeZone: currentLocationTimeZone,
        },
        EndTime: {
          dateTime: end.toISOString(),
          timeZone: currentLocationTimeZone,
        },
        availabilityViewInterval: '15',
      },
      undefined,
      currentLocationTimeZone
    )
  },
}

export default api
