import * as React from 'react'
import ReactDOM from 'react-dom'

import styled from 'theme/styled-components'
import Provider from 'theme/Provider'

import FocusLock from 'react-focus-lock'

const ANIM_DELAY = 200

interface Props {
  Content: () => JSX.Element
  blackScreen?: boolean
}

const Alert = ({ Content, blackScreen = false }: Props) => {
  const [opened, setOpened] = React.useState(false)

  const fadeout = () => {
    setOpened(false)
    setTimeout(AlertFunction.close, ANIM_DELAY)
  }

  const onKeyDown: React.KeyboardEventHandler = (evt) => {
    if (evt.key === 'Escape') {
      AlertFunction.close()
    }
  }

  React.useEffect(() => {
    setOpened(true)

    window.onpopstate = AlertFunction.close
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  return (
    <MainContainer opened={opened} onKeyDown={onKeyDown}>
      <BlackScreen onClick={fadeout} blackScreen={blackScreen} />
      <ModalContainer>
        <FocusLock autoFocus={false}>
          <Content />
        </FocusLock>
      </ModalContainer>
    </MainContainer>
  )
}

let alertRoot: HTMLElement | null

let elementToFocusOnAlertClose: any | null

const AlertFunction = {
  open: (content: () => JSX.Element, blackScreen?: boolean) => {
    elementToFocusOnAlertClose = document.activeElement
    alertRoot = document.getElementById('alert_root')
    if (alertRoot) {
      ReactDOM.render(
        <Provider>
          <Alert Content={content} blackScreen={blackScreen} />
        </Provider>,
        alertRoot
      )
    }
  },
  close: () => {
    if (alertRoot) {
      ReactDOM.unmountComponentAtNode(alertRoot)
    }
    if (elementToFocusOnAlertClose) {
      elementToFocusOnAlertClose.focus()
      elementToFocusOnAlertClose = null
    }
  },
}

export default AlertFunction

const MainContainer = styled('div')<{ opened: boolean }>`
  transition: opacity 0.2s;
  opacity: ${(props) => (props.opened ? 1 : 0)};

  z-index: 51;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
`
const BlackScreen = styled('div')<{ blackScreen: boolean }>`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;

  ${(props) =>
    props.blackScreen &&
    `opacity: 0.5;
  background-color: ${props.theme.colors.black};`}

  :hover {
    cursor: pointer;
  }
`
const ModalContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  justify-content: center;
  z-index: 52;
`
