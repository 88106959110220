import React from 'react'
import styled from 'theme/styled-components'

import useI18n from 'i18n/useI18n'

const FullScreenLoader = () => {
  const i18n = useI18n()
  return (
    <FullScreenContainer>
      <LoaderLabel>{i18n.t('common.redirect')}</LoaderLabel>
    </FullScreenContainer>
  )
}

export default FullScreenLoader

const FullScreenContainer = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;
`

const LoaderLabel = styled('p')`
  ${(props) => props.theme.fonts.title};
  color: ${(props) => props.theme.colors.white};
`
