import NewsScreen from 'news/screens/NewsScreen'
import NewsDetails from 'news/screens/NewsDetails'
import Edito from 'edito/screens/EditoScreen'
import EditoDetail from 'edito/screens/EditoDetailScreen'
import LoginScreen from 'authent365/screens/LoginScreen'
import AccountScreen from 'account/AccountScreen'
import HomeScreen from 'home/HomeScreen'
import SitesScreen from 'sites/screens/SitesScreen'
import TransportScreen from 'transport/TransportScreen'
import CGUPrivacyScreen from 'cgu/CGUPrivacyScreen'
import CGUPrivacyDetailsScreen from 'cgu/CGUPrivacyDetailsScreen'
import OnlyMoovMobile from 'transport/OnlyMoovMobile'
import RoomBookingScreen from 'roombooking/RoomBookingScreen'
import RoomDetails from 'roombooking/RoomDetails'
import RegistrationScreen from 'registration/RegistrationScreen'
import IFrameScreen from 'iframe/IFrameScreen'
import Restaurants from 'restaurants/screens/RestaurantsListScreen'
import RestaurantDetail from 'restaurants/screens/RestaurantDetailScreen'
import InterventionScreen from 'intervention/InterventionScreen'
import SiteMap from 'sitemap/SiteMap'
import VisioWebScreen from 'map/MapScreen'
import PDFScreen from 'pdf/PDFScreen'
import PlanningScreen from 'planning/PlanningScreen'
import ParkingScreen from 'parking/ParkingScreen'
import SurveysScreen from 'surveys/SurveyScreen'
import SurveyDetailsScreen from 'surveys/SurveyDetailsScreen'
import MyMRSScreen from 'restaurants/mrs/MyMRSScreen'
import MeetingCenterScreen from 'planning/meetingCenter/MeetingCenterScreen'
import TotemScreen from 'restaurants/totems/TotemScreen'
import ParkingSharvyScreen from 'parking/ParkingSharvyScreen'
import OtherServicesScreen from 'otherServices/OtherServices'
import PoiRedirectScreen from 'roombooking/PoiRedirect'
import BoxIdeasScreen from 'boxIdeas/BoxIdeasScreen'
import TotemTransportScreen from 'transport/totem/TotemTransportScreen'
import FleetScreen from 'intervention/FleetScreen'

const screens: { [key: string]: ScreenDefinition } = {
  home: {
    name: 'home',
    icon: 'home',
    Screen: HomeScreen as RootScreen,
    path: '/',
    drawer: true,
    footer: true,
  },
  login: {
    name: 'login',
    icon: 'user',
    Screen: LoginScreen as RootScreen,
    path: '/login',
    footer: true,
  },
  cgu: {
    name: 'cgu',
    icon: undefined,
    Screen: CGUPrivacyScreen as RootScreen,
    path: '/cgu',
    footer: true,
  },
  cguDetails: {
    name: 'cguDetails',
    icon: undefined,
    Screen: CGUPrivacyDetailsScreen as RootScreen,
    path: '/cgu/cgu',
    footer: false,
  },
  privacyDetails: {
    name: 'privacyDetails',
    icon: undefined,
    Screen: CGUPrivacyDetailsScreen as RootScreen,
    path: '/cgu/privacy/:userType/:siteId?',
    footer: false,
  },
  trafficInfo: {
    name: 'traffic_info',
    icon: undefined,
    Screen: OnlyMoovMobile as RootScreen,
    path: '/onlymoov',
    footer: false,
  },
  account: {
    name: 'account',
    icon: 'user',
    Screen: AccountScreen as RootScreen,
    path: '/account',
    shortcut: true,
    footer: true,
  },
  restaurant: {
    name: 'CATERING',
    icon: 'restaurant',
    Screen: Restaurants as RootScreen,
    path: '/catering',
    drawer: true,
    shortcut: true,
    footer: true,
  },
  restaurantDetail: {
    name: 'restaurantDetails',
    icon: 'restaurant',
    Screen: (RestaurantDetail as unknown) as RootScreen,
    path: '/catering/:id/:barclap?',
    footer: true,
  },
  incident: {
    name: 'INCIDENT',
    icon: 'intervention',
    Screen: (InterventionScreen as unknown) as RootScreen,
    path: '/incident',
    drawer: true,
    shortcut: true,
    footer: true,
  },
  fleet: {
    name: 'FLEET',
    icon: 'car-parking',
    Screen: (FleetScreen as unknown) as RootScreen,
    path: '/fleet',
    drawer: true,
    shortcut: true,
    footer: true,
  },
  roombooking: {
    name: 'ROOM',
    icon: 'roombooking',
    Screen: RoomBookingScreen as RootScreen,
    path: '/room',
    drawer: true,
    shortcut: true,
    footer: true,
  },
  planning: {
    name: 'PLANNING',
    icon: 'desk',
    Screen: (PlanningScreen as unknown) as RootScreen,
    path: '/planning',
    drawer: true,
    shortcut: true,
    footer: true,
  },
  roomDetails: {
    name: 'roomDetails',
    icon: 'roombooking',
    Screen: (RoomDetails as unknown) as RootScreen,
    path: '/room/:id',
    footer: true,
  },
  registration: {
    name: 'GUEST',
    icon: 'add-contact',
    Screen: RegistrationScreen as RootScreen,
    path: '/guest',
    drawer: true,
    shortcut: true,
    footer: true,
  },
  map: {
    name: 'CARTOGRAPHY',
    icon: 'pin',
    Screen: VisioWebScreen as RootScreen,
    path: '/map',
    drawer: true,
    shortcut: true,
    footer: false,
  },
  news: {
    name: 'NEWS',
    icon: 'news',
    Screen: NewsScreen as RootScreen,
    path: '/news',
    drawer: true,
    shortcut: true,
    footer: true,
  },
  newsDetails: {
    name: 'newsDetails',
    icon: 'news',
    Screen: (NewsDetails as unknown) as RootScreen,
    path: '/news/:id',
    footer: true,
  },
  transport: {
    name: 'TRANSPORT',
    icon: 'transport',
    Screen: TransportScreen as RootScreen,
    path: '/transport',
    drawer: true,
    shortcut: true,
    footer: true,
  },
  information: {
    name: 'PRACTICAL_INFORMATION',
    icon: 'text',
    Screen: Edito as RootScreen,
    path: '/information',
    drawer: true,
    shortcut: true,
    footer: true,
  },
  informationDetail: {
    name: 'informationDetails',
    icon: undefined,
    Screen: (EditoDetail as unknown) as RootScreen,
    path: '/information/:id',
    footer: true,
  },
  sitemap: {
    name: 'sitemap',
    icon: undefined,
    Screen: SiteMap as RootScreen,
    path: '/sitemap',
    footer: true,
  },
  iframe: {
    name: 'iframe',
    icon: undefined,
    Screen: IFrameScreen as RootScreen,
    path: '/iframe',
    footer: false,
  },
  sites: {
    name: 'sites',
    icon: 'building',
    Screen: SitesScreen as RootScreen,
    path: '/sites',
    shortcut: true,
    footer: true,
  },
  pdf: {
    name: 'pdf',
    icon: 'user',
    Screen: (PDFScreen as unknown) as RootScreen,
    path: '/pdf',
    footer: true,
  },
  parking: {
    name: 'parking',
    icon: 'parking',
    Screen: (ParkingScreen as unknown) as RootScreen,
    path: '/parking',
    drawer: true,
    shortcut: true,
    footer: true,
  },
  sharvyParking: {
    name: 'sharvyParking',
    icon: 'parking',
    Screen: (ParkingSharvyScreen as unknown) as RootScreen,
    path: '/sharvyParking',
    drawer: true,
    shortcut: true,
    footer: true,
  },
  surveys: {
    name: 'surveys',
    icon: 'pencil',
    Screen: SurveysScreen as RootScreen,
    path: '/surveys',
    footer: true,
  },
  surveysDetails: {
    name: 'surveysDetails',
    icon: 'pencil',
    Screen: (SurveyDetailsScreen as unknown) as RootScreen,
    path: '/surveys/:id',
    footer: true,
  },
  myMRSScreen: {
    name: 'myMRS',
    icon: 'contactless-card',
    Screen: (MyMRSScreen as unknown) as RootScreen,
    path: '/mymrs',
    footer: true,
  },
  meetingCenter: {
    name: 'MEETING_CENTER',
    icon: 'tv',
    Screen: (MeetingCenterScreen as unknown) as RootScreen,
    path: '/meetingcenter',
    drawer: true,
    shortcut: true,
    footer: true,
  },
  totem: {
    name: 'totem',
    Screen: (TotemScreen as unknown) as RootScreen,
    path: '/totem',
    drawer: false,
    shortcut: false,
    footer: false,
  },
  totemTransport: {
    name: 'totemTransport',
    Screen: (TotemTransportScreen as unknown) as RootScreen,
    path: '/totem',
    drawer: false,
    shortcut: false,
    footer: false,
  },
  otherServices: {
    name: 'otherServices',
    Screen: (OtherServicesScreen as unknown) as RootScreen,
    path: '/otherServices',
    drawer: true,
    shortcut: false,
    footer: false,
  },
  poi: {
    name: 'poi',
    Screen: (PoiRedirectScreen as unknown) as RootScreen,
    path: '/poi/:uniquePlaceName',
    drawer: false,
    shortcut: false,
    footer: false,
  },
  boxIdeas: {
    name: 'BOX_IDEA',
    icon: 'light-bulb',
    Screen: BoxIdeasScreen as RootScreen,
    path: '/boxIdeas',
    drawer: true,
    footer: true,
  },
}

export default screens
