import { createEvent, createStore } from 'effector'

export const actions = {
  setSurveys: createEvent<Survey[]>('setSurveys'),
  resetSurveys: createEvent('resetSurvey'),
}

const initialState: SurveyState = {
  surveys: [],
}

export const store = createStore<SurveyState>(initialState, { name: 'Survey' })
  .on(actions.setSurveys, (s, surveys) => ({ ...s, surveys }))
  .on(actions.resetSurveys, (s) => ({ ...s, survey: initialState.surveys }))
