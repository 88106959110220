import * as React from 'react'
import styled from 'styled-components'
import useTheme from 'theme/useTheme'

import engieDirectoryApi from 'directory/engie/apiEngieDirectory'

import { DirectoryAttendee } from 'roombooking/types'

import Logger from 'utils/Logger'
import useI18n from 'i18n/useI18n'
import { windowSizeBreakpoints } from 'utils/breakpoints'

import useReducer from 'store/useReducer'
import * as contactStore from 'directory/contactStore'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'

import ContactPicture from './AttendeePicture'
import Icons from 'components/icons/Icons'
import Button from 'components/button/Button'
import Modal from 'components/modal/Modal'

interface Props {
  attendee: DirectoryAttendee
  isLast: boolean
  updateFavorites?: (favorites: FavoriteIds[]) => void
  removeAttendeeFunction: (id: string) => void
  registration?: boolean
  myReservationMode?: boolean
  navigation?: Navigation
}

const AttendeeInfos = ({
  attendee,
  isLast,
  updateFavorites,
  removeAttendeeFunction,
  registration = false,
  myReservationMode,
  navigation,
}: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const favorites = useReducer(contactStore.store, (s) => s.favorites)
  const isFavorite = !!favorites.find((favorite) => favorite.idGraph === attendee.id)
  const user = useReducer(userStore.store, (s) => s.user)
  const currentSite = useReducer(siteStore.store, (s) => s.site)

  const modifyFavorite = () => {
    if (isFavorite) {
      const updatedFav = favorites.filter((f) => f.idGraph !== attendee.id)
      contactStore.actions.setFav(updatedFav)
      if (updateFavorites) {
        updateFavorites(updatedFav)
      }
    } else if (user) {
      engieDirectoryApi
        .getIdFromEmail(user.type, attendee.mail)
        .then((res) =>
          contactStore.actions.setFav([
            ...favorites,
            {
              idGraph: attendee.id ? attendee.id : '',
              engieUserId: res.engieUserId && res.engieUserId !== '' ? res.engieUserId : undefined,
            },
          ])
        )
        .catch((err) => {
          contactStore.actions.setFav([
            ...favorites,
            {
              idGraph: attendee.id ? attendee.id : '',
            },
          ])
          Logger.error(err)
        })
    }
  }

  const removeFromAttendees = () => {
    if (attendee.id && !myReservationMode) {
      removeAttendeeFunction(attendee.id)
    } else if (myReservationMode) {
      removeAttendeeFunction(attendee.mail)
    }
  }

  return (
    <>
      <MainContainer>
        <ContactContainer>
          <ContactLeftContainer>
            <div>
              <ContactPicture attendee={attendee} />
            </div>
            <ContactInfosContainer>
              {attendee.type !== 'EXTERNAL' ? (
                <ContactInfo>
                  {attendee.surname} {attendee.givenName}
                </ContactInfo>
              ) : (
                <ContactInfo>{attendee.mail}</ContactInfo>
              )}
            </ContactInfosContainer>
          </ContactLeftContainer>

          <IconsMainContainer>
            {attendee.type !== 'EXTERNAL' && attendee.id && (
              <IconContainer
                onClick={modifyFavorite}
                aria-label={i18n.t(
                  `label.ariaLabel.directory.${isFavorite ? 'removePersonFromFavorites' : 'addPersonToFavorites'}`
                )}>
                <Icons name={isFavorite ? 'heart-filled' : 'heart'} size={26} color={Theme.colors.blue} />
              </IconContainer>
            )}

            <IconContainer
              onClick={removeFromAttendees}
              aria-label={i18n.t(
                `label.ariaLabel.room.removeFromParticipants${currentSite?.id === '4' ? 'AndResource' : ''}`
              )}>
              <Icons name="bin" size={26} color={Theme.colors.blue} />
            </IconContainer>
          </IconsMainContainer>
        </ContactContainer>
        {registration && navigation && (
          <BottomContainer>
            <ButtonContainer>
              <Button
                label={i18n.t('screens.room.registerAttendee')}
                verticalPadding={6}
                horizontalPadding={0}
                font={Theme.fonts.label}
                onClick={() => {
                  Modal.close()
                  navigation.push(`/guest`, { email: attendee.mail })
                }}
              />
            </ButtonContainer>
          </BottomContainer>
        )}
      </MainContainer>
      {!isLast && <BlueLineBreak />}
    </>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
`
const ContactContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3px 10px 3px 10px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding-right: 0px;
  }
`
const ContactInfosContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  max-width: 197px;
  word-wrap: break-word;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 100%;
  }
`
const ContactLeftContainer = styled('div')`
  display: flex;
  flex-direction: row;
  padding: 12px 14px 12px 14px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 12px 0px;
  }
`
const IconContainer = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-left: 0px;
  }
`
const BottomContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
`
const ButtonContainer = styled('div')`
  display: flex;
  width: 162px;
`
const IconsMainContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 14px;
`

// TEXTES

const ContactInfo = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
`

// AUTRES

const BlueLineBreak = styled('div')`
  width: 50px;
  height: 3px;
  background-color: ${(props) => props.theme.colors.blue};
  border-radius: 2px;
  margin-left: 24px;
`

export default AttendeeInfos
