import React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useNavigation from 'utils/navigation/useNavigation'
import useI18n from 'i18n/useI18n'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as cguStore from 'cgu/cguStore'
import * as siteStore from 'sites/store/siteStore'

import analytics, { analyticsKeys } from 'utils/analytics'
import { windowSizeBreakpoints } from 'utils/breakpoints'

import Button from 'components/button/Button'
import Icons from 'components/icons/Icons'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import TitleHelmet from 'components/title/TitleHelmet'
import Checkbox from 'components/button/Checkbox'

const CGUPrivacyScreen = () => {
  const [Theme] = useTheme()

  const user = useReducer(userStore.store, (s) => s.user)
  const site = useReducer(siteStore.store, (s) => s.site)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)
  const cgu = useReducer(cguStore.store, (s) =>
    !!user && (user.id || user.email) ? s.acceptedVersion[(user.id || user.email) as string] : undefined
  )
  const termsAndConditions = useReducer(cguStore.store, (s) => s.termsAndConditions)

  const navigation = useNavigation()
  const i18n = useI18n()

  const [acceptCGU, setAcceptCGU] = React.useState(false)

  const connected = !!user
  const alreadyAccepted =
    !connected ||
    (!!termsAndConditions &&
      termsAndConditions.generalTermsOfUse &&
      cgu === termsAndConditions.generalTermsOfUse.version)

  React.useEffect(() => {
    if (user) {
      analytics.track({ screenName: analyticsKeys.cgu, userType: user.type, currentSite: site, mySites })
    }
  }, [])

  const validateCGU = () => {
    if (
      user &&
      termsAndConditions &&
      termsAndConditions.generalTermsOfUse &&
      termsAndConditions.generalTermsOfUse.version
    ) {
      cguStore.actions.setAcceptedVersion({
        userId: user.id || user.email || '',
        cguVersion: termsAndConditions.generalTermsOfUse.version,
      })
      navigation.push('/')
    }
  }

  return (
    <MainContainer connected={connected}>
      <TitleHelmet title={i18n.t('screens.cgu.title')} />
      {connected ? (
        <BreadcrumbContainer>
          {alreadyAccepted && <Breadcrumb screen="cgu" path="cgu" navigation={navigation} />}
        </BreadcrumbContainer>
      ) : (
        <GoBackIcon
          onClick={navigation.goBack}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              navigation.goBack()
            }
          }}>
          <Icons name="cross" color={Theme.colors.black} size={35} />
        </GoBackIcon>
      )}
      <ContentContainer>
        <IconContainer>
          <Icons name="check-double" color={Theme.colors.blue} size={150} />
        </IconContainer>
        <CGUTitle>{i18n.t('screens.cgu.title')}</CGUTitle>
        <CGUDescription>{i18n.t(`screens.cgu.${alreadyAccepted ? 'read' : 'accept'}Description`)}</CGUDescription>
        <Link onClick={() => navigation.push('/cgu/cgu')}>{i18n.t('screens.cgu.cguTitle')}</Link>
        <Link
          onClick={() =>
            navigation.push(`/cgu/privacy/${!user ? 'internal' : user.type !== 'EXTERNAL' ? 'internal' : 'visitor'}`)
          }>
          {i18n.t(
            `screens.cgu.${
              user ? (user.type !== 'EXTERNAL' ? 'privacyTitle' : 'privacyTitleVisitor') : 'privacyTitleNotConnected'
            }`
          )}
        </Link>
        {!user && (
          <Link onClick={() => navigation.push('/cgu/privacy/visitor')}>
            {i18n.t('screens.cgu.privacyTitleVisitor')}
          </Link>
        )}

        {!alreadyAccepted && (
          <CheckLinesContainer>
            <CheckLine onClick={() => setAcceptCGU((val) => !val)}>
              <Checkbox value={acceptCGU} onChange={setAcceptCGU} />
              <CheckLineText>{i18n.t('screens.cgu.acceptCGU')}</CheckLineText>
            </CheckLine>
          </CheckLinesContainer>
        )}
      </ContentContainer>
      <BottomContainer>
        {!alreadyAccepted && (
          <ButtonContainer>
            <Button
              disabled={!acceptCGU}
              label={i18n.t('screens.cgu.continue')}
              onClick={validateCGU}
              font={Theme.fonts.h3Bold}
            />
          </ButtonContainer>
        )}
        {!connected && (
          <ButtonContainer>
            <Button label={i18n.t('screens.cgu.returnToLogin')} onClick={navigation.goBack} font={Theme.fonts.h3Bold} />
          </ButtonContainer>
        )}
      </BottomContainer>
    </MainContainer>
  )
}

export default CGUPrivacyScreen

// CONTAINERS

const MainContainer = styled('div')<{ connected: boolean }>`
  width: ${(props) => (props.connected ? 'calc(100vw - 114px)' : '100vw')};
  min-height: ${(props) => (props.connected ? 'calc(100vh - 116px)' : '100vh')};
  flex-direction: column;
  display: flex;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 100vw;
  }
  overflow: scroll;
  max-height: 100%;
`

const ContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: 40px 20px;
`

const BottomContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

const ButtonContainer = styled('div')`
  display: flex;
  min-width: 400px;
  margin: 40px 20px;
`

const GoBackIcon = styled('button')`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  display: flex;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.white};
  }
`

const BreadcrumbContainer = styled('div')``

const IconContainer = styled('div')`
  align-items: center;
  padding: 10px;
`

const CGUTitle = styled('h2')`
  ${(props) => props.theme.fonts.h2Bold};
  margin-bottom: 20px;
`

const CGUDescription = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 0px;
  margin-bottom: 30px;
`

const Link = styled('div')`
  padding: 10px 0px;
  ${(props) => props.theme.fonts.body};
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
`

const CheckLinesContainer = styled('div')`
  margin: 30px 0px;
`

const CheckLine = styled('div')`
  display: flex;
  align-items: center;
  margin: 20px;
  cursor: pointer;
`

const CheckLineText = styled('label')`
  ${(props) => props.theme.fonts.body};
  margin-left: 10px;
  cursor: pointer;
`
