import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'
import { windowSizeBreakpoints } from 'utils/breakpoints'

import Icons from 'components/icons/Icons'
import Modal from 'components/modal/Modal'
import Button from 'components/button/Button'
import utils from '../utils'

export interface PickerOption {
  label: string
  value: number
  order?: number
}

interface Props {
  setSelectedFloor: (l: PickerOption[]) => void
  selectedFloors: PickerOption[]
  allFloors: PickerOption[]
}

const MultipleFloorSelector = ({ setSelectedFloor, selectedFloors, allFloors }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()

  const [selectedFloorsState, setSelectedFloorsState] = React.useState(selectedFloors)

  const selectFloor = (item: PickerOption) => {
    setSelectedFloorsState((floors) =>
      floors.find((f) => f.value === item.value)
        ? floors.filter((f) => f.value !== item.value)
        : utils.sortedPickerFloors([...floors, item])
    )
  }

  const renderFloor = (floor: PickerOption, index: number, list: PickerOption[]) => {
    const isLast = list.length === index + 1
    const isSelected = !!selectedFloorsState.find((f) => f.value === floor.value)

    return (
      <FloorContainer>
        <RowContainer isSelected={isSelected} onClick={() => selectFloor(floor)}>
          <FloorText>{floor.label}</FloorText>
          <SelectionRound>{isSelected && <SelectionRoundFilled />}</SelectionRound>
        </RowContainer>

        {!isLast && <BlueLineBreak role="presentation" />}
      </FloorContainer>
    )
  }

  return (
    <MainContainer>
      <TitleContainer>
        <Title>{i18n.t('screens.incident.form.floor')}</Title>

        <CrossContainer onClick={Modal.close} aria-label={i18n.t('label.ariaLabel.windowCrossIcon')}>
          <Icons name="cross" size={25} color={Theme.colors.blue} />
        </CrossContainer>
      </TitleContainer>

      <SelectAllContainer
        onClick={() => {
          if (selectedFloorsState.length < allFloors.length) {
            setSelectedFloorsState(allFloors)
          } else {
            setSelectedFloorsState([])
          }
        }}>
        <SelectAll>
          {i18n.t(`common.${selectedFloorsState.length < allFloors.length ? 'selectAll' : 'unselectAll'}`)}
        </SelectAll>
      </SelectAllContainer>

      <ListContainer>
        {allFloors.length > 0 ? (
          <CategoryListContainer>{allFloors.map(renderFloor)}</CategoryListContainer>
        ) : (
          <ErrorMessageContainer>
            <ErrorMessage>{i18n.t('errors.incident.noResult')}</ErrorMessage>
          </ErrorMessageContainer>
        )}
      </ListContainer>

      <ButtonContainer>
        <Button
          type="submit"
          label={i18n.t('common.validate')}
          onClick={() => {
            setSelectedFloor(selectedFloorsState)
            Modal.close()
          }}
          color={Theme.colors.blue}
          textColor={Theme.colors.white}
          font={Theme.fonts.h3Bold}
        />
      </ButtonContainer>
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  width: 444px;

  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 90vw;
  }
`
const TitleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 20px 30px 10px;
  }
`

const CrossContainer = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`
const ListContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px 40px;
  overflow-y: scroll;

  /* Style scrollbar pour Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    width: 5px;
    background: white;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${(props) => props.theme.colors.scrollBar};
  }

  /* Style scrollbar pour Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.colors.scrollBar} transparent;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 10px 30px;
  }
`
const ErrorMessageContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 20px;
`
const CategoryListContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 0px;
`
const FloorContainer = styled('li')`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  list-style: none;
`

// TEXTES

const Title = styled('h1')`
  display: flex;
  flex: 1;
  margin: 0px;
  ${(props) => props.theme.fonts.title};
  overflow-wrap: anywhere;
`
const SelectAll = styled('h2')`
  display: flex;
  margin: 12px;
  text-align: center;
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.darkGrey};
  text-decoration: underline;
`
const ErrorMessage = styled('p')`
  ${(props) => props.theme.fonts.body};
  text-align: center;
`
const FloorText = styled('h2')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
`

// AUTRES

const BlueLineBreak = styled('div')`
  width: 60px;
  height: 3px;
  background-color: ${(props) => props.theme.colors.blue};
  border-radius: 2px;
  margin: 15px 0px;
`

const ButtonContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 32px 20px;
`

const SelectionRound = styled('div')`
  height: 28px;
  width: 28px;
  border: 2px solid ${(props) => props.theme.colors.blue};
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px;
`
const SelectionRoundFilled = styled('div')`
  height: 20px;
  width: 20px;
  border-radius: 32px;
  background-color: ${(props) => props.theme.colors.blue};
`

const RowContainer = styled('button')<{ isSelected: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => (props.isSelected ? props.theme.colors.paleBlue : props.theme.colors.white)};
`

const SelectAllContainer = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
`

export default MultipleFloorSelector
