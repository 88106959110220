import * as React from 'react'
import styled from 'theme/styled-components'

interface Props {
  allChoices: Choice[]
  activeChoices: Choice[]
  setActiveChoices: React.Dispatch<React.SetStateAction<Choice[]>>
  multipleChoices?: boolean
}

const ChoiceList = ({ allChoices, activeChoices, setActiveChoices, multipleChoices }: Props) => {
  const renderChoice = (item: Choice) => {
    const findActiveChoice = activeChoices.find((c) => c.key === item.key)
    return (
      <Container
        disabled={findActiveChoice?.disabled || item.disabled}
        onClick={() =>
          setActiveChoices((prev) =>
            prev.find((c) => c.key === item.key)
              ? prev.filter((c) => c.key !== item.key)
              : multipleChoices
              ? [...prev, item]
              : [item]
          )
        }>
        <LabelContainer>
          <Label disabled={findActiveChoice?.disabled || item.disabled}>{item.text}</Label>
        </LabelContainer>

        <RoundContainer disabled={findActiveChoice?.disabled || item.disabled}>
          {findActiveChoice && <RoundFill />}
        </RoundContainer>
      </Container>
    )
  }

  return <MainContainer>{allChoices.map(renderChoice)}</MainContainer>
}

export default ChoiceList

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Container = styled.button<{ disabled?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`

const RoundContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  border: 3px solid ${(props) => (props.disabled ? props.theme.colors.middleGrey : props.theme.colors.blue)};
  height: 24px;
  width: 24px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
`

const RoundFill = styled.div`
  background-color: ${(props) => props.theme.colors.blue};
  height: 20px;
  width: 20px;
  border-radius: 10px;
`

const LabelContainer = styled.div`
  margin-right: 12px;
  display: flex;
  flex: 1;
`

const Label = styled.p<{ disabled?: boolean }>`
  ${(props) => props.theme.fonts.body};
  display: flex;
  ${(props) => props.disabled && `color: ${props.theme.colors.middleGrey}`};
`
