import ws from 'utils/Webservice'

type PageReturn<U, V extends string> = Record<V, U[]> & Record<'meta', ArrayMeta>

// parameters
const SIZE = 500

/**
 * fetch every pages and group results
 * @param path api path
 * @param method api method
 * @param body api body
 * @param meta current page to fetch
 * @param last results from previous pages
 * @returns
 */
const fetchPages = <U>(
  userType: UserType,
  path: string,
  method: 'GET' | 'PUT' | 'POST' | 'DELETE',
  headers: { [key: string]: string } = {},
  page = 1,
  last: U[] = [],
  params?: any
): Promise<U[]> => {
  const key = 'data'
  const withParameters = path.includes('?')
  const fullPath = path + (withParameters ? '&' : '?') + `page[size]=${SIZE}&page[number]=${page}`

  return ws<PageReturn<U, typeof key>>(userType, fullPath, method, headers, params).then((res) => {
    // extract datas from object
    const results = res.data

    if (res.meta.paging.next_page !== null) {
      // more to dowload
      return fetchPages(userType, path, method, headers, page + 1, [...last, ...results], params)
    }

    //last page return previous results + last page results
    return [...last, ...results]
  })
}

export { fetchPages }
