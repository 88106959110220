import * as React from 'react'
import useI18n from 'i18n/useI18n'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Button from 'components/button/Button'
import Modal from 'components/modal/Modal'
import Alert from 'components/alert/Alert'
import AlertContent from './AlertContent'

import { I18n } from 'i18n/types'

const passwordValidationSchema = (i18n: I18n) =>
  Yup.object().shape({
    oldPassword: Yup.string().required(i18n.t('errors.form.mandatory')),
    newPassword: Yup.string()
      .matches(
        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@\$!%\*#?&\/\+\-\_=\\,;:.()€]).{14,}$/,
        i18n.t('errors.form.passwordFormat')
      )
      .required(i18n.t('errors.form.mandatory')),
    newPasswordConfirmation: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], i18n.t('errors.form.passwordMatch'))
      .required(i18n.t('errors.form.mandatory')),
  })

const PasswordModalContent = () => {
  const [Theme] = useTheme()
  const i18n = useI18n()

  const [wrongPassword, setWrongPassword] = React.useState(false)

  const submitChange = (values: PasswordUpdateFormV5) => {
    Alert.open(() => AlertContent(values, Modal.close, setWrongPassword), true)
  }

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
    },
    onSubmit: submitChange,
    validationSchema: passwordValidationSchema(i18n),
  })

  return (
    <ContentModal>
      <TitleModal>{i18n.t('screens.account.changePassword')}</TitleModal>
      <ObligatoryText>{i18n.t('common.allFieldsObligatory')}</ObligatoryText>
      <FormContainer>
        <InputContainer>
          <InputLabel htmlFor="currentPassword">{i18n.t('screens.account.currentPassword')}</InputLabel>
          <PasswordInput
            id="currentPassword"
            autoComplete="current-password"
            aria-required="true"
            aria-describedby="accountPasswordError"
            name="oldPassword"
            type="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.oldPassword}
            onFocus={() => setWrongPassword(false)}
          />
        </InputContainer>
        <ErrorText id="accountPasswordError">
          {wrongPassword
            ? i18n.t('screens.account.wrongPassword')
            : formik.touched.oldPassword && formik.errors.oldPassword}
        </ErrorText>

        <InputContainer>
          <InputLabel htmlFor="newPassword">{i18n.t('screens.account.newPassword')}</InputLabel>
          <PasswordInput
            id="newPassword"
            autoComplete="new-password"
            aria-required="true"
            aria-describedby="accountNewPasswordError"
            name="newPassword"
            type="password"
            onChange={(e) => {
              formik.handleChange(e)
              formik.handleBlur(e)
            }}
            onBlur={formik.handleBlur}
            value={formik.values.newPassword}
          />
        </InputContainer>
        <ErrorText id="accountNewPasswordError">{formik.touched.newPassword && formik.errors.newPassword}</ErrorText>

        <InputContainer>
          <InputLabel htmlFor="repeatPassword">{i18n.t('screens.account.confirmNewPassword')}</InputLabel>
          <PasswordInput
            id="repeatPassword"
            name="newPasswordConfirmation"
            aria-required="true"
            aria-describedby="accountRepeatPasswordError"
            type="password"
            onChange={(e) => {
              formik.handleChange(e)
              formik.handleBlur(e)
            }}
            onBlur={formik.handleBlur}
            value={formik.values.newPasswordConfirmation}
          />
        </InputContainer>
        <ErrorText id="accountRepeatPasswordError">
          {formik.touched.newPasswordConfirmation && formik.errors.newPasswordConfirmation}
        </ErrorText>

        <ButtonContainer>
          <Button
            type="submit"
            label={i18n.t('common.validate')}
            onClick={formik.handleSubmit}
            color={Theme.colors.blue}
            textColor={Theme.colors.white}
            font={Theme.fonts.h3Bold}
            disabled={!(formik.isValid && formik.dirty)}
            ariaLabel={i18n.t('label.ariaLabel.account.confirmPasswordForm')}
          />
        </ButtonContainer>
      </FormContainer>
    </ContentModal>
  )
}

export default PasswordModalContent

// CONTAINERS

const ContentModal = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 40px;
  margin-top: 25px;
`
const ButtonContainer = styled('div')`
  display: flex;
  width: 330px;
  margin: 0px 10px 10px 0px;
`

// TEXTS

const TitleModal = styled('h1')`
  ${(props) => props.theme.fonts.bodyBold};
  font-size: 24px;
  line-height: 26px;
  margin-left: 10px;
  margin-bottom: 0px;
`
const ErrorText = styled('p')`
  ${(props) => props.theme.fonts.body};
  font-size: 12px;
  color: ${(props) => props.theme.colors.blue};
  min-height: 20px;
  margin: 0px;
`
const ObligatoryText = styled('p')`
  ${(props) => props.theme.fonts.body};
  font-size: 12px;
  color: ${(props) => props.theme.colors.blue};
  margin-left: 11px;
`

// FORM

const FormContainer = styled('form')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  margin-top: 10px;
  width: 330px;
`
const InputContainer = styled('div')``
const InputLabel = styled('label')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
  font-size: 11px;
  line-height: 18px;
  margin-bottom: 4px;
  margin-top: 0px;
`
const PasswordInput = styled.input`
  width: 100%;
  border: 0;
  outline: 0;
  ${(props) => props.theme.fonts.bodyBold}
  border-bottom: 1px solid ${(props) => props.theme.colors.darkGrey};
  color: ${(props) => props.theme.colors.darkGrey};
  background: transparent;
  font-size: 15px;
  padding: 0px 0px 5px 0px;
  &:focus {
    border-bottom-color: ${(props) => props.theme.colors.blue};
  }
`
