import * as React from 'react'
import styled from 'styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'
import api from '../api'

import Logger from 'utils/Logger'
import { windowSizeBreakpoints } from 'utils/breakpoints'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'

import RoomRequestInfo from 'intervention/components/RoomRequestInfo'
import Button from 'components/button/Button'
import Modal from 'components/modal/Modal'

import { isAfter } from 'date-fns'

interface Props {
  room: AreaV4
  navigation: Navigation
  origin?: string
}

const RoomRequests = ({ room, navigation, origin }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()

  const user = useReducer(userStore.store, (s) => s.user)
  const site = useReducer(siteStore.store, (s) => s.site)

  const [requests, setRequests] = React.useState<IncidentV6[]>([])

  React.useEffect(() => {
    if (room.id && site && user) {
      api
        .getRoomRequests(room.uniquePlaceName, site.id, user.type)
        .then((res) =>
          setRequests(res.incidents.sort((a, b) => (isAfter(new Date(a.date), new Date(b.date)) ? 1 : -1)))
        )
        .catch(Logger.error)
    }
  }, [room])

  return (
    <MainContainer>
      <Title>{i18n.t('screens.incident.pendingRequests')}</Title>
      <RoomName>{room.name}</RoomName>
      <RequestsContainer>
        {requests.map((r, i, requests) => (
          <RoomRequestInfo key={r.id} request={r} isLast={requests.length === i + 1} />
        ))}
      </RequestsContainer>
      <Button
        label={i18n.t('screens.incident.newRequest')}
        onClick={() => {
          Modal.close()
          navigation.push(`/incident`, {
            room,
            origin,
          })
        }}
        font={Theme.fonts.h3Bold}
      />
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 40px 30px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin: 30px 20px;
  }
`
const RequestsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0px 20px;

  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin: 0px 10px;
  }
`
// TEXTES

const Title = styled('h1')`
  ${(props) => props.theme.fonts.title};
  margin: 0 0 20px;
  letter-spacing: 0.3px;
`
const RoomName = styled('h2')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
  margin: 0px;
  padding: 10px 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.mediumDarkGrey};
  border-top: 1px solid ${(props) => props.theme.colors.mediumDarkGrey};
`

export default RoomRequests
