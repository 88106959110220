import * as React from 'react'
import ReactDOM from 'react-dom'

import styled from 'theme/styled-components'
import Provider from 'theme/Provider'
import Icons from '../icons/Icons'

import { AllTimes } from 'roombooking/types'

type PopupSide = 'top-right' | 'bottom'

interface Props {
  title: string
  subtitle: string
  icon: IconName
  textColor: string
  backgroundColor: string
  side: PopupSide
  navigation: Navigation
  actionText?: string
  formTimes?: AllTimes
}

const InteractiveToast = ({
  title,
  subtitle,
  icon,
  textColor,
  backgroundColor,
  side,
  formTimes,
  actionText,
  navigation,
}: Props) => {
  const closeToast = () => {
    ToastFunction.close()
  }

  return (
    <MainContainer side={side}>
      <ToastContainer backgroundColor={backgroundColor} textColor={textColor}>
        <LeftContainer>
          <Icons name={icon} size={30} color={textColor} />
        </LeftContainer>
        <InfosContainer>
          <ModalTitle textColor={textColor}>{title}</ModalTitle>
          <ModalSubtitle textColor={textColor}>{subtitle}</ModalSubtitle>
          {!!formTimes && !!actionText && (
            <ModalAction
              textColor={textColor}
              onClick={() => {
                navigation.push('/room', {
                  formTimes,
                })
                closeToast()
              }}>
              {actionText}
            </ModalAction>
          )}
        </InfosContainer>
        <IconContainer onClick={closeToast}>
          <Icons name="cross" size={30} color={textColor} />
        </IconContainer>
      </ToastContainer>
    </MainContainer>
  )
}

let toastRoot: HTMLElement | null

const ToastFunction = {
  open: (
    title: string,
    subtitle: string,
    icon: IconName,
    textColor: string,
    backgroundColor: string,
    side: PopupSide,
    navigation: Navigation,
    formTimes?: AllTimes,
    actionText?: string
  ) => {
    toastRoot = document.getElementById('toast_root')
    if (toastRoot) {
      ReactDOM.render(
        <Provider>
          <InteractiveToast
            title={title}
            subtitle={subtitle}
            icon={icon}
            textColor={textColor}
            backgroundColor={backgroundColor}
            side={side}
            navigation={navigation}
            formTimes={formTimes}
            actionText={actionText}
          />
        </Provider>,
        toastRoot
      )
    }
  },
  close: () => {
    if (toastRoot) {
      ReactDOM.unmountComponentAtNode(toastRoot)
      toastRoot = null
    }
  },
}

export default ToastFunction

const MainContainer = styled('div')<{ side: PopupSide }>`
  position: fixed;
  z-index: 51;
  right: -1000px;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 0.5s;
  animation: slide 0.5s forwards;
  animation-delay: 0.5s;

  ${(props) => (props.side === 'top-right' ? 'top: 80px;' : 'bottom: 3%;')}

  ${(props) =>
    props.side === 'top-right'
      ? `@-webkit-keyframes slide {
    100% {
      right: 5%;
    }
  }

  @keyframes slide {
    100% {
      right: 5%;
    }
  }`
      : `@-webkit-keyframes slide {
        100% {
            right: 24px;
        }
      }
    
      @keyframes slide {
        100% {
            right: 24px;
        }
      }`}


  flex-direction: column;
`

const ToastContainer = styled('div')<{ backgroundColor: string; textColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  width: 330px;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 10px;
  border: 1px solid ${(props) => props.textColor};
`

const InfosContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-right: 8px;
`
const ModalTitle = styled('h1')<{ textColor: string }>`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.textColor};
  margin: 0px;
  padding-bottom: 4px;
`

const ModalSubtitle = styled('h3')<{ textColor: string }>`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.textColor};
  margin: 0px;
`

const ModalAction = styled('h3')<{ textColor: string }>`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.textColor};
  padding-top: 4px;
  margin: 0px;
  cursor: pointer;
  text-decoration: underline;
`

const LeftContainer = styled('div')`
  padding-right: 16px;
`

const IconContainer = styled('div')`
  cursor: pointer;
  padding: 4px;
`
