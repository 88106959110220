import { encryptStorage, encryptStorage as storage } from 'store/encryptStore'
import api from './api'
import { addSeconds, isAfter } from 'date-fns'

import * as PlanningStore from './planningStore'
import * as UserStore from 'store/user/user'
import * as SitesStore from 'sites/store/siteStore'

const getSSOTokenKey = (ssoID: SSOID) => `CACHE.SSO_${ssoID}_TOKEN`

interface SSOToken {
  token: string
  expirationDate: string
}

const getSSOToken = (userType: UserType, siteId: string, ssoID: SSOID) => {
  const last = storage.getItem(getSSOTokenKey(ssoID))
  if (!last) {
    return Promise.reject(`no sso token for ${ssoID}`)
  }

  if (isAfter(new Date(), new Date(last.expirationDate))) {
    return refreshSSOToken(userType, siteId, ssoID)
  } else {
    return Promise.resolve(last.token as string)
  }
}

const saveSSOToken = (token: SSOToken, ssoID: SSOID) => storage.setItem(getSSOTokenKey(ssoID), token)

const assureSSOToken = (userType: UserType, siteId: string, ssoID: SSOID) =>
  getSSOToken(userType, siteId, ssoID).catch(() => refreshSSOToken(userType, siteId, ssoID))

const refreshSSOToken = (userType: UserType, siteId: string, ssoID: SSOID) => {
  if (userType === 'ENGIE' || userType === 'EQUANS') {
    return api
      .getToken(userType, siteId, ssoID)
      .then((token) => {
        if (ssoID === 'ZAPFLOOR') {
          return api.zapfloor
            .getAllUserTokenInfos(userType, token.access_token)
            .then((res) => {
              const currentSite = SitesStore.store.getState().site
              const badgesList = UserStore.store.getState().badges
              const findBadge = badgesList.find((badge) => badge.siteId === currentSite?.id)
              const findZapfloorToken =
                res.find((tokenInfos) => tokenInfos.attributes.customer_number === findBadge?.departmentId) ||
                res.length > 0
                  ? res[0]
                  : undefined
              if (!!findZapfloorToken) {
                const ssoToken: SSOToken = {
                  token: findZapfloorToken.attributes.token,
                  expirationDate: addSeconds(
                    new Date(),
                    Math.min(findZapfloorToken.attributes.token_expires_in, 2592000)
                  ).toISOString(), // 2592000 secondes = 30 jours.
                  //Min entre la date d'expiration du token Zapfloor et 30j, pour que le token soit renouvelé au moins une fois par mois
                }
                PlanningStore.actions.setUserHasAccessGroups(findZapfloorToken.attributes.has_access_groups)
                PlanningStore.actions.setZapfloorUserId(findZapfloorToken.id)
                saveSSOToken(ssoToken, ssoID)
                return findZapfloorToken.attributes.token
              } else {
                encryptStorage.removeItem(getSSOTokenKey('ZAPFLOOR'))
                return Promise.reject('error')
              }
            })
            .catch(() => {
              return Promise.reject('user is unknown')
            })
        } else {
          const ssoToken: SSOToken = {
            token: token.access_token,
            expirationDate: new Date(parseJwt(token.access_token).exp * 1000).toISOString(),
          }
          saveSSOToken(ssoToken, ssoID)
          return token.access_token
        }
      })
      .catch(() => {
        return Promise.reject('error')
      })
  } else {
    return Promise.reject('error')
  }
}

const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

const clearSSO = () => {
  // Clear les tokens du store
  encryptStorage.removeItem(getSSOTokenKey('BARCLAP'))
  encryptStorage.removeItem(getSSOTokenKey('ZAPFLOOR'))
}

export { getSSOToken, saveSSOToken, assureSSOToken, refreshSSOToken, clearSSO }
