import { createEvent, createStore } from 'effector'

export const actions = {
  setConfirmNavigation: createEvent<boolean>('setAcceptedVersion'),
  reset: createEvent('reset'),
}

export interface FormState {
  confirmNavigation: boolean // if true -> user has to confirm before navigation (used when user started filling a form and is trying to leave without submitting)
}

const initialState: FormState = {
  confirmNavigation: false,
}

export const store = createStore<FormState>(initialState, { name: "Form's state" })
  .on(actions.setConfirmNavigation, (c, confirmNavigation) => ({ ...c, confirmNavigation }))
  .on(actions.reset, () => initialState)
