import Webservice from 'utils/Webservice'
import { assureToken } from 'authent365/utils/utils'

const api = {
  getSurveysList: (userType: UserType, siteId: string) =>
    assureToken(userType).then((token) =>
      Webservice<Surveys>(
        userType,
        `/services/v1/surveys`,
        'GET',
        {
          Authorization: `Bearer ${token}`,
        },
        { siteSurveyLink: siteId, companyEntitySurveyLink: userType === 'ENGIE' ? 1 : 2 }
      )
    ),
  answerSurvey: (userType: UserType, surveyId: string, body: SurveyAnswers) =>
    assureToken(userType).then((token) =>
      Webservice<void>(
        userType,
        `/services/v1/surveys/${surveyId}/answer`,
        'POST',
        {
          Authorization: `Bearer ${token}`,
        },
        undefined,
        body
      )
    ),
}

export default api
