import useReducer from 'store/useReducer'
import * as ThemeStore from './store'

import Theme from './Theme'
import ThemeAccessible from './Theme-accessible'

const Themes = {
  classic: Theme,
  accessible: ThemeAccessible,
}

const useTheme = () => {
  const theme = useReducer(ThemeStore.store, (s) => s.theme)

  return [Themes[theme], theme, ThemeStore.actions.setTheme] as const
}

export default useTheme
