import { createEvent, createStore } from 'effector'

export const actions = {
  setAcceptedVersion: createEvent<AcceptedCGUVersion>('setAcceptedVersion'),
  setTermsAndConditions: createEvent<TermsAndConditions>('setTermsAndConditions'),
  reset: createEvent('reset'),
}

const initialState: CGUState = {
  acceptedVersion: {},
  termsAndConditions: undefined,
}

export const store = createStore<CGUState>(initialState, { name: 'CGU' })
  .on(actions.setAcceptedVersion, (v, acceptedCGU) => ({
    ...v,
    acceptedVersion: { ...v.acceptedVersion, [acceptedCGU.userId]: acceptedCGU.cguVersion },
  }))
  .on(actions.setTermsAndConditions, (s, termsAndConditions) => ({
    ...s,
    termsAndConditions,
  }))
  .on(actions.reset, () => initialState)
