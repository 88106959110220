import { NestedTranslations } from 'i18n/i18n'

const i18nUtils = {
  transformTranslations: (translations: PoEditorTranslation[]) => {
    const translationsByLanguage: { [language: string]: NestedTranslations } = {}

    translations.forEach(({ context, key, value, language }) => {
      if (!!context && !!key && !!language) {
        const fullKey = `${context?.replace(/\"/g, '')}.${key}`
        const keys = fullKey.split('.')
        translationsByLanguage[language] = translationsByLanguage[language] || {}

        keys.reduce((acc, part, index) => {
          if (index === keys.length - 1) {
            acc[part] = value
          } else {
            acc[part] = acc[part] || {}
          }
          return acc[part]
        }, translationsByLanguage[language])
      }
    })

    return translationsByLanguage
  },
}

export default i18nUtils
