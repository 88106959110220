import * as React from 'react'
import styled from 'styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'

import api from './api'

import Logger from 'utils/Logger'
import { windowSizeBreakpoints } from 'utils/breakpoints'

import useReducer from 'store/useReducer'
import * as contactStore from './contactStore'
import * as userStore from 'store/user/user'

import utils from './utils'

import ContactInfosGraph from './ContactInfosGraph'

import Loader from 'react-loader-spinner'

type Status = 'ok' | 'error' | 'loading'

interface Props {
  onSelectContact: (contact: DirectoryUser) => void
  visitors?: VisitorV6[]
}

const FavoriteGraphContacts = ({ onSelectContact, visitors = [] }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const favorites = useReducer(contactStore.store, (s) => s.favorites).filter(
    (favorite) => !!favorite.idGraph
  ) as GraphFavorite[]
  const userType = useReducer(userStore.store, (s) => s.user?.type || 'ENGIE')

  const [favoritesProfile, setFavoritesProfile] = React.useState<DirectoryUser[]>([])
  const [status, setStatus] = React.useState<Status>()

  const getFavoriteUsers = (favs: GraphFavorite[]) => {
    setStatus('loading')
    Promise.all(favs.map((f) => api.getUser(userType, f.idGraph)))
      .then((res) => {
        setFavoritesProfile([
          ...visitors.map((v, index) => ({
            id: `${index}`,
            givenName: v.firstName,
            surname: v.lastName,
            profilePhotoUrl: 'VISITOR',
            mail: v.email || '',
            businessPhones: [],
          })),
          ...res,
        ])
      })
      .then(() => setStatus('ok'))
      .catch((err) => {
        setStatus('error')
        Logger.error(err)
      })
  }

  React.useEffect(() => {
    getFavoriteUsers(favorites)
  }, [])

  const sortContactsByLetter = React.useMemo(() => {
    if (favoritesProfile) {
      return utils.sortUsersByLetter(favoritesProfile)
    }
  }, [favoritesProfile])

  return (
    <MainContainer>
      {status === 'loading' ? (
        <LoaderContainer>
          <Loader type="TailSpin" color={Theme.colors.blue} />
        </LoaderContainer>
      ) : status === 'ok' ? (
        sortContactsByLetter && sortContactsByLetter.length > 0 ? (
          <ListContainer>
            {visitors.length > 0 && <ErrorMessage>{i18n.t('screens.directory.favoritesDescription')}</ErrorMessage>}
            {sortContactsByLetter.map((data) => (
              <div key={data.letter}>
                <CategoryLetter>{data.letter}</CategoryLetter>
                {data.users
                  .sort((a, b) => a.surname.localeCompare(b.surname))
                  .map((u, i) => (
                    <ContactInfosGraph
                      contact={u}
                      isLast={data.users.length === i + 1}
                      key={u.id}
                      onSelectContact={onSelectContact}
                      updateFavorites={(favs) => getFavoriteUsers(favs as GraphFavorite[])}
                    />
                  ))}
              </div>
            ))}
          </ListContainer>
        ) : (
          <ErrorMessageContainer>
            <ErrorMessage>{i18n.t('screens.directory.noFavorites')}</ErrorMessage>
          </ErrorMessageContainer>
        )
      ) : (
        <ErrorMessageContainer>
          <ErrorMessage>{i18n.t('screens.directory.noFavorites')}</ErrorMessage>
        </ErrorMessageContainer>
      )}
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: calc(100% - 116.85px);
  margin-top: 16.85px;
  background-color: ${(props) => props.theme.colors.white};
`
const ListContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;

  margin: 0 40px;

  /* Style scrollbar pour Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    width: 5px;
    background: white;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${(props) => props.theme.colors.scrollBar};
  }

  /* Style scrollbar pour Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.colors.scrollBar} transparent;

  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin: 0 12px;
  }
`
const ErrorMessageContainer = styled('div')`
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 0 40px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin: 0 12px;
  }
`
const ErrorMessage = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin-top: 20px;
  text-align: center;
`
const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  width: 375px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    min-width: 300px;
  }
`

// TEXTES

const CategoryLetter = styled('h4')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
  margin: 5px 0px 6px 0px;
  padding: 7px 0px 6px 24px;
  text-transform: capitalize;
  border-top: 0.5px solid ${(props) => props.theme.colors.mediumDarkGrey};
  border-bottom: 1px solid ${(props) => props.theme.colors.mediumDarkGrey};
`

export default FavoriteGraphContacts
