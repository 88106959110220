import React from 'react'
import useI18n from 'i18n/useI18n'

import { windowSizeBreakpoints } from 'utils/breakpoints'
import { getBookingReservationTime, getDayTimezone } from '../utils'
import stringUtils from 'utils/strings'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'
import Icon from 'components/icons/Icons'
import Button from 'components/button/Button'
import PicturesList from '../components/PicturesList'

interface Props {
  booking: ZapfloorBooking
  zone: ZapfloorZone
  rate: number
  iconChoice: IconName
  occupancyColor: string
  peopleList?: People[]
  removeBooking: () => void
  desk?: AreaV4
  navigation: Navigation
  isParking: boolean
}

const BookingDetails = ({
  booking,
  zone,
  rate,
  iconChoice,
  occupancyColor,
  peopleList,
  removeBooking,
  desk,
  navigation,
  isParking,
}: Props) => {
  const [theme] = useTheme()
  const i18n = useI18n()

  const { access_group_name, unit_name, desk_count, desks_taken } = zone.attributes
  const { waiting_list } = booking.attributes
  const reservationTime = getBookingReservationTime(booking.attributes.time_from, booking.attributes.time_to)

  const renderInfos = (iconName: IconName, size: number, color: string, label: string, arialLabel?: string) => (
    <InfosContainer>
      <IconContainer>
        <Icon name={iconName} size={size} color={color} ariaLabel={arialLabel} />
      </IconContainer>

      <Label>{label}</Label>
    </InfosContainer>
  )

  return (
    <MainContainer>
      <HeaderTitle>{i18n.t('screens.planning.booking.myBooking')}</HeaderTitle>

      <DateContainer>
        <DateLabel>
          {stringUtils.capitalize(
            i18n.t('screens.parking.dateBooking', {
              date: getDayTimezone(booking.attributes.date_from),
            })
          )}
        </DateLabel>
      </DateContainer>
      {renderInfos('clock', 20, theme.colors.blue, i18n.t(`screens.desk.reservationTime.${reservationTime}`))}
      {renderInfos('id_card', 20, theme.colors.blue, access_group_name, i18n.t('screens.planning.iconsAction.team'))}
      {renderInfos(
        isParking ? 'parking' : 'desk',
        20,
        theme.colors.blue,
        desk?.name || unit_name,
        i18n.t('screens.planning.iconsAction.zone')
      )}
      {!desk && (
        <OccupancyInfo>
          <IconContainer>
            <Icon
              name="users-wm"
              color={theme.colors.blue}
              size={20}
              ariaLabel={i18n.t('screens.planning.iconsAction.occupancy')}
            />
          </IconContainer>
          <Occupancy>
            {i18n.t('screens.planning.secondForm.occupancy', {
              occupancy: desks_taken,
              capacity: desk_count,
            })}
          </Occupancy>
          <IconContainer>
            <Icon
              name={iconChoice}
              color={occupancyColor}
              size={15}
              ariaLabel={i18n.t('screens.planning.iconsAction.occupancyRate')}
            />
          </IconContainer>
          <OccupancyRate occupancyColor={occupancyColor}>{rate}%</OccupancyRate>
        </OccupancyInfo>
      )}
      <RowContainer>
        <PointContainer isWaitingList={waiting_list} />
        <Info isWaitingList={waiting_list}>
          {i18n.t(`screens.planning.booking.${waiting_list ? 'waiting' : 'confirmed'}`)}
        </Info>
      </RowContainer>
      {!!peopleList && <PicturesList clickable peopleList={peopleList} withAlert navigation={navigation} />}

      <ButtonContainer>
        <Button
          font={theme.fonts.h3Bold}
          onClick={removeBooking}
          label={i18n.t('screens.planning.booking.removeMyBooking')}
          color={theme.colors.blue}
          textColor={theme.colors.white}
          shadow
        />
      </ButtonContainer>
    </MainContainer>
  )
}

export default BookingDetails

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 15px;
  }
  width: 300px;
`

const HeaderTitle = styled.p`
  ${(props) => props.theme.fonts.h2Bold};
  margin: 0 0 20px 0;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`

const InfosContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 12px 6px 0px;
`

const Label = styled.p`
  ${(props) => props.theme.fonts.body};
  margin: 0px;
`

const DateContainer = styled.div`
  display: flex;
  margin: 20px 0px;
`

const DateLabel = styled.p`
  ${(props) => props.theme.fonts.bodyBold};
  margin: 0 0 6px 6px;
`

const RowContainer = styled.div`
  ${(props) => props.theme.fonts.body};
  font-size: 12px;
  line-height: 14px;
  margin: 0px 0px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const IconContainer = styled.div`
  align-items: center;
  justify-content: center;
  margin-right: 6px;
`

const Occupancy = styled.p`
  margin: 0px 10px 0 0;
  ${(props) => props.theme.fonts.body};
`

const OccupancyRate = styled.p<{ occupancyColor: string }>`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.occupancyColor};
  margin: 0px;
`

const OccupancyInfo = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 12px 6px 0px;
`

const PointContainer = styled.div<{ isWaitingList: boolean }>`
  width: 4px;
  height: 4px;
  border-radius: 2px;
  margin-right: 7px;
  background-color: ${(props) =>
    !props.isWaitingList ? props.theme.colors.strongTurquoise : props.theme.colors.orange};
`

const Info = styled.p<{ isWaitingList?: boolean }>`
  margin: 0px;
  padding-top: 3px;
  color: ${(props) =>
    props.isWaitingList === undefined
      ? props.theme.colors.black
      : !props.isWaitingList
      ? props.theme.colors.strongTurquoise
      : props.theme.colors.orange};
`
