import React from 'react'
import useI18n from 'i18n/useI18n'
import { windowSizeBreakpoints } from 'utils/breakpoints'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'
import Icon from 'components/icons/Icons'
import Button from 'components/button/Button'

interface Props {
  zoneName: string
  profilName: string
  onDeleteFavorite: () => void
  closeModal: () => void
  isParking: boolean
}

const FavoriteZone = ({ zoneName, profilName, onDeleteFavorite, closeModal, isParking }: Props) => {
  const [theme] = useTheme()
  const i18n = useI18n()

  return (
    <MainContainer>
      <HeaderTitle>{i18n.t('screens.planning.favoriteZone.title')}</HeaderTitle>
      <InfosContainer>
        <Icon
          name="id_card"
          size={20}
          color={theme.colors.blue}
          ariaLabel={i18n.t('screens.planning.iconsAction.team')}
        />
        <Label>{profilName}</Label>
      </InfosContainer>
      <InfosContainer>
        <Icon
          name={isParking ? 'parking' : 'desk'}
          size={20}
          color={theme.colors.blue}
          ariaLabel={i18n.t('screens.planning.iconsAction.zone')}
        />
        <Label>{zoneName}</Label>
      </InfosContainer>
      <ButtonContainer>
        <Button
          font={theme.fonts.h3Bold}
          onClick={onDeleteFavorite}
          label={i18n.t('screens.planning.favoriteZone.remove')}
        />
        <Margin />
        <Button
          font={theme.fonts.h3Bold}
          onClick={closeModal}
          label={i18n.t('common.close')}
          color={theme.colors.white}
          textColor={theme.colors.blue}
          shadow
        />
      </ButtonContainer>
    </MainContainer>
  )
}

export default FavoriteZone

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 15px;
  }
  width: 300px;
`

const HeaderTitle = styled.p`
  ${(props) => props.theme.fonts.h2Bold};
  margin: 0 0 20px 0;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`

const Margin = styled.div`
  height: 15px;
`

const InfosContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Label = styled.p`
  ${(props) => props.theme.fonts.body};
  margin: 0 0 6px 6px;
`
