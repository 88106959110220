import * as React from 'react'
import styled from 'theme/styled-components'

interface Props {
  value: boolean
  onChange: (val: boolean) => void
}

const Radio = ({ value, onChange }: Props) => (
  <Container
    onClick={(e) => {
      e.stopPropagation()
      onChange(!value)
    }}
    selected={value}>
    {!!value && <SelectedContainer />}
  </Container>
)

export default Radio

const Container = styled('button')<{ selected: boolean }>`
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.blue};
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  padding: 0;
  box-sizing: border-box;
`

const SelectedContainer = styled('div')`
  background-color: ${(props) => props.theme.colors.blue};
  height: 12px;
  width: 12px;
  border-radius: 50%;
`
