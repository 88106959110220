import { roundToNearestMinutes, addMinutes, isBefore } from 'date-fns'

const dateUtils = {
  roundToNextQuarter: (date: Date) => {
    const roundedDate = roundToNearestMinutes(date, { nearestTo: 15 })
    return isBefore(roundedDate, date) ? addMinutes(roundedDate, 15) : roundedDate
  },
}

export default dateUtils
