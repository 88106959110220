import { isWeekend, setHours, setMinutes, isSameDay, isBefore } from 'date-fns'
import { I18n } from 'i18n/types'
import { getDatesInInterval, getDayTimezone } from 'planning/utils'

export const findStatusColor = (status: ParkingSpotRequestV4['nextAllocationStatus'], theme: Theme) => {
  switch (status) {
    case 'APPROVED':
      return theme.colors.strongTurquoise
    case 'PENDING':
    case 'ALREADY_ATTEMPTED':
      return theme.colors.orange
    case 'DEAD':
      return theme.colors.red
    default:
      return theme.colors.middleGrey
  }
}

export const findSharvyStatusColor = (status: SharvyParkingBookingV6['status'], theme: Theme) => {
  switch (status) {
    case 'SUCCESS':
      return theme.colors.strongTurquoise
    case 'PENDING':
      return theme.colors.orange
    case 'FAILURE':
      return theme.colors.red
    default:
      return theme.colors.red
  }
}

export const isSamePlaceBookedSharvy = (bookings: SharvyParkingBookingV6[]) =>
  bookings.length === 2 && bookings[0].spot?.id === bookings[1].spot?.id && bookings[0].status === bookings[1].status

export const filterSharvyBookings = (bookings: SharvyParkingBookingV6[]) =>
  bookings.filter(
    (booking) =>
      isBefore(new Date(), setHours(setMinutes(getDayTimezone(booking.reservationDate || ''), 59), 23)) &&
      !isWeekend(getDayTimezone(booking.reservationDate || ''))
  )

export const datesToAvoidSharvy = (bookings: SharvyParkingBookingV6[]) =>
  bookings
    .filter((booking) => {
      const allDayBookings = bookings.filter((b) =>
        isSameDay(getDayTimezone(b.reservationDate || ''), getDayTimezone(booking.reservationDate || ''))
      )
      return allDayBookings.length > 1
    })
    .map((booking) => getDayTimezone(booking.reservationDate || ''))

export const minSelectableDateSharvy = (minDate: Date, maxDate: Date, disabledDates: Date[]) => {
  const getAllPossibleDates = getDatesInInterval(minDate, maxDate, [1, 2, 3, 4, 5])

  const findFirstAvailableDate = getAllPossibleDates.find((d1) => !disabledDates.find((d2) => isSameDay(d1, d2)))

  return findFirstAvailableDate || new Date()
}

export const allowedDaysToBook = (
  daysToBook: Date[],
  bookings: SharvyParkingBookingV6[],
  selectedReservationTime: ReservationTime
) =>
  daysToBook.filter((day) => {
    const findBookingsDay = bookings.filter((booking) => isSameDay(day, getDayTimezone(booking.reservationDate || '')))
    const isNotAllowedToBook =
      findBookingsDay.length > 1 ||
      (findBookingsDay.length === 1 &&
        ((findBookingsDay[0].isMorning && selectedReservationTime !== 'AFTERNOON') ||
          (!findBookingsDay[0].isMorning && selectedReservationTime !== 'MORNING')))
    return !isNotAllowedToBook
  })

export const isNotAllowedToBookSharvy = (
  bookings: SharvyParkingBookingV6[],
  selectedReservationTime: ReservationTime
) =>
  bookings.length > 1 ||
  (bookings.length === 1 &&
    ((bookings[0].isMorning && selectedReservationTime !== 'AFTERNOON') ||
      (!bookings[0].isMorning && selectedReservationTime !== 'MORNING')))

export const bookingsSharvyToSend = (
  dateToSend: string,
  isRecurrenceActive: boolean,
  selectedReservationTime: ReservationTime,
  filteredDaysToBook: Date[],
  selectedTypePlace: TypePlace,
  i18n: I18n
) =>
  isRecurrenceActive
    ? selectedReservationTime === 'ALL_DAY'
      ? [
          ...filteredDaysToBook.map((day) => ({
            isMorning: true,
            reservationDate: i18n.t('screens.planning.secondForm.dateToSend', {
              date: day,
            }),
            spotTypeRequested: selectedTypePlace,
          })),
          ...filteredDaysToBook.map((day) => ({
            isMorning: false,
            reservationDate: i18n.t('screens.planning.secondForm.dateToSend', {
              date: day,
            }),
            spotTypeRequested: selectedTypePlace,
          })),
        ]
      : filteredDaysToBook.map((day) => ({
          isMorning: selectedReservationTime === 'MORNING',
          reservationDate: i18n.t('screens.planning.secondForm.dateToSend', {
            date: day,
          }),
          spotTypeRequested: selectedTypePlace,
        }))
    : selectedReservationTime === 'ALL_DAY'
    ? [
        {
          isMorning: true,
          reservationDate: dateToSend,
          spotTypeRequested: selectedTypePlace,
        },
        {
          isMorning: false,
          reservationDate: dateToSend,
          spotTypeRequested: selectedTypePlace,
        },
      ]
    : [
        {
          isMorning: selectedReservationTime === 'MORNING',
          reservationDate: dateToSend,
          spotTypeRequested: selectedTypePlace,
        },
      ]

export type TypePlace = 'STANDARD' | 'ELECTRIC' | 'DISABLED' | 'MOTORBIKE'

export const TIME_OPTIONS: ReservationTime[] = ['MORNING', 'AFTERNOON', 'ALL_DAY']
export const TYPE_PLACE_OPTIONS: TypePlace[] = ['STANDARD', 'ELECTRIC', 'DISABLED', 'MOTORBIKE']
export const WEEKDAYS: WeekDay[] = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY']
export const WEEK_DAY_SORTER = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
}
