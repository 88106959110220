import { createEvent, createStore } from 'effector'

export const actions = {
  setNews: createEvent<NewsSimpleV6[]>('setNews'),
  resetNews: createEvent('resetNews'),
  setLiked: createEvent<string[]>('setNewsLikes'),
  resetLiked: createEvent('resetLiked'),
  setDisplayedType: createEvent<NewsDisplayedType>('setType'),
  resetType: createEvent('resetType'),
  setTags: createEvent<string[]>('setTags'),
  resetTags: createEvent('resetTags'),
}

const initialState: NewsState = {
  news: [],
  liked: [],
  type: 'INFO',
  tags: ['INFORMATION', 'URGENT', 'CAMPUS'],
}

export const store = createStore<NewsState>(initialState, { name: 'News' })
  .on(actions.setNews, (s, news) => ({ ...s, news }))
  .on(actions.resetNews, (s) => ({ ...s, news: initialState.news }))
  .on(actions.setLiked, (s, liked) => ({ ...s, liked }))
  .on(actions.resetLiked, (s) => ({ ...s, liked: initialState.liked }))
  .on(actions.setDisplayedType, (s, type) => ({ ...s, type }))
  .on(actions.resetType, (s) => ({ ...s, type: initialState.type }))
  .on(actions.setTags, (s, tags) => ({ ...s, tags }))
  .on(actions.resetTags, (s) => ({ ...s, tags: initialState.tags }))
