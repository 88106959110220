import * as React from 'react'
import ReactDOM from 'react-dom'

import styled from 'theme/styled-components'
import Provider from 'theme/Provider'

const ANIM_DELAY = 3000 // 3sec

type PopupSide = 'top' | 'top-right' // La position du Pop-up

interface Props {
  Content: () => JSX.Element
  side?: PopupSide
}

const Toast = ({ Content, side = 'top-right' }: Props) => {
  React.useEffect(() => {
    setTimeout(ToastFunction.close, ANIM_DELAY)
  }, [])

  return (
    <ToastContainer side={side}>
      <Content />
    </ToastContainer>
  )
}

let toastRoot: HTMLElement | null

const ToastFunction = {
  open: (content: () => JSX.Element, side?: PopupSide) => {
    toastRoot = document.getElementById('toast_root')
    if (toastRoot) {
      ReactDOM.render(
        <Provider>
          <Toast Content={content} side={side} />
        </Provider>,
        toastRoot
      )
    }
  },
  close: () => {
    if (toastRoot) {
      ReactDOM.unmountComponentAtNode(toastRoot)
      toastRoot = null
    }
  },
}

export default ToastFunction

const ToastContainer = styled('div')<{ side: PopupSide }>`
  background-color: ${(props) => props.theme.colors.white};
  position: fixed;
  z-index: 51;
  -webkit-animation: slideInAndOut 3.1s linear;
  animation: slideInAndOut 3.1s linear;

  top: 9%;
  ${(props) => props.side === 'top' && `left: calc(50% - 85px)`};

  ${(props) =>
    props.side === 'top-right'
      ? `
  @keyframes slideInAndOut {
    0% {
      right: -170px;
    }
    10%,
    50% {
      right: 3%;
    }
    75%,
    100% {
      right: -220px;
    }
  }`
      : props.side === 'top' &&
        `
  @keyframes slideInAndOut {
    0% {
      top: -170px;
    }
    10%,
    50% {
      top: 7%;
    }
    75%,
    100% {
      top: -220px;
    }
  }`};

  flex-direction: column;
`
