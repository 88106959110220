import ws from 'utils/Webservice'
import config from 'core/src/config'

const api = {
  getTermsAndConditions: (lang: string) =>
    ws<TermsAndConditions>(
      'EXTERNAL',
      `${config.globalAPIVersion}/public/terms-and-conditions`,
      'GET',
      {
        'Accept-Language': lang,
        'X-Api-Key': config.API_KEY,
      },
      undefined,
      undefined,
      true
    ),
}

export default api
