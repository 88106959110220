import { createEvent, createStore } from 'effector'

export const actions = {
  setFav: createEvent<FavoriteDirection[]>('setTransportFavs'),
  resetFav: createEvent('resetTransportFavs'),
}

const initialState: FavoriteState = {
  favorites: [],
}

export const store = createStore<FavoriteState>(initialState, { name: 'Transport Favorites' })
  .on(actions.setFav, (s, favorites) => ({ ...s, favorites }))
  .on(actions.resetFav, () => initialState)
