import * as React from 'react'
import styled from 'styled-components'

import useReducer from 'store/useReducer'
import * as featureStore from 'sites/store/featureStore'
import * as siteStore from 'sites/store/siteStore'

import { filterFeatures, sortByCategory, orderCategory } from './utils'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import useI18n from 'i18n/useI18n'
import useNavigation from 'utils/navigation/useNavigation'

import TitleHelmet from 'components/title/TitleHelmet'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'

const SiteMap = () => {
  const i18n = useI18n()
  const navigation = useNavigation()

  const features = useReducer(featureStore.store, (f) => f.features)
  const parkingUrl = useReducer(siteStore.store, (s) => s.parkingUrl)

  const allPages: FeatureCategory[] = [
    ...filterFeatures(features),
    { name: i18n.t('screens.home.title'), type: 'home', position: 0, path: '/' },
    { name: i18n.t('screens.account.title'), type: 'account', position: 0, path: '/account' },
    { name: i18n.t('screens.sites.title'), type: 'sites', position: 0, path: '/sites' },
    { name: i18n.t('screens.cgu.title'), type: 'cgu', position: 0, path: '/cgu' },
  ].map((feature) => sortByCategory(feature))

  const pagesSortedCategory = allPages.reduce((acc, cur) => {
    const index = acc.findIndex((c) => c.category === cur.category)

    if (cur.category) {
      if (index > -1) {
        acc[index].features.push(cur)
      } else {
        acc.push({ features: [cur], category: cur.category })
      }
    }
    return orderCategory(acc)
  }, [] as FeatureByCategory[])

  const onClickFunction = (feature: FeatureCategory) => {
    if (feature.path) {
      navigation.push(feature.path)
    } else if (feature.url) {
      window.open(feature.url)
    } else if (feature.name === 'Parking') {
      window.open(parkingUrl)
    }
  }

  const renderLink = (feature: FeatureCategory) => (
    <FeatureLink key={feature.name}>
      <TextContainer>
        <FeatureText onClick={() => onClickFunction(feature)}>{feature.name}</FeatureText>
      </TextContainer>
    </FeatureLink>
  )

  const renderCategory = (category: FeatureByCategory) => (
    <CategoryContainer>
      <CategoryTitle>{i18n.t(`screens.sitemap.${category.category}`)}</CategoryTitle>
      <FeaturesList>{category.features.map(renderLink)}</FeaturesList>
    </CategoryContainer>
  )

  return (
    <>
      <TitleHelmet title={i18n.t('screens.sitemap.title')} />
      <BreadcrumbContainer>
        <Breadcrumb screen="sitemap" path="sitemap" navigation={navigation} />
      </BreadcrumbContainer>
      <Title>{i18n.t('screens.sitemap.title')}</Title>
      <SiteMapContainer>{pagesSortedCategory.map(renderCategory)}</SiteMapContainer>
    </>
  )
}

export default SiteMap

// CONTAINERS

const SiteMapContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-left: 130px;
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    display: flex;
    flex-direction: column;
    margin-left: 70px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin: 0px 0px 30px 30px;
  }
`
const CategoryContainer = styled('div')`
  display: flex;
  flex-direction: column;
`
const FeaturesList = styled('ul')`
  padding-left: 20px;
  margin: 10px 0px 60px 0px;
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    margin-bottom: 90px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    margin-bottom: 60px;
  }
`
const BreadcrumbContainer = styled('div')`
  margin-bottom: 40px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-bottom: 0px;
  }
`
const FeatureLink = styled('li')`
  list-style: none;
`
const TextContainer = styled('div')`
  display: flex;
`

// TEXTES

const Title = styled('h1')`
  ${(props) => props.theme.fonts.bodyBold};
  font-size: 24px;
  margin: 0px 0px 60px 100px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    margin-left: 40px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin: 30px 0px 60px 20px;
  }
`
const CategoryTitle = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
  font-size: 24px;
  margin: 0px;
`
const FeatureText = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 15px 0px 0px;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.colors.blue};
  }
`
