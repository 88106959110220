import React from 'react'
import useI18n from 'i18n/useI18n'
import utils from 'utils/strings'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import { findDay } from '../utils'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'
import Icon from 'components/icons/Icons'
import Button from 'components/button/Button'

interface Props {
  referenceWeek: ReferenceWeek
  onDelete: () => void
  onApply: () => void
}

const ReferenceWeek = ({ referenceWeek, onDelete, onApply }: Props) => {
  const [theme] = useTheme()
  const i18n = useI18n()

  const renderInfos = (iconName: IconName, size: number, color: string, label?: string, ariaLabel?: string) => (
    <InfosContainer>
      <IconContainer>
        <Icon name={iconName} size={size} color={color} ariaLabel={ariaLabel} />
      </IconContainer>
      <Label>{label}</Label>
    </InfosContainer>
  )

  const renderZoneInfos = (zoneInfos: FavoriteZone, borderBottom: boolean) => (
    <InfosMainContainer borderBottom={borderBottom}>
      {zoneInfos.reservationTime !== 'ALL_DAY' &&
        renderInfos(
          'clock',
          20,
          theme.colors.blue,
          i18n.t(`screens.desk.reservationTime.${zoneInfos.reservationTime}`)
        )}
      {renderInfos('id_card', 20, theme.colors.blue, zoneInfos.profilName, i18n.t('screens.planning.iconsAction.team'))}
      {/* We check if the reservation is a desk or a zone, and display the name accordingly */}
      {!!zoneInfos.hotDeskName
        ? renderInfos(
            zoneInfos.isParking ? 'parking' : 'desk',
            20,
            theme.colors.blue,
            zoneInfos.hotDeskName,
            i18n.t('screens.planning.iconsAction.zone')
          )
        : renderInfos(
            zoneInfos.isParking ? 'parking' : 'desk',
            20,
            theme.colors.blue,
            zoneInfos.zoneName,
            i18n.t('screens.planning.iconsAction.zone')
          )}
    </InfosMainContainer>
  )

  const renderDay = (day: string) => {
    const dayOfWeek = findDay(day, referenceWeek)

    return (
      <DayContainer>
        <DayHeader>
          <DayTitle>{utils.capitalize(i18n.t(`screens.planning.referenceWeek.weekDays.${day}`))}</DayTitle>
        </DayHeader>

        {dayOfWeek.map((day, index) => {
          if (!!day && !!day.profilId && !!day.profilName && !!day.zoneId && !!day.zoneName && !!day.reservationTime) {
            return renderZoneInfos(day, index < dayOfWeek.length)
          }
          return (
            <InfosMainContainer key={`${index}`}>
              <Label>{i18n.t('screens.planning.referenceWeek.noBooking')}</Label>
            </InfosMainContainer>
          )
        })}

        {dayOfWeek.length === 0 && (
          <InfosMainContainer>
            <Label>{i18n.t('screens.planning.referenceWeek.noBooking')}</Label>
          </InfosMainContainer>
        )}
      </DayContainer>
    )
  }

  return (
    <ModalContainer>
      <Title>{i18n.t('screens.planning.referenceWeek.title')}</Title>
      {renderDay('MONDAY')}
      {renderDay('TUESDAY')}
      {renderDay('WEDNESDAY')}
      {renderDay('THURSDAY')}
      {renderDay('FRIDAY')}
      <ButtonsContainer>
        <ApplyContainer>
          <Button
            shadow
            font={theme.fonts.h3Bold}
            onClick={onApply}
            label={i18n.t('screens.planning.referenceWeek.apply')}
          />
        </ApplyContainer>

        <Button
          font={theme.fonts.h3Bold}
          onClick={onDelete}
          label={i18n.t('common.delete')}
          textColor={theme.colors.blue}
          color={theme.colors.white}
          shadow
        />
      </ButtonsContainer>
    </ModalContainer>
  )
}

export default ReferenceWeek

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  width: 400px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    width: 280px;
    padding: 15px;
  }
`

const Title = styled.p`
  ${(props) => props.theme.fonts.h2Bold};
  align-self: flex-start;
  margin: 0 0 20px 0;
`

const DayContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0 0 10px 0 ${(props) => props.theme.colors.shadow};
  margin: 10px 0px;
  flex-direction: column;
  width: 100%;
`

const DayHeader = styled.div`
  padding-left: 24px;
  border-bottom: 1px solid ${(props) => props.theme.colors.mediumDarkGrey};
`

const InfosMainContainer = styled.div<{ borderBottom?: boolean }>`
  padding: 12px;
  ${(props) => props.borderBottom && ` border-bottom: 1px solid ${props.theme.colors.mediumDarkGrey};`}
`

const DayTitle = styled.p`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
`

const InfosContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const IconContainer = styled.div`
  width: 20px;
  justify-content: center;
  align-items: center;
`

const Label = styled.p`
  padding-left: 6px;
  ${(props) => props.theme.fonts.label};
`

const ButtonsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
`

const ApplyContainer = styled('div')`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`
