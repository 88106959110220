import * as React from 'react'
import styled from 'styled-components'
import useI18n from 'i18n/useI18n'

import SingleValueWheelPicker from 'components/picker/SingleValueWheelPicker'

const ModalContent = (
  title: string,
  limit: number,
  selectedChoice: number,
  setChoice: React.Dispatch<React.SetStateAction<number>>
) => {
  const i18n = useI18n()
  const [ownOption, setOwnOption] = React.useState(selectedChoice)

  const options = Array.from(Array(limit), (_, index) => index + 1)

  const valuesText = options.map((o) => (o < limit ? o.toString() : i18n.t('screens.room.countPlus', { count: limit })))

  return (
    <MainContainer>
      <ModalTitle>{title}</ModalTitle>
      <SingleValueWheelPicker
        values={options}
        selectedValue={ownOption}
        setValue={(value: number) => {
          setOwnOption(value)
          setChoice(value)
        }}
        allValues={options}
        displayedValuesText={valuesText}
      />
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
`

// TEXTES

const ModalTitle = styled('h1')`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 24px;
  margin-top: 0px;
`

export default ModalContent
