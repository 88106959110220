import React from 'react'
import useI18n from 'i18n/useI18n'
import { windowSizeBreakpoints } from 'utils/breakpoints'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'
import Icons from 'components/icons/Icons'
import Button from 'components/button/Button'
import Modal from 'components/modal/Modal'

interface Props {
  vamosParkingBooking: VamosBookingV6
}

// TODO
// -> Affichage du nom du parking => impossible pour le moment

const TandemInfosModal = ({ vamosParkingBooking }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  return (
    <MainContainer>
      <Title>{i18n.t('screens.planning.parking.bookingTitle')}</Title>

      <BookingContainer>
        <LabelContainer>
          <InfoBold>{i18n.t('screens.planning.parking.title')}</InfoBold>
        </LabelContainer>
        <RowContainer>
          <IconContainer>
            <Icons name="parking" size={15} color={Theme.colors.blue} />
          </IconContainer>
          <Info>{vamosParkingBooking.parkingPlace}</Info>
        </RowContainer>
        <RowContainer>
          <IconContainer>
            <Icons name="car" size={15} color={Theme.colors.blue} />
          </IconContainer>
          <Info>
            {i18n.t(`screens.planning.parking.tandemPlace`, {
              place: vamosParkingBooking.parkingNumber,
            })}
          </Info>
        </RowContainer>
      </BookingContainer>

      {!vamosParkingBooking.tandem.tandemBooked ? (
        <TandemContainer>
          <LabelContainer>
            <InfoBold>{i18n.t('screens.planning.parking.tandemFree')}</InfoBold>
          </LabelContainer>
        </TandemContainer>
      ) : vamosParkingBooking.tandem.contactTandem ? (
        <TandemContainer>
          <LabelContainer>
            <InfoBold>{i18n.t('screens.planning.parking.tandem')}</InfoBold>
          </LabelContainer>
          {vamosParkingBooking.tandem.contactTandem.firstName && vamosParkingBooking.tandem.contactTandem.lastName && (
            <ContactContainer>
              <ContactLeftContainer>
                <PictureContainer>
                  <ContactLetters>
                    {vamosParkingBooking.tandem.contactTandem.firstName[0]}
                    {vamosParkingBooking.tandem.contactTandem.lastName[0]}
                  </ContactLetters>
                </PictureContainer>
                <ContactInfosContainer>
                  <ContactName>
                    {vamosParkingBooking.tandem.contactTandem.lastName}{' '}
                    {vamosParkingBooking.tandem.contactTandem.firstName}
                  </ContactName>
                  <ContactPhone>{vamosParkingBooking.tandem.contactTandem.phoneNumber}</ContactPhone>
                </ContactInfosContainer>
              </ContactLeftContainer>
            </ContactContainer>
          )}
        </TandemContainer>
      ) : null}

      <ButtonContainer>
        <Button
          label={i18n.t('common.back')}
          onClick={() => Modal.close()}
          font={Theme.fonts.h3Bold}
          ariaLabel={i18n.t('common.back')}
        />
      </ButtonContainer>
    </MainContainer>
  )
}

export default TandemInfosModal

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 15px;
  }
  width: 300px;
`

const Title = styled.div`
  ${(props) => props.theme.fonts.h2Bold};
  text-align: center;
`

const BookingContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 30px;
`

const RowContainer = styled.div`
  ${(props) => props.theme.fonts.body}
  font-size: 12px;
  line-height: 14px;
  margin: 0px 0px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const LabelContainer = styled.div`
  display: flex;
  flex: 1;
`

const InfoBold = styled.p`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 12px;
  line-height: 14px;
  margin: 0px 0px 10px;
  padding-top: 3px;
`

const IconContainer = styled.div`
  align-items: center;
  justify-content: center;
  margin-right: 6px;
`

const Info = styled.p`
  ${(props) => props.theme.fonts.label};
  margin: 0px;
  color: ${(props) => props.theme.colors.black};
`

const ButtonContainer = styled('div')`
  display: flex;
  height: 50px;
  margin-top: 30px;
`

const TandemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 30px;
`

const ContactContainer = styled('p')`
  display: flex;
  margin-top: 8px;
`
const ContactInfosContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  word-wrap: break-word;
`
const ContactLeftContainer = styled('div')`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 12px 0px;
  }
`

const PictureContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
  border-radius: 28px;
  background-color: ${(props) => props.theme.colors.mediumDarkGrey};
`

const ContactName = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
`
const ContactPhone = styled('p')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
`
const ContactLetters = styled('p')`
  ${(props) => props.theme.fonts.subtitleBold};
  color: ${(props) => props.theme.colors.darken};
  text-transform: uppercase;
  margin-left: 1.5px;
`
