import { differenceInMinutes } from 'date-fns'

const utils = {
  calculateWidthFromMinutes: (totalWidth: number, start: Date, end: Date) =>
    totalWidth * (differenceInMinutes(end, start) / 60),
  calculatePaddingFromMinutes: (totalWidth: number, minDate: Date, startDate: Date) =>
    totalWidth * (differenceInMinutes(minDate, startDate) / 60),
}

export default utils
