import * as React from 'react'
import { Helmet } from 'react-helmet'

interface Props {
  title: string
}

const TitleHelmet = ({ title }: Props) => {
  return (
    <Helmet>
      <title>Living@ by My Portal | {title}</title>
    </Helmet>
  )
}

export default TitleHelmet
