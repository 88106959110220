import Webservice, { executeGetCode } from 'utils/Webservice'
import config from 'core/src/config'

const API_KEY = config.API_KEY

const api = {
  getGuestStatus: (email: string) =>
    executeGetCode<number>(
      'EXTERNAL',
      `${config.globalAPIVersion}/public/guests/preregistered`,
      'GET',
      { 'X-Api-Key': API_KEY },
      {
        email: email,
      },
      undefined
    ),
  sendValidationCode: (email: string) =>
    Webservice(
      'EXTERNAL',
      `${config.globalAPIVersion}/public/guests/code`,
      'POST',
      { 'X-Api-Key': API_KEY },
      undefined,
      {
        email: email,
      }
    ),
  validateCode: (email: string, code: string, action: 'CREATE_ACCOUNT' | 'RESET_PASSWORD') =>
    Webservice<{ token: string }>(
      'EXTERNAL',
      `${config.globalAPIVersion}/public/guests/validation`,
      'POST',
      { 'X-Api-Key': API_KEY },
      undefined,
      {
        email: email,
        code: code,
        action: action,
      }
    ),
  createGuest: (firstName: string, lastName: string, password: string, token: string) =>
    Webservice<UserSelf>(
      'EXTERNAL',
      `${config.globalAPIVersion}/public/guests/create`,
      'POST',
      { 'X-Api-Key': API_KEY, 'engie-api-token': token },
      undefined,
      {
        lastName: lastName,
        firstName: firstName,
        password: password,
      }
    ),
  login: (email: string, password: string) =>
    Webservice<OAuth2Response>(
      'EXTERNAL',
      `/login/guest/token`,
      'POST',
      { 'X-Api-Key': API_KEY, 'Content-Type': 'application/x-www-form-urlencoded' },
      {
        username: email,
        password: password,
        client_id: 'LKwFharbcFje4Xhf7m7VCCmtASHWDE2ckiArLzLh',
        scope: 'engie:guest',
        grant_type: 'password',
      },
      undefined
    ),
  newPassword: (newPassword: string, token: string) =>
    Webservice<UserSelf>(
      'EXTERNAL',
      `${config.globalAPIVersion}/public/guests/password`,
      'POST',
      { 'X-Api-Key': API_KEY, 'engie-api-token': token },
      undefined,
      {
        newPassword: newPassword,
      }
    ),
}

export default api
