import * as React from 'react'
import useI18n from 'i18n/useI18n'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import { windowSizeBreakpoints } from 'utils/breakpoints'

import Button from 'components/button/Button'
import Modal from 'components/modal/Modal'

interface Props {
  title: string
  onConfirm: () => void
  description?: string
  cancelButton?: boolean
  confirmButtonTitle?: string
  cancelButtonTitle?: string
  font?: Font
  onCancel?: () => void
  ariaLabelConfirm?: string
  ariaLabelCancel?: string
}

const ValidationContent = ({
  title,
  onConfirm,
  description,
  cancelButton,
  confirmButtonTitle,
  cancelButtonTitle,
  font,
  onCancel,
  ariaLabelConfirm,
  ariaLabelCancel,
}: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()

  return (
    <ContentModal>
      <TitleModal font={font}>{title}</TitleModal>
      {!!description && <Description>{description}</Description>}
      <Button
        label={confirmButtonTitle || i18n.t('common.ok')}
        onClick={onConfirm}
        color={Theme.colors.blue}
        textColor={Theme.colors.white}
        font={Theme.fonts.h3Bold}
        ariaLabel={ariaLabelConfirm}
      />
      {!!cancelButton && (
        <Button
          label={cancelButtonTitle || i18n.t('common.cancel')}
          onClick={onCancel || Modal.close}
          color={Theme.colors.white}
          textColor={Theme.colors.blue}
          shadow
          font={Theme.fonts.h3Bold}
          ariaLabel={ariaLabelCancel}
        />
      )}
    </ContentModal>
  )
}

export default ValidationContent

// CONTAINERS

const ContentModal = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 30px;
  max-width: 330px;
  gap: 10px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 20px;
  }
`

// TEXTS

const TitleModal = styled('h1')<{ font?: Font }>`
  ${(props) => props.font || props.theme.fonts.h1Bold};
  font-size: 24px;
  line-height: 28px;
  text-align: center;
`
const Description = styled('p')`
  ${(props) => props.theme.fonts.body};
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin-top: 0px;
`
