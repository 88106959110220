import * as React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icons'

interface Props {
  onChange: (note: number) => void
  editable?: boolean
  note?: number
}

const renderArray = [...Array(5)]

const Notation = ({ onChange, editable = true, note }: Props) => {
  const [theme] = useTheme()

  const changeNote = (newNote: number) => {
    if (editable) {
      onChange(newNote)
    }
  }

  const renderStar = (index: number) => {
    return (
      <StarContainer onClick={() => changeNote(index + 1)}>
        <Icon name={!!note && index < note ? 'star-filled' : 'star'} size={32} color={theme.colors.blue} />
      </StarContainer>
    )
  }

  return <StarsContainer>{renderArray.map((val, index) => renderStar(index))}</StarsContainer>
}

export default Notation

const StarsContainer = styled('div')`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 50px 12px;
  margin-top: 20px;
`

const StarContainer = styled('button')`
  cursor: pointer;
  margin: 0px 12px;
  background-color: ${(props) => props.theme.colors.white};
  border: 0px;
`
