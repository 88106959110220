import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Switch from 'react-switch'

import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'
import useReducer from 'store/useReducer'

import planningApi from 'planning/api'

import Logger from 'utils/Logger'
import useI18n from 'i18n/useI18n'

const PreferencesContent = () => {
  const currentSite = useReducer(siteStore.store, (s) => s.site)
  const user = useReducer(userStore.store, (s) => s.user)
  const [Theme] = useTheme()
  const [disableZapFloorOptions, setDisableZapFloorOptions] = React.useState(false)
  const [toggle, setToggle] = React.useState(true)
  const [zapfloorId, setZapfloorId] = React.useState('')
  const i18n = useI18n()

  React.useEffect(() => {
    if (!!user && !!currentSite) {
      planningApi.zapfloor
        .getUserInfos(user.type, currentSite.id)
        .then((res) => {
          setToggle(res.data.attributes.is_public_in_desking)
          setZapfloorId(res.data.id)
        })
        .catch((err) => Logger.error('Error when getting info user : ', err))
    }
  }, [user, currentSite])

  const toggleSwitch = (bool: boolean) => {
    setDisableZapFloorOptions(true)
    if (!!user && !!currentSite) {
      planningApi.zapfloor
        .putUserPreferencesInfo(user.type, currentSite.id, zapfloorId, bool)
        .then(() => {
          setToggle(bool)
        })
        .catch((err) => Logger.error('Error when putting info for user : ', err))
        .finally(() => setDisableZapFloorOptions(false))
    }
  }

  return (
    <>
      <SubTitle>{i18n.t('components.preferences.zapfloor.title')}</SubTitle>
      <TopicContainer>
        <RowContainer>
          <SwitchLabel htmlFor="ZapfloorShareBooking">
            {i18n.t('components.preferences.zapfloor.shareBookings')}
          </SwitchLabel>
          <SwitchContainer>
            <Switch
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  toggleSwitch(!toggle)
                  e.preventDefault()
                }
              }}
              checkedIcon={false}
              uncheckedIcon={false}
              handleDiameter={16}
              onColor={Theme.colors.blue}
              offColor={Theme.colors.middleGrey}
              checked={toggle}
              onChange={toggleSwitch}
              disabled={disableZapFloorOptions}
              height={20}
              width={39}
              aria-checked={undefined}
              id="ZapfloorShareBooking"
            />
          </SwitchContainer>
        </RowContainer>
      </TopicContainer>
    </>
  )
}

export default PreferencesContent

const SubTitle = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 5px 0px;
`
const TopicContainer = styled('div')`
  margin: 6px 0px 6px 20px;
`
const RowContainer = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
`
const SwitchLabel = styled('label')`
  cursor: pointer;
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 5px 0px;
`
const SwitchContainer = styled('div')`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
`
