import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'
import { isSameDay, setMinutes, setHours } from 'date-fns'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'

import Button from 'components/button/Button'
import Icons from 'components/icons/Icons'
import { getDayTimezone } from 'planning/utils'

interface Props {
  visit: PersonalVisitV6
  isLast: boolean
  selectMode: boolean
  selectedList: string[]
  canModify: boolean
  circleOnClick: (visitId: string, isSelected: boolean) => void
  onCancel: (visitId: string, visitGroupId?: string) => void
  onModify: (visitId: string) => void
  onRemoveZapfloorBooking: (zapfloorId: string) => void
}

const VisitInfo = ({
  visit,
  isLast,
  selectMode,
  selectedList,
  canModify,
  circleOnClick,
  onCancel,
  onModify,
  onRemoveZapfloorBooking,
}: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const user = useReducer(userStore.store, (s) => s.user)

  const differentVisited = !!user && visit.visitedId !== user.officeId
  const isSelected = selectMode && selectedList.indexOf(visit.multiDayGroupId ?? visit.visitId) > -1

  const onSelectVisit = () => {
    if (selectMode) {
      circleOnClick(visit.multiDayGroupId ?? visit.visitId, isSelected)
    }
  }

  const canDelete = !isSameDay(new Date(), getDayTimezone(visit.day))

  const arrivalDateTime = React.useMemo(() => {
    if (visit.arrivalTime) {
      try {
        const [hours, minutes] = visit.arrivalTime.split(':')
        if (hours === '00') {
          return undefined
        }
        return setMinutes(setHours(new Date(), parseInt(hours)), parseInt(minutes))
      } catch {
        return undefined
      }
    }
    return undefined
  }, [visit])

  return (
    <>
      <Visit
        zapfloorBookings={!!visit.zapfloorBookings}
        selected={isSelected}
        isSelectMode={selectMode && canDelete}
        onClick={onSelectVisit}
        tabIndex={selectMode ? 0 : -1}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSelectVisit()
          }
        }}>
        <NamesContainer>
          <VisitorName>
            {i18n.t('screens.guest.fullName', {
              firstname: visit.visitorFirstName,
              lastname: visit.visitorLastName.toUpperCase(),
            })}
          </VisitorName>
          {arrivalDateTime && <VisitedName>{i18n.t('screens.guest.visitTime', { date: arrivalDateTime })}</VisitedName>}
          {differentVisited && (
            <VisitedName>
              {i18n.t('screens.guest.visitedFullname', {
                firstname: visit.visitedFirstName,
                lastname: visit.visitedLastName.toUpperCase(),
              })}
            </VisitedName>
          )}
        </NamesContainer>

        {(canDelete || canModify) &&
          (!selectMode ? (
            <DeleteButtonContainer>
              {canModify && (
                <ButtonContainer marginBottom={true}>
                  <Button
                    label={i18n.t('common.modify')}
                    shadow
                    onClick={() => onModify(visit.multiDayGroupId || visit.visitId)}
                    verticalPadding={6}
                    horizontalPadding={0}
                    font={Theme.fonts.label}
                  />
                </ButtonContainer>
              )}
              {canDelete && (
                <ButtonContainer>
                  <Button
                    color={canModify ? Theme.colors.white : Theme.colors.blue}
                    textColor={canModify ? Theme.colors.blue : Theme.colors.white}
                    shadow
                    label={i18n.t('common.delete')}
                    onClick={() => onCancel(visit.visitId, visit.multiDayGroupId)}
                    verticalPadding={6}
                    horizontalPadding={0}
                    font={Theme.fonts.label}
                  />
                </ButtonContainer>
              )}
            </DeleteButtonContainer>
          ) : canDelete ? (
            <Circle>
              <SelectedCircle selected={isSelected} />
            </Circle>
          ) : null)}
      </Visit>
      {visit.zapfloorBookings && (
        <ZapfloorBookingsContainer>
          {visit.zapfloorBookings.map((booking) => (
            <ZapfloorBooking key={booking.zapfloorId}>
              <RowContainer>
                <IconContainer>
                  <Icons name={booking.bookingType === 'DESK' ? 'desk' : 'parking'} color={Theme.colors.blue} />
                </IconContainer>
                <VisitedName>{booking.place ? booking.place : booking.zone}</VisitedName>
              </RowContainer>
              {!!booking.zapfloorId && (
                <DeleteButtonContainer>
                  <ButtonContainer>
                    <Button
                      label={i18n.t('common.delete')}
                      onClick={() => onRemoveZapfloorBooking(booking.zapfloorId!)}
                      verticalPadding={6}
                      horizontalPadding={0}
                      font={Theme.fonts.label}
                    />
                  </ButtonContainer>
                </DeleteButtonContainer>
              )}
            </ZapfloorBooking>
          ))}
        </ZapfloorBookingsContainer>
      )}
      {!isLast && <BlueLineBreak />}
    </>
  )
}

// CONTAINERS

const Visit = styled('div')<{ selected: boolean; isSelectMode: boolean; zapfloorBookings: boolean }>`
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin: ${(props) => (props.zapfloorBookings ? '15px 0px 4px 0px' : '15px 0px')};
  padding: 5px;
  ${(props) => props.selected && `background-color: ${props.theme.colors.paleBlue}`};
  ${(props) => props.isSelectMode && 'cursor: pointer'};
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const ZapfloorBookingsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 4px 0px 15px;
  padding: 5px;
  justify-content: center;
`
const RowContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const IconContainer = styled('div')`
  padding-right: 8px;
`
const DeleteButtonContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  margin: 0px 9px;
`
const NamesContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const ButtonContainer = styled('div')<{ marginBottom?: boolean }>`
  display: flex;
  width: 80px;
  ${(props) => props.marginBottom && 'margin-bottom: 8px;'}
`

const ZapfloorBooking = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 4px;
  align-items: center;
`

// TEXTES

const VisitorName = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
  margin-bottom: 10px;
`
const VisitedName = styled('p')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
`

// AUTRES

const BlueLineBreak = styled('div')`
  width: 50px;
  height: 3px;
  background-color: ${(props) => props.theme.colors.blue};
  margin: 0px;
  border-radius: 2px;
`
const Circle = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 25px;
  height: 25px;
  border: 2px solid ${(props) => props.theme.colors.blue};
  border-radius: 15px;
  margin-right: 2px;
  margin-left: 38px;
`
const SelectedCircle = styled('div')<{ selected: boolean }>`
  width: 19px;
  height: 19px;
  border-radius: 15px;
  ${(props) => props.selected && `background-color: ${props.theme.colors.blue}`};
`

export default VisitInfo
