import * as React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Breadcrumb from 'components/breadcrumb/Breadcrumb'

import useReducer from 'store/useReducer'
import * as siteStore from 'sites/store/siteStore'
import * as userStore from 'store/user/user'

import config from 'core/src/config'
import useNavigation from 'utils/navigation/useNavigation'
import Logger from 'utils/Logger'
import { assureSSOToken } from '../ssoToken'
import analytics, { analyticsKeys } from 'utils/analytics'

import useI18n from 'i18n/useI18n'

import Loader from 'react-loader-spinner'

type Status = 'loading' | 'ok' | 'error'

interface Props {
  origin?: string
}

const PlanningScreen = ({ origin }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const navigation = useNavigation()

  const user = useReducer(userStore.store, (s) => s.user)
  const currentSite = useReducer(siteStore.store, (s) => s.site)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)

  const [screenStatus, setScreenStatus] = React.useState<Status>('loading')
  const [token, setToken] = React.useState<string>()

  React.useEffect(() => {
    if (!!user && !!currentSite) {
      analytics.track({
        screenName: analyticsKeys.meetingCenter,
        userType: user.type,
        currentSite,
        mySites,
        origin,
      })
      assureSSOToken(user.type, currentSite.id, 'ZAPFLOOR')
        .then((res) => {
          setScreenStatus('ok')
          setToken(res)
        })
        .catch((err) => {
          Logger.error(err)
          setScreenStatus('error')
        })
    }
  }, [])

  return (
    <>
      <Breadcrumb screen="meeting_center" path="meetingcenter" navigation={navigation} />

      <MainContainer>
        {screenStatus === 'loading' ? (
          <LoaderErrorContainer>
            <Loader type="TailSpin" color={Theme.colors.blue} />
          </LoaderErrorContainer>
        ) : screenStatus === 'error' || !token ? (
          <LoaderErrorContainer>
            <ErrorLabel>{i18n.t('screens.meeting_center.error')}</ErrorLabel>
          </LoaderErrorContainer>
        ) : (
          <IFrame
            src={`${config.zapfloor.prefixMeetingCenter}/?page=meetings&iframe=1&token=${token}`}
            title="iframetitle"
          />
        )}
      </MainContainer>
    </>
  )
}
export default PlanningScreen

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  min-height: calc(100vh - 200px);
`

const LoaderErrorContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ErrorLabel = styled.label`
  ${(props) => props.theme.fonts.h3Bold};
`

const IFrame = styled.iframe`
  flex: 1;
`
