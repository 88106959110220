import msal from 'authent365/azure/MSALAuth'
import equansMsal from 'authent365/azure/MSALEquans'
import oauth2 from 'authent365/oauth/OAuth2'

import * as store from 'store/store'

import { clearSSO } from 'planning/ssoToken'

import analytics from 'utils/analytics'

const blobToB64 = (data: Blob): Promise<string> =>
  new Promise((res, rej) => {
    const reader = new FileReader()
    reader.readAsDataURL(data)
    reader.onloadend = function () {
      const base64data = reader.result
      res(base64data as string)
    }
  })

const assureToken = (userType: UserType) =>
  userType === 'ENGIE'
    ? msal.assureToken('server')
    : userType === 'EQUANS'
    ? equansMsal.assureToken('server')
    : oauth2.assureToken()

const executeGraphRequest: <T>(
  userType: UserType,
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  body?: any,
  responseType?: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream',
  timezone?: string
) => Promise<T> = (userType, url, method, body, responseType, timezone) =>
  userType === 'EQUANS'
    ? equansMsal.executeGraphRequest(url, method, body, responseType)
    : msal.executeGraphRequest(url, method, body, responseType, timezone)

const clearAll = () => {
  store.reset()
  sessionStorage.clear()
  clearSSO()
  new Array(localStorage.length).fill(0).forEach((x, idx) => {
    const key = localStorage.key(idx)
    if (key && !key.startsWith('persist')) {
      localStorage.removeItem(key)
    }
  })
}

const logout = (userType: UserType) => {
  analytics.reset()
  if (userType === 'ENGIE') {
    msal.logout().then(() => {
      clearAll()
    })
  } else if (userType === 'EQUANS') {
    equansMsal.logout().then(() => {
      clearAll()
    })
  } else {
    oauth2.logout()
    clearAll()
  }
}

export { blobToB64, assureToken, logout, executeGraphRequest }
