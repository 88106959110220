import { createEvent, createStore } from 'effector'
import { Status } from 'components/map/PlaceSearch'

type MapState = {
  itineraryStatus: Status
}

export const actions = {
  setItineraryStatus: createEvent<Status>('setItineraryStatus'),
  resetSite: createEvent('resetSite'),
}

const initialState: MapState = {
  itineraryStatus: 'notStarted',
}

export const store = createStore<MapState>(initialState, { name: 'Map Info' })
  .on(actions.setItineraryStatus, (s, itineraryStatus) => ({ ...s, itineraryStatus }))
  .on(actions.resetSite, () => initialState)
