import { createEvent, createStore } from 'effector'

export const actions = {
  setEditos: createEvent<InformationSimpleV6[]>('setEditos'),
  resetEditos: createEvent('resetEditos'),
  setLikes: createEvent<string[]>('setEditosLikes'),
}

const initialState: InformationState = {
  editos: [],
  liked: [],
}

export const store = createStore<InformationState>(initialState)
  .on(actions.setEditos, (s, editos) => ({ ...s, editos }))
  .on(actions.resetEditos, (s) => ({ ...s, editos: initialState.editos }))
  .on(actions.setLikes, (s, liked) => ({ ...s, liked }))
