import React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icons'

type Status = 'selected' | 'unselected' | 'neutral'

interface Props {
  iconName: IconName
  onClick: () => void
  iconColor?: string
  iconSize?: number
  tabPos?: 'top' | 'middle' | 'bottom' | 'single'
  status?: Status
  ariaLabel?: string
}

const Tab = ({ iconName, onClick, iconColor, iconSize = 30, tabPos = 'top', status = 'neutral', ariaLabel }: Props) => {
  const [Theme] = useTheme()
  return (
    <MainContainer shadowPos={tabPos} onClick={onClick} status={status} aria-label={ariaLabel}>
      <Icon
        name={iconName}
        size={iconSize}
        color={status === 'unselected' ? Theme.colors.darkGrey : iconColor || Theme.colors.blue}
      />
    </MainContainer>
  )
}
export default Tab

// CONTAINERS

const MainContainer = styled('button')<{ shadowPos: string; status: Status }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.status === 'selected' ? 74 : 64)}px;
  height: ${(props) => (props.status === 'selected' ? 84 : 74)}px;
  margin: 1px 0px;
  background-color: ${(props) =>
    props.status === 'unselected' ? props.theme.colors.mediumDarkGrey : props.theme.colors.white};
  border: 0;
  border-radius: 15px 0px 0px 15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.13);
  z-index: ${(props) => (props.status === 'selected' ? 10 : 0)};
  clip-path: ${(props) =>
    props.status === 'selected' || props.shadowPos === 'single'
      ? 'inset(-15px 0px -15px -15px)'
      : props.shadowPos === 'top'
      ? 'inset(-15px 0px 0px -15px)'
      : props.shadowPos === 'middle'
      ? 'inset(0px 0px 0px -15px)'
      : props.shadowPos === 'bottom' && 'inset(0px 0px -15px -15px)'};
  :focus-visible {
    clip-path: inset(-15px);
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
