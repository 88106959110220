import { createEvent, createStore } from 'effector'

export const actions = {
  setFavoriteLocation: createEvent<SiteFavoriteZone>('setFavoriteLocation'),
  resetFavoriteLocation: createEvent('resetFavoriteLocation'),
  setReferenceWeek: createEvent<{ siteId: string; weekDaysInfos?: ReferenceWeek }>('setReferenceWeek'),
  resetReferenceWeek: createEvent('resetReferenceWeek'),
  setZapfloorUserId: createEvent<string>('setZapfloorUserId'),
  resetZapfloorUserId: createEvent('resetZapfloorUserId'),
  setUserHasAccessGroups: createEvent<boolean>('setUserHasAccessGroups'),
  resetUserHasAccessGroups: createEvent('resetUserHasAccessGroups'),
  setMaxDaysInFuture: createEvent<number | undefined>('setMaxDaysInFuture'),
}

const initialState: PlanningState = {
  favoriteLocation: {},
  referenceWeek: {},
  zapfloorUserId: undefined,
  userHasAccessGroups: false,
}

export const store = createStore<PlanningState>(initialState, { name: 'Planning' })
  .on(actions.setFavoriteLocation, (s, newFavZone) => {
    const favZones = { ...s.favoriteLocation }
    favZones[newFavZone.siteId] = {
      zoneId: newFavZone.zoneId,
      zoneName: newFavZone.zoneName,
      profilId: newFavZone.profilId,
      profilName: newFavZone.profilName,
      isParking: newFavZone.isParking,
    }
    return { ...s, favoriteLocation: favZones }
  })
  .on(actions.resetFavoriteLocation, (s) => ({ ...s, favoriteLocation: initialState.favoriteLocation }))
  .on(actions.setReferenceWeek, (s, newReferenceWeek) => {
    const referenceWeek = { ...s.referenceWeek }
    referenceWeek[newReferenceWeek.siteId] = newReferenceWeek.weekDaysInfos
    return { ...s, referenceWeek: referenceWeek }
  })
  .on(actions.resetReferenceWeek, (s) => ({ ...s, referenceWeek: initialState.referenceWeek }))
  .on(actions.setZapfloorUserId, (s, zapfloorUserId) => ({ ...s, zapfloorUserId }))
  .on(actions.setMaxDaysInFuture, (s, maxDaysInFuture) => ({ ...s, maxDaysInFuture }))
  .on(actions.resetZapfloorUserId, (s) => ({ ...s, zapfloorUserId: initialState.zapfloorUserId }))
  .on(actions.setUserHasAccessGroups, (s, userHasAccessGroups) => ({ ...s, userHasAccessGroups }))
  .on(actions.resetUserHasAccessGroups, (s) => ({ ...s, userHasAccessGroups: initialState.userHasAccessGroups }))
