import * as React from 'react'
import useI18n from 'i18n/useI18n'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Button from 'components/button/Button'
import Modal from 'components/modal/Modal'
import ToastAlert from 'components/toast/ToastAlert'
import ValidationContent from 'components/modal/ValidationContent'
import LanguageChoice, { LANG_LIST } from 'authent365/screens/LanguageChoice'
import Icon from 'components/icons/Icons'
import { FormFixField, FormTextInput, FormValuePicker, FormValuesPicker } from 'components/form/FormItems'

import * as sitesStore from 'sites/store/siteStore'
import * as userStore from 'store/user/user'
import useReducer from 'store/useReducer'

import api from './accountApi'

import Logger from 'utils/Logger'

import { useFormik } from 'formik'
import * as yup from 'yup'
import deepEqual from 'fast-deep-equal'
import Loader from 'react-loader-spinner'
import { Language } from 'i18n/types'

interface Values {
  firstName: string
  lastName: string
  phone: string
  lang: ProfileRingRing['languageTag']
  sites: SiteLightV6[]
}

type ScreenState = 'LOADING' | 'SUBSCRIBE' | 'DISPLAY' | 'UPDATE' | 'ERROR'

const phoneRegExp = /^[\+][0-9]{11,}$/

const formSchema = yup.object().shape({
  phone: yup
    .string()
    .required('screens.ringRing.form.errors.phoneRequired')
    .matches(phoneRegExp, 'screens.ringRing.form.errors.phoneFormat'),
  sites: yup.array().min(1),
  lang: yup.string().required(),
})

const RingRingModalContent = () => {
  const i18n = useI18n()
  const [theme] = useTheme()

  const user = useReducer(userStore.store, (s) => s.user)
  const currentSite = useReducer(sitesStore.store, (s) => s.site)
  const allSites = useReducer(sitesStore.store, (s) => s.allSites)

  const allSitesWithRingRing = React.useMemo(() => allSites.filter((s) => !!s.sysId), [allSites])

  const initial: Values = React.useMemo(
    () => ({
      firstName: '',
      lastName: '',
      phone: '',
      lang: i18n.lang.toUpperCase() as Values['lang'],
      sites: [],
    }),
    [i18n.lang]
  )

  const { values, setFieldValue, errors, isValid, setValues } = useFormik<Values>({
    initialValues: initial,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: () => {
      null
    },
    validationSchema: formSchema,
  })

  const [valuesInCache, setValuesInCache] = React.useState<Values>(initial)

  const [screenState, setScreenState] = React.useState<ScreenState>('LOADING')

  React.useEffect(() => {
    if (user && currentSite?.id) {
      setFieldValue('firstName', user.firstName)
      setFieldValue('lastName', user.lastName)
      fetchProfile()
    }
  }, [user, currentSite])

  const fetchProfile = () => {
    if (user && currentSite) {
      setScreenState('LOADING')
      api.ringRing
        .getSubscriptions(user.type, currentSite.id)
        .then((res) => {
          const { languageTag, sitesSubscribed, userPhoneNumber, userId } = res
          if (languageTag && sitesSubscribed && sitesSubscribed.length > 0 && userPhoneNumber && userId) {
            const fetchedValues: Values = {
              firstName: user.firstName,
              lastName: user.lastName,
              lang: languageTag,
              sites: allSitesWithRingRing.filter((s) => s.sysId && sitesSubscribed.includes(s.sysId)),
              phone: userPhoneNumber || '',
            }
            setValues(fetchedValues)
            setValuesInCache(fetchedValues)
            setScreenState('DISPLAY')
          } else {
            setScreenState('SUBSCRIBE')
          }
        })
        .catch((err) => {
          Logger.error(err)
          setScreenState('ERROR')
        })
    }
  }

  const deleteSubscription = () => {
    if (user && currentSite) {
      ToastAlert.open(() => (
        <ValidationContent
          title={i18n.t('screens.ringRing.delete.alertTitle')}
          description={i18n.t('screens.ringRing.delete.alertDescription')}
          cancelButton
          cancelButtonTitle={i18n.t('screens.ringRing.delete.cancel')}
          onCancel={ToastAlert.close}
          onConfirm={() => {
            setScreenState('LOADING')
            ToastAlert.close()
            api.ringRing
              .deleteSubscription(currentSite.id, user.type)
              .then(Modal.close)
              .catch((err) => {
                Logger.error(err)
                ToastAlert.open(() => (
                  <ValidationContent
                    title={i18n.t('screens.ringRing.delete.alertErrorTitle')}
                    description={i18n.t('screens.ringRing.delete.alertErrorDescription')}
                    onConfirm={ToastAlert.close}
                  />
                ))
                setScreenState('DISPLAY')
              })
          }}
        />
      ))
    }
  }

  const postSubscription = (prevState: ScreenState) => {
    if (user && currentSite) {
      setScreenState('LOADING')
      api.ringRing
        .postSubscription(currentSite.id, user.type, {
          languageTag: values.lang || 'FR',
          sitesSysIdsToSubscribe: values.sites.filter((s) => !!s.sysId).map((s) => s.sysId as string),
          userPhoneNumber: values.phone,
        })
        .then(fetchProfile)
        .catch((err) => {
          Logger.error(err)
          ToastAlert.open(() => (
            <ValidationContent
              title={i18n.t('screens.ringRing.subscription.alertErrorTitle')}
              description={i18n.t(
                `screens.ringRing.${prevState === 'SUBSCRIBE' ? 'subscription' : 'update'}.alertErrorDescription`
              )}
              onConfirm={ToastAlert.close}
            />
          ))
          setScreenState(prevState)
        })
    }
  }

  if (!user || !currentSite) {
    return null
  }

  const renderDisplay = () => (
    <DisplayContainer>
      <DescriptionBis>{i18n.t('screens.ringRing.displayDescription')}</DescriptionBis>
      <DisplayName>{user.firstName + ' ' + user.lastName}</DisplayName>
      <DisplayInfoContainer>
        <Icon name="phone" color={theme.colors.blue} />
        <DisplayInfoText>{values.phone}</DisplayInfoText>
      </DisplayInfoContainer>
      {!!values.lang && (
        <DisplayInfoContainer>
          <Icon name="globe" color={theme.colors.blue} />
          <DisplayInfoText>{values.lang ? LANG_LIST[values.lang.toLowerCase() as Language] || '' : ''}</DisplayInfoText>
        </DisplayInfoContainer>
      )}
      <DisplayInfoContainer>
        <Icon name="building" color={theme.colors.blue} />
        <SitesContainer>
          <DisplayInfoText>{i18n.t('screens.ringRing.displaySites')}</DisplayInfoText>
          {values.sites.map((s) => (
            <SiteLine key={s.id}>{'•   ' + s.name}</SiteLine>
          ))}
        </SitesContainer>
      </DisplayInfoContainer>
    </DisplayContainer>
  )

  const renderFormFields = () => (
    <DisplayContainer>
      <FormFixField id="firstName" title={i18n.t('screens.ringRing.form.firstName')} info={values.firstName} />

      <Separator role="presentation" />

      <FormFixField id="lastName" title={i18n.t('screens.ringRing.form.lastName')} info={values.lastName} />

      <Separator role="presentation" />

      <FormTextInput
        id="phone"
        title={i18n.t('screens.ringRing.form.phone')}
        value={values.phone}
        setValue={(val) => setFieldValue('phone', val)}
        error={errors.phone ? i18n.t(errors.phone) : undefined}
        inputMode="tel"
      />

      <Separator role="presentation" />

      <FormValuePicker
        title={i18n.t('screens.ringRing.form.lang')}
        withArrow
        value={values.lang ? LANG_LIST[values.lang.toLowerCase() as Language] || '' : ''}
        id="lang"
        onClick={() =>
          ToastAlert.open(() => (
            <LanguageChoice
              initialLanguage={values.lang.toLowerCase() as Language}
              languagesProps={['fr', 'en', 'nl']}
              onSelectConfirm={(val) => {
                setFieldValue('lang', val.toUpperCase())
                ToastAlert.close()
              }}
            />
          ))
        }
      />

      <Separator role="presentation" />

      <FormValuesPicker
        title={i18n.t('screens.ringRing.form.sites')}
        allOptions={allSitesWithRingRing.map((s) => ({ label: s.name, value: s }))}
        selectedOptions={values.sites.map((s) => ({ label: s.name, value: s }))}
        id="sites"
        selectOption={(val) => setFieldValue('sites', [...values.sites, val])}
        deleteOption={(val) =>
          setFieldValue(
            'sites',
            values.sites.filter((s) => s.id !== val.id)
          )
        }
      />
    </DisplayContainer>
  )

  const renderButtons = () => (
    <ButtonsContainer>
      {screenState === 'DISPLAY' ? (
        <>
          <ButtonContainer>
            <Button
              label={i18n.t('screens.ringRing.buttons.modify')}
              onClick={() => setScreenState('UPDATE')}
              font={theme.fonts.h1}
              fontSize={18}
            />
          </ButtonContainer>
          <ButtonContainer>
            <Button
              label={i18n.t('screens.ringRing.buttons.delete')}
              onClick={deleteSubscription}
              color={theme.colors.white}
              textColor={theme.colors.blue}
              font={theme.fonts.h1}
              fontSize={18}
              shadow
            />
          </ButtonContainer>
        </>
      ) : screenState === 'SUBSCRIBE' ? (
        <>
          <ButtonContainer>
            <Button
              label={i18n.t('screens.ringRing.buttons.subscribe')}
              onClick={() => postSubscription('SUBSCRIBE')}
              disabled={!isValid}
              font={theme.fonts.h1}
              fontSize={18}
            />
          </ButtonContainer>
        </>
      ) : screenState === 'UPDATE' ? (
        <>
          <ButtonContainer>
            <Button
              label={i18n.t('screens.ringRing.buttons.update')}
              onClick={() => postSubscription('UPDATE')}
              disabled={!isValid || deepEqual(values, valuesInCache)}
              font={theme.fonts.h1}
              fontSize={18}
            />
          </ButtonContainer>
          <ButtonContainer>
            <Button
              label={i18n.t('screens.ringRing.buttons.cancel')}
              onClick={() => {
                setValues(valuesInCache)
                setScreenState('DISPLAY')
              }}
              color={theme.colors.white}
              textColor={theme.colors.blue}
              shadow
              font={theme.fonts.h1}
              fontSize={18}
            />
          </ButtonContainer>
        </>
      ) : null}
    </ButtonsContainer>
  )

  return (
    <ContentModal>
      <Title>{i18n.t('screens.ringRing.title')}</Title>
      {screenState === 'LOADING' ? (
        <LoaderContainer>
          <Loader type="TailSpin" color={theme.colors.blue} />
        </LoaderContainer>
      ) : screenState === 'ERROR' ? (
        <ErrorContainer>
          <ErrorText>{i18n.t('screens.ringRing.fetchError')}</ErrorText>
        </ErrorContainer>
      ) : (
        <>
          <MainContainer>
            <Description>{i18n.t(`screens.ringRing.descriptions.${screenState}`)}</Description>
            {screenState === 'DISPLAY' ? renderDisplay() : renderFormFields()}
          </MainContainer>
          {renderButtons()}
        </>
      )}
    </ContentModal>
  )
}

export default RingRingModalContent

const ContentModal = styled('div')`
  max-width: 320px;
  padding: 25px 40px 40px;
`

const LoaderContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`

const Title = styled('h2')`
  ${(props) => props.theme.fonts.h2Bold};
`

const MainContainer = styled('div')`
  overflow-y: auto;
`

const Description = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 0px;
  margin-bottom: 20px;
`

const DescriptionBis = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 0px;
  margin-bottom: 10px;
`

const ButtonsContainer = styled('div')`
  margin-top: 20px;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

const ButtonContainer = styled('div')`
  display: flex;
  width: 300px;
  margin: 10px 10px 0px 0px;
`

const DisplayContainer = styled('div')``

const ErrorContainer = styled('div')`
  flex: 1;
  align-items: center;
  justify-content: center;
`

const ErrorText = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 0px;
`

const Separator = styled('div')`
  width: 60px;
  height: 3px;
  background-color: ${(props) => props.theme.colors.blue};
  margin: 0px;
  border-radius: 2px;
`

const DisplayName = styled('p')`
  ${(props) => props.theme.fonts.h3Bold};
  margin: 0px;
  margin-bottom: 20px;
`

const DisplayInfoContainer = styled('div')`
  display: flex;
  margin-bottom: 10px;
`

const DisplayInfoText = styled('p')`
  ${(props) => props.theme.fonts.label};
  margin: 5px 10px;
`

const SitesContainer = styled('div')``

const SiteLine = styled('p')`
  ${(props) => props.theme.fonts.label};
  margin: 0px;
  padding: 2px 20px;
`
