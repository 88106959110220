import * as React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import ToastAlert from 'components/toast/ToastAlert'
import Icon from 'components/icons/Icons'
import PickerModalContent, { Option } from 'components/picker/PickerModalContent'

interface FormFixFieldProps {
  title: string
  info: string
  id: string
}

export const FormFixField = ({ title, info, id }: FormFixFieldProps) => (
  <ItemContainer>
    <InputCategoryContainer>
      <InputCategoryTitle htmlFor={id}>{title}</InputCategoryTitle>
      <Value id={id} fixed>
        {info}
      </Value>
    </InputCategoryContainer>
  </ItemContainer>
)

interface FormTextInputProps {
  title: string
  placeholder?: string
  value: string
  setValue: (val: string) => void
  id: string
  error?: string
  inputMode?: 'text' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search'
}

export const FormTextInput = ({ title, placeholder, value, setValue, id, error, inputMode }: FormTextInputProps) => (
  <ItemContainer>
    <InputCategoryContainer>
      <InputCategoryTitle htmlFor={id}>{title}</InputCategoryTitle>
      <InputField
        id={id}
        value={value}
        placeholder={placeholder || title}
        onChange={(e) => setValue(e.target.value)}
        inputMode={inputMode}
      />
    </InputCategoryContainer>
    <ErrorText id={`${id}Error`}>{error}</ErrorText>
  </ItemContainer>
)

interface FormValuePickerProps {
  title: string
  value: string
  id: string
  onClick: () => void
  withArrow?: boolean
}

export const FormValuePicker = ({ title, value, id, onClick, withArrow }: FormValuePickerProps) => {
  const [theme] = useTheme()
  return (
    <ItemContainer>
      <InputCategoryContainer onClick={onClick} clickable>
        <InputCategoryTitle htmlFor={id}>{title}</InputCategoryTitle>
        <Value id={id}>{value}</Value>
        {withArrow && (
          <IconContainer>
            <Icon name="chevron_right" color={theme.colors.black} size={15} />
          </IconContainer>
        )}
      </InputCategoryContainer>
    </ItemContainer>
  )
}

interface FormValuesPickerProps {
  title: string
  id: string
  selectedOptions: Option[]
  selectOption: (value: any) => void
  deleteOption: (value: any) => void
  allOptions: Option[]
}

export const FormValuesPicker = ({
  title,
  selectedOptions,
  allOptions,
  selectOption,
  deleteOption,
  id,
}: FormValuesPickerProps) => {
  const [theme] = useTheme()

  const availableOptions = allOptions.filter((opt1) => !selectedOptions.find((opt2) => opt2.label === opt1.label))

  const disabled = availableOptions.length === 0

  const openPicker = () =>
    ToastAlert.open(() => (
      <PickerModalContent
        title={title}
        options={availableOptions}
        selectOption={(val) => {
          selectOption(val)
          ToastAlert.close()
        }}
        close={ToastAlert.close}
      />
    ))

  return (
    <ItemContainer>
      <InputCategoryContainer onClick={!disabled ? openPicker : undefined} clickable={!disabled}>
        <InputCategoryTitle htmlFor={id}>{title}</InputCategoryTitle>
        <IconContainer>
          <Icon name="plus" color={disabled ? theme.colors.middleGrey : theme.colors.black} size={25} />
        </IconContainer>
      </InputCategoryContainer>
      <MultipleValuesContainer id={id}>
        {selectedOptions.map((opt) => (
          <SingleValueContainer key={opt.label} onClick={() => deleteOption(opt.value)}>
            <SingleValueText>{opt.label}</SingleValueText>
            <Icon name="cross" color={theme.colors.darkGrey} size={14} />
          </SingleValueContainer>
        ))}
      </MultipleValuesContainer>
    </ItemContainer>
  )
}

const ItemContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 20px 0px;
`

const InputCategoryContainer = styled('button')<{ clickable?: boolean; disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
`

const InputCategoryTitle = styled('label')`
  display: flex;
  flex-wrap: wrap;
  overflow-wrap: break-word;
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
  margin-right: 10px;
`

const InputField = styled('input')`
  border: 0;
  outline: 0;
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
  background: transparent;
  text-align: right;
  padding: 0px;
  min-width: 0px;
  ::placeholder {
    color: ${(props) => props.theme.colors.middleGrey};
  }
`

const Value = styled('p')<{ fixed?: boolean }>`
  width: 80%;
  border: 0;
  outline: 0;
  ${(props) => props.theme.fonts.bodyBold}
  color: ${(props) => (props.fixed ? props.theme.colors.darkGrey : props.theme.colors.blue)};
  background: transparent;
  text-align: right;
  margin: 0px;
`

const IconContainer = styled('div')`
  padding-left: 10px;
`

const ErrorText = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.red};
  margin: 0px;
  margin-top: 10px;
`

const MultipleValuesContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 5px;
`

const SingleValueContainer = styled('button')`
  background: ${(props) => props.theme.colors.mediumDarkGrey};
  border-radius: 4px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const SingleValueText = styled('p')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
  margin-right: 5px;
`
