import { createEvent, createStore } from 'effector'

export const actions = {
  setTheme: createEvent<'classic' | 'accessible'>('setTheme'),
  reset: createEvent('reset'),
}

export interface ThemeState {
  theme: 'classic' | 'accessible'
}

const initialState: ThemeState = {
  theme: 'classic',
}

export const store = createStore<ThemeState>(initialState, { name: 'Colors' })
  .on(actions.setTheme, (s, theme) => ({ ...s, theme }))
  .reset(actions.reset)
