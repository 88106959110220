import { createEvent, createStore } from 'effector'

export const actions = {
  setUserFeatures: createEvent<FeatureV4[]>('setUserFeatures'),
  resetUserFeatures: createEvent('resetUserFeatures'),
}

const initialState: FeatureState = {
  features: [],
}

export const store = createStore<FeatureState>(initialState, { name: "Current Site's Features" })
  .on(actions.setUserFeatures, (s, features) => ({ ...s, features }))
  .on(actions.resetUserFeatures, () => initialState)
