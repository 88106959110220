import * as React from 'react'
import styled from 'theme/styled-components'

import useI18n from 'i18n/useI18n'

import useReducer from 'store/useReducer'
import * as siteStore from 'sites/store/siteStore'
import * as userStore from 'store/user/user'

import analytics, { analyticsKeys } from 'utils/analytics'

import Map from './Map'
import useNavigation from 'utils/navigation/useNavigation'

interface Props {
  search?: string
  origin?: string
}

const MapScreen = ({ search, origin }: Props) => {
  const site = useReducer(siteStore.store, (s) => s.site)
  const user = useReducer(userStore.store, (s) => s.user)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)
  const i18n = useI18n()
  const navigation = useNavigation()

  const query = new URLSearchParams(search)
  const areaUniqueName = query.get('areaId')

  React.useEffect(() => {
    analytics.track({ screenName: analyticsKeys.map, userType: user?.type, currentSite: site, mySites, origin })
  }, [])

  return (
    <MainContainer>
      {!!site && !!site.webVisioglobeHash ? (
        <Map hash={site.webVisioglobeHash} areaUniqueName={areaUniqueName} navigation={navigation} />
      ) : (
        <ErrorMessage>{i18n.t('errors.map.noHash')}</ErrorMessage>
      )}
    </MainContainer>
  )
}

export default MapScreen

const MainContainer = styled('div')`
  display: flex;
  flex: 1;
  justify-content: center;
`
const ErrorMessage = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
`
