import * as React from 'react'

import styled from 'theme/styled-components'

import { windowSizeBreakpoints } from 'utils/breakpoints'

interface Props {
  TitleContent: () => JSX.Element
  ListContent: () => JSX.Element
  visible: boolean
  setVisible: (value: React.SetStateAction<boolean>) => void
  isInfo?: boolean
}

const DropDownHeader = ({ TitleContent, ListContent, visible, setVisible, isInfo = false }: Props) => {
  const dropdownRef: any = React.useRef()

  const handleClick = (event: any) => {
    if (!dropdownRef.current.contains(event.target)) {
      setVisible(false)
    }
  }

  // Pour détecter si on click en dehors du dropdown -> ce qui permet de le fermer
  React.useEffect(() => {
    if (visible) {
      document.addEventListener('mousedown', handleClick)
    } else {
      document.removeEventListener('mousedown', handleClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [visible])

  return (
    <div>
      <TitleContent />
      <DropDownContainer ref={dropdownRef} isInfo={isInfo}>
        <ListContainer modalVisible={visible}>
          <ListContent />
        </ListContainer>
      </DropDownContainer>
    </div>
  )
}

export default DropDownHeader

const ListContainer = styled.div<{ modalVisible: boolean }>`
  flex: 1;
  z-index: 51;
  display: ${(props) => (props.modalVisible ? 'flex' : 'none')};
  flex-direction: column;
  box-shadow: 0px 10px 20px ${(props) => props.theme.colors.shadow};
`
const DropDownContainer = styled('div')<{ isInfo: boolean }>`
  margin-top: -2px;
  display: flex;
  width: 100%;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    right: 0;
    ${(props) => props.isInfo && 'position: fixed; width: 156px;'}
  }
`
