import * as React from 'react'
import styled from 'theme/styled-components'

import useTheme from 'theme/useTheme'
import useI18n from 'i18n/useI18n'

import { RouteResult, InstructionData } from './visioweb'
import { getTimeRounded, getDistanceRounded } from './utils'

import Icons from 'components/icons/Icons'

interface Props {
  itineraryInfo: RouteResult
  current?: InstructionData
  step: number
  steps: number

  previous: () => void
  next: () => void
}

const ItineraryNav = ({ itineraryInfo, current, step, steps, previous, next }: Props) => {
  const i18n = useI18n()
  const [Theme] = useTheme()

  if (!current) {
    return null
  }

  return (
    <>
      <NavMessage>{current.detail}</NavMessage>

      <Points>
        {Array.from(new Array(steps)).map((p, i) => (
          <Point active={i === step} key={i} />
        ))}
      </Points>

      <NavContainer>
        <NavButton onClick={previous}>
          <Icons name="chevron_left" size={25} color={Theme.colors.white} />
        </NavButton>
        <NavButton onClick={next}>
          <Icons name="chevron_right" size={25} color={Theme.colors.white} />
        </NavButton>
      </NavContainer>

      <ItineraryInfo>
        <Icons name="clock" size={20} color={Theme.colors.blue} />
        <ItineraryInfoText>{getTimeRounded(i18n, itineraryInfo.data.duration)}</ItineraryInfoText>
        <Icons name="walking" size={20} color={Theme.colors.blue} />
        <ItineraryInfoText>{getDistanceRounded(i18n, itineraryInfo.data.length)}</ItineraryInfoText>
      </ItineraryInfo>
    </>
  )
}

export default ItineraryNav

// CONTAINERS

const Points = styled('div')`
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
  margin: 20px;
`
const Point = styled('div')<{ active: boolean }>`
  margin: 0px 10px;
  background-color: ${(props) => (props.active ? props.theme.colors.blue : props.theme.colors.placeholderGrey)};
  height: ${(props) => (props.active ? 15 : 10)}px;
  width: ${(props) => (props.active ? 15 : 10)}px;
  border-radius: 15px;
  border: ${(props) => (props.active ? 1 : 0)}px solid ${(props) => props.theme.colors.black};
`
const NavContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const NavButton = styled('div')`
  cursor: pointer;
  display: flex;
  background-color: ${(props) => props.theme.colors.blue};
  align-items: center;
  justify-content: center;
  height: 51px;
  width: 51px;
  border-radius: 30px;
`
const ItineraryInfo = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  margin-top: 20px;
`

// TEXTES

const NavMessage = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin-bottom: 10px;
`
const ItineraryInfoText = styled('p')`
  ${(props) => props.theme.fonts.label}
  margin: 0px 10px 0px 3px;
`
