import config from 'core/src/config'

import { encryptStorage } from 'store/encryptStore'

import Logger from 'utils/Logger'
import ws from 'utils/Webservice'

import * as SiteStore from 'sites/store/siteStore'
import * as UserStore from 'store/user/user'

import axios from 'axios'

import { ApiError } from 'utils/Webservice'

const REFRESH_TOKENS_KEY = 'CACHE.TOKEN.REFRESH'
const ACCESS_TOKENS_KEY = 'CACHE.TOKEN.ACCESS'
const MAX_TIMEOUT = 30000

interface RefreshToken {
  id: string
  token: string
}

const logoutClear = () => {
  UserStore.actions.resetUser()
  SiteStore.actions.resetSite()
}

const saveRefreshToken = (token: string, id: string) => {
  const refresh: RefreshToken = {
    id,
    token,
  }

  return new Promise<void>((resolve) => {
    setTimeout(resolve, 200)
    encryptStorage.setItem(REFRESH_TOKENS_KEY, refresh)
  })
}

const saveAccessToken = (token: string, id: string) => {
  const access: RefreshToken = {
    id,
    token,
  }

  return new Promise<void>((resolve) => {
    setTimeout(resolve, 200)
    encryptStorage.setItem(ACCESS_TOKENS_KEY, access)
  })
}

const OAuth2: OAuth2 = {
  login: (email, password) =>
    execute<OAuth2Response>(
      `${config.SERVER_PREFIX}/login/guest/token`,
      'POST',
      { 'Content-Type': 'application/x-www-form-urlencoded' },
      {
        username: email,
        password,
        client_id: config.oauth2.clientId,
        client_secret: '',
        scope: config.oauth2.scope,
        grant_type: 'password',
      }
    ).then((res) => {
      saveAccessToken(res.access_token, res.jti)
      return res
    }),
  getAccessToken: () => {
    const refreshToken: RefreshToken | undefined = encryptStorage.getItem(REFRESH_TOKENS_KEY)

    return execute<OAuth2Response>(
      `${config.SERVER_PREFIX}/login/guest/token`,
      'POST',
      { 'Content-Type': 'application/x-www-form-urlencoded' },
      {
        refresh_token: refreshToken ? refreshToken.token : '',
        client_id: config.oauth2.clientId,
        grant_type: 'refresh_token',
      }
    )
      .then((res) => {
        saveAccessToken(res.access_token, res.jti)
        return res.access_token
      })
      .catch((err) => {
        Logger.error(err)
        logoutClear()
        return ''
      })
  },
  assureToken: () => {
    const accessToken: RefreshToken | undefined = encryptStorage.getItem(ACCESS_TOKENS_KEY)

    return accessToken
      ? new Promise<string>((resolve) => {
          setTimeout(resolve(accessToken.token), 200)
        })
      : OAuth2.getAccessToken()
  },
  saveRefreshToken: (oauth2Response: OAuth2Response) => {
    const { refresh_token, jti } = oauth2Response
    return saveRefreshToken(refresh_token, jti)
  },
  logout: () =>
    OAuth2.assureToken()
      .then((token) =>
        ws<void>('EXTERNAL', `${config.SERVER_PREFIX}/logout/guest`, 'POST', {
          Authorization: `Bearer ${token}`,
        })
      )
      .catch(Logger.error),
}

export default OAuth2

const execute = <T>(
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  heads: { [key: string]: string } = {},
  body?: { [key: string]: string }
): Promise<T> => {
  const headers: { [key: string]: string } = {
    'Content-Type': 'application/x-www-form-urlencoded',
    ...heads,
  }

  const data = body
    ? Object.keys(body)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(body[key])}`)
        .join('&')
    : undefined

  return axios
    .request<T>({
      url: url,
      method: method,
      headers,
      data,
      timeout: MAX_TIMEOUT,
    })
    .then((res) => res.data)
    .catch((err) => {
      const { status, data, statusText } = err.response

      if (status === 401 || status === 403) {
        return retryOrThrow<T>(url, method, body).catch(() => {
          // Authentication or right error and max retry reached
          throw new ApiError('Expired session ?', status, data)
        })
      }

      if (status >= 400) {
        // Query or Server Error
        throw new ApiError('Unable to query ' + url + ' : ' + status + ' / ' + statusText, status, data)
      }

      throw err
    })
}

const errors: { [api: string]: number } = {}
const MAX_ERROR = 2

/**
 * Retry api calls or break cycle if max error reached
 */
const retryOrThrow = <T>(path: string, method: 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH', body?: any): Promise<T> => {
  const key = `${path}/${method}`
  const errorCount = errors[key] || 0

  if (errorCount < MAX_ERROR) {
    // MAX not reached
    errors[key] = errorCount + 1
    return execute(path, method, body)
  }

  // MAX reached we throw an error
  return Promise.reject('Max try reached')
}
