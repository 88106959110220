import * as React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import SingleValueWheelPicker from './SingleValueWheelPicker'
import Button from '../button/Button'

import useI18n from 'i18n/useI18n'

const MAX_HOURS = 23
const MAX_MINUTES = 55
const STEP = 15 // 15 minutes

const HOURS = Array.from({ length: MAX_HOURS + 1 }, (_, i) => i)
const MINUTES = Array.from({ length: MAX_MINUTES / STEP + 1 }, (_, i) => i * STEP)
interface Props {
  value: TimePickerDuration
  onChange: (duration: TimePickerDuration) => void
  close: () => void
  title?: string
  maxDuration?: TimePickerDuration
  minDuration?: TimePickerDuration
}

const TimePickerInput = ({
  value,
  maxDuration = { hours: 23, minutes: 59 },
  minDuration = { hours: 0, minutes: 0 },
  onChange,
  close,
  title,
}: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()

  const [ownHours, setOwnHours] = React.useState<number>(value.hours)
  const [ownMinutes, setOwnMinutes] = React.useState<number>(value.minutes)

  const HOURS_DISPLAYED = HOURS.filter((h) => h <= maxDuration.hours).filter((h) => h >= minDuration.hours)
  const MINUTES_DISPLAYED =
    ownHours === maxDuration.hours
      ? ownHours === minDuration.hours
        ? MINUTES.filter((m) => m >= minDuration.minutes && m <= maxDuration.minutes)
        : MINUTES.filter((m) => m <= maxDuration.minutes)
      : ownHours === minDuration.hours
      ? MINUTES.filter((m) => m >= minDuration.minutes)
      : MINUTES

  return (
    <>
      <Container>
        {title && <Title>{title}</Title>}
        <RowContainer>
          <div>
            <SingleValueWheelPicker
              values={HOURS_DISPLAYED}
              selectedValue={ownHours}
              setValue={(value: number) => {
                setOwnHours(value)
                if (value === minDuration.hours && ownMinutes < minDuration.minutes) {
                  setOwnMinutes(minDuration.minutes)
                }
                if (value === maxDuration.hours && ownMinutes > maxDuration.minutes) {
                  setOwnMinutes(maxDuration.minutes)
                }
              }}
              allValues={HOURS}
              displayedValuesText={HOURS.map((time) => `${time < 10 ? '0' : ''}${time}`)}
            />
          </div>
          <Separator>:</Separator>

          <div>
            <SingleValueWheelPicker
              values={MINUTES_DISPLAYED}
              selectedValue={ownMinutes}
              setValue={(value: number) => {
                setOwnMinutes(value)
              }}
              allValues={MINUTES}
              displayedValuesText={MINUTES.map((time) => `${time < 10 ? '0' : ''}${time}`)}
            />
          </div>
        </RowContainer>
      </Container>
      <ButtonContainer>
        <Button
          label={i18n.t('common.validate')}
          onClick={() => {
            onChange({ hours: ownHours, minutes: ownMinutes })
            close()
          }}
          font={theme.fonts.h3Bold}
          color={theme.colors.white}
          textColor={theme.colors.blue}
          shadow
        />
      </ButtonContainer>
    </>
  )
}

export default TimePickerInput

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 15px 60px 25px 60px;
`
const RowContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`
const Separator = styled('div')`
  ${(props) => props.theme.fonts.body};
  margin: 0px 20px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`
const Title = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  font-size: 24px;
  color: ${(props) => props.theme.colors.darkGrey};
`

const ButtonContainer = styled('div')`
  display: flex;
  flex: 1;
  margin-top: 10px;
`
