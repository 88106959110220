import i18next from 'i18next'
import dateFormat from 'date-fns/format'
import { formatInTimeZone } from 'date-fns-tz'
import LngDetector from 'i18next-browser-languagedetector'

import { createEvent, createStore } from 'effector'
import { fr, enUS as en, de, es, pt, nl } from 'date-fns/locale'

import { Language, I18n } from './types'

export let i18nextIsInitialized = false

export const supportedLang: Language[] = ['fr', 'en', 'es', 'nl', 'pt', 'de']

export const numbroLang: { [key: string]: string } = {
  fr: 'fr-FR',
  en: 'en-US',
  de: 'de-DE',
  es: 'es-ES',
  pt: 'pt-PT',
  nl: 'nl-NL',
}

export interface NestedTranslations {
  [key: string]: any
}

const fallbackLang = 'en'

const resources = {
  fr: {
    translation: {
      ...require('./locales/fr.json'),
    },
  },
  en: {
    translation: {
      ...require('./locales/en.json'),
    },
  },
  de: {
    translation: {
      ...require('./locales/de.json'),
    },
  },
  es: {
    translation: {
      ...require('./locales/es.json'),
    },
  },
  pt: {
    translation: {
      ...require('./locales/pt.json'),
    },
  },
  nl: {
    translation: {
      ...require('./locales/nl.json'),
    },
  },
}

export const locales: { [key: string]: Locale } = {
  fr,
  en,
  de,
  es,
  pt,
  nl,
}

i18next
  .use(LngDetector)
  .init({
    // debug: true,
    whitelist: supportedLang,
    fallbackLng: fallbackLang,
    returnEmptyString: false,
    interpolation: {
      escapeValue: false,
      format: (value, f, lng) => {
        let format = f

        const formatKey = 'formats.' + format
        const i18nformat = i18next.t(formatKey)
        if (i18nformat !== formatKey) {
          format = i18nformat
        }

        // You can extends functionality here by providing custom format function based on value and format
        if (format && value instanceof Date) {
          const locale = locales[lng || fallbackLang]
          const s = dateFormat(value, format, { locale })
          return s
        }
        if (format && !!value && value.mode === 'dateWithTimezone') {
          const { date, timeZone } = value
          if (!!date && date instanceof Date && !!timeZone && typeof timeZone === 'string') {
            const locale = locales[(lng as Language | undefined) || fallbackLang]
            return formatInTimeZone(date, timeZone, format, { locale })
          }
        }
        return value
      },
    },
    resources,
  })
  .then(() => (i18nextIsInitialized = true))

const i18n = (lng?: Language) => {
  if (lng) {
    const lang = supportedLang.find((l) => l === lng) || supportedLang.find((l) => lng.indexOf(l) > -1) || fallbackLang
    i18next.changeLanguage(lang)

    return {
      // eslint-disable-next-line prefer-spread
      t: (...args: any) => i18next.t.apply(i18next, args),
      lang: i18next.language,
      supportedLang,
      locales,
      locale: locales[lang],
    } as I18n
  } else {
    return {
      // eslint-disable-next-line prefer-spread
      t: (...args: any) => i18next.t.apply(i18next, args),
      lang: i18next.language,
      supportedLang,
      locales,
    } as I18n
  }
}

export const actions = {
  setLang: createEvent<Language>('setLang'),
}

export const I18nKeysActions = {
  setI18nKeys: createEvent<{
    [language: string]: NestedTranslations
  }>('setI18nKeys'),
}

interface I18nKeysState {
  i18nKeys?: { [language: string]: NestedTranslations }
}

const initialState: I18nKeysState = {
  i18nKeys: undefined,
}

export const store = createStore<I18n>(i18n()).on(actions.setLang, (_, lang) => i18n(lang))

export const I18nKeysStore = createStore<I18nKeysState>(initialState, { name: 'I18nKeys' }).on(
  I18nKeysActions.setI18nKeys,
  (s, i18nKeys) => {
    return { ...s, i18nKeys }
  }
)
