import { blobToB64, executeGraphRequest } from 'authent365/utils/utils'

const api = {
  fetchUsers: (userType: UserType, words: string[]): Promise<DirectoryUser[]> => {
    const params = words
      .map((w) => `("givenName:${w}" OR "surName:${w}" OR "mail:${w}" OR "displayName:${w}")`)
      .join(' AND ')

    const query = `${params}&$select=id,surName,givenName,mail,mobilePhone,businessPhones`

    return executeGraphRequest<UsersGraphResponse>(userType, `/users?$search=` + query, 'GET').then((res) =>
      res.value
        .filter((user) => user.surname)
        .sort((a, b) => a.surname.toLocaleLowerCase().localeCompare(b.surname.toLocaleLowerCase()))
        .map((user) => ({
          ...user,
          mail: user.mail && user.mail !== '-' ? user.mail : '',
        }))
    )
  },
  getUserPhoto: (userType: UserType, userId: string) =>
    executeGraphRequest<Blob>(userType, `/users/${userId}/photos/48x48/$value`, 'GET', undefined, 'blob').then(
      blobToB64
    ),
  getUser: (userType: UserType, userId: string): Promise<DirectoryUser> =>
    executeGraphRequest(userType, `/users/${userId}`, 'GET'),
  getUserByMail: (userType: UserType, userMail: string) =>
    executeGraphRequest<UsersGraphResponse>(
      userType,
      `/users?$filter=startswith(mail,'${userMail}')&$select=id,surName,givenName,mail,mobilePhone,businessPhones`,
      'GET'
    ).then((res) => res.value[0] as DirectoryUser | undefined),
}

export default api
