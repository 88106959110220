import { createEvent, createStore } from 'effector'

export const actions = {
  setSiteShortcuts: createEvent<SiteShortcutList>('setSiteShortcuts'),
  reset: createEvent('reset'),
}

const initialState: ShortcutsState = {
  sitesShortcuts: {},
}

export const store = createStore<ShortcutsState>(initialState, { name: 'Home Shortcuts V2' })
  .on(actions.setSiteShortcuts, (s, shortcuts) => {
    s.sitesShortcuts[shortcuts.siteId] = { ...shortcuts }
    return { ...s }
  })
  .on(actions.reset, () => initialState)
