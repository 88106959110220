import * as React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as surveysStore from 'surveys/store'
import * as siteStore from 'sites/store/siteStore'

import Loader from 'react-loader-spinner'

import useNavigation from 'utils/navigation/useNavigation'
import { windowSizeBreakpoints } from 'utils/breakpoints'

import Icon from 'components/icons/Icons'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import TitleHelmet from 'components/title/TitleHelmet'
import Alert from 'components/alert/Alert'
import ValidationContent from 'components/modal/ValidationContent'
import Button from 'components/button/Button'
import RGPDModal from './RGPDModal'
import Modal from 'components/modal/Modal'
import Notation from 'components/surveys/SurveyNotation'
import MultipleChoices from 'components/surveys/MultipleChoices'

import api from './api'

interface Props {
  id: string
}

const MIN_NOTE_COMMENT = 3
const MAX_LENGTH = 500

const SurveyDetailsScreen = ({ id }: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()
  const navigation = useNavigation()

  const surveys = useReducer(surveysStore.store, (s) => s.surveys)
  const user = useReducer(userStore.store, (s) => s.user)
  const currentSite = useReducer(siteStore.store, (s) => s.site)

  const [statusScreen, setStatusScreen] = React.useState<'loading' | 'error' | 'ok'>('loading')
  const [RGPDVisible, setRGPDVisible] = React.useState(false)

  const survey = React.useMemo(() => surveys.find((s) => s.id === id), [surveys])

  React.useEffect(() => {
    if (!!user && !!currentSite) {
      api
        .getSurveysList(user.type, currentSite.id)
        .then((res) => {
          setStatusScreen('ok')
          surveysStore.actions.setSurveys(res.surveys)
        })
        .catch(() => setStatusScreen('error'))
    }
  }, [])

  const openRGPDModal = () => Modal.open(() => <RGPDModal closeModal={Modal.close} />)

  const renderHeader = () => {
    if (!survey) {
      return null
    }

    return (
      <>
        {RGPDVisible && (
          <RGPDContainer onClick={openRGPDModal}>
            <IconContainer>
              <Icon name="info" size={30} color={theme.colors.blue} />
            </IconContainer>
            <InfosRGPDContainer>
              <Description dangerouslySetInnerHTML={{ __html: i18n.t('screens.surveys.details.RGPDTitle') }} />
            </InfosRGPDContainer>
            <IconContainer>
              <Icon name="chevron_right" size={30} color={theme.colors.darkGrey} />
            </IconContainer>
          </RGPDContainer>
        )}

        <EndDateContainer>
          <IconContainer>
            <Icon name="agenda" size={20} color={theme.colors.blue} />
          </IconContainer>
          <EndDate>
            {i18n.t('screens.surveys.endDate', {
              date: new Date(survey.endDate),
            })}
          </EndDate>
        </EndDateContainer>
        <TitleContainer>
          <Title>{survey.title}</Title>
        </TitleContainer>
        {!!survey.description && (
          <DescriptionContainer>
            <Description dangerouslySetInnerHTML={{ __html: survey.description }} />
          </DescriptionContainer>
        )}
      </>
    )
  }

  if (statusScreen === 'loading') {
    return (
      <LoaderContainer>
        <Loader type="TailSpin" color={theme.colors.blue} />
      </LoaderContainer>
    )
  }

  if (statusScreen === 'error' || !survey) {
    return (
      <ErrorContainer>
        <ErrorText>{i18n.t('screens.surveys.error')}</ErrorText>
      </ErrorContainer>
    )
  }

  return (
    <>
      <TitleHelmet title={survey.title} />
      <Breadcrumb screen="surveys" path="surveys" details={survey.title} navigation={navigation} />
      <MainContainer>
        {renderHeader()}
        <SurveyDetail navigation={navigation} survey={survey} user={user} changeRGPDVisible={setRGPDVisible} />
      </MainContainer>
    </>
  )
}

interface SurveyProps {
  navigation: Navigation
  survey: Survey
  user?: UserSelf
  changeRGPDVisible: (RGPDVisible: boolean) => void
}

const SurveyDetail = ({ navigation, survey, user, changeRGPDVisible }: SurveyProps) => {
  const i18n = useI18n()
  const [theme] = useTheme()

  const questions = survey.questions.sort((a, b) => a.position - b.position)

  const initialAnswers = React.useMemo(
    () =>
      questions.reduce((acc, cur) => {
        acc.push({
          questionId: cur.id,
          type: cur.type,
          choices: cur.type === 'CHOICE' ? [] : undefined,
          minNoteComment: cur.minNoteComment,
        })
        return acc
      }, [] as SurveyAnswerForm[]),
    [questions]
  )

  const [answers, setAnswers] = React.useState<SurveyAnswerForm[]>(initialAnswers)
  const [isAnswering, setIsAnswering] = React.useState(false)

  const isRGPDvisible = React.useMemo(
    () =>
      !!questions.find((q) => q.type === 'TEXT') ||
      !!answers.find(
        (answer) => answer.type === 'NOTE' && !!answer.note && answer.minNoteComment && answer.note <= MIN_NOTE_COMMENT
      ),
    [questions, answers]
  )

  React.useEffect(() => {
    changeRGPDVisible(isRGPDvisible)
  }, [isRGPDvisible])

  const emptyFields = React.useMemo(
    () =>
      questions.filter((question) => {
        const findAnswer = answers.find((answer) => answer.questionId === question.id)
        if (!!findAnswer && !!findAnswer.choices && findAnswer.choices.length === 0) {
          return true
        }
        if (
          !!findAnswer &&
          findAnswer.type === 'NOTE' &&
          findAnswer.minNoteComment &&
          !!findAnswer.note &&
          findAnswer.note <= MIN_NOTE_COMMENT &&
          !findAnswer.text
        ) {
          return true
        }
        if (!!findAnswer && findAnswer.type === 'NOTE' && !findAnswer.note) {
          return true
        }
        if (!!findAnswer && findAnswer.type === 'TEXT' && !findAnswer.text) {
          return true
        }
        return false
      }),
    [questions, answers]
  )

  const submit = () => {
    if (!!user && emptyFields.length === 0) {
      setIsAnswering(true)
      const body: SurveyAnswers = {
        surveyAnswers: answers.reduce(
          (acc, cur) => [
            ...acc,
            {
              questionId: cur.questionId,
              choices: cur.choices,
              note: cur.note,
              text:
                cur.type === 'TEXT' || (cur.minNoteComment && !!cur.note && cur.note <= MIN_NOTE_COMMENT)
                  ? cur.text
                  : undefined,
            },
          ],
          [] as SurveyAnswer[]
        ),
      }
      api
        .answerSurvey(user.type, survey.id, body)
        .then(() => {
          navigation.push('/home')
          Alert.open(
            () => (
              <ValidationContent
                title={i18n.t('screens.surveys.details.sendSucceed', { title: survey.title })}
                onConfirm={Alert.close}
                ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
              />
            ),
            true
          )
        })
        .catch(() =>
          Alert.open(
            () => (
              <ValidationContent
                title={i18n.t('screens.surveys.details.error')}
                onConfirm={Alert.close}
                ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
              />
            ),
            true
          )
        )
        .finally(() => setIsAnswering(false))
    }
  }

  const onChange = (answer: SurveyAnswerForm) => {
    const savedAnswerIdx = answers.findIndex((a) => a.questionId === answer.questionId)
    if (savedAnswerIdx >= 0) {
      const newAnswers = [...answers]
      newAnswers[savedAnswerIdx] = answer
      setAnswers(newAnswers)
    } else {
      setAnswers([...answers, answer])
    }
  }

  const renderItem = (item: Question, index: number) => (
    <>
      <QuestionContainer>
        <QuestionNumber>
          {i18n.t('screens.surveys.details.questionNumber', {
            number: index + 1,
          })}
        </QuestionNumber>
        <QuestionTitle>{item.title}</QuestionTitle>
        {!!item.description && (
          <DescriptionContainer>
            <Description dangerouslySetInnerHTML={{ __html: item.description }} />
          </DescriptionContainer>
        )}
        {renderQuestion(item)}
      </QuestionContainer>
      {index < survey.questions.length - 1 && <Separator role="presentation" />}
    </>
  )

  const renderQuestion = (question: Question) => {
    const findAnswer = answers.find((a) => a.questionId === question.id)
    if (!findAnswer) {
      return null
    }
    switch (question.type) {
      case 'CHOICE':
        return (
          <MultipleChoices
            choices={question.choices || []}
            multipleChoices={question.multiple}
            onChange={(choices) => onChange({ questionId: question.id, choices, type: question.type })}
            editable={!isAnswering}
            selectedIDs={findAnswer.choices || []}
          />
        )
      case 'NOTE':
        return (
          <>
            <Notation
              onChange={(note) =>
                onChange({
                  questionId: question.id,
                  text: findAnswer.text,
                  note,
                  type: question.type,
                  minNoteComment: question.minNoteComment,
                })
              }
              editable={!isAnswering}
              note={findAnswer.note}
            />
            {findAnswer.minNoteComment && !!findAnswer.note && findAnswer.note <= MIN_NOTE_COMMENT && (
              <QuestionInputContainer>
                <QuestionInput
                  value={findAnswer.text}
                  onChange={(event) => {
                    if (event.target.value.length <= MAX_LENGTH) {
                      onChange({
                        questionId: question.id,
                        note: findAnswer.note,
                        text: event.target.value,
                        type: question.type,
                        minNoteComment: question.minNoteComment,
                      })
                    }
                  }}
                  placeholder={i18n.t('screens.surveys.details.minNotePlaceholder')}
                  inputMode="text"
                  spellCheck={false}
                  autoCorrect="off"
                  autoCapitalize="off"
                  aria-required="true"
                  disabled={isAnswering}
                />
              </QuestionInputContainer>
            )}
          </>
        )
      case 'TEXT':
        return (
          <QuestionInputContainer>
            <QuestionInput
              value={findAnswer.text}
              onChange={(event) => {
                if (event.target.value.length <= MAX_LENGTH) {
                  onChange({ questionId: question.id, text: event.target.value, type: question.type })
                }
              }}
              placeholder={i18n.t('screens.surveys.details.textPlaceholder')}
              inputMode="text"
              spellCheck={false}
              autoCorrect="off"
              autoCapitalize="off"
              aria-required="true"
              disabled={isAnswering}
            />
          </QuestionInputContainer>
        )
      default:
        return null
    }
  }

  return (
    <SurveyDetailContainer>
      {survey.questions.map(renderItem)}
      <CenterButtonContainer>
        <ButtonContainer>
          <Button
            label={i18n.t('common.validate')}
            onClick={submit}
            font={theme.fonts.h3Bold}
            disabled={isAnswering || emptyFields.length > 0}
            loading={isAnswering}
          />
        </ButtonContainer>
      </CenterButtonContainer>
    </SurveyDetailContainer>
  )
}

export default SurveyDetailsScreen

const SurveyDetailContainer = styled('div')`
  margin-bottom: 60px;
  display: flex;
  flex: 1;
  flex-direction: column;
`

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 32px;
  margin-left: 5%;
  margin-right: 10%;
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    margin-right: 2%;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    margin-right: 4%;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-left: 4%;
  }
`

const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`

const ErrorContainer = styled('div')`
  padding: 30px 50px;
  min-height: calc(100vh - 176px);
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 10px 10px;
    min-height: calc(100vh - 136px);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const ErrorText = styled('p')`
  ${(props) => props.theme.fonts.body};
  font-size: 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    text-align: center;
  }
`

const TitleContainer = styled('div')`
  display: flex;
  align-items: center;
  margin: 20px 0px;
`

const Separator = styled('div')`
  width: 60px;
  height: 3px;
  background-color: ${(props) => props.theme.colors.blue};
  margin: 0px;
  border-radius: 2px;
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 24px;
  margin: 0px;
`

const DescriptionContainer = styled('div')``

const Description = styled('div')`
  ${(props) => props.theme.fonts.body};
  margin: 0px;
`

const EndDateContainer = styled('div')`
  display: flex;
  align-items: center;
`

const EndDate = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.middleGrey};
  margin: 0px;
`

const IconContainer = styled('div')`
  margin-right: 8px;
`

const QuestionContainer = styled('div')`
  margin: 25px 0px;
`

const QuestionNumber = styled('p')`
  ${(props) => props.theme.fonts.label};
  margin: 0px;
`

const QuestionTitle = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  margin: 10px 0px;
`

const ButtonContainer = styled('div')`
  display: flex;
  width: 350px;
`

const CenterButtonContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-bottom: 80px;
  }
`

const RGPDContainer = styled('button')`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  min-height: 70px;
  margin-bottom: 30px;
  padding: 30px 42px;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 16px;
  }
`

const InfosRGPDContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0px 26px;
`

const QuestionInput = styled('textarea')`
  resize: none;
  height: 130px;
  display: flex;
  flex: 1;
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.blue};
  margin: 0px;
  padding: 8px 12px;
  border: 1px solid ${(props) => props.theme.colors.middleGrey};
  outline: 0;
  ::placeholder {
    font-weight: 300;
  }
  &:focus {
    border-color: ${(props) => props.theme.colors.blue};
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    min-width: 80vw;
  }
`

const QuestionInputContainer = styled('div')<{ clickable?: boolean }>`
  display: flex;
  ${(props) => props.clickable && 'cursor: pointer'};
  justify-content: space-between;
`
