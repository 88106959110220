import * as React from 'react'
import styled from 'theme/styled-components'

import useTheme from 'theme/useTheme'

import utils from 'roombooking/utils'

import Icons from 'components/icons/Icons'

interface Props {
  iconSize: number
  width?: number
  height?: number
  src?: string
  roomType: string
}

const RoomImage = ({ iconSize, width, height, src, roomType }: Props) => {
  const [Theme] = useTheme()
  const [imageError, setImageError] = React.useState(false)

  const isImage = utils.isPlaceTypeIsImage(roomType)

  return (
    <>
      {imageError || !src ? (
        !isImage ? (
          <Icons name={utils.getRoomPlaceholder(roomType)} color={Theme.colors.blue} size={iconSize} />
        ) : (
          <Photo width={width} height={height} src={utils.getRoomImagePlaceholder(roomType)} alt="" />
        )
      ) : (
        <Photo width={width} height={height} src={src} onError={() => setImageError(true)} alt="" />
      )}
    </>
  )
}

const Photo = styled('img')<{ width?: number; height?: number }>`
  ${(props) => (props.width ? `${props.width}px` : '')};
  ${(props) => (props.height ? `${props.height}px` : '')};

  ${(props) => (!props.width && !props.height ? 'width: 100%' : '')};
  ${(props) => (!props.height && !props.height ? 'height: 100%' : '')};

  object-fit: cover;
`

export default RoomImage
