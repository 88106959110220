import { createEvent, createStore } from 'effector'
import { AllTimes } from './types'

export interface FavoriteRoomsState {
  favorites: string[]
}

export interface FavoriteRoomsNotPersistentState {
  graphIdBooking?: string
  graphEmailBooking?: string
  formTimes?: AllTimes
}

export const actions = {
  setFav: createEvent<string[]>('setRoomsFavs'),
  setGraphIdBooking: createEvent<string>('setGraphIdBooking'),
  setGraphEmailBooking: createEvent<string>('setGraphEmailBooking'),
  setFormTimes: createEvent<AllTimes>('setFormTimes'),
  resetFav: createEvent('resetRoomsFavs'),
  resetGraphInfos: createEvent('resetGraphInfos'),
}

const initialState: FavoriteRoomsState = {
  favorites: [],
}

const initialStateNotPersistent: FavoriteRoomsNotPersistentState = {
  graphIdBooking: undefined,
  graphEmailBooking: undefined,
  formTimes: undefined,
}

export const store = createStore<FavoriteRoomsState>(initialState, { name: 'Room Favorites' })
  .on(actions.setFav, (s, favorites) => ({ ...s, favorites }))
  .on(actions.resetFav, () => initialState)

export const storeNotPersistant = createStore<FavoriteRoomsNotPersistentState>(initialStateNotPersistent, {
  name: 'Room Favorites Not Persistant',
})
  .on(actions.setGraphIdBooking, (s, graphIdBooking) => ({ ...s, graphIdBooking }))
  .on(actions.setGraphEmailBooking, (s, graphEmailBooking) => ({ ...s, graphEmailBooking }))
  .on(actions.setFormTimes, (s, formTimes) => ({ ...s, formTimes }))
  .on(actions.resetGraphInfos, () => initialStateNotPersistent)
