import { useHistory } from 'react-router-dom'
import Routes from 'core/src/layout/Screens'

import useI18n from 'i18n/useI18n'

const useNavigation = (): Navigation => {
  const history = useHistory()
  const i18n = useI18n()

  const getAllParams = (path: string, params?: { [key: string]: any }) => {
    const ParamsPath =
      params && params.pathParams
        ? Object.keys(params.pathParams).reduce((url, key) => url.replace(':' + key, params.pathParams[key]), path)
        : path

    return {
      pathname: `/${i18n.lang}${ParamsPath}`,
      state: params,
    }
  }

  return {
    getLocation: () => history.location,
    push: (path: string, params?: { [key: string]: any }) => history.push(getAllParams(path, params)),
    pushQuery: (path: string, search: string, params?: { [key: string]: any }) =>
      history.push({ ...getAllParams(path, params), search }),
    goBack: history.goBack,
    back: history.goBack,
    replace: (path: string, params?: { [key: string]: any }) => history.replace(getAllParams(path, params)),
    navigate: (name: string, params?: { [key: string]: any }) => {
      const s = Routes[name]
      if (s) {
        history.push(s.path, params)
      }
    },
    openDrawer: () => null,
    reset: () => history.replace('/'),
    resetTo: (path: string) => history.replace(path),
  }
}

export default useNavigation
