import React from 'react'
import styled from 'theme/styled-components'

import useI18n from 'i18n/useI18n'

import { windowSizeBreakpoints } from 'utils/breakpoints'

interface Props {
  floorNum: number
  selected: boolean
  onClick: () => void
}

const FloorButton = React.forwardRef(
  ({ floorNum, selected, onClick }: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
    const i18n = useI18n()
    return (
      <FloorButtonSelector
        tabIndex={0}
        selected={selected}
        onClick={onClick}
        aria-label={i18n.t('label.ariaLabel.map.floorButton', { floor: floorNum })}>
        <FloorButtonText ref={ref} selected={selected}>
          {floorNum}
        </FloorButtonText>
      </FloorButtonSelector>
    )
  }
)

export default FloorButton

// BUTTONS

const FloorButtonSelector = styled('div')<{ selected: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.1s, height 0.1s;
  margin-top: 7px;
  width: ${(props) => (props.selected ? 74 : 64)}px;
  height: ${(props) => (props.selected ? 74 : 64)}px;
  border-radius: 40px;
  background-color: ${(props) => (props.selected ? props.theme.colors.blue : props.theme.colors.white)};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.160784);
  &:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  }
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: ${(props) => (props.selected ? 58 : 50)}px;
    height: ${(props) => (props.selected ? 58 : 50)}px;
  }
`

// TEXTES

const FloorButtonText = styled('div')<{ selected: boolean }>`
  ${(props) => props.theme.fonts.h3Bold};
  color: ${(props) => (props.selected ? props.theme.colors.white : props.theme.colors.black)};
`
