const utils = {
  sortUsersByLetter: (users: DirectoryUser[]) =>
    users
      .reduce((acc, cur) => {
        const index = acc.findIndex((u) => u.letter === cur.surname.charAt(0).toUpperCase())
        if (index > -1) {
          acc[index].users.push(cur)
        } else {
          acc.push({ letter: cur.surname.charAt(0).toUpperCase(), users: [cur] })
        }
        return acc
      }, [] as UsersByLetters[])
      .map((letter) => ({
        ...letter,
        users: letter.users.sort((a, b) => {
          const surnameCompare = a.surname.toLowerCase().localeCompare(b.surname.toLowerCase())
          if (surnameCompare === 0) {
            return a.givenName.toLowerCase().localeCompare(b.givenName.toLowerCase())
          } else {
            return surnameCompare
          }
        }),
      }))
      .sort((a, b) => a.letter.localeCompare(b.letter)),
  idsToString: (idsList: string[]) => idsList.map((id) => `${id}`).join(','),
}

export default utils
