import * as React from 'react'
import styled from 'theme/styled-components'

import { windowSizeBreakpoints } from 'utils/breakpoints'

import { format, setHours } from 'date-fns'

interface Props {
  start: number
  end: number // not included
  width: number
  refDate: Date
}

const Back = ({ start, end, width, refDate }: Props) => {
  const hours = new Array(end - start).fill(0).map((z, idx) => setHours(refDate, start + idx))

  return (
    <Line>
      {hours.map((h) => (
        <HourContainer key={h.toISOString()} width={width}>
          <Hour>{format(h, "HH':'00")}</Hour>
          <MiddleLine width={width} />
        </HourContainer>
      ))}
      <AvailableView />
    </Line>
  )
}

export default Back

const Line = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 10px 0px;
  position: relative;
  max-width: 480px;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    max-width: 420px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    max-width: 330px;
  }
`

const HourContainer = styled('div')<{ width: number }>`
  position: relative;
  width: ${(props) => props.width - 1}px;
  height: 30px;
  border: 0px solid ${(props) => props.theme.colors.middleGrey};
  border-left-width: 1px;
  border-bottom-width: 1px;
`

const Hour = styled('p')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.black};
  position: absolute;
  top: -13px;
  left: 4px;
`

const MiddleLine = styled('div')<{ width: number }>`
  position: absolute;
  left: ${(props) => props.width / 2 - 1}px;
  bottom: 0px;
  height: 15px;
  border: 0px solid ${(props) => props.theme.colors.middleGrey};
  border-left-width: 1px;
`

const AvailableView = styled('div')`
  height: 12px;
  background-color: ${(props) => props.theme.colors.available};
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
`
