import equal from 'fast-deep-equal'
import { createEvent, createStore } from 'effector'

export const actions = {
  setSite: createEvent<SiteV6>('setSite'),
  setParkingUrl: createEvent<string>('setParkingUrl'),
  setMySites: createEvent<PersonalSiteV5[]>('setMySites'),
  setAllSites: createEvent<SiteLightV6[]>('setAllSitesLight'),
  resetSite: createEvent('resetSite'),
}

const initialState: SiteState = {
  site: undefined,
  mySites: [],
  allSites: [],
  parkingUrl: undefined,
}

export const store = createStore<SiteState>(initialState, { name: 'Site Info V2' })
  .on(actions.setSite, (s, site) => ({ ...s, site: equal(s.site, site) ? s.site : site, parkingUrl: undefined }))
  .on(actions.setMySites, (s, mySites) => ({ ...s, mySites }))
  .on(actions.setAllSites, (s, allSites) => ({ ...s, allSites }))
  .on(actions.setParkingUrl, (s, parkingUrl) => ({ ...s, parkingUrl }))
  .on(actions.resetSite, () => initialState)
