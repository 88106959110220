import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'

import { windowSizeBreakpoints } from 'utils/breakpoints'
import analytics, { analyticsActionKeys, analyticsKeys } from 'utils/analytics'
import useNavigation from 'utils/navigation/useNavigation'
import Logger from 'utils/Logger'
import {
  documentMimeTypesIntervention,
  FleetForm,
  formatDescriptionFleet,
  generateFleetList,
  imageMimeTypes,
  isFleetFormValid,
  TYPE_CARD_REQUEST,
  TYPE_DOCUMENT,
  TYPE_RADIO_BUTTON_BOOLEAN,
  TYPE_REPAIR,
  TYPE_TRACTION,
  TYPE_VEHICLE_DURATION,
  TypeDocument,
  TypeVehicleDuration,
} from './utils'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'
import * as formStore from 'store/form/formStore'

import api from './api'

import EmptyImagePlaceholder from 'components/image/assets/intervention_vide.svg'
import UserRequestInfo from './components/UserRequestInfo'
import Button from 'components/button/Button'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import Directory from 'directory/DirectoryScreen'
import Icons from 'components/icons/Icons'
import ReservationTimeModal from 'planning/myPlanning/ReservationTimeModal'
import TitleHelmet from 'components/title/TitleHelmet'
import { FormTextInput, FormRadioButton, RadioOption } from 'components/form/InterventionFormItems'
import CustomDatePicker from 'components/picker/CustomDatePicker'
import Modal from 'components/modal/Modal'
import ValidationContent from 'components/modal/ValidationContent'
import { Attachment } from './InterventionScreen'

import Loader from 'react-loader-spinner'
import { motion } from 'framer-motion'
import Select from 'react-select'
import { useFormik } from 'formik'
import { isAfter, isSameDay } from 'date-fns'
import SVG from 'react-inlinesvg'

type Status = 'ok' | 'loading' | 'error'
interface DropDownOption {
  value: string
  label: string
}

const { FIRST_LIST, SECOND_LIST, THIRD_LIST } = generateFleetList()

export interface FleetValues {
  userDeliveringVehicle?: string
  userDeliveringVehiclePhoneNumber?: string
  currentVehicleKilometers?: string
  additionalCorrectiveAction?: string
  onSiteVehicleRemoval?: RadioOption
  replacementVehicleRequired?: RadioOption
  technicalRevisionExpirationDate: Date
  vehicleWithdrawalDate: Date
  responsibleVehicleUser?: UserData
  repair?: RadioOption
  vehicleReasonProblem?: string
  vehicleNeededDate: Date
  equipmentsAdditional?: string
  vehicleTraction?: RadioOption
  vehicleDuration?: TypeVehicleDuration
  equipmentNeededDate: Date
  typeDocument?: TypeDocument
  otherInfos?: string
  applicantUserPhoneNumber?: string
  cardRequestType?: RadioOption
  rut?: string
  userInvolvedAccident?: string
  claimNumber?: string
  claimDeclarationAttachment?: Attachment
  complaintProofAttachment?: Attachment
  policeStatementAttachment?: Attachment
  identityCardAttachment?: Attachment
  driverLicenseAttachment?: Attachment
  thirdPartyVehiclePlateNumber?: string
  ocl?: DropDownOption
  vehicleLicensePlate?: DropDownOption
  vehicleLocation?: DropDownOption
  applicantManagement?: DropDownOption
}

const NOW = new Date()

const FleetScreen = () => {
  const i18n = useI18n()
  const [Theme] = useTheme()
  const navigation = useNavigation()

  const user = useReducer(userStore.store, (s) => s.user)
  const site = useReducer(siteStore.store, (s) => s.site)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)

  const [status, setStatus] = React.useState<Status>('loading')
  const [floorsStatus, setFloorsStatus] = React.useState<Status>('loading')
  const [vehicleStatus, setVehicleStatus] = React.useState<Status>('loading')
  const [isSendingForm, setIsSendingForm] = React.useState(false)
  const [formSelected, setFormSelected] = React.useState<FleetForm>()
  const [fleetVehiclesInfos, setFleetVehiclesInfos] = React.useState<FleetVehicle[]>([])
  const [myInterventions, setMyInterventions] = React.useState<IncidentV6[]>([])
  const [floors, setFloors] = React.useState<LocationV6[]>([])

  const hiddenFileInput: any = React.useRef()

  const oclList: DropDownOption[] = React.useMemo(
    () =>
      Array.from(
        new Set(
          fleetVehiclesInfos.map((item) => ({
            label: item.ocl || '',
            value: item.ocl || '',
          }))
        )
      ).filter((item) => !!item.label && !!item.value),
    [fleetVehiclesInfos]
  )
  const vehicleLicensePlateList: DropDownOption[] = React.useMemo(
    () =>
      Array.from(
        new Set(
          fleetVehiclesInfos.map((item) => ({
            label: item.patenteVehiculo || '',
            value: item.patenteVehiculo || '',
          }))
        )
      ).filter((item) => !!item.label && !!item.value),
    [fleetVehiclesInfos]
  )
  const vehicleLocationList: DropDownOption[] = React.useMemo(
    () =>
      Array.from(
        new Set(
          fleetVehiclesInfos.map((item) => ({
            label: item.sitioUbicacion || '',
            value: item.sitioUbicacion || '',
          }))
        )
      ).filter((item) => !!item.label && !!item.value),
    [fleetVehiclesInfos]
  )
  const applicantManagementList: DropDownOption[] = React.useMemo(
    () =>
      Array.from(
        new Set(
          fleetVehiclesInfos.map((item) => ({
            label: item.gerencia || '',
            value: item.gerencia || '',
          }))
        )
      ).filter((item) => !!item.label && !!item.value),
    [fleetVehiclesInfos]
  )

  const requestsSortedByDate = React.useMemo(() => {
    if (myInterventions) {
      return myInterventions
        .reduce((acc, cur) => {
          const index = acc.findIndex((r) => isSameDay(new Date(r.date), new Date(cur.date)))

          if (index > -1) {
            acc[index].interventions.push(cur)
          } else {
            acc.push({ date: cur.date, interventions: [cur] })
          }
          return acc
        }, [] as RequestByDate[])
        .sort((a, b) => (isAfter(new Date(a.date), new Date(b.date)) ? -1 : 0))
    }
  }, [myInterventions])

  React.useEffect(() => {
    if (user && site) {
      analytics.track({ screenName: analyticsKeys.fleet, userType: user.type, currentSite: site, mySites, origin })
      fetchFloors()
      getVehicleInfos()
      getUserRequests()
    }
  }, [i18n.lang])

  const getUserRequests = () => {
    if (user && site) {
      setStatus('loading')
      api
        .getMyInterventions(site.id, user.type, 'FLEET')
        .then((res) => {
          setStatus('ok')
          setMyInterventions(res.incidents.sort((a, b) => (isAfter(new Date(a.date), new Date(b.date)) ? -1 : 0)))
        })
        .catch((err) => {
          Logger.error(err)
          setStatus('error')
        })
    }
  }

  const getVehicleInfos = () => {
    if (!!user && !!site) {
      setVehicleStatus('loading')
      api
        .getFleetVehiclesInfos(site.id, user.type)
        .then((res) => {
          setVehicleStatus('ok')
          setFleetVehiclesInfos(res.fleetVehicles)
        })
        .catch((err) => {
          Logger.error(err)
          setVehicleStatus('error')
        })
    }
  }

  const fetchFloors = () => {
    if (user && site) {
      setFloorsStatus('loading')
      api
        .getAllFloorLocations(site.id, user.type)
        .then((res) => {
          setFloorsStatus('ok')
          const newFloors = res.locations.sort((l1, l2) => l1.level - l2.level)
          setFloors(res.locations.sort((l1, l2) => l1.level - l2.level))
          if (newFloors.length === 1) {
            setFieldValue('selectedFloor', newFloors[0])
          }
        })
        .catch((err) => {
          Logger.error(err)
          setFloorsStatus('error')
        })
    }
  }

  const { values, handleChange, setFieldValue, resetForm, dirty, handleSubmit } = useFormik({
    initialValues: {
      userDeliveringVehicle: `${user?.firstName} ${user?.lastName}`,
      technicalRevisionExpirationDate: NOW,
      vehicleWithdrawalDate: NOW,
      vehicleNeededDate: NOW,
      equipmentNeededDate: NOW,
      userInvolvedAccident: `${user?.firstName} ${user?.lastName}`,
    } as FleetValues,
    onSubmit: (values: FleetValues) => {
      if (!!formSelected && !!site && !!user) {
        setIsSendingForm(true)
        const formData = new FormData()
        formData.append('categoryId', formSelected.id)
        formData.append('description', formatDescriptionFleet(values, formSelected.type, i18n))

        const attachments = [
          values.claimDeclarationAttachment,
          values.complaintProofAttachment,
          values.policeStatementAttachment,
          values.identityCardAttachment,
          values.driverLicenseAttachment,
        ].filter((attachment) => !!attachment)

        if (attachments.length > 0) {
          formData.append('attachmentsName', attachments.map((i) => i?.name).toString())
          formData.append('attachmentsContent', attachments.map((i) => i?.data.split(',').pop() || '').toString())
        }

        analytics.track({
          screenName: analyticsKeys.fleet,
          userType: user.type,
          currentSite: site,
          mySites,
          action: analyticsActionKeys.fleetSendForm,
          label: formSelected.id,
        })

        api
          .createIncident(formData, site.id, user.type, 'FLEET')
          .then(() => {
            getUserRequests()
            resetForm()
            setFormSelected(undefined)
            Modal.open(() => (
              <ValidationContent
                title={i18n.t('screens.incident.requestRegistered')}
                onConfirm={Modal.close}
                description={i18n.t('screens.incident.registeredDescription')}
                confirmButtonTitle={i18n.t('common.close')}
              />
            ))
          })
          .catch((err) => {
            setStatus('ok')
            Logger.error(err)
            Modal.open(() => (
              <ValidationContent
                title={i18n.t('screens.incident.requestError')}
                onConfirm={Modal.close}
                confirmButtonTitle={i18n.t('common.ok')}
              />
            ))
          })
          .finally(() => setIsSendingForm(false))
      }
    },
  })

  const isFormValid = React.useMemo(() => (!!formSelected ? isFleetFormValid(values, formSelected.type) : false), [
    values,
    formSelected,
  ])

  React.useEffect(() => {
    if (dirty) {
      formStore.actions.setConfirmNavigation(true)
    } else {
      formStore.actions.reset()
    }
  }, [dirty])

  const onUploadFile = (uploadEvent: React.ChangeEvent<HTMLInputElement>, onChange: (val: Attachment) => void) => {
    if (uploadEvent.target.files && uploadEvent.target.files[0]) {
      const file = uploadEvent.target.files[0]
      const reader = new FileReader()

      reader.readAsDataURL(file)
      reader.onload = (readEvent) => {
        if (readEvent.target) {
          const imageFormat = Object.keys(imageMimeTypes).find(
            (key) => imageMimeTypes[key as AttachmentImageFormat] === file.type
          )
          const documentFormat = Object.keys(documentMimeTypesIntervention).find(
            (key) => documentMimeTypesIntervention[key as AttachmentDocumentFormatIntervention] === file.type
          )
          if (imageFormat || documentFormat) {
            const format = (imageFormat || documentFormat) as
              | AttachmentImageFormat
              | AttachmentDocumentFormatIntervention

            const attachment: Attachment = {
              file: file,
              name: file.name,
              data: readEvent.target.result as string,
              format,
              type: !!imageFormat ? 'IMAGE' : 'DOCUMENT',
            }
            const fileSizeMo = file.size / (1024 * 1024)
            if (fileSizeMo > 5) {
              Modal.open(() => (
                <ValidationContent
                  title={i18n.t('common.error')}
                  description={i18n.t('screens.incident.form.maxSize')}
                  onConfirm={Modal.close}
                  confirmButtonTitle={i18n.t('common.ok')}
                />
              ))
            } else {
              onChange(attachment)
            }
          }
        }
      }
    }
  }

  const renderButton = (fleetForm: FleetForm) => (
    <ButtonContainer>
      <motion.button
        whileTap={{ scale: 0.9 }}
        whileHover={{
          scale: 1.1,
          transition: { duration: 0.2 },
        }}>
        <Button
          label={i18n.t(`screens.fleet.forms.${fleetForm.type}.title`)}
          onClick={() => setFormSelected(fleetForm)}
          horizontalPadding={12}
          verticalPadding={6}
          noFlex
          color={Theme.colors.white}
          textColor={Theme.colors.blue}
          shadow
          font={Theme.fonts.label}
        />
      </motion.button>
    </ButtonContainer>
  )

  const renderTextInput = (
    name: string,
    mandatory: boolean,
    value?: string,
    placeholder?: string,
    isPhoneNumber?: boolean,
    isKilometers?: boolean,
    maxLength?: number,
    error?: string
  ) => (
    <FormTextInput
      id={name}
      name={name}
      onChange={(newValue) => {
        if (isPhoneNumber) {
          // On ne garde que les +, les espaces et les chiffres
          setFieldValue(name, newValue.target.value.replace(/[^\d\s+]/g, ''))
        } else if (isKilometers) {
          // On ne garde que les chiffres
          const regexOnlyNumbers = newValue.target.value.replace(/\D/g, '')
          setFieldValue(name, regexOnlyNumbers)
        } else {
          handleChange(newValue)
        }
      }}
      title={i18n.t(`screens.fleet.forms.${name}`)}
      value={!!value ? (isKilometers ? `${value} Km` : value) : ''}
      error={error}
      mandatory={mandatory}
      placeholder={placeholder || i18n.t('screens.incident.form.placeholder.descriptionPlaceholder')}
      maxLength={maxLength}
      defaultHeight={27}
      isKilometers={!!isKilometers}
    />
  )

  const renderRadioButton = (
    name: string,
    options: RadioOption[],
    onSelectedOption: (opt: RadioOption) => void,
    selectedOption?: RadioOption,
    mandatory?: boolean,
    marginRight?: number
  ) => (
    <FormRadioButton
      title={i18n.t(`screens.fleet.forms.${name}.title`)}
      selectedOption={selectedOption}
      onOptionSelected={onSelectedOption}
      options={options}
      mandatory={mandatory}
      marginRight={marginRight}
    />
  )

  const renderDatePicker = (
    name: string,
    mandatory: boolean,
    value: Date,
    onchange: (val: Date) => void,
    maxDate?: Date
  ) => {
    const openDatePicker = () =>
      Modal.open(() => {
        return (
          <CustomDatePicker
            selectedDate={value}
            onChange={onchange}
            minDate={NOW}
            maxDate={maxDate}
            closePicker={() => Modal.close()}
          />
        )
      })

    return (
      <ItemContainer minWidth={200}>
        <InputCategoryTitle mandatory={mandatory}>{i18n.t(`screens.fleet.forms.${name}`)}</InputCategoryTitle>
        <InputValueContainer onClick={openDatePicker}>
          <InputValue>
            {i18n.t('screens.room.reservationDate', {
              date: value,
            })}
          </InputValue>
          <IconContainer>
            <Icons name="chevron_bottom" color={Theme.colors.middleGrey} size={15} />
          </IconContainer>
        </InputValueContainer>
      </ItemContainer>
    )
  }

  const renderDirectory = (
    name: string,
    mandatory: boolean,
    onchange: (val: UserData) => void,
    value?: UserData,
    withDefaultValue?: boolean,
    engieDirectory?: boolean
  ) => {
    const openDirectory = () =>
      Modal.open(() => (
        <Directory
          title={i18n.t(`screens.fleet.forms.${name}`)}
          onSelectContact={(newUserData) => {
            onchange(newUserData)
            Modal.close()
          }}
          engieDirectory={engieDirectory}
        />
      ))
    return (
      <ItemContainer minWidth={300}>
        <InputCategoryTitle mandatory={mandatory}>{i18n.t(`screens.fleet.forms.${name}`)}</InputCategoryTitle>
        <InputValueContainer onClick={openDirectory}>
          <InputValue isPlaceholder={!value && !withDefaultValue}>
            {!value
              ? withDefaultValue
                ? `${user?.firstName} ${user?.lastName}`
                : i18n.t('screens.guest.searchContact')
              : `${value.firstname || ''} ${value.lastname}`}
          </InputValue>
          <IconContainer>
            <Icons name="chevron_bottom" color={Theme.colors.middleGrey} size={15} />
          </IconContainer>
        </InputValueContainer>
      </ItemContainer>
    )
  }

  const renderChoiceSelector = (
    name: string,
    options: TypeVehicleDuration[] | TypeDocument[],
    mandatory: boolean,
    onchange: (val: string) => void,
    value?: string
  ) => {
    const openChoiceSelector = () =>
      Modal.open(() => (
        <ReservationTimeModal
          title={i18n.t(`screens.fleet.forms.${name}.title`)}
          options={options}
          setChoice={(newChoice) => onchange(newChoice)}
          typeModal="modal"
          type="fleet"
          formName={name}
        />
      ))

    return (
      <ItemContainer minWidth={200}>
        <InputCategoryTitle mandatory={mandatory}>{i18n.t(`screens.fleet.forms.${name}.title`)}</InputCategoryTitle>
        <InputValueContainer onClick={openChoiceSelector}>
          <InputValue isPlaceholder={!value}>
            {i18n.t(`screens.fleet.forms.${name}.${value || 'placeholder'}`)}
          </InputValue>
          <IconContainer>
            <Icons name="chevron_bottom" color={Theme.colors.middleGrey} size={15} />
          </IconContainer>
        </InputValueContainer>
      </ItemContainer>
    )
  }

  const renderAttachmentPicker = (
    name: string,
    mandatory: boolean,
    onChange: (val?: Attachment) => void,
    value?: Attachment
  ) => (
    <LabelAndInputContainer>
      <PhotoLabelContainer>
        <InputLabel mandatory={mandatory}>{i18n.t(`screens.fleet.forms.${name}`)}</InputLabel>
      </PhotoLabelContainer>
      {!!value ? (
        <>
          <PictureContainer>
            <DeleteIcon onClick={() => onChange()}>
              <Icons name="cross" size={20} />
            </DeleteIcon>
            {value.type === 'IMAGE' ? <IncidentPicture src={value.data} /> : <Icons name="news" size={50} />}
          </PictureContainer>
          <AttachmentLabel>{value.name}</AttachmentLabel>
        </>
      ) : (
        <AddPictureButton
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              hiddenFileInput.current.click()
            }
          }}
          tabIndex={0}
          aria-label={i18n.t('label.ariaLabel.incident.uploadPicture')}>
          <Icons name="plus" size={30} />
          <AddPictureInput
            ref={hiddenFileInput}
            type="file"
            accept={[...Object.values(imageMimeTypes), ...Object.values(documentMimeTypesIntervention)].join(',')}
            onChange={(evt) => onUploadFile(evt, (val) => onChange(val))}
          />
        </AddPictureButton>
      )}
    </LabelAndInputContainer>
  )

  const renderDropdownList = (
    name: string,
    mandatory: boolean,
    options: DropDownOption[],
    onChange: (val?: DropDownOption) => void,
    value?: DropDownOption,
    placeholder?: string
  ) => (
    <ItemContainer minWidth={200}>
      <InputCategoryTitle mandatory={mandatory}>{name}</InputCategoryTitle>
      <DropDownContainer>
        <Select
          value={value}
          placeholder={placeholder}
          noOptionsMessage={() => i18n.t('screens.fleet.noResults')}
          onChange={(newValue) => {
            if (!!newValue) {
              onChange(newValue)
            }
          }}
          options={options}
          styles={{
            singleValue: (base, props) => ({
              ...base,
              fontSize: 16,
              fontFamily: 'Lato',
              fontWeight: 900,
              color: Theme.colors.blue,
            }),
            option: (base, props) => ({
              ...base,
              fontSize: 16,
              fontFamily: 'Lato',
              fontWeight: 900,
            }),
            control: (base, props) => ({
              ...base,
              borderColor: 'transparent',
            }),
            placeholder: (base, props) => ({
              ...base,
              fontSize: 16,
              fontFamily: 'Lato',
              fontWeight: 400,
              color: Theme.colors.middleGrey,
            }),
            noOptionsMessage: (base, props) => ({
              ...base,
              fontSize: 16,
              fontFamily: 'Lato',
              fontWeight: 400,
            }),
            input: (base, props) => ({
              ...base,
              fontSize: 16,
              fontFamily: 'Lato',
              fontWeight: 400,
            }),
          }}
        />
      </DropDownContainer>
    </ItemContainer>
  )

  const renderForms = () => {
    if (!formSelected) {
      return null
    }
    if (formSelected.type === 'type1' || formSelected.type === 'type2') {
      return (
        <>
          <CategoryFormContainer>
            {renderTextInput(
              'userDeliveringVehicle',
              true,
              values.userDeliveringVehicle,
              i18n.t(`screens.fleet.forms.userDeliveringVehiclePlaceholder`)
            )}
            {renderTextInput(
              'userDeliveringVehiclePhoneNumber',
              true,
              values.userDeliveringVehiclePhoneNumber,
              i18n.t(`screens.fleet.forms.userDeliveringVehiclePhoneNumberPlaceholder`),
              true
            )}
            {renderDirectory(
              'responsibleVehicleUser',
              true,
              (newUserData) => setFieldValue('responsibleVehicleUser', newUserData),
              values.responsibleVehicleUser
            )}
          </CategoryFormContainer>
          <CategoryFormContainer>
            {renderDropdownList(
              i18n.t(`screens.fleet.forms.vehicleLocation`),
              true,
              vehicleLocationList,
              (newValue) => setFieldValue('vehicleLocation', newValue),
              values.vehicleLocation,
              i18n.t(`screens.fleet.forms.vehicleLocationPlaceholder`)
            )}
            {renderDropdownList(
              i18n.t(`screens.fleet.forms.vehicleLicensePlate`),
              true,
              vehicleLicensePlateList,
              (newValue) => setFieldValue('vehicleLicensePlate', newValue),
              values.vehicleLicensePlate,
              i18n.t(`screens.fleet.forms.vehicleLicensePlatePlaceholder`)
            )}
            {formSelected.type === 'type1' &&
              renderTextInput(
                'currentVehicleKilometers',
                true,
                values.currentVehicleKilometers,
                i18n.t(`screens.fleet.forms.currentVehicleKilometersPlaceholder`),
                false,
                true
              )}
          </CategoryFormContainer>
          <CategoryFormContainer>
            {formSelected.type === 'type2' &&
              renderRadioButton(
                'repair',
                TYPE_REPAIR.map((type, index) => ({
                  id: `${index}`,
                  label: i18n.t(`screens.fleet.forms.repair.${type}`),
                })),
                (opt) => setFieldValue('repair', opt),
                values.repair,
                true
              )}
            {formSelected.type === 'type2' &&
              renderTextInput(
                'vehicleReasonProblem',
                true,
                values.vehicleReasonProblem,
                i18n.t(`screens.fleet.forms.vehicleReasonProblemPlaceholder`)
              )}
          </CategoryFormContainer>
          <CategoryFormContainer>
            {formSelected.type === 'type1' &&
              renderDatePicker(
                'technicalRevisionExpirationDate',
                true,
                values.technicalRevisionExpirationDate,
                (newDate) => setFieldValue('technicalRevisionExpirationDate', newDate)
              )}
            {formSelected.type === 'type1' &&
              renderDatePicker('vehicleWithdrawalDate', true, values.vehicleWithdrawalDate, (newDate) =>
                setFieldValue('vehicleWithdrawalDate', newDate)
              )}
            {formSelected.type === 'type1' &&
              renderTextInput(
                'additionalCorrectiveAction',
                true,
                values.additionalCorrectiveAction,
                i18n.t(`screens.fleet.forms.additionalCorrectiveActionPlaceholder`)
              )}
          </CategoryFormContainer>
          <CategoryFormContainer>
            {formSelected.type === 'type2' &&
              renderDatePicker('vehicleWithdrawalDate', true, values.vehicleWithdrawalDate, (newDate) =>
                setFieldValue('vehicleWithdrawalDate', newDate)
              )}
            {renderRadioButton(
              'onSiteVehicleRemoval',
              TYPE_RADIO_BUTTON_BOOLEAN.map((type, index) => ({ id: `${index}`, label: i18n.t(`common.${type}`) })),
              (opt) => setFieldValue('onSiteVehicleRemoval', opt),
              values.onSiteVehicleRemoval,
              true
            )}
            {renderRadioButton(
              'replacementVehicleRequired',
              TYPE_RADIO_BUTTON_BOOLEAN.map((type, index) => ({ id: `${index}`, label: i18n.t(`common.${type}`) })),
              (opt) => setFieldValue('replacementVehicleRequired', opt),
              values.replacementVehicleRequired,
              true
            )}
          </CategoryFormContainer>
        </>
      )
    }
    if (
      formSelected.type === 'type3' ||
      formSelected.type === 'type4' ||
      formSelected.type === 'type5' ||
      formSelected.type === 'type6' ||
      formSelected.type === 'type7'
    ) {
      return (
        <>
          <CategoryFormContainer>
            {renderTextInput(
              'userDeliveringVehicle',
              true,
              values.userDeliveringVehicle,
              i18n.t(`screens.fleet.forms.userDeliveringVehiclePlaceholder`)
            )}
            {(formSelected.type === 'type6' || formSelected.type === 'type7') &&
              renderTextInput(
                'applicantUserPhoneNumber',
                true,
                values.applicantUserPhoneNumber,
                i18n.t(`screens.fleet.forms.applicantUserPhoneNumberPlaceholder`),
                true
              )}
            {renderDirectory(
              'responsibleVehicleUser',
              true,
              (newUserData) => setFieldValue('responsibleVehicleUser', newUserData),
              values.responsibleVehicleUser
            )}
          </CategoryFormContainer>
          <CategoryFormContainer>
            {(formSelected.type === 'type3' || formSelected.type === 'type6') &&
              renderDropdownList(
                i18n.t(`screens.fleet.forms.${formSelected.type === 'type6' ? 'card' : 'vehicle'}DeliveryLocation`),
                true,
                vehicleLocationList,
                (newValue) => setFieldValue('vehicleLocation', newValue),
                values.vehicleLocation,
                i18n.t(`screens.fleet.forms.vehicleLocationPlaceholder`)
              )}
            {(formSelected.type === 'type4' ||
              formSelected.type === 'type5' ||
              formSelected.type === 'type6' ||
              formSelected.type === 'type7') &&
              renderDropdownList(
                i18n.t(`screens.fleet.forms.vehicleLicensePlate`),
                true,
                vehicleLicensePlateList,
                (newValue) => setFieldValue('vehicleLicensePlate', newValue),
                values.vehicleLicensePlate,
                i18n.t(`screens.fleet.forms.vehicleLicensePlatePlaceholder`)
              )}
            {(formSelected.type === 'type3' || formSelected.type === 'type4' || formSelected.type === 'type6') &&
              renderDropdownList(
                i18n.t(`screens.fleet.forms.ocl`),
                true,
                oclList,
                (newValue) => setFieldValue('ocl', newValue),
                values.ocl,
                i18n.t(`screens.fleet.forms.oclPlaceholder`)
              )}
            {formSelected.type === 'type7' &&
              renderTextInput('rut', true, values.rut, i18n.t(`screens.fleet.forms.rutPlaceholder`))}
          </CategoryFormContainer>
          <CategoryFormContainer>
            {formSelected.type === 'type3' &&
              renderDatePicker('vehicleNeededDate', true, values.vehicleNeededDate, (newDate) =>
                setFieldValue('vehicleNeededDate', newDate)
              )}
            {formSelected.type === 'type4' &&
              renderDatePicker('equipmentNeededDate', true, values.equipmentNeededDate, (newDate) =>
                setFieldValue('equipmentNeededDate', newDate)
              )}
            {formSelected.type === 'type3' &&
              renderChoiceSelector(
                'vehicleDuration',
                TYPE_VEHICLE_DURATION,
                true,
                (newValue) => setFieldValue('vehicleDuration', newValue),
                values.vehicleDuration
              )}
            {formSelected.type === 'type3' &&
              renderRadioButton(
                'vehicleTraction',
                TYPE_TRACTION.map((type, index) => ({
                  id: `${index}`,
                  label: i18n.t(`screens.fleet.forms.vehicleTraction.${type}`),
                })),
                (opt) => setFieldValue('vehicleTraction', opt),
                values.vehicleTraction,
                true
              )}
            {formSelected.type === 'type4' &&
              renderRadioButton(
                'onSiteVehicleRemoval',
                TYPE_RADIO_BUTTON_BOOLEAN.map((type, index) => ({ id: `${index}`, label: i18n.t(`common.${type}`) })),
                (opt) => setFieldValue('onSiteVehicleRemoval', opt),
                values.onSiteVehicleRemoval,
                true
              )}
            {formSelected.type === 'type4' &&
              !!values.onSiteVehicleRemoval &&
              values.onSiteVehicleRemoval.id === '0' &&
              renderDropdownList(
                i18n.t(`screens.fleet.forms.vehicleDeliveryLocation`),
                true,
                vehicleLocationList,
                (newValue) => setFieldValue('vehicleLocation', newValue),
                values.vehicleLocation,
                i18n.t(`screens.fleet.forms.vehicleLocationPlaceholder`)
              )}
          </CategoryFormContainer>
          <CategoryFormContainer>
            {(formSelected.type === 'type3' || formSelected.type === 'type4') &&
              renderTextInput(
                'equipmentsAdditional',
                true,
                values.equipmentsAdditional,
                i18n.t(`screens.fleet.forms.equipmentsAdditionalPlaceholder`)
              )}
            {formSelected.type === 'type5' &&
              renderChoiceSelector(
                'typeDocument',
                TYPE_DOCUMENT,
                true,
                (newValue) => setFieldValue('typeDocument', newValue),
                values.typeDocument
              )}
            {formSelected.type === 'type5' &&
              values.typeDocument === 'OTHERS' &&
              renderTextInput(
                'otherInfos',
                true,
                values.otherInfos,
                i18n.t(`screens.fleet.forms.otherInfosPlaceholder`)
              )}
            {formSelected.type === 'type6' &&
              renderDropdownList(
                i18n.t(`screens.fleet.forms.applicantManagement`),
                true,
                applicantManagementList,
                (newValue) => setFieldValue('applicantManagement', newValue),
                values.applicantManagement,
                i18n.t(`screens.fleet.forms.applicantManagementPlaceholder`)
              )}
            {formSelected.type === 'type6' &&
              renderRadioButton(
                'cardRequestType',
                TYPE_CARD_REQUEST.map((type, index) => ({
                  id: `${index}`,
                  label: i18n.t(`screens.fleet.forms.cardRequestType.${type}`),
                })),
                (opt) => setFieldValue('cardRequestType', opt),
                values.cardRequestType,
                true,
                10
              )}
          </CategoryFormContainer>
        </>
      )
    }
    if (formSelected.type === 'type8' || formSelected.type === 'type9' || formSelected.type === 'type10') {
      return (
        <>
          <CategoryFormContainer>
            {renderTextInput(
              'userInvolvedAccident',
              true,
              values.userInvolvedAccident,
              i18n.t(`screens.fleet.forms.userInvolvedAccidentPlaceholder`)
            )}
            {renderDirectory(
              'responsibleVehicleUser',
              true,
              (newUserData) => setFieldValue('responsibleVehicleUser', newUserData),
              values.responsibleVehicleUser
            )}
          </CategoryFormContainer>
          <CategoryFormContainer>
            {renderDropdownList(
              i18n.t(`screens.fleet.forms.vehicleLicensePlate`),
              true,
              vehicleLicensePlateList,
              (newValue) => setFieldValue('vehicleLicensePlate', newValue),
              values.vehicleLicensePlate,
              i18n.t(`screens.fleet.forms.vehicleLicensePlatePlaceholder`)
            )}
            {formSelected.type === 'type10' &&
              renderTextInput(
                'thirdPartyVehiclePlateNumber',
                true,
                values.thirdPartyVehiclePlateNumber,
                i18n.t(`screens.fleet.forms.thirdPartyVehiclePlateNumberPlaceholder`)
              )}
            {renderTextInput(
              'claimNumber',
              true,
              values.claimNumber,
              i18n.t(`screens.fleet.forms.claimNumberPlaceholder`)
            )}
          </CategoryFormContainer>
          <CategoryFormContainer>
            {renderAttachmentPicker(
              'claimDeclarationAttachment',
              true,
              (newAttachment) => setFieldValue('claimDeclarationAttachment', newAttachment),
              values.claimDeclarationAttachment
            )}
            {(formSelected.type === 'type8' || formSelected.type === 'type10') &&
              renderAttachmentPicker(
                'complaintProofAttachment',
                true,
                (newAttachment) => setFieldValue('complaintProofAttachment', newAttachment),
                values.complaintProofAttachment
              )}
            {formSelected.type === 'type8' &&
              renderAttachmentPicker(
                'policeStatementAttachment',
                true,
                (newAttachment) => setFieldValue('policeStatementAttachment', newAttachment),
                values.policeStatementAttachment
              )}
            {renderAttachmentPicker(
              'identityCardAttachment',
              true,
              (newAttachment) => setFieldValue('identityCardAttachment', newAttachment),
              values.identityCardAttachment
            )}
            {renderAttachmentPicker(
              'driverLicenseAttachment',
              true,
              (newAttachment) => setFieldValue('driverLicenseAttachment', newAttachment),
              values.driverLicenseAttachment
            )}
          </CategoryFormContainer>
        </>
      )
    }
    return null
  }

  const renderDatesRequests = (requests: RequestByDate) => (
    <GridCellContainer key={requests.date}>
      <DateTitle>{i18n.t('screens.incident.requestDate', { date: new Date(requests.date) })}</DateTitle>
      {requests.interventions.map((intervention, i, interventions) => (
        <UserRequestInfo
          key={intervention.id}
          intervention={intervention}
          isLast={interventions.length === i + 1}
          refreshIncidents={getUserRequests}
          floors={floors}
        />
      ))}
    </GridCellContainer>
  )

  return (
    <MainContainer>
      <TitleHelmet title={i18n.t('screens.fleet.title')} />
      <Breadcrumb screen="fleet" path="fleet" navigation={navigation} />
      {status === 'loading' || floorsStatus === 'loading' || vehicleStatus === 'loading' ? (
        <LoaderContainer>
          <Loader type="TailSpin" color={Theme.colors.blue} />
        </LoaderContainer>
      ) : status === 'error' || floorsStatus === 'error' || vehicleStatus === 'error' ? (
        <ErrorContainer>
          <ErrorText>{i18n.t('screens.fleet.error')}</ErrorText>
        </ErrorContainer>
      ) : (
        <>
          <TopContainer>
            <TitleAlignContainer>
              <BackContainer
                formIsSelected={!!formSelected}
                onClick={() => {
                  if (!!formSelected) {
                    if (dirty) {
                      Modal.open(() => (
                        <ValidationContent
                          title={i18n.t('screens.fleet.aboutToLeave')}
                          description={i18n.t('screens.incident.wontSave')}
                          onConfirm={() => {
                            formStore.actions.reset()
                            Modal.close()
                            resetForm()
                            setFormSelected(undefined)
                          }}
                          cancelButton={true}
                          font={Theme.fonts.label}
                        />
                      ))
                    } else {
                      resetForm()
                      setFormSelected(undefined)
                    }
                  }
                }}>
                {!!formSelected && <Icons name="chevron_left" size={24} color={Theme.colors.blue} />}

                <Title bold paddingLeft={!!formSelected ? 4 : 0}>
                  {i18n.t('screens.fleet.newRequest')} {!!formSelected ? ' :' : ''}
                </Title>
                {!!formSelected && (
                  <Title paddingLeft={4}>{i18n.t(`screens.fleet.forms.${formSelected.type}.title`)}</Title>
                )}
              </BackContainer>
            </TitleAlignContainer>
            <>
              {!!formSelected && (
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={{
                    hidden: { opacity: 0, y: -50 },
                    visible: { opacity: 1, y: 0 },
                  }}
                  transition={{ duration: 0.5, ease: 'easeOut' }}>
                  {(formSelected.type === 'type8' ||
                    formSelected.type === 'type9' ||
                    formSelected.type === 'type10') && (
                    <DescriptionForm>{i18n.t('screens.fleet.forms.description')}</DescriptionForm>
                  )}
                  <FormContainer
                    marginTop={
                      formSelected.type === 'type8' || formSelected.type === 'type9' || formSelected.type === 'type10'
                        ? 0
                        : 20
                    }>
                    {renderForms()}
                  </FormContainer>
                  <ButtonContainer>
                    <Button
                      noFlex
                      type="submit"
                      label={i18n.t('screens.incident.submitDemand')}
                      verticalPadding={14}
                      horizontalPadding={100}
                      font={Theme.fonts.h3Bold}
                      onClick={handleSubmit}
                      disabled={!isFormValid || isSendingForm}
                      loading={isSendingForm}
                    />
                  </ButtonContainer>
                </motion.div>
              )}
              {!formSelected && (
                <motion.div
                  initial={{ opacity: 0, scale: 0.5, x: -800 }}
                  animate={{ opacity: 1, scale: 1, x: 0 }}
                  transition={{ duration: 0.5 }}>
                  <ButtonsContainer>
                    <ListContainer>{FIRST_LIST.map(renderButton)}</ListContainer>
                    <ListContainer>{SECOND_LIST.map(renderButton)}</ListContainer>
                    <ListContainer>{THIRD_LIST.map(renderButton)}</ListContainer>
                  </ButtonsContainer>
                </motion.div>
              )}
            </>
          </TopContainer>
          <BottomContainer isEmpty={!!myInterventions && myInterventions.length === 0}>
            <Title bold paddingLeft={0}>
              {i18n.t('screens.fleet.yourRequests')}
            </Title>

            {myInterventions && myInterventions.length > 0 ? (
              <GridContainer>{requestsSortedByDate && requestsSortedByDate.map(renderDatesRequests)}</GridContainer>
            ) : (
              <EmptyListContainer>
                <EmptyText>{i18n.t('screens.incident.emptyRequests')}</EmptyText>
                <EmptyImageContainer>
                  <EmptyImageplaceHolder src={EmptyImagePlaceholder} aria-hidden />
                </EmptyImageContainer>
              </EmptyListContainer>
            )}
          </BottomContainer>
        </>
      )}
    </MainContainer>
  )
}

export default FleetScreen

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 116px);
  overflow: hidden;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-left: 0px;
    width: 100%;
  }
`

const TopContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 50px 80px 0px;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    margin: 40px 30px 0px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin: 30px 20px 0px;
  }
`

const BottomContainer = styled('div')<{ isEmpty: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  padding: 40px 0px 60px 80px;
  ${(props) => props.isEmpty && 'min-height: 480px'};
  background-color: ${(props) => props.theme.colors.mediumLightGrey};
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    padding: 40px 30px 60px 50px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    padding-left: 30px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 20px;
  }
`

const TitleAlignContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    align-items: flex-start;
  }
`

const BackContainer = styled('div')<{ formIsSelected: boolean }>`
  display: flex;
  flex-direction: row;
  ${(props) => props.formIsSelected && 'cursor: pointer;'}
`

const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`

const Title = styled('h1')<{ bold?: boolean; paddingLeft: number }>`
  ${(props) => (!!props.bold ? props.theme.fonts.title : props.theme.fonts.thinTitle)};
  margin: 0px;
  padding-left: ${(props) => props.paddingLeft}px;
`

const ButtonsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 30px 0px 40px;
`

const ListContainer = styled('div')`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const ButtonContainer = styled('div')`
  margin-right: 20px;
  margin-bottom: 20px;
`

const FormContainer = styled('div')<{ marginTop: number }>`
  display: flex;
  margin-top: ${(props) => props.marginTop}px;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 40px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-top: 35px;
    gap: 10px;
  }
`

const CategoryFormContainer = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px 50px;
`

const ItemContainer = styled('div')<{ minWidth?: number; noMarginTop?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  ${(props) => props.noMarginTop && 'margin-top: 0px;'}
  max-width: 400px;
  min-width: ${(props) => (props.minWidth ? props.minWidth : '300')}px;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    min-width: 100%;
  }
`

const IconContainer = styled('div')`
  padding-left: 10px;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const InputCategoryTitle = styled('label')<{ mandatory?: boolean }>`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
  ${(props) =>
    props.mandatory &&
    `
    ::after {
      color: ${props.theme.colors.red};
      content: "*";
      position: relative;
      left: 4px;
    }
  `}
`

const InputValueContainer = styled('button')`
  display: flex;
  flex: 1;
`

const InputValue = styled('p')<{ isPlaceholder?: boolean }>`
  ${(props) => (props.isPlaceholder ? props.theme.fonts.body : props.theme.fonts.bodyBold)};
  color: ${(props) => (props.isPlaceholder ? props.theme.colors.middleGrey : props.theme.colors.blue)};
  display: flex;
  flex: 1;
`

const DescriptionForm = styled('p')`
  ${(props) => props.theme.fonts.labelBold};
  margin: 20px 0px 40px;
`

const LabelAndInputContainer = styled('div')`
  display: flex;
  flex-direction: column;
  min-width: 350px;
  max-width: 450px;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    min-width: 100%;
  }
`
const PhotoLabelContainer = styled('div')`
  display: flex;
  align-items: center;
  margin: 10px 0px;
`

const PictureContainer = styled('div')`
  display: flex;
  position: relative;
  width: fit-content;
`
const DeleteIcon = styled('div')`
  display: flex;
  position: absolute;
  top: -10px;
  right: -10px;
  height: 25px;
  width: 25px;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 0px 5px 1px ${(props) => props.theme.colors.shadow};
`

const AttachmentLabel = styled('label')`
  width: 100%;
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
  padding: 10px 0px;
  text-overflow: ellipsis;
`

const AddPictureButton = styled('label')`
  width: 58px;
  height: 58px;
  background-color: ${(props) => props.theme.colors.blue10p};
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
  :focus {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`

const AddPictureInput = styled('input')`
  display: none;
`

const IncidentPicture = styled('img')`
  max-width: 100%;
  object-fit: cover;
`

const InputLabel = styled('label')<{ mandatory?: boolean }>`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
  ${(props) =>
    props.mandatory &&
    `
    ::after {
      color: ${props.theme.colors.red};
      content: "*";
      position: relative;
      left: 4px;
    }
  `}
`

const ErrorContainer = styled('div')`
  padding: 30px 50px;
  min-height: calc(100vh - 176px);
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 10px 10px;
    min-height: calc(100vh - 136px);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const ErrorText = styled('p')`
  ${(props) => props.theme.fonts.body};
  font-size: 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    text-align: center;
  }
`

const DropDownContainer = styled('div')`
  margin-top: 12px;
`

const GridContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    display: flex;
    flex-direction: column;
    max-width: 90vw;
  }
`

const EmptyListContainer = styled('div')`
  display: flex;
  flex-direction: column;
`
const EmptyImageContainer = styled('div')`
  position: absolute;
  top: 0;
  left: calc(50% - 339px);
  display: flex;
  align-items: center;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    left: 40px;
    right: 40px;
  }
`

const EmptyImageplaceHolder = styled(SVG)`
  object-fit: fill;
  width: 678px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    width: 100%;
  }
`

const EmptyText = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
  margin-top: 19px;
`

const GridCellContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 400px;
  max-width: calc(100vw - 40px);
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.13);
`

const DateTitle = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
  margin: 0px;
  padding: 8px 0px 7px 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.mediumDarkGrey};
`
