import React from 'react'
import styled from 'styled-components'
import useTheme from 'theme/useTheme'

import { TagName } from './Map'

import { RouteResult, InstructionData } from './visioweb'
import { ItineraryPoint } from './Map'
import { ItineraryProps } from './useMap'

import useReducer from 'store/useReducer'
import * as mapStore from './mapStore'

import useI18n from 'i18n/useI18n'
import Logger from 'utils/Logger'

import { windowSizeBreakpoints } from 'utils/breakpoints'

import Icons from 'components/icons/Icons'
import Switch from 'components/switch/SwitchFilter'
import Button from 'components/button/Button'
import ItineraryNav from './ItineraryNav'

interface Props {
  setSelectedPoint: (p: ItineraryPoint) => void

  computeItinerary: () => Promise<RouteResult>
  stopItinerary: () => void

  usePMR: boolean
  setUsePMR: (b: boolean) => void
  setSelectedTab: (t: TagName) => void

  // Itinerary
  currentNavStep?: InstructionData
  navStep: number
  navSteps: number
  itineraryInfo?: RouteResult

  navPrevious: () => void
  navNext: () => void
  setItineraryInfo: (i: RouteResult) => void
  itinerary: ItineraryProps
}

const MapItinerary = ({
  setSelectedPoint,
  usePMR,
  setUsePMR,
  setSelectedTab,
  computeItinerary,
  stopItinerary,
  currentNavStep,
  navStep,
  navSteps,
  navPrevious,
  navNext,
  itineraryInfo,
  setItineraryInfo,
  itinerary,
}: Props) => {
  const { from, to, setFrom, setTo, nav } = itinerary
  const [Theme] = useTheme()
  const i18n = useI18n()

  const itineraryStatus = useReducer(mapStore.store, (s) => s.itineraryStatus)

  const reverseItineraryPoints = () => {
    if (from && to) {
      const previousStart = from
      setFrom(to)
      setTo(previousStart)
    }
  }

  const findRoom = (point: 'start' | 'end') => {
    if (itineraryStatus === 'notStarted') {
      setSelectedPoint(point)
      setSelectedTab('searchItinerary')
    }
  }

  const startItinerary = () => {
    computeItinerary()
      .then((res) => {
        if (!!res) {
          mapStore.actions.setItineraryStatus('ready')
          setItineraryInfo(res)
        } else {
          mapStore.actions.setItineraryStatus('error')
        }
      })
      .catch((err) => {
        Logger.error(err)
        mapStore.actions.setItineraryStatus('error')
      })
  }

  const findStartRoom = () => findRoom('start')

  const findEndRoom = () => findRoom('end')

  return (
    <MainContainer>
      <TitleContainer>
        <ItineraryTitle>
          {i18n.t(`screens.cartography.${itineraryStatus === 'ready' ? 'itinerary' : 'newItinerary'}`)}
        </ItineraryTitle>

        <IconContainer
          onClick={() => {
            setSelectedTab(undefined)
            setFrom(undefined)
            setTo(undefined)
          }}>
          <Icons name="cross" size={25} color={Theme.colors.blue} />
        </IconContainer>
      </TitleContainer>

      <ItineraryContainer>
        {itineraryStatus === 'notStarted' ? (
          <>
            <ItineraryPlaceContainer>
              <PointTitle>{i18n.t(`screens.cartography.start`)}</PointTitle>
              <PointPlace filled={!!from} onClick={findStartRoom} aria-label={i18n.t('label.ariaLabel.map.startPoint')}>
                {!!from ? from.name : i18n.t('screens.cartography.startPlace')}
              </PointPlace>
            </ItineraryPlaceContainer>

            <ReverseBreakContainer>
              <BlueLineBreak role="presentation" />
              <ArrowUpDownContainer
                onClick={reverseItineraryPoints}
                aria-label={i18n.t('label.ariaLabel.map.reversePoints')}>
                <Icons name="arrow_updown" size={25} color={Theme.colors.blue} />
              </ArrowUpDownContainer>
            </ReverseBreakContainer>

            <ItineraryPlaceContainer>
              <PointTitle>{i18n.t(`screens.cartography.end`)}</PointTitle>
              <PointPlace filled={!!to} onClick={findEndRoom} aria-label={i18n.t('label.ariaLabel.map.endPoint')}>
                {!!to ? to.name : i18n.t('screens.cartography.endPlace')}
              </PointPlace>
            </ItineraryPlaceContainer>
            <SwitchContainer>
              <Switch value={usePMR} setValue={setUsePMR} label={i18n.t('screens.cartography.PMRItinerary')} />
            </SwitchContainer>
          </>
        ) : itineraryStatus === 'ready' ? (
          itineraryInfo &&
          nav && (
            <ItineraryNav
              itineraryInfo={itineraryInfo}
              current={currentNavStep}
              step={navStep}
              steps={navSteps}
              previous={navPrevious}
              next={navNext}
            />
          )
        ) : (
          <ErrorMessage>{i18n.t('errors.map.itineraryError')}</ErrorMessage>
        )}

        <BottomContainer>
          <ButtonContainer>
            <Button
              label={i18n.t(
                `screens.cartography.${itineraryStatus !== 'notStarted' ? 'stopItinerary' : 'startItinerary'}`
              )}
              onClick={itineraryStatus !== 'notStarted' ? stopItinerary : startItinerary}
              font={Theme.fonts.h3Bold}
              disabled={itineraryStatus !== 'ready' && (itineraryStatus === 'loading' || !from || !to)}
              loading={itineraryStatus === 'loading'}
            />
          </ButtonContainer>
        </BottomContainer>
      </ItineraryContainer>
    </MainContainer>
  )
}

export default MapItinerary

// CONTAINERS

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 0px 20px 0px 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-top: 20px;
    height: calc(100% - 20px);
  }
`
const TitleContainer = styled('div')`
  display: flex;
  align-items: center;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    justify-content: space-between;
  }
`
const IconContainer = styled('div')`
  display: none;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    display: flex;
  }
`
const ItineraryContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    height: 100%;
  }
`
const ItineraryPlaceContainer = styled('div')`
  margin: 20px 0px 12px 0px;
`
const ReverseBreakContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 30px;
`
const ArrowUpDownContainer = styled('button')`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border: 1px solid ${(props) => props.theme.colors.blue};
  border-radius: 24px;
  background: transparent;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const SwitchContainer = styled('div')`
  margin: 11px 0px 23px -6px;
`
const ButtonContainer = styled('div')`
  display: flex;
  margin-top: 8px;
  height: 50px;
  width: 100%;
`
const BottomContainer = styled('div')`
  display: flex;
  flex: 1;
  align-items: flex-end;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-bottom: 40px;
  }
`

// TEXTES

const ItineraryTitle = styled('h1')`
  ${(props) => props.theme.fonts.bodyBold};
  font-size: 18px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.blue};
  margin: 0px;
`
const PointTitle = styled('h2')`
  ${(props) => props.theme.fonts.bodyBold};
  margin: 0px;
`
const PointPlace = styled('button')<{ filled: boolean }>`
  cursor: pointer;
  ${(props) => (props.filled ? props.theme.fonts.bodyBold : props.theme.fonts.body)};
  color: ${(props) => props.theme.colors.middleGrey};
  margin-left: -6px;
  padding: 8px 6px 8px 6px;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const ErrorMessage = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
`

// AUTRES

const BlueLineBreak = styled.div`
  width: 60px;
  height: 3px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.blue};
`
