import * as React from 'react'
import styled from 'styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'
import { getColorFromStatus } from 'intervention/utils'

import Icons from 'components/icons/Icons'

interface Props {
  request: IncidentV6
  isLast: boolean
}

const RoomRequestInfo = ({ request, isLast }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()

  return (
    <div>
      <MainContainer>
        <CategoryTitle>{request.category}</CategoryTitle>
        <RegularText>{request.subcategory}</RegularText>

        <RoomInfoContainer>
          <IconTextContainer>
            <StatusIndicator statusColor={getColorFromStatus(request.status)} />
            <StatusText statusColor={getColorFromStatus(request.status)}>
              {i18n.t([`screens.incident.status.${request.status}`, 'screens.incident.status.UNKNOWN'])}
            </StatusText>
          </IconTextContainer>

          <IconTextContainer>
            <IconContainer>
              <Icons name="agenda" size={15} color={Theme.colors.blue} />
            </IconContainer>
            <RegularText>{i18n.t('screens.incident.requestTime', { date: new Date(request.date) })}</RegularText>
          </IconTextContainer>
        </RoomInfoContainer>
      </MainContainer>
      {!isLast && <BlueLineBreak role="presentation" />}
    </div>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
`
const RoomInfoContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`
const IconTextContainer = styled('div')`
  display: flex;
  align-items: center;
  margin: 1px 0px;
`
const IconContainer = styled('div')`
  margin-right: 5px;
`

// TEXTES

const CategoryTitle = styled('h1')`
  ${(props) => props.theme.fonts.h3Bold};
  margin: 0px;
`
const RegularText = styled('p')`
  ${(props) => props.theme.fonts.label};
  margin: 0px;
`
const StatusText = styled('p')<{ statusColor: string }>`
  ${(props) => props.theme.fonts.label};
  margin: 0px;
  color: ${(props) => props.theme.colors[props.statusColor]};
`

// AUTRES

const BlueLineBreak = styled('div')`
  width: 60px;
  height: 3px;
  background-color: ${(props) => props.theme.colors.blue};
  border-radius: 2px;
`
const StatusIndicator = styled('div')<{ statusColor: string }>`
  width: 4px;
  height: 4px;
  background-color: ${(props) => props.theme.colors[props.statusColor]};
  border-radius: 2px;
  margin: 0px 5px;
`

export default RoomRequestInfo
