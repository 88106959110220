import * as React from 'react'

import { windowSizeBreakpoints } from './breakpoints'

type KeyBreakpoint = keyof typeof windowSizeBreakpoints

const useBreakpoint = () => {
  const [windowSize, setWindowSize] = React.useState(0)
  const [currentBreakpoint, setCurrentBreakpoint] = React.useState<KeyBreakpoint>('phone')

  const onSizeChange: EventListener = () => {
    setWindowSize(window.innerWidth)
  }

  React.useEffect(() => {
    setWindowSize(window.innerWidth)
    window.addEventListener('resize', onSizeChange)

    return () => window.removeEventListener('resize', onSizeChange)
  }, [])

  React.useEffect(() => {
    if (windowSize < windowSizeBreakpoints.phone) {
      setCurrentBreakpoint('phone')
    } else if (windowSize > windowSizeBreakpoints.laptop) {
      setCurrentBreakpoint('laptop')
    } else {
      const keys = Object.keys(windowSizeBreakpoints) as KeyBreakpoint[]
      const currentKey = keys.find(
        (b, i) =>
          i > 0 && windowSizeBreakpoints[keys[i - 1]] < windowSize && windowSizeBreakpoints[keys[i]] > windowSize
      )
      setCurrentBreakpoint(currentKey || 'phone')
    }
  }, [windowSize])

  return [windowSize, currentBreakpoint, windowSizeBreakpoints] as const
}
export default useBreakpoint
