import { createEvent, createStore } from 'effector'
import { Language } from 'i18n/types'

export const actions = {
  setLanguage: createEvent<Language>('setLanguage'),
  resetLanguage: createEvent('resetLanguage'),
}

export interface LanguageState {
  preferredLanguage?: Language
}

const initialState: LanguageState = {
  preferredLanguage: undefined,
}

export const store = createStore<LanguageState>(initialState, { name: 'Preferred Language' })
  .on(actions.setLanguage, (s, preferredLanguage) => ({ ...s, preferredLanguage }))
  .on(actions.resetLanguage, () => initialState)
