import * as React from 'react'
import styled from 'theme/styled-components'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import config from 'core/src/config'

import useI18n from 'i18n/useI18n'
import useNavigation from 'utils/navigation/useNavigation'
import useTheme from 'theme/useTheme'

import useReducer from 'store/useReducer'
import * as siteStore from 'sites/store/siteStore'
import * as formStore from 'store/form/formStore'
import * as userStore from 'store/user/user'

import analytics, { analyticsKeys } from 'utils/analytics'

import Modal from 'components/modal/Modal'
import ValidationContent from 'components/modal/ValidationContent'

const Footer = () => {
  const navigation = useNavigation()
  const i18n = useI18n()
  const [Theme, name, setTheme] = useTheme()

  const confirmNavigation = useReducer(formStore.store, (s) => s.confirmNavigation)
  const site = useReducer(siteStore.store, (s) => s.site)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)
  const user = useReducer(userStore.store, (s) => s.user)

  const goToCgu = () => confirmLeave(() => navigation.push('/cgu'), confirmNavigation)
  const goToCookieChartPdf = () =>
    confirmLeave(
      () =>
        navigation.push('/pdf', {
          pdfUrl: `cookies_terms_pdf/cookies_terms_${i18n.lang.toUpperCase()}.pdf`,
        }),
      confirmNavigation
    )
  const goToSitemap = () => confirmLeave(() => navigation.push('/sitemap'), confirmNavigation)

  const goToLegalNotices = (url: string) =>
    confirmLeave(() => {
      analytics.track({ screenName: analyticsKeys.legalNotices, currentSite: site, mySites, userType: user?.type })
      window.open(url)
    })

  const confirmLeave = (goToFunction: () => void, confirmNavigation?: boolean) => {
    if (confirmNavigation) {
      Modal.open(() => (
        <ValidationContent
          title={i18n.t('screens.incident.aboutToLeave')}
          description={i18n.t('screens.incident.wontSave')}
          onConfirm={() => {
            formStore.actions.reset()
            Modal.close()
            goToFunction()
          }}
          cancelButton={true}
          font={Theme.fonts.label}
        />
      ))
    } else {
      goToFunction()
    }
  }

  const onEnterKey = (e: React.KeyboardEvent, action: () => void) => {
    if (e.key === 'Enter') {
      action()
    }
  }

  const openCookieBanner = () => (window as any).Cookiebot.renew()

  return (
    <MainContainer>
      <FooterItemsCOntainer>
        <ItemFooter onClick={goToCgu} tabIndex={0} onKeyDown={(e) => onEnterKey(e, goToCgu)}>
          {i18n.t('screens.cgu.title')}
        </ItemFooter>

        {!!site && !!site.legalNotice && (
          <ItemFooter
            onClick={() => goToLegalNotices(site.legalNotice!)}
            tabIndex={0}
            onKeyDown={(e) => onEnterKey(e, goToCgu)}>
            {i18n.t('screens.footer.legalNotices')}
          </ItemFooter>
        )}

        <ItemFooter onClick={goToCookieChartPdf} tabIndex={0} onKeyDown={(e) => onEnterKey(e, goToCookieChartPdf)}>
          {i18n.t('screens.footer.cookiesChart')}
        </ItemFooter>

        <ItemFooter onClick={goToSitemap} tabIndex={0} onKeyDown={(e) => onEnterKey(e, goToSitemap)}>
          {i18n.t('screens.sitemap.title')}
        </ItemFooter>

        <ItemFooter onClick={openCookieBanner} tabIndex={0} onKeyDown={(e) => onEnterKey(e, openCookieBanner)}>
          {i18n.t('screens.footer.cookies')}
        </ItemFooter>
      </FooterItemsCOntainer>

      <ContrastContainer>
        <ContrastText>{i18n.t('screens.footer.contrast')}</ContrastText>
        <ContrastSwitchContainer>
          <StandardButton selected={name === 'classic'} onClick={() => setTheme('classic')}>
            <ContrastButtonText selected={name === 'classic'}>{i18n.t('screens.footer.classic')}</ContrastButtonText>
          </StandardButton>

          <ContrastButton selected={name === 'accessible'} onClick={() => setTheme('accessible')}>
            <ContrastButtonText selected={name === 'accessible'}>
              {i18n.t('screens.footer.accessible')}
            </ContrastButtonText>
          </ContrastButton>
        </ContrastSwitchContainer>
      </ContrastContainer>

      <AppVersion>{i18n.t('common.version', { version: config.appVersion })}</AppVersion>
    </MainContainer>
  )
}

export default Footer

const MainContainer = styled('div')`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 56px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.black};
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    left: 0;
    right: 0;
    height: 126px;
  }
`
const FooterItemsCOntainer = styled('div')`
  display: flex;
  align-items: center;
`
const ItemFooter = styled('p')`
  ${(props) => props.theme.fonts.footerText};
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  text-align: center;
  text-decoration: underline;
  margin: 5px 15px;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.white};
  }
  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.colors.blue};
  }
`
const AppVersion = styled('p')`
  ${(props) => props.theme.fonts.label};
  margin: 5px;
  color: ${(props) => props.theme.colors.white};
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    margin: 3px 0px 7px 0px;
  }
`
const ContrastContainer = styled('div')`
  position: absolute;
  display: flex;
  flex-direction: row;
  right: 30px;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    position: initial;
  }
`
const ContrastSwitchContainer = styled('div')`
  margin-left: 10px;
  display: flex;
  flex-direction: row;
`
const ContrastText = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.white};
`
const StandardButton = styled('button')<{ selected?: boolean }>`
  padding: 5px 12px;
  border-radius: 15px 0px 0px 15px;
  border: 1px solid ${(props) => props.theme.colors.white};
  background-color: ${(props) => (props.selected ? props.theme.colors.white : props.theme.colors.black)};
`
const ContrastButton = styled('button')<{ selected?: boolean }>`
  padding: 5px 12px;
  border-radius: 0px 15px 15px 0px;
  border: 1px solid ${(props) => props.theme.colors.white};
  background-color: ${(props) => (props.selected ? props.theme.colors.white : props.theme.colors.black)};
`
const ContrastButtonText = styled('label')<{ selected?: boolean }>`
  ${(props) => props.theme.fonts.subtitleBold};
  color: ${(props) => (props.selected ? props.theme.colors.black : props.theme.colors.white)};
  margin: 0;
`
