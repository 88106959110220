import { createEvent, createStore } from 'effector'

export const actions = {
  setHasPicture: createEvent<{ [key: string]: boolean }>('setHasPictureList'),
}

const initialState: UserPicState = {
  hasPictureList: {},
}

export const store = createStore<UserPicState>(initialState, { name: 'User Profile Picture' }).on(
  actions.setHasPicture,
  (s, userHasPicture) => ({ ...s, hasPictureList: { ...s.hasPictureList, ...userHasPicture } })
)
