import * as React from 'react'
import styled from 'styled-components'
import useTheme from 'theme/useTheme'

import useReducer from 'store/useReducer'
import * as languageStore from 'store/lang/languageStore'

import useI18n from 'i18n/useI18n'
import { windowSizeBreakpoints } from 'utils/breakpoints'

import Button from 'components/button/Button'
import Modal from 'components/modal/Modal'
import Icons from 'components/icons/Icons'
import { Language } from 'i18n/types'

interface Props {
  navigation?: Navigation
  path?: string
  languagesProps?: Language[]
  onSelectConfirm?: (lang: Language) => void
  initialLanguage?: Language
}

export const LANG_LIST: { [key in Language]: string } = {
  fr: 'Français',
  en: 'English',
  de: 'Deutsch',
  es: 'Español',
  pt: 'Português',
  nl: 'Nederlands',
}

const LanguageChoice = ({ navigation, path, languagesProps, onSelectConfirm, initialLanguage }: Props) => {
  const preferredLang = useReducer(languageStore.store, (s) => s.preferredLanguage)
  const i18n = useI18n()
  const [Theme] = useTheme()

  const [currentLang, setCurrentLang] = React.useState(initialLanguage || preferredLang || 'fr')

  const languagesToSelect = React.useMemo(
    () =>
      (Object.entries(LANG_LIST) as [Language, string][])
        .filter((l) => !languagesProps || languagesProps.includes(l[0]))
        .sort((l1, l2) => (l1[1] > l2[1] ? 1 : -1)),
    [languagesProps]
  )

  const onConfirm = () => {
    if (onSelectConfirm) {
      onSelectConfirm(currentLang)
    } else if (navigation && path) {
      navigation.resetTo(`/${currentLang}${path.slice(3)}`)
      Modal.close()
    }
  }

  const renderLang = ([key, value]: [key: Language, value: string]) => {
    const isSelected = currentLang === key

    return (
      <LangButton onClick={() => setCurrentLang(key)} key={key}>
        <LangNameContainer>
          <LangIcon selected={isSelected}>
            <LangCode selected={isSelected}>{key}</LangCode>
          </LangIcon>

          <LangName selected={isSelected} lang={key}>
            {value}
          </LangName>
        </LangNameContainer>

        {isSelected && (
          <IconContainer>
            <Icons color={Theme.colors.blue} name="check-circle" size={31} />
          </IconContainer>
        )}
      </LangButton>
    )
  }

  return (
    <MainContainer>
      <Title>{i18n.t('screens.login.chooseLang')}</Title>

      {languagesToSelect.map(renderLang)}

      <ButtonContainer>
        <Button label={i18n.t('common.confirm')} onClick={onConfirm} verticalPadding={14} font={Theme.fonts.h3Bold} />
      </ButtonContainer>
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  background-color: ${(props) => props.theme.colors.white};
  padding: 40px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 30px;
  }
`
const LangButton = styled('button')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 327px;
  margin-bottom: 5px;
  padding: 9px 0px;
  border: 0px;
  background-color: ${(props) => props.theme.colors.white};
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 280px;
  }
`
const ButtonContainer = styled('div')`
  display: flex;
  width: 327px;
  margin-top: 25px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 280px;
  }
`
const LangNameContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const LangIcon = styled('div')<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.selected ? props.theme.colors.blue : props.theme.colors.mediumDarkGrey)};
  width: 34px;
  height: 34px;
  border-radius: 20px;
  margin-left: 15px;
`
const IconContainer = styled('div')`
  margin-right: 17px;
`

// TEXTES

const Title = styled('h1')`
  ${(props) => props.theme.fonts.title};
  margin: 0px 0px 20px 0px;
  color: ${(props) => props.theme.colors.blue};
`
const LangCode = styled('p')<{ selected: boolean }>`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => (props.selected ? props.theme.colors.white : props.theme.colors.darkGrey)};
  text-transform: uppercase;
`
const LangName = styled('p')<{ selected: boolean }>`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => (props.selected ? props.theme.colors.blue : props.theme.colors.darkGrey)};
  margin: 0px 0px 0px 10px;
`

export default LanguageChoice
