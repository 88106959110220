import * as React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import api from '../api/restaurantsApi'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'

import Icons from 'components/icons/Icons'
import Button from 'components/button/Button'

import Logger from 'utils/Logger'

import useI18n from 'i18n/useI18n'

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import Loader from 'react-loader-spinner'
import { Buffer } from 'buffer'

interface Props {
  item: MrsTransactionV5
  close: () => void
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

const MyMRSReceiptPdf = ({ item, close }: Props) => {
  const [theme] = useTheme()
  const i18n = useI18n()

  const user = useReducer(userStore.store, (s) => s.user)
  const currentSite = useReducer(siteStore.store, (s) => s.site)

  const [callStatus, setCallStatus] = React.useState<'error' | 'success' | 'loading'>('loading')
  const [numberOfPages, setNumberOfPages] = React.useState(0)

  const [pdfArrayBuffer, setPdfArrayBuffer] = React.useState<ArrayBuffer>()

  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions())

  const pageWidth = React.useMemo(() => {
    const widthPercentage = (windowDimensions.width * 90) / 100
    return Math.min(500, widthPercentage)
  }, [windowDimensions])

  React.useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleResize = () => {
    setWindowDimensions(getWindowDimensions())
  }

  React.useEffect(() => {
    if (!!user && !!currentSite) {
      api
        .getReceipt(user.type, currentSite.id, item.ticketNumber, item.date, item.sitePie, item.registerId)
        .then((res) => {
          setPdfArrayBuffer(res)
          setCallStatus('success')
        })
        .catch((err) => {
          Logger.error('Error while fetching receipt pdf : ', err)
          setCallStatus('error')
        })
    }
  }, [user, currentSite])

  const downloadTicket = () => {
    if (!!pdfArrayBuffer) {
      const a = document.createElement('a')
      a.href = `data:application/pdf;base64,${Buffer.from(pdfArrayBuffer).toString('base64')}`
      a.download = `${item.type}-${item.date}.pdf`
      a.click()
      document.removeChild(a)
    }
  }

  return (
    <MainContainer>
      <HeaderContainer>
        <CrossContainer onClick={close}>
          <Icons name="cross" color={theme.colors.blue} size={30} />
        </CrossContainer>
      </HeaderContainer>
      {!!pdfArrayBuffer ? (
        <>
          <Document
            file={{ data: pdfArrayBuffer }}
            onLoadSuccess={(event: any) => setNumberOfPages(event._pdfInfo.numPages)}>
            {Array.from(new Array(numberOfPages), (el, index) => (
              <Page width={pageWidth} key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
          <ButtonContainer>
            <Button
              noFlex
              color={theme.colors.blue}
              verticalPadding={14}
              font={theme.fonts.h3Bold}
              label={i18n.t('screens.mymrs.downloadTicket')}
              onClick={downloadTicket}
            />
          </ButtonContainer>
        </>
      ) : (
        <NoPdfContainer>
          {callStatus === 'loading' ? (
            <Loader type="TailSpin" color={theme.colors.blue} />
          ) : (
            <ErrorText>{i18n.t('screens.mymrs.errorOnFetchTicket')}</ErrorText>
          )}
        </NoPdfContainer>
      )}
    </MainContainer>
  )
}

export default MyMRSReceiptPdf

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  max-width: 500px;
  min-height: 100px;
  max-height: 90vh;
  overflow-x: hidden;
  overflow-y: scroll;
  margin: 20px 0px;
`

const HeaderContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`

const CrossContainer = styled.button`
  margin: 0px 20px;
`

const ErrorText = styled.p`
  ${(props) => props.theme.fonts.bodyBold};
  text-align: center;
`

const NoPdfContainer = styled.div`
  display: flex;
  padding: 20px;
  justify-content: center;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`
