import React from 'react'

import { windowSizeBreakpoints } from 'utils/breakpoints'

import useI18n from 'i18n/useI18n'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Button from 'components/button/Button'

interface Props {
  closeModal: () => void
}

const RGPDModal = ({ closeModal }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()

  return (
    <MainContainer>
      <Title>{i18n.t('screens.surveys.details.RGPDModalTitle')}</Title>
      <Description>{i18n.t('screens.surveys.details.RGPDModalDescription')}</Description>
      <ButtonContainer>
        <Button label={i18n.t('common.close')} onClick={closeModal} font={Theme.fonts.h3Bold} />
      </ButtonContainer>
    </MainContainer>
  )
}

export default RGPDModal

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  max-height: 70vh;
  overflow: auto;
  width: 400px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    width: 280px;
  }
`

const Title = styled.p`
  ${(props) => props.theme.fonts.h2Bold}
  margin: 0px 0px 20px;
`

const Description = styled.p`
  ${(props) => props.theme.fonts.body}
  margin: 0px 0px 40px;
  white-space: pre-wrap;
`

const ButtonContainer = styled('div')`
  display: flex;
  flex: 1;
`
