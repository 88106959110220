import * as React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icons from 'components/icons/Icons'
import Button from 'components/button/Button'

import { windowSizeBreakpoints } from 'utils/breakpoints'

export interface Option {
  label: string
  value: any
}

interface Props {
  title: string
  close: () => void
  options: Option[]
  selectOption: (value: any) => void
  generateAriaLabel?: (opt: Option) => string
  goBack?: () => void
}

const PickerModalContent = ({ title, close, options, selectOption, generateAriaLabel, goBack }: Props) => {
  const [theme] = useTheme()
  return (
    <ModalContainer>
      <TopContainer>
        {!!goBack && (
          <IconContainer
            onClick={() => {
              close()
              goBack()
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                close()
                goBack()
              }
            }}>
            <Icons name="chevron_left" color={theme.colors.darkGrey} size={25} />
          </IconContainer>
        )}
        <ModalTitle>{title}</ModalTitle>
        <IconContainer
          onClick={close}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              close()
            }
          }}>
          <Icons name="cross" color={theme.colors.darkGrey} size={25} />
        </IconContainer>
      </TopContainer>
      <ModalButtonsContainer>
        {options.map((opt) => (
          <ButtonContainer key={opt.label}>
            <Button
              onClick={() => selectOption(opt.value)}
              label={opt.label}
              font={theme.fonts.h3Bold}
              ariaLabel={generateAriaLabel ? generateAriaLabel(opt) : undefined}
            />
          </ButtonContainer>
        ))}
      </ModalButtonsContainer>
    </ModalContainer>
  )
}

export default PickerModalContent

const ModalContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding-top: 20px;
  }
`
const ModalTitle = styled('h1')`
  ${(props) => props.theme.fonts.bodyBold};
  font-size: 24px;
  line-height: 30px;
  margin: 0px;
`
const ModalButtonsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ButtonContainer = styled('div')`
  display: flex;
  width: 350px;
  margin: 5px 0px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 280px;
  }
`
const TopContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`
const IconContainer = styled('div')`
  cursor: pointer;
  display: flex;
  align-items: center;
  :focus {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
