import * as React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'
import utils from 'utils/strings'

import Icon from 'components/icons/Icons'
import Button from 'components/button/Button'
import CustomDatePicker from 'components/picker/CustomDatePicker'
import Loader from 'react-loader-spinner'
import Alert from 'components/alert/Alert'
import ValidationContent from 'components/modal/ValidationContent'

import useI18n from 'i18n/useI18n'

import { windowSizeBreakpoints } from 'utils/breakpoints'
import useBreakpoint from 'utils/useBreakpoints'
import stringUtils from 'utils/strings'
import {
  getClosestDate,
  getCurrentTimeInCurrentSite,
  getDayTimezone,
  getNextSelectableDate,
  utcDateToTimezoneSite,
} from '../utils'

import api from '../api'

import {
  addDays,
  isAfter,
  isBefore,
  setHours,
  setMinutes,
  addBusinessDays,
  isToday,
  isTomorrow,
  isSameDay,
  getDay,
  differenceInMinutes,
  compareAsc,
  isWeekend,
} from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import useReducer from 'store/useReducer'
import * as siteStore from 'sites/store/siteStore'
import * as userStore from 'store/user/user'

import config from 'core/src/config'

type TAB = 'FORM' | 'BOOKINGS'

interface Props {
  initialDateProps?: Date
  closeModal: () => void
  refreshPlanningBookings?: () => void
  refreshModulesInfos?: () => void
  bookingTab?: TAB
  bookingIdToDisplayProps?: string
  restaurants?: RestaurantV6[]
  initialRestaurant?: RestaurantV6
  moduleName?: 'commuTable' | 'tableService'
  restaurantId?: string
}

type ApiStatus = 'notStarted' | 'loading' | 'success' | 'error'

const onEnterKey = (e: React.KeyboardEvent, onClick: () => void) => {
  if (e.key === 'Enter') {
    onClick()
  }
}

// Si cette valeur passe à true, vérifier dans tout le projet les occurences de MULTI_DAYS
const MULTI_DAYS = false

const CURRENT_DATE = new Date()

const FULL_ZONE = 100
const ALMOST_FULL_ZONE = 70

type Days = 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday'

const DAYS: Days[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

const BarclapScreen = ({
  initialDateProps = CURRENT_DATE,
  closeModal,
  refreshPlanningBookings,
  refreshModulesInfos,
  bookingTab,
  restaurants = [],
  bookingIdToDisplayProps,
  initialRestaurant,
  moduleName,
  restaurantId,
}: Props) => {
  const [theme] = useTheme()
  const i18n = useI18n()
  const [, currentBreakpoint] = useBreakpoint()

  const user = useReducer(userStore.store, (s) => s.user)
  const currentSite = useReducer(siteStore.store, (s) => s.site)

  const [statusTimeslots, setStatusTimeSlots] = React.useState<ApiStatus>('notStarted')
  const [statusResa, setStatusResa] = React.useState<ApiStatus>('notStarted')
  const [statusTables, setStatusTables] = React.useState<ApiStatus>('notStarted')
  const [isBooking, setIsBooking] = React.useState(false)
  const [isDeleting, setIsDeleting] = React.useState(false)
  const [dateIsPastArray, setDateIsPastArray] = React.useState<Date[]>([])

  const [tablesList, setTablesList] = React.useState<BarclapModuleTable[]>([])
  const [selectedTable, setSelectedTable] = React.useState<BarclapModuleTable>()

  const [restaurantWithSchedule, setRestaurantWithSchedule] = React.useState<BarclapModuleDatas>()
  const [selectedTimeSlot, setSelectedTimeSlot] = React.useState<{ start_time: string; end_time: string } | undefined>()
  const [maxGuestNumber, setMaxGuestNumber] = React.useState(0)
  const [guestNumber, setGuestNumber] = React.useState(0)
  const [selectedRestaurant, setSelectedRestaurant] = React.useState<RestaurantV6>(
    initialRestaurant || restaurants.find((r) => r.isDefaultRestaurant) || restaurants[0]
  )
  const [selectedDate, setSelectedDate] = React.useState(initialDateProps || new Date())
  const dateIsPast = React.useMemo(() => !!dateIsPastArray.find((d) => isSameDay(d, selectedDate)), [
    selectedDate,
    dateIsPastArray,
  ])
  const [bookings, setBookings] = React.useState<BarclapBooking[]>([])

  const [isDatePickerVisible, setIsDatePickerVisible] = React.useState(false)
  const [currentTab, setCurrentTab] = React.useState<TAB>(bookingTab || 'FORM')
  const [bookingToModify, setBookingToModify] = React.useState<BarclapBooking>()

  const bookingRefToDisplay = React.useRef<any>(null)
  const [bookingIdToDisplay, setBookingIdToDisplay] = React.useState(bookingIdToDisplayProps)
  const [mustScrollToBooking, setMustScrollToBooking] = React.useState(
    bookingTab === 'BOOKINGS' && !!bookingIdToDisplay
  )

  const [currentIndex, setCurrentIndex] = React.useState(0)

  const MAX_PEOPLE_NUMBER = maxGuestNumber + 1
  const MAX_PEOPLE_ITEMS =
    currentBreakpoint === 'laptop' || currentBreakpoint === 'big' || currentBreakpoint === 'mediumBig' ? 5 : 3

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0))
  }

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, MAX_PEOPLE_NUMBER - MAX_PEOPLE_ITEMS))
  }

  React.useEffect(() => {
    const newIndex = Math.max(0, Math.min(guestNumber, MAX_PEOPLE_NUMBER - MAX_PEOPLE_ITEMS))
    setCurrentIndex(newIndex)
  }, [MAX_PEOPLE_ITEMS])

  const moduleUUID = !!moduleName
    ? moduleName === 'commuTable'
      ? config.barclap.tableCommuUUID
      : config.barclap.tableServiceUUID
    : undefined

  const timeInCurrentSite = React.useMemo(
    () =>
      !!currentSite && !!currentSite.timezone
        ? getCurrentTimeInCurrentSite(currentSite.timezone, CURRENT_DATE)
        : undefined,
    [currentSite]
  )

  const MIN_DATE = React.useMemo(
    () =>
      !MULTI_DAYS
        ? CURRENT_DATE
        : !!timeInCurrentSite && timeInCurrentSite < 14 && !isWeekend(CURRENT_DATE)
        ? CURRENT_DATE
        : !!timeInCurrentSite
        ? getNextSelectableDate(
            timeInCurrentSite < 14 ? CURRENT_DATE : addDays(CURRENT_DATE, 1),
            addDays(CURRENT_DATE, 7)
          )
        : CURRENT_DATE,
    [currentSite, timeInCurrentSite]
  )

  const MAX_DATE = React.useMemo(() => addDays(setHours(setMinutes(MIN_DATE, 59), 23), MULTI_DAYS ? 7 : 0), [MIN_DATE])

  const initialDate = React.useMemo(
    () =>
      getClosestDate(
        MIN_DATE,
        MAX_DATE,
        initialDateProps,
        bookingToModify || !currentSite
          ? []
          : bookings.map((b) => utcToZonedTime(new Date(b.date), currentSite.timezone)),
        true,
        !!moduleUUID ? 10 : 14,
        timeInCurrentSite
      ),
    [bookings, initialDateProps, bookingToModify, timeInCurrentSite]
  )

  React.useEffect(() => {
    if (!!restaurantId) {
      const findRestaurant = restaurants.find((resto) => resto.id === restaurantId)
      if (!!findRestaurant) {
        setSelectedRestaurant(findRestaurant)
      }
    }
  }, [restaurantId])

  React.useEffect(() => {
    if (initialDate) {
      setSelectedDate(initialDate)
    }
  }, [initialDate])

  const formatBarclapDate = (date: Date) => i18n.t('formats.utcDate', { date: date })

  const filterOpeningDays = (date: Date) =>
    !!restaurantWithSchedule &&
    !!restaurantWithSchedule.module_opening[DAYS[getDay(date)]] &&
    !restaurantWithSchedule.team_day_offs.some(({ date_off }) => isSameDay(new Date(date_off), date)) &&
    !restaurantWithSchedule.team_closing_periods.some(
      (period) => isAfter(date, new Date(period.start_date)) && isBefore(date, new Date(period.end_date))
    ) &&
    !bookings.some((b) =>
      !!currentSite ? isSameDay(date, utcToZonedTime(new Date(b.date), currentSite.timezone)) : false
    )

  const fetchBookings = () => {
    if (!!user && user.type !== 'EXTERNAL' && currentSite && !!currentSite.barclapId) {
      setBookingToModify(undefined)
      setStatusResa('loading')

      if (!!moduleName || !!selectedRestaurant) {
        const moduleUUID =
          !moduleName && !!selectedRestaurant && !!selectedRestaurant.barclapId
            ? selectedRestaurant.barclapId
            : moduleName === 'commuTable'
            ? config.barclap.tableCommuUUID
            : config.barclap.tableServiceUUID

        if (!moduleName && !!selectedRestaurant && !!selectedRestaurant.barclapId) {
          api.barclap
            .getBookings(
              user.type,
              currentSite.id,
              currentSite.barclapId,
              formatBarclapDate(CURRENT_DATE),
              formatBarclapDate(addBusinessDays(CURRENT_DATE, 5))
            )
            .then((res) => {
              const bookings = res
                .filter((r) => !Object.values(config.barclap).includes(r.module_uuid))
                .sort((b1, b2) => compareAsc(new Date(b1.date), new Date(b2.date)))
              setBookings(bookings)
              setStatusResa('success')
              if (bookings.length > 0) {
                setCurrentTab('BOOKINGS')
              } else {
                setCurrentTab('FORM')
              }
            })
            .catch(() => setStatusResa('error'))
        } else {
          api.barclap
            .getModuleBookings(
              user.type,
              currentSite.id,
              currentSite.barclapId,
              moduleUUID,
              formatBarclapDate(CURRENT_DATE),
              formatBarclapDate(addBusinessDays(CURRENT_DATE, 5))
            )
            .then((res) => {
              setBookings(
                res
                  .filter((b) => !!b.booking)
                  .sort((b1, b2) => compareAsc(new Date(b1.date), new Date(b2.date)))
                  .map((r) => r.booking) as BarclapBooking[]
              )
              setStatusResa('success')
            })
            .catch(() => setStatusResa('error'))
        }
      }
    }
  }

  React.useEffect(() => {
    if (mustScrollToBooking && statusResa === 'success' && bookingRefToDisplay.current) {
      setMustScrollToBooking(false)
      bookingRefToDisplay.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [statusResa, mustScrollToBooking, bookingRefToDisplay.current])

  React.useEffect(() => {
    fetchBookings()
  }, [currentSite, user])

  React.useEffect(() => {
    if (!!bookingToModify) {
      setSelectedDate(getDayTimezone(bookingToModify.date))
      setGuestNumber(bookingToModify.nb_guest || 0)
      const findRestaurant = restaurants.find((restaurant) => restaurant.barclapId === bookingToModify.module_uuid)
      if (!!findRestaurant) {
        setSelectedRestaurant(findRestaurant)
      }
    }
  }, [bookingToModify])

  React.useEffect(() => {
    if (!!user && !!currentSite && !!selectedRestaurant && !!currentSite.barclapId) {
      const selectedDateCache = selectedDate
      setStatusTimeSlots('loading')
      api.barclap
        .getRestaurantDatas(
          user.type,
          currentSite.id,
          currentSite.barclapId!,
          selectedRestaurant.barclapId as string,
          formatBarclapDate(selectedDate)
        )
        .then((restaurantData) => {
          if (restaurantData.module_status === 'date-is-past') {
            setDateIsPastArray((vals) => [...vals, selectedDateCache])
          }
          setRestaurantWithSchedule(restaurantData)
          setMaxGuestNumber(restaurantData.module.max_guest)
          setStatusTimeSlots('success')
          if (!!bookingToModify) {
            const findTimeslot = restaurantData.module_timeslots.find(
              (timeslot) =>
                new Date(`${stringUtils.getYMDFromDate(selectedDate)}T${timeslot.start_time}Z`).getTime() ===
                new Date(bookingToModify.start_time).getTime()
            )
            if (!!findTimeslot) {
              setSelectedTimeSlot({
                end_time: findTimeslot.end_time,
                start_time: findTimeslot.start_time,
              })
            }
          }
        })
        .catch(() => setStatusTimeSlots('error'))
    }
  }, [selectedRestaurant, selectedDate.getDate(), user, currentSite, bookingToModify])

  React.useEffect(() => {
    if (!!user && !!currentSite && !!currentSite.barclapId && !!moduleUUID) {
      // Appel du WS pour récupérer les timeSlots du module selon la date
      setStatusTimeSlots('loading')
      api.barclap
        .getModuleInfos(
          's4d1',
          user.type,
          currentSite.id,
          moduleUUID,
          currentSite.barclapId,
          formatBarclapDate(selectedDate)
        )
        .then((res) => {
          setStatusTimeSlots('success')
          setRestaurantWithSchedule(res)
          setMaxGuestNumber(res.module.max_guest)
          if (res.module.max_guest >= 3) {
            setGuestNumber(3)
          } else {
            setGuestNumber(res.module.max_guest)
          }
        })
        .catch(() => setStatusTimeSlots('error'))
    }
  }, [moduleUUID, user, currentSite, selectedDate])

  React.useEffect(() => {
    if (!!user && !!currentSite && !!currentSite.barclapId && !!selectedTimeSlot && !!moduleUUID) {
      // Appel du WS pour récupérer les tables selon la date, le module, le nombre de guests, le timeSlot
      setSelectedTable(undefined)
      setStatusTables('loading')
      api.barclap
        .getModuleTables(
          user.type,
          currentSite.id,
          moduleUUID,
          currentSite.barclapId,
          formatBarclapDate(selectedDate),
          guestNumber,
          selectedTimeSlot.start_time,
          selectedTimeSlot.end_time
        )
        .then((res) => {
          setStatusTables('success')
          setTablesList(res)
        })
        .catch(() => setStatusTables('error'))
    }
  }, [selectedTimeSlot, moduleUUID, guestNumber, user, currentSite, selectedDate])

  const onErrorBooking = (err: any) => {
    Alert.open(
      () => (
        <ValidationContent
          title={i18n.t(`screens.barclap.form.${err.data === 'already-ordered' ? 'alreadyOrdered' : 'error'}`)}
          onConfirm={Alert.close}
          ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
        />
      ),
      true
    )
    setIsBooking(false)
  }

  const onConfirmBooking = () => {
    if (!!user && !!currentSite && !!selectedTimeSlot && !!currentSite.barclapId) {
      setIsBooking(true)
      if (!!moduleUUID && !!selectedTable) {
        api.barclap
          .postModuleBooking(user.type, currentSite.id, {
            booking: {
              start_time: selectedTimeSlot.start_time,
              end_time: selectedTimeSlot.end_time,
              date: stringUtils.getYMDFromDate(selectedDate),
              nb_guest: guestNumber,
              name: i18n.t(`screens.catering.services.placeName`),
              place_uuid: selectedTable.place_uuid,
            },
            module_uuid: moduleUUID,
            site_uuid: currentSite.barclapId,
          })
          .then((res) => {
            setCurrentTab('BOOKINGS')
            if (refreshPlanningBookings) {
              refreshPlanningBookings()
            }
            if (!!refreshModulesInfos) {
              refreshModulesInfos()
            }
            fetchBookings()
            setIsBooking(false)
            setMustScrollToBooking(true)
            setBookingIdToDisplay(res.booking_id)
          })
          .catch(onErrorBooking)
      } else if (!!selectedRestaurant) {
        if (bookingToModify) {
          if (
            bookingToModify.module_uuid !== selectedRestaurant.barclapId ||
            new Date(`${stringUtils.getYMDFromDate(selectedDate)}T${selectedTimeSlot.start_time}Z`).getTime() !==
              new Date(bookingToModify.start_time).getTime() ||
            stringUtils.getYMDFromDate(selectedDate) !==
              stringUtils.getYMDFromDate(getDayTimezone(bookingToModify.date))
          ) {
            // On supprime la résa puis on en crée une nouvelle
            api.barclap
              .deleteBooking(
                user.type,
                currentSite.id,
                currentSite.barclapId,
                bookingToModify.module_uuid,
                bookingToModify.id
              )
              .then(() => {
                // On crée la nouvelle résa
                api.barclap
                  .postBooking(user.type, currentSite.id, {
                    booking: {
                      start_time: selectedTimeSlot.start_time,
                      end_time: selectedTimeSlot.end_time,
                      date: stringUtils.getYMDFromDate(selectedDate),
                      nb_guest: guestNumber,
                      name: selectedRestaurant.name,
                    },
                    module_uuid: selectedRestaurant.barclapId!,
                    site_uuid: currentSite!.barclapId!,
                  })
                  .then(() => {
                    setCurrentTab('BOOKINGS')
                    if (refreshPlanningBookings) {
                      refreshPlanningBookings()
                    }
                    fetchBookings()
                    setIsBooking(false)
                    setMustScrollToBooking(true)
                    setBookingIdToDisplay(bookingToModify.id)
                  })
                  .catch(() => {
                    // On essaye de faire l'ancienne résa
                    const findTimeslot = restaurantWithSchedule?.module_timeslots.find(
                      (timeslot) =>
                        new Date(`${stringUtils.getYMDFromDate(selectedDate)}T${timeslot.start_time}Z`).getTime() ===
                        new Date(bookingToModify.start_time).getTime()
                    )
                    if (!!findTimeslot) {
                      api.barclap
                        .postBooking(user.type, currentSite.id, {
                          booking: {
                            date: stringUtils.getYMDFromDate(new Date(bookingToModify.date)),
                            start_time: findTimeslot.start_time,
                            end_time: findTimeslot.end_time,
                            nb_guest: bookingToModify.nb_guest || 0,
                            name: bookingToModify.name,
                          },
                          module_uuid: bookingToModify.module_uuid,
                          site_uuid: currentSite!.barclapId!,
                        })
                        .then(() => {
                          Alert.open(
                            () => (
                              <ValidationContent
                                title={i18n.t(`screens.barclap.form.modificationError`)}
                                onConfirm={Alert.close}
                                ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
                              />
                            ),
                            true
                          )
                          setIsBooking(false)
                        })
                        .catch(onErrorBooking)
                    }
                  })
              })
              .catch(onErrorBooking)
          } else {
            // On appele le WS pour modifier le nombre d'invités
            api.barclap
              .modifyNumberOfGuestsInBooking(user.type, currentSite.id, {
                booking_id: parseInt(bookingToModify.id),
                module_uuid: bookingToModify.module_uuid,
                new_nb_guest: guestNumber,
                site_uuid: currentSite.barclapId,
              })
              .then((res) => {
                setCurrentTab('BOOKINGS')
                if (refreshPlanningBookings) {
                  refreshPlanningBookings()
                }
                fetchBookings()
                setIsBooking(false)
                setMustScrollToBooking(true)
                setBookingIdToDisplay(bookingToModify.id)
              })
              .catch(onErrorBooking)
          }
        } else {
          api.barclap
            .postBooking(user.type, currentSite.id, {
              booking: {
                start_time: selectedTimeSlot.start_time,
                end_time: selectedTimeSlot.end_time,
                date: stringUtils.getYMDFromDate(selectedDate),
                nb_guest: guestNumber,
                name: selectedRestaurant.name,
              },
              module_uuid: selectedRestaurant.barclapId as string,
              site_uuid: currentSite.barclapId,
            })
            .then((res) => {
              setCurrentTab('BOOKINGS')
              if (refreshPlanningBookings) {
                refreshPlanningBookings()
              }
              fetchBookings()
              setIsBooking(false)
              setMustScrollToBooking(true)
              setBookingIdToDisplay(res.booking_id)
            })
            .catch(onErrorBooking)
        }
      }
    }
  }

  const modifyBooking = (item: BarclapBooking) => {
    setCurrentTab('FORM')
    setBookingToModify(item)
  }

  const renderTableSlots = () => {
    if (statusTables === 'loading' || statusTables === 'notStarted') {
      return (
        <CenteredDiv>
          <Loader type="TailSpin" color={theme.colors.blue} />
        </CenteredDiv>
      )
    }

    if (!initialDate || dateIsPast || statusTables === 'error' || tablesList.length === 0) {
      return (
        <ErrorText>
          {i18n.t(
            `screens.barclap.form.${
              !initialDate
                ? 'noBookingsLeft'
                : dateIsPast
                ? 'errorDateIsPast'
                : tablesList.length === 0
                ? 'noTable'
                : 'timeslotError'
            }`
          )}
        </ErrorText>
      )
    }

    return tablesList.map((table, index) => {
      const isTableSelected = !!selectedTable && selectedTable.place_uuid === table.place_uuid

      return (
        <TimeSlotContainer
          tabIndex={0}
          role="option"
          aria-selected={isTableSelected}
          aria-description={i18n.t('label.ariaLabel.restaurant.form.table', {
            table: table.place_name,
            capacity: table.max_seat,
          })}
          key={table.place_name}
          index={index}
          onClick={() => (!isTableSelected ? setSelectedTable(table) : setSelectedTable(undefined))}
          selected={isTableSelected}>
          <CheckContainer>
            {isTableSelected ? (
              <Icon aria-hidden name="check-circle" size={24} color={theme.colors.blue} />
            ) : (
              <Placeholder />
            )}
          </CheckContainer>
          <TimeSlot aria-hidden isDisabled={false}>
            {table.place_name}
          </TimeSlot>
          <OccupancyContainer>
            <OccupancyIconContainer>
              <Icon aria-hidden name="users-wm" size={16} color={theme.colors.blue} />
            </OccupancyIconContainer>
            <OccupancyText aria-hidden color={theme.colors.black}>
              {i18n.t('components.capacity.value', {
                count: table.max_seat,
              })}
            </OccupancyText>
          </OccupancyContainer>
        </TimeSlotContainer>
      )
    })
  }

  const renderTimeSlots = () => {
    if (statusTimeslots === 'loading' || statusTimeslots === 'notStarted') {
      return (
        <CenteredDiv>
          <Loader type="TailSpin" color={theme.colors.blue} />
        </CenteredDiv>
      )
    }

    if (!initialDate || dateIsPast || statusTimeslots === 'error') {
      return (
        <ErrorText>
          {i18n.t(
            `screens.barclap.form.${!initialDate ? 'noBookingsLeft' : dateIsPast ? 'errorDateIsPast' : 'timeslotError'}`
          )}
        </ErrorText>
      )
    }

    return (
      !!restaurantWithSchedule &&
      !!currentSite &&
      restaurantWithSchedule.module_timeslots.map(({ remaining_booking, start_time, end_time, max_booking }, index) => {
        const startDateTime = utcDateToTimezoneSite(
          `${stringUtils.getYMDFromDate(selectedDate)}T${start_time}Z`,
          currentSite.timezone
        )
        const endDateTime = utcDateToTimezoneSite(
          `${stringUtils.getYMDFromDate(selectedDate)}T${end_time}Z`,
          currentSite.timezone
        )

        const taken_booking = max_booking - remaining_booking
        const isTimeSlotDisable =
          remaining_booking < guestNumber + 1 || isAfter(utcToZonedTime(new Date(), currentSite.timezone), endDateTime)
        const rate = Math.round((taken_booking / max_booking) * 100)
        const isTimeSlotSelected =
          !!selectedTimeSlot && selectedTimeSlot.start_time === start_time && selectedTimeSlot.end_time === end_time
        const iconChoice: IconName =
          rate === FULL_ZONE ? 'gauge-full' : rate > ALMOST_FULL_ZONE ? 'gauge-middle' : 'gauge-empty'
        const occupancyColor = !isTimeSlotDisable
          ? rate === FULL_ZONE
            ? theme.colors.red
            : rate > ALMOST_FULL_ZONE
            ? theme.colors.orange
            : theme.colors.strongTurquoise
          : theme.colors.darken
        const isInitialTimeSlot = bookingToModify
          ? startDateTime.getTime() === new Date(bookingToModify.start_time).getTime()
          : undefined

        return (
          <TimeSlotContainer
            tabIndex={0}
            role="option"
            aria-selected={isTimeSlotSelected}
            aria-description={i18n.t('label.ariaLabel.restaurant.form.slot', {
              timeSlot: i18n.t('screens.barclap.form.timeSlot', {
                start: startDateTime,
                end: endDateTime,
              }),
              occupancy: `${taken_booking}/${max_booking}`,
              rate: `${rate}%`,
            })}
            key={start_time + ' - ' + end_time}
            disabled={isTimeSlotDisable}
            index={index}
            initialSlot={!!isInitialTimeSlot}
            onClick={() =>
              !isTimeSlotDisable &&
              (!isTimeSlotSelected
                ? setSelectedTimeSlot({ start_time: start_time, end_time: end_time })
                : setSelectedTimeSlot(undefined))
            }
            selected={isTimeSlotSelected}>
            <CheckContainer>
              {isTimeSlotSelected ? <Icon name="check-circle" size={24} color={theme.colors.blue} /> : <Placeholder />}
            </CheckContainer>
            <TimeSlot isDisabled={isTimeSlotDisable} aria-hidden>
              {i18n.t('screens.barclap.form.timeSlot', {
                start: startDateTime,
                end: endDateTime,
              })}
            </TimeSlot>
            <OccupancyContainer>
              <OccupancyIconContainer>
                <Icon
                  aria-hidden
                  name="users-wm"
                  size={16}
                  color={isTimeSlotDisable ? theme.colors.darken : theme.colors.blue}
                />
              </OccupancyIconContainer>
              <OccupancyText
                aria-hidden
                color={
                  isTimeSlotDisable ? theme.colors.darken : theme.colors.black
                }>{`${taken_booking}/${max_booking}`}</OccupancyText>
              <OccupancyIconContainer>
                <Icon aria-hidden name={iconChoice} size={16} color={occupancyColor} />
              </OccupancyIconContainer>
              <OccupancyText aria-hidden color={occupancyColor}>{`${rate}%`}</OccupancyText>
            </OccupancyContainer>
          </TimeSlotContainer>
        )
      })
    )
  }

  const renderModuleItem = (item: RestaurantV6) => (
    <SingleModaleButton>
      <Button
        label={item.name}
        font={theme.fonts.h3Bold}
        onClick={() => {
          Alert.close()
          setSelectedRestaurant(item)
        }}
      />
    </SingleModaleButton>
  )

  const renderModulePicker = () => (
    <ModalContainer>
      <ModaleTitle>{i18n.t('screens.barclap.form.location')}</ModaleTitle>
      <ModalButtonsContainer>
        {restaurants.map(renderModuleItem)}
        <SingleModaleButton>
          <Button
            color={theme.colors.white}
            onClick={Alert.close}
            label={i18n.t('common.close')}
            font={theme.fonts.h3Bold}
            ariaLabel={i18n.t('common.close')}
            textColor={theme.colors.blue}
            shadow
          />
        </SingleModaleButton>
      </ModalButtonsContainer>
    </ModalContainer>
  )

  const renderPeople = () => {
    if (statusTimeslots === 'loading' || statusTimeslots === 'notStarted') {
      return (
        <CenteredDiv>
          <Loader type="TailSpin" color={theme.colors.blue} height={32} />
        </CenteredDiv>
      )
    }

    if (maxGuestNumber < 2) {
      return (
        <TimeSlotListContainer role="listbox">
          {renderPeopleItem(0, guestNumber === 0 && !!initialDate)}
          {maxGuestNumber > 0 && renderPeopleItem(1, guestNumber === 1 && !!initialDate)}
        </TimeSlotListContainer>
      )
    }

    const showPrevButton = currentIndex > 0
    const showNextButton = currentIndex + MAX_PEOPLE_ITEMS < MAX_PEOPLE_NUMBER

    return (
      <PeopleContainer>
        <ArrowButtonContainer margin>
          {showPrevButton && (
            <ArrowContainer type="left" onClick={handlePrev}>
              <Icon name="chevron_left" size={20} />
            </ArrowContainer>
          )}
        </ArrowButtonContainer>
        {Array.from({ length: Math.min(MAX_PEOPLE_ITEMS, MAX_PEOPLE_NUMBER - currentIndex) }, (_, i) => {
          const itemNumber = currentIndex + i + 1
          return (
            <PeopleItemContainer
              role="option"
              key={itemNumber}
              selected={guestNumber + 1 === itemNumber}
              onClick={() => {
                setGuestNumber(itemNumber - 1)
              }}
              onKeyDown={(e) => {
                onEnterKey(e, () => setGuestNumber(itemNumber - 1))
              }}>
              <PeopleItemText>{itemNumber}</PeopleItemText>
            </PeopleItemContainer>
          )
        })}
        <ArrowButtonContainer>
          {showNextButton && (
            <ArrowContainer type="right" onClick={handleNext}>
              <Icon name="chevron_right" size={20} />
            </ArrowContainer>
          )}
        </ArrowButtonContainer>
      </PeopleContainer>
    )
  }

  const renderPeopleItem = (index: number, selected: boolean) => (
    <TimeSlotContainer
      tabIndex={index}
      role="option"
      index={index}
      selected={selected}
      onClick={() => setGuestNumber(index)}
      initialSlot={
        !!bookingToModify && (bookingToModify.nb_guest === index || (!bookingToModify.nb_guest && index === 0))
      }
      disabled={!initialDate}>
      <CheckContainer>
        {selected ? <Icon name="check-circle" size={24} color={theme.colors.blue} /> : <Placeholder />}
      </CheckContainer>
      <TimeSlot isDisabled={!initialDate} aria-hidden>
        {i18n.t('screens.barclap.form.peopleNumber', {
          count: index + 1,
        })}
      </TimeSlot>
    </TimeSlotContainer>
  )

  const renderFormTab = () => (
    <TabContentContainer>
      <FormContainer disabled={!initialDate}>
        {!!moduleName && (
          <DescriptionContainer>
            <Label>{i18n.t('screens.barclap.form.description')}</Label>
          </DescriptionContainer>
        )}

        <RowContainer>
          <Label>{i18n.t('screens.barclap.form.location')}</Label>
          {!!selectedRestaurant && restaurants.length > 1 ? (
            <ChoiceContainer
              tabIndex={initialDate ? 0 : -1}
              onClick={() => Alert.open(renderModulePicker, true)}
              onKeyDown={(e) => onEnterKey(e, () => Alert.open(renderModulePicker))}>
              <ChoiceText disabled={!initialDate}>{selectedRestaurant.name}</ChoiceText>
              <Icon name="chevron_right" color={theme.colors.darkGrey} />
            </ChoiceContainer>
          ) : (
            <ChoiceContainerNonClickable tabIndex={initialDate ? 0 : -1}>
              <ChoiceTextBlack>
                {!!moduleName ? i18n.t(`screens.catering.services.placeName`) : selectedRestaurant.name}
              </ChoiceTextBlack>
            </ChoiceContainerNonClickable>
          )}
        </RowContainer>
        <BlueLine role="presentation" />

        <RowContainer>
          <Label>{i18n.t('screens.planning.firstForm.date')}</Label>
          <ChoiceContainer disabled={!MULTI_DAYS}>
            <CliquableDiv
              tabIndex={initialDate ? 0 : -1}
              onClick={() => {
                if (MULTI_DAYS) {
                  setIsDatePickerVisible(true)
                }
              }}
              onKeyDown={(e) => {
                if (MULTI_DAYS) {
                  onEnterKey(e, () => setIsDatePickerVisible(true))
                }
              }}>
              <ChoiceText disabled={!MULTI_DAYS}>
                {utils.capitalize(i18n.t('screens.planning.reservationDate', { date: selectedDate }))}
              </ChoiceText>
              <Icon name="chevron_right" color={theme.colors.darkGrey} />
            </CliquableDiv>
            {isDatePickerVisible && (
              <CustomDatePicker
                selectedDate={selectedDate}
                onChange={setSelectedDate}
                minDate={MIN_DATE}
                maxDate={MAX_DATE}
                closePicker={() => setIsDatePickerVisible(false)}
                filterDate={filterOpeningDays}
              />
            )}
          </ChoiceContainer>
        </RowContainer>
        <BlueLine role="presentation" />
        <RowContainer>
          <Label id="guestPicker">{i18n.t('screens.barclap.form.people')}</Label>
        </RowContainer>
        {renderPeople()}
        <BlueLine role="presentation" />

        <RowContainer>
          <Label id="timeslotTitle">{i18n.t('screens.barclap.form.timeSlotTitle')}</Label>
        </RowContainer>
        <TimeSlotListContainer role="listbox" aria-describedby="timeslotTitle">
          {renderTimeSlots()}
        </TimeSlotListContainer>
        {!!moduleName && !!selectedTimeSlot && (
          <>
            <BlueLine role="presentation" />
            <RowContainer>
              <Label id="tableTitle">{i18n.t('screens.barclap.form.tableTitle')}</Label>
            </RowContainer>
            <TimeSlotListContainer aria-describedby="tableTitle" role="listbox">
              {renderTableSlots()}
            </TimeSlotListContainer>
          </>
        )}
      </FormContainer>

      <ButtonContainer>
        <Button
          label={i18n.t('screens.barclap.form.confirm')}
          color={theme.colors.blue}
          textColor={theme.colors.white}
          font={theme.fonts.h3Bold}
          loading={isBooking}
          onClick={onConfirmBooking}
          disabled={!!moduleName ? !selectedTimeSlot || !selectedTable : !selectedTimeSlot || !initialDate}
        />
      </ButtonContainer>
      <ButtonContainer>
        <Button
          label={i18n.t('common.cancel')}
          onClick={closeModal}
          color={theme.colors.white}
          textColor={theme.colors.blue}
          shadow
          font={theme.fonts.h3Bold}
        />
      </ButtonContainer>
    </TabContentContainer>
  )

  const deleteBooking = (item: BarclapBooking) => {
    if (!!user && !!currentSite && currentSite.barclapId) {
      setIsDeleting(true)
      api.barclap
        .deleteBooking(user.type, currentSite.id, currentSite.barclapId, item.module_uuid, item.id)
        .then(() => {
          Alert.open(
            () => (
              <ValidationContent title={i18n.t('screens.planning.booking.successDelete')} onConfirm={Alert.close} />
            ),
            true
          )
          fetchBookings()
          if (refreshPlanningBookings) {
            refreshPlanningBookings()
          }
          if (!!refreshModulesInfos) {
            refreshModulesInfos()
          }
          setIsDeleting(false)
        })
        .catch(() => {
          {
            Alert.open(
              () => (
                <ValidationContent
                  title={i18n.t('screens.planning.delete.error')}
                  onConfirm={Alert.close}
                  ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
                />
              ),
              true
            )
            setIsDeleting(false)
          }
        })
    }
  }

  const renderBooking = (item: BarclapBooking) => {
    if (item === null || !currentSite) {
      return null
    }
    const isTodayBooking = isToday(getDayTimezone(item.date))
    const isTomorrowBooking = isTomorrow(getDayTimezone(item.date))
    const isServiceBookingRemovable = isTodayBooking && differenceInMinutes(new Date(item.start_time), new Date()) > 60
    return (
      <BookingContainer key={item.id} ref={item.id === bookingIdToDisplay ? bookingRefToDisplay : undefined}>
        <DateBooking>
          {utils.capitalize(
            i18n.t(
              `screens.barclap.bookings.${
                isTodayBooking ? 'todayDate' : isTomorrowBooking ? 'tomorrowDate' : 'otherDate'
              }`,
              {
                date: getDayTimezone(item.date),
              }
            )
          )}
        </DateBooking>
        {!moduleName &&
          (isTodayBooking ? (
            <QrCodeImageContainer>
              <QrCodeImage src={item.qr_code} alt={i18n.t('label.ariaLabel.restaurant.qrCode')} />
            </QrCodeImageContainer>
          ) : (
            <BodyText>{i18n.t('screens.barclap.bookings.comeBackLater')}</BodyText>
          ))}
        <RowContainer>
          <IconContainer>
            <Icon name="building" size={20} color={theme.colors.blue} />
          </IconContainer>
          <Label>{!!moduleName ? i18n.t('screens.catering.services.placeName') : item.name}</Label>
        </RowContainer>
        {!!moduleName && (
          <RowContainer>
            <IconContainer>
              <Icon name="desk" size={20} color={theme.colors.blue} />
            </IconContainer>
            <Label>{item.place_name}</Label>
          </RowContainer>
        )}
        <RowContainer>
          <IconContainer>
            <Icon name="user" size={20} color={theme.colors.blue} />
          </IconContainer>
          <Label>
            {i18n.t(`screens.barclap.form.${item.nb_guest ? 'guestCount2' : 'noGuest'}`, {
              count: item.nb_guest,
            })}
          </Label>
        </RowContainer>
        <RowContainer>
          <IconContainer>
            <Icon name="clock" size={15} color={theme.colors.blue} />
          </IconContainer>
          <Label>
            {i18n.t('screens.barclap.form.timeSlot', {
              start: utcDateToTimezoneSite(item.start_time, currentSite.timezone),
              end: utcDateToTimezoneSite(item.end_time, currentSite.timezone),
            })}
          </Label>
        </RowContainer>
        {(!isTodayBooking ||
          (!!moduleName ? isServiceBookingRemovable : isBefore(new Date(), new Date(item.end_time)))) && (
          <>
            <ButtonContainer>
              <Button
                label={i18n.t('screens.barclap.bookings.modifyBooking')}
                color={theme.colors.white}
                textColor={theme.colors.blue}
                font={theme.fonts.bodyBold}
                onClick={() => {
                  modifyBooking(item)
                }}
                loading={isDeleting}
                shadow
              />
            </ButtonContainer>
            <ButtonContainer>
              <Button
                label={i18n.t('screens.barclap.bookings.deleteBooking')}
                color={theme.colors.blue}
                textColor={theme.colors.white}
                font={theme.fonts.bodyBold}
                onClick={() => {
                  deleteBooking(item)
                }}
                loading={isDeleting}
              />
            </ButtonContainer>
          </>
        )}
      </BookingContainer>
    )
  }

  const renderBookingsListTab = () => {
    return (
      <BookingsContainer>
        {bookings.length === 0 ? (
          <CenteredView>
            <BodyText>{i18n.t('screens.barclap.bookings.noBookings')}</BodyText>
          </CenteredView>
        ) : (
          <TabContentContainer>
            {MULTI_DAYS && (
              <BodyText>
                {i18n.t(`screens.barclap.bookings.${!!moduleName ? `${moduleName}Description` : 'description'}`)}
              </BodyText>
            )}

            {bookings.map(renderBooking)}
          </TabContentContainer>
        )}
        <ButtonContainer>
          <Button
            label={i18n.t('common.close')}
            onClick={closeModal}
            color={theme.colors.white}
            textColor={theme.colors.blue}
            shadow
            font={theme.fonts.h3Bold}
          />
        </ButtonContainer>
      </BookingsContainer>
    )
  }

  const renderHeader = () => {
    if (!MULTI_DAYS) {
      return null
    }

    return (
      <TabContainer>
        <TabTitleContainer
          tabIndex={0}
          onClick={() => setCurrentTab('FORM')}
          onKeyDown={(e) => onEnterKey(e, () => setCurrentTab('FORM'))}>
          <TabTitle isCurrentTab={currentTab === 'FORM'}>{i18n.t('screens.barclap.tabs.BOOK')}</TabTitle>
        </TabTitleContainer>

        <Divider />
        <TabTitleContainer
          tabIndex={0}
          onClick={() => setCurrentTab('BOOKINGS')}
          onKeyDown={(e) => onEnterKey(e, () => setCurrentTab('BOOKINGS'))}>
          <TabTitle isCurrentTab={currentTab === 'BOOKINGS'}>{i18n.t('screens.barclap.tabs.MYBOOKINGS')}</TabTitle>
        </TabTitleContainer>
      </TabContainer>
    )
  }

  return (
    <MainContainer>
      <Title>
        {!!moduleName ? i18n.t(`screens.catering.services.${moduleName}`) : i18n.t('screens.barclap.title')}
      </Title>

      {statusResa === 'loading' ? (
        <CenteredDiv>
          <Loader type="TailSpin" color={theme.colors.blue} />
        </CenteredDiv>
      ) : statusResa === 'error' ? (
        <ErrorText>{i18n.t('screens.barclap.bookings.error')}</ErrorText>
      ) : currentTab === 'FORM' ? (
        <>
          {renderHeader()}
          {renderFormTab()}
        </>
      ) : (
        <>
          {renderHeader()}
          {renderBookingsListTab()}
        </>
      )}
    </MainContainer>
  )
}

export default BarclapScreen

const ModalContainer = styled('div')`
  background-color: ${(props) => props.theme.colors.white};
  z-index: 52;
  padding: 15px;

  max-height: -webkit-fill-available;
`

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
  max-height: 70vh;
  width: 400px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    width: 280px;
  }
  overflow: auto;
`

const TabContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0px 12px;
`

const FormContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${(props) => props.disabled && 'pointer-events: none'};
`

const Title = styled.p`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 18px;
  line-height: 20px;
  margin: 20px 0px 20px 10px;
`

const TabContainer = styled.div`
  display: flex;
  flex: 1;
  ${(props) => props.theme.fonts.label}
  font-size: 16px;
  line-height: 19px;
  justify-content: center;
  margin-bottom: 15px;
`

const TabTitleContainer = styled('div')`
  cursor: pointer;
  flex: 1;
`

const TabTitle = styled.p<{ isCurrentTab: boolean }>`
  text-decoration: underline solid
    ${(props) => (props.isCurrentTab ? props.theme.colors.blue : props.theme.colors.black)};
  color: ${(props) => (props.isCurrentTab ? props.theme.colors.blue : props.theme.colors.black)};
  margin: 0px;
  text-align: center;
`

const Divider = styled.div`
  height: 21px;
  width: 1px;
  background-color: ${(props) => props.theme.colors.darkGrey};
`

const RowContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 15px;
  align-items: center;
`

const Label = styled.p`
  ${(props) => props.theme.fonts.label}
  margin: 0px;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  flex: 1;
`

const CliquableDiv = styled.div`
  ${MULTI_DAYS && 'cursor: pointer;'}
  display: flex;
`

const ChoiceContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${(props) => !props.disabled && 'cursor: pointer;'}
  flex: 3;
  max-height: 20px;
`

const ChoiceContainerNonClickable = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 3;
  max-height: 20px;
`

const ChoiceText = styled.p<{ disabled?: boolean }>`
  ${(props) => props.theme.fonts.labelBold}
  color: ${(props) => (props.disabled ? props.theme.colors.middleGrey : props.theme.colors.blue)};
  font-size: 16px;
  line-height: 19px;
  margin: 0px;
  padding-right: 5px;
`

const ChoiceTextBlack = styled.p`
  ${(props) => props.theme.fonts.labelBold}
  color: ${(props) => props.theme.colors.black};
  font-size: 16px;
  line-height: 19px;
  margin: 0px;
  padding-right: 5px;
`

const BlueLine = styled.div`
  display: flex;
  width: 60px;
  height: 3px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.blue};
  margin-bottom: 15px;
`

const IconContainer = styled.div`
  width: 30px;
  align-items: center;
  justify-content: center;
`

const TimeSlotListContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
`

const TimeSlotContainer = styled.button<{
  index: number
  disabled?: boolean
  selected?: boolean
  initialSlot?: boolean
}>`
  position: relative;
  ${(props) => props.theme.constants.shadow};
  background-color: ${(props) => (!!props.disabled ? props.theme.colors.mediumLightGrey : props.theme.colors.white)};
  padding: 8px;
  margin: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 2px solid
    ${(props) =>
      !!props.initialSlot
        ? props.theme.colors.turquoise
        : !!props.disabled
        ? 'transparent'
        : !!props.selected
        ? props.theme.colors.blue
        : props.theme.colors.white};
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px ${(props) => props.theme.colors.shadow};
  cursor: ${(props) => (!!props.disabled ? 'not-allowed' : 'pointer')};
`

const TimeSlot = styled.p<{ isDisabled: boolean }>`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => (props.isDisabled ? props.theme.colors.darken : props.theme.colors.black)};
  font-size: 13px;
  line-height: 14px;
  margin: 0px;
`

const OccupancyContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 0px 6px 10px;
  margin-top: 8px;
`

const OccupancyIconContainer = styled.div`
  display: flex;
  margin-right: 6px;
  justify-content: center;
  align-items: center;
`

const OccupancyText = styled.p<{ color: string }>`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.color};
  margin: 0px;
  padding: 3px 10px 0px 0px;
`

const CheckContainer = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 16px;
  z-index: 10;
`

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 20px;
`

const Placeholder = styled.div`
  height: 24px;
`

const CenteredDiv = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`
const BookingsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const BodyText = styled.p`
  ${(props) => props.theme.fonts.body};
`

const ErrorText = styled.p`
  ${(props) => props.theme.fonts.body};
  text-align: center;
`

const CenteredView = styled.div`
  justify-content: center;
  align-items: center;
`

const BookingContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const DateBooking = styled.p`
  ${(props) => props.theme.fonts.h3Bold};
  color: ${(props) => props.theme.colors.blue};
  margin-bottom: 10px;
`

const QrCodeImage = styled.img`
  object-fit: contain;
`

const QrCodeImageContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: 12px 20px;
`

const ModaleTitle = styled('h1')`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 24px;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    text-align: center;
    line-height: 25px;
  }
`

const ModalButtonsContainer = styled('div')`
  display: flex;
  flex-direction: column;
`
const SingleModaleButton = styled('div')`
  display: flex;
  width: 330px;
  margin: 5px 0px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    width: 150;
  }
`

const DescriptionContainer = styled('div')`
  margin-bottom: 8px;
`

const PeopleContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 15px;
  align-items: center;
  justify-content: center;
`

const PeopleItemContainer = styled('div')<{ selected?: boolean }>`
  display: flex;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.13);
  margin-right: 16px;
  border: 2px solid ${(props) => (props.selected ? props.theme.colors.blue : props.theme.colors.white)};
`

const PeopleItemText = styled.p`
  ${(props) => props.theme.fonts.menuSubtitle};
`

const ArrowContainer = styled.div<{ type: 'right' | 'left' }>`
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: ${(props) => (props.type === 'right' ? 'flex-end' : 'flex-start')};
  margin: ${(props) => `0px ${props.type === 'right' ? '0px' : '16px'} 0px 0px`};
`

const ArrowButtonContainer = styled.div<{ margin?: boolean }>`
  width: 30px;
  ${(props) => props.margin && 'margin-right: 16px;'}
`
