import * as React from 'react'
import styled from 'theme/styled-components'
import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import { assureToken } from 'authent365/utils/utils'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import useNavigation from 'utils/navigation/useNavigation'
import TitleHelmet from 'components/title/TitleHelmet'
import Icons from 'components/icons/Icons'

import useTheme from 'theme/useTheme'

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'

interface File {
  url: string
  httpHeaders: { [key: string]: string }
}

interface Props {
  pdfUrl: string
  pdfTitle?: string
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

const PDFScreen = ({ pdfUrl, pdfTitle }: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()
  const navigation = useNavigation()

  const user = useReducer(userStore.store, (s) => s.user)

  const [token, setToken] = React.useState<string>()
  const [error, setError] = React.useState(false)
  const [numberOfPages, setNumberOfPages] = React.useState(0)
  const [file, setFile] = React.useState<File>()
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions())

  React.useEffect(() => {
    if (!!user) {
      assureToken(user.type)
        .then(setToken)
        .catch(() => setError(true))
    }
  }, [user])

  React.useEffect(() => {
    if (!!token) {
      setFile({
        url: pdfUrl.startsWith('/') ? pdfUrl : `/${pdfUrl}`,
        httpHeaders: {
          Authorization: `Bearer ${token}`,
        },
      })
    }
  }, [token])

  React.useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleResize = () => {
    setWindowDimensions(getWindowDimensions())
  }

  const renderLoading = () => (
    <CenteredView>
      <ErrorView>{i18n.t('common.loading')}</ErrorView>
    </CenteredView>
  )

  return (
    <>
      {pdfTitle && <TitleHelmet title={pdfTitle} />}

      <MainContainer>
        <IconContainer
          onClick={navigation.goBack}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              navigation.goBack()
            }
          }}>
          <Icons name="cross" color={theme.colors.darkGrey} size={36} />
        </IconContainer>
        {error || !token || !file ? (
          <CenteredView>
            <ErrorView>{i18n.t('screens.pdf.error')}</ErrorView>
          </CenteredView>
        ) : (
          <PDFContainer>
            <Document
              file={file}
              onLoadSuccess={(event: any) => setNumberOfPages(event._pdfInfo.numPages)}
              loading={renderLoading}
              onLoadError={() => setError(true)}>
              {Array.from(new Array(numberOfPages), (el, index) => (
                <Page
                  loading={() => (index === 0 ? renderLoading() : undefined)}
                  width={
                    windowDimensions.width <= windowSizeBreakpoints.phone
                      ? windowDimensions.width - 50
                      : windowDimensions.width - 350
                  }
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                />
              ))}
            </Document>
          </PDFContainer>
        )}
      </MainContainer>
    </>
  )
}

export default PDFScreen

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
`

const CenteredView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 33px;
  color: ${(props) => props.theme.colors.middleGrey};
`

const ErrorView = styled('p')`
  ${(props) => props.theme.fonts.body};
  font-size: 20px;
`

const PDFContainer = styled.div`
  margin: 4%;
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    margin: 40px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin: 20px;
  }
`

const IconContainer = styled('div')`
  margin-top: 32px;
  margin-right: 32px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  :focus {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
