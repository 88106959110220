import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'
import { SubByMainCategory } from 'intervention/modal/RequestTypeSelector'

import Icons from 'components/icons/Icons'
import Modal from 'components/modal/Modal'

interface Props {
  category: SubByMainCategory
  isLast: boolean
  setSelectedCategory: (c: CategoryV6) => void
}

const CategorySelector = ({ category, isLast, setSelectedCategory }: Props) => {
  const [Theme] = useTheme()
  const [subCategoriesVisible, setSubCategoriesVisible] = React.useState(false)

  const subCategories = category.categories.sort((a, b) => a.subcategoryName.localeCompare(b.subcategoryName))

  return (
    <MainContainer>
      <MainCategoryContainer onClick={() => setSubCategoriesVisible((v) => !v)}>
        <MainCategory>{category.categoryName}</MainCategory>
        <Icons
          name={subCategoriesVisible ? 'chevron_bottom' : 'chevron_right'}
          size={20}
          color={Theme.colors.middleGrey}
        />
      </MainCategoryContainer>

      <SubCategoriesContainer visible={subCategoriesVisible}>
        {subCategories.map((c) => (
          <SubCategoryContainer key={c.categoryId}>
            <IconContainer>
              {c.editorialPageIds.length > 0 && <Icons name="text" size={20} color={Theme.colors.blue} />}
            </IconContainer>
            <SubCategoryName
              onClick={() => {
                setSelectedCategory(c)
                Modal.close()
              }}>
              {c.subcategoryName}
            </SubCategoryName>
          </SubCategoryContainer>
        ))}
      </SubCategoriesContainer>
      {!isLast && <BlueLineBreak role="presentation" />}
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('li')`
  list-style: none;
  display: flex;
  flex: 1;
  flex-direction: column;
`
const MainCategoryContainer = styled('button')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.white};
  border: 0px;
`
const SubCategoriesContainer = styled('ul')<{ visible: boolean }>`
  margin: 15px 0px;
  padding: 0px;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
`

const SubCategoryContainer = styled('li')`
  display: flex;
  align-items: center;
  padding: 0px;
  margin: 0px;
`

const IconContainer = styled('div')`
  width: 30px;
  flex-shrink: 0;
`

// TEXTES

const MainCategory = styled('h2')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
`
const SubCategoryName = styled('button')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
  padding: 5px 0px;
  margin: 5px 0px;
  &:hover {
    cursor: pointer;
  }
`

// AUTRES

const BlueLineBreak = styled('div')`
  width: 60px;
  height: 3px;
  background-color: ${(props) => props.theme.colors.blue};
  border-radius: 2px;
  margin: 15px 0px;
`

export default CategorySelector
