import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'
import useI18n from 'i18n/useI18n'

import engieDirectoryApi from './engie/apiEngieDirectory'

import Logger from 'utils/Logger'
import { windowSizeBreakpoints } from 'utils/breakpoints'

import useReducer from 'store/useReducer'
import * as contactStore from './contactStore'
import * as userStore from 'store/user/user'

import UserPicture from 'authent365/utils/UserPicture'
import Icons from 'components/icons/Icons'

interface Props {
  contact: DirectoryUser
  isLast: boolean
  onSelectContact: (contact: DirectoryUser) => void
  updateFavorites?: (favorites: FavoriteIds[]) => void
}

const ContactInfosGraph = ({ contact, isLast, onSelectContact, updateFavorites }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const favorites = useReducer(contactStore.store, (s) => s.favorites)
  const isFavorite = !!favorites.find((favorite) => favorite.idGraph === contact.id)
  const user = useReducer(userStore.store, (s) => s.user)

  const { givenName, surname, mail: contactEmail, id: contactId } = contact

  const modifyFavorite = () => {
    if (isFavorite) {
      const updatedFav = favorites.filter((f) => f.idGraph !== contact.id)
      contactStore.actions.setFav(updatedFav)
      if (updateFavorites) {
        updateFavorites(updatedFav)
      }
    } else if (user) {
      engieDirectoryApi
        .getIdFromEmail(user.type, contact.mail)
        .then((res) =>
          contactStore.actions.setFav([
            ...favorites,
            {
              idGraph: contact.id,
              engieUserId: res.engieUserId && res.engieUserId !== '' ? res.engieUserId : undefined,
            },
          ])
        )
        .catch((err) => {
          contactStore.actions.setFav([
            ...favorites,
            {
              idGraph: contact.id,
            },
          ])
          Logger.error(err)
        })
    }
  }

  const onClickContact = () => onSelectContact(contact)

  return (
    <MainContainer>
      <ContactContainer onClick={onClickContact}>
        <ContactLeftContainer>
          <div>
            <UserPicture
              size="smaller"
              color={Theme.colors.mediumDarkGrey}
              textColor={Theme.colors.darken}
              userFirstName={givenName}
              userLastName={surname}
              userDisplayName={contactEmail}
              userId={contactId}
            />
          </div>
          <ContactInfosContainer>
            <ContactName>
              {contact.surname} {contact.givenName}
            </ContactName>
            <ContactMail>{contact.mail}</ContactMail>
          </ContactInfosContainer>
        </ContactLeftContainer>
      </ContactContainer>

      <HeartIconContainer
        isDisabled={contact.profilePhotoUrl === 'VISITOR'}
        disabled={contact.profilePhotoUrl === 'VISITOR'}
        onClick={modifyFavorite}
        aria-label={i18n.t(
          `label.ariaLabel.directory.${isFavorite ? 'removePersonFromFavorites' : 'addPersonToFavorites'}`
        )}>
        {contact.profilePhotoUrl === 'VISITOR' ? (
          <VisitorContainer>
            <VisitorText>{i18n.t('screens.guest.form.visitor')}</VisitorText>
          </VisitorContainer>
        ) : (
          <Icons name={isFavorite ? 'heart-filled' : 'heart'} size={26} color={Theme.colors.blue} />
        )}
      </HeartIconContainer>

      {!isLast && <BlueLineBreak />}
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  position: relative;
`
const ContactContainer = styled('button')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3px 10px 3px 10px;
  padding-right: 14px;
  &:hover {
    background-color: ${(props) => props.theme.colors.lightGrey};
  }
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin: 3px 0px;
    padding-right: 0px;
  }
`
const ContactInfosContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 197px;
  word-wrap: break-word;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 100%;
  }
`
const ContactLeftContainer = styled('div')`
  display: flex;
  flex-direction: row;
  padding: 12px 14px 12px 14px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 12px 0px;
  }
`
const HeartIconContainer = styled('button')<{ isDisabled?: boolean }>`
  display: flex;
  position: absolute;
  top: calc(50% - 13px);
  right: 24px;
  ${(props) => (props.isDisabled ? 'cursor: auto;' : 'cursor: pointer;')}
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  background: transparent;
  padding: 0px;
  border: 0;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-left: 0px;
  }
`
// TEXTES

const ContactName = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
`
const ContactMail = styled('p')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
`

// AUTRES

const BlueLineBreak = styled('div')`
  width: 50px;
  height: 3px;
  background-color: ${(props) => props.theme.colors.blue};
  border-radius: 2px;
  margin-left: 24px;
`

const VisitorContainer = styled('div')`
  width: 80px;
  display: flex;
  background-color: ${(props) => props.theme.colors.mediumDarkGrey};
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 5px;
`

const VisitorText = styled('p')`
  margin: 0;
  ${(props) => props.theme.fonts.label};
`

export default ContactInfosGraph
