import * as React from 'react'
import styled from 'theme/styled-components'
import Theme from 'theme/Theme'

import useI18n from 'i18n/useI18n'
import { windowSizeBreakpoints } from 'utils/breakpoints'

import Button from 'components/button/Button'
import Icons from 'components/icons/Icons'
import { FormTextInput } from 'components/form/InterventionFormItems'
import Modal from 'components/modal/Modal'

interface Props {
  required: boolean
  setNewDescription: (newDescription: string) => void
  goBack?: () => void
}

const DescriptionModal = ({ required, setNewDescription, goBack }: Props) => {
  const i18n = useI18n()

  const [description, setDescription] = React.useState('')

  return (
    <MainContainer>
      <TitleContainer>
        {!!goBack && (
          <CrossContainer
            onClick={() => {
              goBack()
              Modal.close()
            }}>
            <Icons name="chevron_left" size={25} color={Theme.colors.blue} />
          </CrossContainer>
        )}
        <Title>{i18n.t('screens.incident.details.description')}</Title>
        <CrossContainer onClick={Modal.close} aria-label={i18n.t('label.ariaLabel.windowCrossIcon')}>
          <Icons name="cross" size={25} color={Theme.colors.blue} />
        </CrossContainer>
      </TitleContainer>

      <ContentContainer>
        <FormTextInput
          id="interventionDescription"
          name="description"
          onChange={(newText) => setDescription(newText.target.value)}
          title=""
          value={description}
          placeholder={i18n.t('screens.incident.form.placeholder.descriptionPlaceholder')}
          titleAriaLabel={i18n.t('screens.incident.form.placeholder.descriptionPlaceholder')}
        />
        <ButtonContainer>
          <Button
            noFlex
            type="submit"
            label={i18n.t('common.validate')}
            verticalPadding={14}
            horizontalPadding={100}
            font={Theme.fonts.h3Bold}
            onClick={() => setNewDescription(description)}
            disabled={required && !description}
          />
        </ButtonContainer>
      </ContentContainer>
    </MainContainer>
  )
}

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  max-height: 85vh;
  width: 444px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 90vw;
  }
`
const TitleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 20px 30px 10px;
  }
`
const CrossContainer = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`

const Title = styled('h1')`
  display: flex;
  flex: 1;
  margin: 0px;
  ${(props) => props.theme.fonts.title};
  overflow-wrap: anywhere;
`

const ButtonContainer = styled('div')`
  display: flex;
  margin: 40px 0px;
`

const ContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export default DescriptionModal
