import * as React from 'react'
import ReactDOM from 'react-dom'

import styled from 'theme/styled-components'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import Provider from 'theme/Provider'
import Icons from '../icons/Icons'
import useTheme from 'theme/useTheme'

import FocusLock from 'react-focus-lock'

const ANIM_DELAY = 200

interface Props {
  imageURL: string
}

const ZoomImage = ({ imageURL }: Props) => {
  const [opened, setOpened] = React.useState(false)
  const [theme] = useTheme()

  const fadeout = () => {
    setOpened(false)
    setTimeout(ModalFunction.close, ANIM_DELAY)
  }

  const onKeyDown: React.KeyboardEventHandler = (evt) => {
    if (evt.key === 'Escape') {
      ModalFunction.close()
    }
  }

  React.useEffect(() => {
    setOpened(true)

    window.onpopstate = ModalFunction.close
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  return (
    <ModalContainer onKeyDownCapture={onKeyDown}>
      <FocusLock autoFocus={true}>
        <BlackScreen opened={opened} onClick={fadeout} />
        <FilterModal opened={opened}>
          <Image src={imageURL} />
          <CrossContainer onClick={ModalFunction.close}>
            <Icons name="cross" color={theme.colors.white} size={40} />
          </CrossContainer>
        </FilterModal>
      </FocusLock>
    </ModalContainer>
  )
}

let modalRoot: HTMLElement | null

let elementToFocusOnModalClose: any | null

const ModalFunction = {
  open: (imageURL: string) => {
    elementToFocusOnModalClose = document.activeElement
    modalRoot = document.getElementById('modal_root')
    if (modalRoot) {
      ReactDOM.render(
        <Provider>
          <ZoomImage imageURL={imageURL} />
        </Provider>,
        modalRoot
      )
    }
  },
  close: () => {
    if (modalRoot) {
      ReactDOM.unmountComponentAtNode(modalRoot)
    }
    if (elementToFocusOnModalClose) {
      elementToFocusOnModalClose.focus()
      elementToFocusOnModalClose = null
    }
  },
}

export default ModalFunction

const FilterModal = styled.div<{ opened: boolean }>`
  transition: opacity 0.5s;
  opacity: ${(props) => (props.opened ? 1 : 0)};
`

const BlackScreen = styled.div<{ opened: boolean }>`
  transition: opacity 0.5s;
  opacity: ${(props) => (props.opened ? 0.7 : 0)};
  background-color: ${(props) => props.theme.colors.black};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
`
const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
`
const Image = styled('img')`
  width: 60vw;
  height: auto;
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    width: 70vw;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 90vw;
  }
`

const CrossContainer = styled.button`
  position: absolute;
  top: 60px;
  right: 60px;
  padding: 10px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    right: 40px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    right: 20px;
  }
`
